import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { Space, Card, Typography, Skeleton, Empty } from 'antd';
import { MessageNotification01Icon } from "hugeicons-react";
import FeedbackCard from "./cards/FeedbackCard";
import './Feedback.css';

const { Title } = Typography;

class Feedback extends React.Component {
	state = {
		profile: this.props.profile,
		feedback: this.props.feedback,
		loading: this.props.loading,
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps !== this.props) {
			this.setState({
				profile: nextProps.profile,
				feedback: nextProps.feedback,
				loading: nextProps.loading,
			});
		}
	}

	renderFeedbacks() {
		const { feedback, loading } = this.state;

		if (loading) {
			return (
				<div className="nafasi-feedback__loading">
					{[...Array(3)].map((_, index) => (
						<Card key={index} className="nafasi-feedback__skeleton">
							<Skeleton active avatar={false} paragraph={{ rows: 3 }} />
						</Card>
					))}
				</div>
			);
		}

		const feedbackArray = _.orderBy(feedback, ['timestamp'], ['desc']);

		if (feedbackArray.length === 0) {
			return (
				<Card className="nafasi-feedback__empty">
					<Empty
						description={
							<span className="nafasi-feedback__empty-text">
								No feedback found
							</span>
						}
					/>
				</Card>
			);
		}

		return (
			<div className="nafasi-feedback__content">
				<FeedbackCard items={feedbackArray} />
			</div>
		);
	}

	checkUser() {
		const { profile } = this.state;

		if (!(_.isEmpty(profile)) && profile.role === "superAdmin") {
			return (
				<div className="nafasi-feedback__container">
					<div className="nafasi-feedback">
						<Space direction="vertical" style={{ width: '100%' }} size="middle">
							<Card size="small" className="nafasi-feedback__header-card">
								<div className="nafasi-feedback__header">
									<MessageNotification01Icon size={24} className="nafasi-feedback__icon" />
									<Title level={4} className="nafasi-feedback__title">User Feedback</Title>
								</div>
							</Card>

							{this.renderFeedbacks()}
						</Space>
					</div>
				</div>
			);
		}
		return null;
	}

	render() {
		return this.checkUser();
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile.profile,
	feedback: state.settings.feedback,
	loading: state.settings.loading
});

export default connect(mapStateToProps)(Feedback);