import React from 'react';
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Tooltip } from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import moment from "moment";
import CustomHeaderCard from '../../../search/cards/CustomHeaderCard';
import DescriptionModal from './DescriptionModal';
import RecruiterDetailView from './RecruiterDetailView';
import './RecruitersRequestsCard.css';

class RecruitersRequestsCard extends React.Component {
	state = {
		items: this.props.items,
		selectedRecruiter: null,
		detailViewVisible: false
	};

	handleRowClick = (record) => {
		this.setState({
			selectedRecruiter: record,
			detailViewVisible: true
		});
	};

	handleDetailViewClose = () => {
		this.setState({
			detailViewVisible: false
		});
	};

	render() {
		const { items, selectedRecruiter, detailViewVisible } = this.state;
		const processedResults = items.map(result => {
			const seconds = result.createdOn.seconds || result.createdOn._seconds;
			const time = moment.unix(seconds).format("DD/MM/YYYY | HH:mm");
			return { ...result, time };
		});

		const columns = [
			{
				title: "Company",
				dataIndex: 'companyName',
				key: 'companyName',
				ellipsis: true,
				width: 200,
				render: (_, record) => (
					<Tooltip title={record.companyName} placement="topLeft">
						<div className="nafasi-recruiter-request__company-name">
							{record.companyName}
						</div>
					</Tooltip>
				),
			},
			{
				title: "Location",
				dataIndex: 'location',
				key: 'location',
				ellipsis: true,
				width: 150,
				render: (_, record) => (
					<Tooltip title={`${record.companyLocation.region}, ${record.companyLocation.country}`} placement="topLeft">
						<div className="nafasi-recruiter-request__location">
							{record.companyLocation.region}
						</div>
					</Tooltip>
				),
			},
			{
				title: "Admin",
				dataIndex: 'adminName',
				key: 'adminName',
				ellipsis: true,
				width: 140,
				render: (_, record) => (
					<Tooltip title={`${record.firstName} ${record.lastName}`} placement="topLeft">
						<div className="nafasi-recruiter-request__admin-name">
							{record.firstName} {record.lastName}
						</div>
					</Tooltip>
				),
			},
			{
				title: "Email",
				dataIndex: 'email',
				key: 'email',
				ellipsis: true,
				width: 200,
				render: (_, record) => (
					<Tooltip title={record.email} placement="topLeft">
						<div className="nafasi-recruiter-request__email">
							{record.email}
						</div>
					</Tooltip>
				),
			},
			{
				title: "Phone",
				dataIndex: 'phone',
				key: 'phone',
				ellipsis: true,
				width: 130,
				render: (_, record) => (
					<Tooltip title={record.phone} placement="topLeft">
						<div className="nafasi-recruiter-request__phone">
							{record.phone}
						</div>
					</Tooltip>
				),
			},
			{
				title: "Verified",
				dataIndex: 'verified',
				key: 'verified',
				align: 'center',
				width: 80,
				render: (_, record) => (
					<div className="nafasi-recruiter-request__verified">
						{record.verified ? 
							<CheckCircleFilled style={{ color: '#52c41a' }} /> : 
							<CloseCircleFilled style={{ color: '#ff4d4f' }} />
						}
					</div>
				),
			},
			{
				title: "Paid",
				dataIndex: 'paid',
				key: 'paid',
				align: 'center',
				width: 80,
				render: (_, record) => (
					<div className="nafasi-recruiter-request__paid">
						{record.paid ? 
							<CheckCircleFilled style={{ color: '#52c41a' }} /> : 
							<CloseCircleFilled style={{ color: '#ff4d4f' }} />
						}
					</div>
				),
			},
			{
				title: "Created",
				dataIndex: 'time',
				key: 'time',
				ellipsis: true,
				width: 105,
				render: (_, record) => (
					<Tooltip title={record.time} placement="topLeft">
						<div className="nafasi-recruiter-request__time">
							{record.time}
						</div>
					</Tooltip>
				),
			},
			{
				title: "View",
				dataIndex: 'view',
				key: 'view',
				align: 'center',
				width: 60,
				render: (_, record) => (
					<div className="nafasi-recruiter-request__view">
						<DescriptionModal record={record} />
					</div>
				),
			}
		];

		return (
			<div className="nafasi-recruiter-request">
				<Table 
					dataSource={processedResults} 
					columns={columns} 
					rowKey="id"
					className="nafasi-recruiter-request__table"
					scroll={{ x: 900 }}
					onRow={(record) => ({
						onClick: () => this.handleRowClick(record),
						style: { cursor: 'pointer' }
					})}
					pagination={{
						className: "nafasi-recruiter-request__pagination",
						pageSize: 30,
						showSizeChanger: false
					}}
				/>
				
				<RecruiterDetailView
					recruiter={selectedRecruiter}
					visible={detailViewVisible}
					onClose={this.handleDetailViewClose}
				/>
			</div>
		);
	}
}

export default RecruitersRequestsCard;