import React, { Component } from 'react';
import { Modal, Button, Result } from 'antd';
import { connect } from "react-redux";
import _ from "lodash";
import { LoadingOutlined, SafetyOutlined, CloseCircleOutlined } from '@ant-design/icons';

// action imports
import { fetchPaymentStatus, subscriptionFieldUpdated } from "../../../../actions/SubscriptionActions";

//common imports
import { Spinner } from "../../../common/Spinner";

class PaymentModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			flwUrl: this.props.flwUrl,
			systemInfo: this.props.systemInfo,
			visible: this.props.visible,
			loading: true,
			error: null,
			paymentStatus: 'loading' // loading, processing, success, error
		};

		this.webViewRef = React.createRef();
	}

	componentDidMount() {
		const { flwUrl } = this.state;

		try {
			this.props.subscriptionFieldUpdated({ prop: "flwUrl", value: flwUrl });
		} catch (e) {
			this.setState({ 
				error: 'Failed to initialize payment gateway',
				paymentStatus: 'error'
			});
			console.error(e);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps !== this.props) {
			this.setState({
				flwUrl: nextProps.flwUrl,
				systemInfo: nextProps.systemInfo,
				visible: nextProps.visible,
			});
		}
	}

	onNavigationStateChange = (state) => {
		const { url } = state;
		const { systemInfo } = this.state;

		if (!url) return;

		if (!(_.isEmpty(systemInfo))) {
			const callbackUrl = systemInfo.foreignCallback;

			if (url === callbackUrl) {
				this.setState({ paymentStatus: 'success' });
			}
		}

		if (url === 'https://standard.paystack.co/close') {
			this.handleCloseModal();
		}
	};

	componentWillUnmount() {
		this.props.subscriptionFieldUpdated({ prop: "payment", value: {} });
	}

	handleCloseModal = () => {
		this.setState({ visible: false });
		this.props.subscriptionFieldUpdated({ prop: "flwUrl", value: '' });
	};

	handleLoadEnd = () => {
		this.setState({ loading: false, paymentStatus: 'processing' });

		const iframe = this.webViewRef.current;
		if (iframe && iframe.contentWindow) {
			iframe.contentWindow.postMessage('IframeLoaded', '*');
		}
	};

	handleIframeError = (error) => {
		console.error('Iframe error:', error);
		this.setState({ 
			error: 'Failed to load payment gateway',
			paymentStatus: 'error'
		});
	};

	renderModalContent() {
		const { loading, error, paymentStatus, flwUrl } = this.state;

		if (error) {
			return (
				<Result
					status="error"
					title="Payment Gateway Error"
					subTitle={error}
					extra={[
						<Button type="primary" key="retry" onClick={() => window.location.reload()}>
							Try Again
						</Button>,
						<Button key="cancel" onClick={this.handleCloseModal}>
							Cancel
						</Button>,
					]}
				/>
			);
		}

		if (paymentStatus === 'success') {
			return (
				<Result
					status="success"
					title="Payment Successful!"
					subTitle="Your subscription has been activated. You can now access all features."
					extra={[
						<Button type="primary" key="console" onClick={this.handleCloseModal}>
							Continue
						</Button>
					]}
				/>
			);
		}

		return (
			<div className="nafasi-payment-modal">
				{loading && (
					<div className="nafasi-payment-modal__loading">
						<LoadingOutlined style={{ fontSize: 24 }} spin />
						<p>Loading secure payment gateway...</p>
					</div>
				)}
				
				<div className="nafasi-payment-modal__security">
					<SafetyOutlined style={{ color: '#52c41a' }} />
					<span>Secure Payment Gateway</span>
				</div>

				<iframe
					ref={this.webViewRef}
					src={flwUrl}
					onLoad={this.handleLoadEnd}
					onError={this.handleIframeError}
					style={{ 
						width: '100%', 
						height: '600px',
						border: 'none',
						borderRadius: '8px',
						opacity: loading ? 0 : 1,
						transition: 'opacity 0.3s ease'
					}}
					title="Secure Payment Gateway"
					allow="payment"
				/>

				{paymentStatus === 'processing' && (
					<div className="nafasi-payment-modal__footer">
						<p>Please complete your payment in the secure gateway above.</p>
						<Button onClick={this.handleCloseModal}>Cancel Payment</Button>
					</div>
				)}
			</div>
		);
	}

	render() {
		const { visible } = this.state;

		return (
			<Modal
				title={
					<div className="nafasi-payment-modal__header">
						<span>Complete Your Payment</span>
						{this.state.paymentStatus === 'processing' && (
							<div className="nafasi-payment-modal__header-status">
								<Spinner size="small" />
								<span>Processing payment...</span>
							</div>
						)}
					</div>
				}
				open={visible}
				onCancel={this.handleCloseModal}
				footer={null}
				destroyOnClose
				width={800}
				className="nafasi-payment-modal__container"
				maskClosable={false}
				style={{ top: 20 }}
			>
				{this.renderModalContent()}
			</Modal>
		);
	}
}

const mapStateToProps = (state) => {
	const { flwUrl } = state.subscription;
	const { systemInfo } = state.system;

	return {
		flwUrl,
		systemInfo
	}
}

export default connect(mapStateToProps, { fetchPaymentStatus, subscriptionFieldUpdated })(PaymentModal);