import React from "react";
import {
    isMobile,
    isAndroid,
    isIOS,
} from "react-device-detect";
import Nafasi from "../../assets/logos/taglineLogo.png";
import { message } from 'antd';

const Download = () => {
  React.useEffect(() => {
    handleDownload();
  }, []);

  const handleDownload = () => {
    if (isMobile) {
      if (isIOS) {
        window.location.href = "https://apps.apple.com/tz/app/nafasi/id6450264936";
      }
      if (isAndroid) {
        window.location.href = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.nafasi.io";
      }
    } else {
      message.info("View Download Link From Mobile a Phone");
    }
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black flex flex-col items-center justify-center p-4">
      {/* Logo Container with Animation */}
      <div className="animate-fade-in-down mb-8">
        <img
          src={Nafasi}
          alt="Nafasi Logo"
          className="w-32 h-32 object-contai    n"
        />
      </div>

      {/* Main Content */}
      <div className="text-center animate-fade-in">
        <h1 className="text-white text-4xl font-bold mb-4 tracking-tight">
          Download Nafasi
        </h1>
        <p className="text-gray-400 text-lg mb-8 max-w-md">
          Land your Job
        </p>

        {/* Loading Animation */}
        <div className="flex justify-center items-center mb-8">
          <div className="animate-pulse-ring">
            <div className="w-16 h-16 rounded-full border-4 border-t-blue-500 border-blue-500/30 animate-spin" />
          </div>
        </div>

        {/* Store Badges */}
        <div className="flex flex-col sm:flex-row gap-4 justify-center animate-fade-in-up">
          <button onClick={handleDownload} className="bg-white/10 hover:bg-white/20 text-white rounded-xl px-8 py-4 backdrop-blur-sm transition-all duration-300 flex items-center justify-center gap-2">
            <span className="text-2xl">↓</span>
            App Store
          </button>
          <button onClick={handleDownload} className="bg-white/10 hover:bg-white/20 text-white rounded-xl px-8 py-4 backdrop-blur-sm transition-all duration-300 flex items-center justify-center gap-2">
            <span className="text-2xl">↓</span>
            Play Store
          </button>
        </div>
      </div>

      <style>{`
        .animate-fade-in {
          animation: fadeIn 1s ease-out;
        }
        
        .animate-fade-in-down {
          animation: fadeInDown 1s ease-out;
        }
        
        .animate-fade-in-up {
          animation: fadeInUp 1s ease-out;
        }
        
        .animate-pulse-ring {
          animation: pulseRing 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
        }
        
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        
        @keyframes fadeInDown {
          from {
            opacity: 0;
            transform: translateY(-20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        
        @keyframes fadeInUp {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }
        
        @keyframes pulseRing {
          0% {
            transform: scale(0.8);
            opacity: 0.5;
          }
          50% {
            transform: scale(1);
            opacity: 1;
          }
          100% {
            transform: scale(0.8);
            opacity: 0.5;
          }
        }
      `}</style>
    </div>
  );
};

export default Download;