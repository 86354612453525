import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDJugXJ593M37xMb_6FuxUPEZ-boG0Nsow",
    authDomain: "nafasiio.firebaseapp.com",
    projectId: "nafasiio",
    storageBucket: "nafasiio.appspot.com",
    messagingSenderId: "849321740104",
    appId: "1:849321740104:web:f898d624aa65370591d57d",
    measurementId: "G-LN0267W1TL"
};

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export default firebase; 