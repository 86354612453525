import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { Space, Card, Typography, Skeleton, Empty } from 'antd';
import { AccountSetting03Icon } from "hugeicons-react";
import AssessmentCard from "./cards/AssessmentCard";
import './Assessment.css';

const { Title } = Typography;

class Assessment extends React.Component {
	state = {
		profile: this.props.profile,
		assessment: this.props.assessment,
		loading: this.props.loading,
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps !== this.props) {
			this.setState({
				profile: nextProps.profile,
				assessment: nextProps.assessment,
				loading: nextProps.loading,
			});
		}
	}

	renderAssessment() {
		const { assessment, loading } = this.state;

		if (loading) {
			return (
				<Card className="nafasi-assessment__content">
					<div className="nafasi-assessment__loading">
						{[...Array(3)].map((_, index) => (
							<Card key={index} className="nafasi-assessment__skeleton">
								<Skeleton active avatar paragraph={{ rows: 3 }} />
							</Card>
						))}
					</div>
				</Card>
			);
		}

		const assessmentArray = _.orderBy(assessment, ['timestamp'], ['desc']);

		if (assessmentArray.length === 0) {
			return (
				<Card className="nafasi-assessment__empty">
					<Empty
						description={
							<span className="nafasi-assessment__empty-text">
								No assessments found
							</span>
						}
					/>
				</Card>
			);
		}

		return (
			<Card className="nafasi-assessment__content">
				<AssessmentCard items={assessmentArray} />
			</Card>
		);
	}

	checkUser() {
		const { profile } = this.state;

		if (!(_.isEmpty(profile)) && profile.role === "superAdmin") {
			return (
				<div className="nafasi-assessment">
					<Space direction="vertical" style={{ width: '100%' }} size="middle">
						<Card size="small" className="nafasi-assessment__header-card">
							<div className="nafasi-assessment__header">
								<AccountSetting03Icon size={24} className="nafasi-assessment__icon" />
								<Title level={4} className="nafasi-assessment__title">Assessment</Title>
							</div>
						</Card>

						{this.renderAssessment()}
					</Space>
				</div>
			);
		}
		return null;
	}

	render() {
		return (
			<div className="nafasi-assessment__container">
				{this.checkUser()}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile.profile,
	assessment: state.settings.assessment,
	loading: state.settings.loading
});

export default connect(mapStateToProps)(Assessment);