import React from "react";
import { connect } from "react-redux";
import {useNavigate} from "react-router-dom";
import { Empty } from 'antd';
import "./ShortlistCandidates.css";

//actions import
import { fetchSelectedSeeker } from "../../../actions/PostsActions";

//components import
import ShortlistCard from "../cards/ShortlistCard";

class ShortlistCandidatesBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shortlist: this.props.shortlist,
            profile: this.props.profile,
        };

        this.renderShortlist = this.renderShortlist.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                shortlist: nextProps.shortlist,
                profile: nextProps.profile,
            });
        }
    }

    renderShortlist() {
        const { shortlist, profile } = this.state;

        if (shortlist.length !== 0) {
            return (
                <div className="nafasi-shortlist-container">
                    <ShortlistCard shortlist={shortlist} profile={profile}/>
                </div>
            );
        } else {
            return (
                <div className="nafasi-shortlist-empty">
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={
                            <span className="nafasi-shortlist-empty-text">
                                You currently have no shortlisted candidates
                            </span>
                        }
                    />
                </div>
            );
        }
    }

    render() {
        return (
            <div className="nafasi-shortlist-wrapper">
                {this.renderShortlist()}
            </div>
        );
    }
}

const mapStateTopProps = state => {
    const { shortlist } = state.posts;
    const { profile } = state.profile;

    return {
        shortlist,
        profile
    }
};

// Wrapper functional component to use hooks
function ShortlistCandidates(props) {
    const navigate = useNavigate();
    return <ShortlistCandidatesBase {...props} navigate={navigate} />;
}

export default connect(mapStateTopProps, {fetchSelectedSeeker})(ShortlistCandidates);
