import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
import { searchFieldChanged } from '../../../actions/SearchActions';

const { Option } = Select;

class ExperienceFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            experienceID: null,
            experienceYears: null,
            isExperienceYearsEnabled: false,
        };
    }

    componentDidMount() {
        // Initialize state from props
        this.setState({
            experienceID: this.props.experienceID,
            experienceYears: this.props.experienceYears,
            isExperienceYearsEnabled: !!this.props.experienceID
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.experienceID !== this.props.experienceID || 
            prevProps.experienceYears !== this.props.experienceYears) {
            this.setState({
                experienceID: this.props.experienceID,
                experienceYears: this.props.experienceYears,
                isExperienceYearsEnabled: !!this.props.experienceID
            });
        }
    }

    handleExperienceChange = (value) => {
        if (value) {
            this.setState({ isExperienceYearsEnabled: true });
            this.props.searchFieldChanged({ prop: "experienceID", value });
        } else {
            this.setState({ isExperienceYearsEnabled: false });
            this.props.searchFieldChanged({ prop: "experienceID", value: null });
            this.props.searchFieldChanged({ prop: "experienceYears", value: null });
        }
    };

    handleExperienceYearsChange = (value) => {
        this.props.searchFieldChanged({ prop: "experienceYears", value: value || null });
    };

    render() {
        const { experienceID, experienceYears, isExperienceYearsEnabled } = this.state;

        return (
            <div className="filter-component">
                <label style={{ fontSize: "13px", fontFamily: "ramblaBold" }}>Minimum Experience</label>
                <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    <Select
                        placeholder={"Position"}
                        value={experienceID}
                        allowClear
                        style={{ width: "100%" }}
                        onChange={this.handleExperienceChange}
                    >
                        <Option value="managerial">Managerial</Option>
                        <Option value="senior">Senior</Option>
                        <Option value="lead">Lead</Option>
                        <Option value="junior">Junior</Option>
                    </Select>

                    <Select
                        placeholder={"Minimum Years"}
                        value={experienceYears}
                        allowClear
                        style={{ width: "100%" }}
                        onChange={this.handleExperienceYearsChange}
                        disabled={!isExperienceYearsEnabled}
                    >
                        <Option value={1}>1 year</Option>
                        <Option value={2}>2 years</Option>
                        <Option value={5}>5 years</Option>
                        <Option value={10}>10 years</Option>
                    </Select>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { experienceID, experienceYears } = state.search;
    return {
        experienceID,
        experienceYears
    };
};

export default connect(mapStateToProps, { searchFieldChanged })(ExperienceFilter);
