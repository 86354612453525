import React, { useState } from "react";
import { Modal } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Truncated.css';

const TruncatedText = ({ text, maxLength }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const formatIndustries = (text) => {
        if (!text) return 'No industries listed';
        
        // Handle both array and string inputs and clean up the data
        const industries = Array.isArray(text) 
            ? text 
            : text.split(',')
                .map(industry => industry.trim())
                .filter(industry => industry.length > 0);

        // Remove any trailing commas from individual industries
        const cleanIndustries = industries.map(industry => 
            industry.replace(/,+$/, '').trim()
        ).filter(Boolean); // Remove empty strings
        
        return cleanIndustries.map((industry, index) => (
            <div key={index} className="industry-item">
                <FontAwesomeIcon icon="building" className="mr-2" />
                {industry}
            </div>
        ));
    };

    const getTruncatedText = (text) => {
        if (!text) return 'No industries listed';
        
        // Handle both array and string inputs and clean up the data
        const industries = Array.isArray(text) 
            ? text 
            : text.split(',')
                .map(industry => industry.trim())
                .filter(industry => industry.length > 0);

        // Remove any trailing commas and join with proper formatting
        const fullText = industries
            .map(industry => industry.replace(/,+$/, '').trim())
            .filter(Boolean)
            .join(', ');
        
        if (fullText.length > maxLength) {
            return `${fullText.substring(0, maxLength)}...`;
        }
        return fullText;
    };

    return (
        <>
            <div 
                className="truncated-link"
                onClick={() => setIsModalVisible(true)}
                title="Click to view all industries"
            >
                <FontAwesomeIcon icon="building" className="mr-2" />
                <p style={{ fontFamily: "ramblaRegular" }}>{getTruncatedText(text)}</p>
                {text && text.length > maxLength && (
                    <span className="more-indicator">more</span>
                )}
            </div>

            <Modal
                title={
                    <div className="modal-title">
                        <FontAwesomeIcon icon="building" className="mr-2" />
                        Industries
                    </div>
                }
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                width={500}
                className="industries-modal"
            >
                <div className="industries-list">
                    {formatIndustries(text)}
                </div>
            </Modal>
        </>
    );
};

export default TruncatedText;