import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { Table, Switch, Popover } from 'antd';
import { MinusOutlined } from '@ant-design/icons';
import arraySort from "array-sort";
import moment from "moment";
import { updateGraphicSent } from '../../../../actions/PostsActions';
import DescriptionModal from './DescriptionModal';
import './AdminJobPosts.css';

class AdminJobPosts extends React.Component {
    state = {
        adminJobs: this.props.adminJobs,
        visible: false,
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.adminJobs !== this.props.adminJobs) {
            this.setState({adminJobs: nextProps.adminJobs})
        }
    }

    handleSwitchChange = (isChecked, record) => {
        if (record.graphicSent) {
            this.props.updateGraphicSent({record, graphicSent: false});
        } else {
            this.props.updateGraphicSent({record, graphicSent: true});
        }
    }

    renderPosts() {
        const { adminJobs } = this.state;
        const sortedPosts = arraySort(adminJobs, "deadline", { reverse: true });

        const processedPosts = sortedPosts.map(post => {
            let seconds = post.deadline.seconds || post.deadline._seconds;
            let time = moment.unix(seconds).format('ddd, Do MMM YYYY');
            const formattedLocation = `${post.companyLocation.country}, ${post.companyLocation.region}`;
            return { ...post, formattedLocation, time };
        });

        const columns = [
            {
                title: 'Company',
                dataIndex: 'companyName',
                key: 'companyName',
                width: '12%',
                ellipsis: true,
                render: (text) => (
                    <Popover content={text} trigger="hover">
                        <div className="nafasi-job-posts__cell" title={text}>{text}</div>
                    </Popover>
                ),
            },
            {
                title: 'Title',
                dataIndex: 'postTitle',
                key: 'postTitle',
                width: '15%',
                ellipsis: true,
                render: (text) => (
                    <div className="nafasi-job-posts__cell" title={text}>{text}</div>
                ),
            },
            {
                title: 'Deadline',
                dataIndex: 'time',
                key: 'time',
                width: '12%',
                render: (text) => (
                    <div className="nafasi-job-posts__cell">{text}</div>
                ),
            },
            {
                title: 'Type',
                dataIndex: 'jobType',
                key: 'jobType',
                width: '8%',
                render: (text) => (
                    <div className="nafasi-job-posts__cell">{text}</div>
                ),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                width: '8%',
                align: 'center',
                render: (text) => {
                    const status = text.toLowerCase();
                    return (
                        <div className={`nafasi-job-posts__status nafasi-job-posts__status--${status}`}>
                            {text}
                        </div>
                    );
                },
            },
            {
                title: 'Location',
                dataIndex: 'formattedLocation',
                key: 'formattedLocation',
                width: '12%',
                ellipsis: true,
                render: (text) => (
                    <div className="nafasi-job-posts__cell" title={text}>{text}</div>
                ),
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                width: '7%',
                align: 'center',
                render: (_, record) => (
                    <div className="nafasi-job-posts__description">
                        <DescriptionModal record={record}/>
                    </div>
                ),
            },
            {
                title: 'Graphic',
                dataIndex: 'graphicSent',
                key: 'graphicSent',
                width: '6%',
                align: 'center',
                render: (_, record) => (
                    'graphicSent' in record ? (
                        <Switch 
                            checked={record.graphicSent} 
                            onChange={(e) => this.handleSwitchChange(e, record)}
                            className="nafasi-job-posts__switch"
                            size="small"
                        />
                    ) : (
                        <MinusOutlined style={{ color: '#bfbfbf' }} />
                    )
                ),
            },
        ];

        return (
            <div className="nafasi-job-posts">
                <Table 
                    dataSource={processedPosts} 
                    columns={columns} 
                    rowKey="id"
                    pagination={{ 
                        pageSize: 10,
                        showSizeChanger: true,
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
                    }}
                    bordered={false}
                    scroll={{ x: 800 }}
                />
            </div>
        );
    }

    render() {
        return this.renderPosts();
    }
}

const mapStateToProps = (state) => ({
    adminJobs: state.settings.adminJobs
});

export default connect(mapStateToProps, { updateGraphicSent })(AdminJobPosts);