import React, {useEffect, useState} from 'react';
import { Table, Button, Modal, message, Popconfirm } from 'antd';
import { connect, useDispatch, useSelector } from "react-redux";
import { Spinner } from '../../common/Spinner';
import TruncatedText from '../../search/modals/TruncatedText';
import TruncatedExperience from '../../search/modals/TruncatedExperience';
import {useNavigate} from "react-router-dom";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecurityScanFilled } from '@ant-design/icons';
import "./ShortlistCard.css";

import { deleteFromShortlist, requestCoverLetter } from "../../../actions/SearchActions";
import { postsFieldChanged, pushSeeker } from "../../../actions/PostsActions";
import SelectPost from '../subComponents/SelectPost';

const ShortlistCard = ({ shortlist, shortlistLoading, deleteFromShortlist, pushSeeker, profile, isToggled }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSeeker, setSelectedSeeker] = useState(null); 
    const [isCoverLetterModalVisible, setIsCoverLetterModalVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const [state, setState] = useState({
        post: "",
        profile:{}
    });

   //extract app state
   const postState = useSelector((state) => state.posts);
   const profileState = useSelector((state) => state.profile);

   useEffect(() => {
       try {
           setState(prevState => ({
               ...prevState,
               post: postState.post,
               profile:profileState.profile
           }));
       } catch (e) {
           console.error("error in updating make payment");
       }
   }, [postState, profileState]);

    const onSelectPost =(value)=> {
        dispatch(postsFieldChanged({prop: 'post', value }))
    }

    const handleDelete = (seeker) => {
        deleteFromShortlist({ seeker });
        message.success('Removed from shortlist');
        setIsModalVisible(false);
    };

    const onSelectCandidate = (seeker) => {
        if ("paid" in profile) {
            if (profile.paid) {
                pushSeeker({ pushedSeeker: seeker });
                navigate("/seeker-profile");
            } else {
                message.info("Upgrade your package to view candidate").then(() => null);
            }
        } else {
            message.info("Upgrade your package to view candidate").then(() => null);
        }
    };

    const handleShortlist = (seeker, shortlistInfo) => {
        dispatch(requestCoverLetter({ seeker, shortlistInfo }))
        message.info('Sending Request ..');
        setIsCoverLetterModalVisible(false);
    };
    
    const onRequestCoverLetter = (record) => {
        setSelectedSeeker(record);
         
        const shortlistInfo = {}
        shortlistInfo.companyName = state.profile.companyName;
        shortlistInfo.adminName = state.profile.firstName;
        shortlistInfo.post = state.post.label;
        shortlistInfo.isCoverLetterSent = false;
        shortlistInfo.adminID = state.profile.userID;
        
        handleShortlist(selectedSeeker, shortlistInfo)
    };

    const showModal = (record) => {
        setSelectedSeeker(record);
        setIsModalVisible(true);
    };

    const showCoverLetterModal = (record) => {
        setSelectedSeeker(record);
        setIsCoverLetterModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleClose = () => {
        setIsCoverLetterModalVisible(false);
    };

    let processedResults = shortlist.map(result => {
        let seconds;
        result.birthdate.seconds? (seconds = result.birthdate.seconds) : (seconds = result.birthdate._seconds);
        
        const birthday = moment.unix(seconds);
        const today = moment();
        const age = today.diff(birthday, "years");
        
        const name = (result.firstName + " " + result.lastName).toUpperCase();
        const acceptanceRate = result.acceptanceRate || 0;
        const responseRate = result.responseRate || 0;
        const showUpRate = result.showUpRate || 0;
        
        const formattedLocation = `${result.location.country}, ${result.location.region}`;

        let color = "#000";
        switch (result.packageIndex) {
            case 1:
                color = "#228b22";
                break;
            case 2:
                color = "#0793d9";
                break;
            default:
                break;
        }
        
        let indSentence = "";
        if (result.education.length!== 0) {
            const edIndustry = result.education.map((ind) => `${ind},`);
            indSentence = edIndustry.slice(0, -1).join(", ") + " " + edIndustry[edIndustry.length - 1];
        }
        
        const coverLetterRequested = result.coverLetterRequested !== undefined;
        
        return { ...result, indSentence, formattedLocation, name, age, color, coverLetterRequested, acceptanceRate, responseRate, showUpRate };
    });

    const columns = [
        {
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',
            width: 65,
            fixed: true,
            render: (_, record) => (
                <div style={{ color: record.color }}>
                    <SecurityScanFilled style={{ fontSize: '16px' }} />
                </div>
            ),
        },
        {
            title: 'Candidate',
            dataIndex: 'name',
            key: 'name',
            width: 180,
            fixed: true,
            render: (_, record) => (
                <div className="nafasi-candidate-card">
                    <div className="nafasi-candidate-info">
                        <div 
                            className="nafasi-candidate-name" 
                            data-full-name={record.name}
                            title={record.name}
                        >
                            {record.name}
                        </div>
                        <div className="nafasi-candidate-details">
                            <span style={{ fontFamily: "ramblaRegular" }}>{record.gender}, {record.age}y</span>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: 'Location',
            dataIndex: 'formattedLocation',
            key: 'formattedLocation',
            width: 150,
            fixed: true,
            render: (text) => (
                <div className="nafasi-truncated-text" title={text}>
                    <FontAwesomeIcon icon="map-marker-alt" className="nafasi-location-icon" />
                    <span style={{ fontFamily: "ramblaRegular" }}>{text}</span>
                </div>
            ),
        },
        {
            title: 'Experience',
            dataIndex: 'experience',
            key: 'experience',
            width: 180,
            fixed: true,
            render: (_, record) => (
                <div className="nafasi-truncated-text">
                    <TruncatedExperience seeker={record} maxLength={50}/>
                </div>
            ),
        },
        {
            title: 'Industries',
            dataIndex: 'indSentence',
            key: 'indSentence',
            width: 160,
            fixed: true,
            render: (text) => (
                <div className="nafasi-truncated-text">
                    <TruncatedText text={text} maxLength={20}/>
                </div>
            ),
        },
        {
            title: 'Response',
            dataIndex: 'responseRate',
            key: 'responseRate',
            width: 80,
            fixed: true,
            align: 'center',
            render: (value) => (
                <div className={`nafasi-stats-badge ${value > 80 ? 'high' : value > 50 ? 'medium' : ''}`}>
                    <span>{value}%</span>
                </div>
            ),
        },
        {
            title: 'Show-Up',
            dataIndex: 'showUpRate',
            key: 'showUpRate',
            width: 80,
            fixed: true,
            align: 'center',
            render: (value) => (
                <div className={`nafasi-stats-badge ${value > 80 ? 'high' : value > 50 ? 'medium' : ''}`}>
                    <span>{value}%</span>
                </div>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 120,
            fixed: true,
            render: (_, record) => (
                <div className="nafasi-action-buttons">
                    <button 
                        className="nafasi-action-button"
                        onClick={() => onSelectCandidate(record)}
                    >
                        <FontAwesomeIcon icon="user" size="lg" />
                    </button>
                    {!record.coverLetterRequested && (
                        <Popconfirm
                            title="Request a cover letter from this candidate"
                            onConfirm={() => showCoverLetterModal(record)}
                            okText="Request Letter"
                            cancelText="Cancel"
                            overlayStyle={{ width: "300px" }}
                        >
                            <button className="nafasi-action-button">
                                <FontAwesomeIcon icon="envelope" size="lg" />
                            </button>
                        </Popconfirm>
                    )}
                    <Popconfirm
                        title="Remove this candidate from your shortlist?"
                        onConfirm={() => handleDelete(record)}
                        okText="Remove"
                        cancelText="Cancel"
                        overlayStyle={{ width: "300px" }}
                    >
                        <button className="nafasi-action-button danger">
                            <FontAwesomeIcon icon="trash" size="lg" />
                        </button>
                    </Popconfirm>
                </div>
            ),
        },
    ];

    return (
        <div className="nafasi-results-container">
            <Table 
                dataSource={processedResults} 
                columns={columns} 
                rowKey="seekerID"
                scroll={{ x: 970 }}
                loading={shortlistLoading}
                pagination={{
                    current: currentPage,
                    pageSize: 20,
                    showSizeChanger: false,
                    showTotal: (total) => `${total} candidates`,
                    onChange: (page) => setCurrentPage(page),
                    hideOnSinglePage: false,
                    showLessItems: false
                }}
                style={{ width: '100%' }}
            />

            <Modal
                title="Request Cover Letter"
                open={isCoverLetterModalVisible}
                onCancel={handleClose}
                footer={null}
                width={500}
                className="nafasi-modal"
            >
                <div className="nafasi-modal-content">
                    <SelectPost
                        placeholder="Select job post"
                        label="Job Position"
                        value={state.post}
                        onChange={onSelectPost}
                    />
                    <div className="nafasi-modal-actions">
                        {shortlistLoading ? (
                            <Spinner />
                        ) : (
                            <Button 
                                type="primary" 
                                onClick={() => onRequestCoverLetter(selectedSeeker)}
                                disabled={!state.post}
                            >
                                Send Request
                            </Button>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default connect(null, { deleteFromShortlist, pushSeeker })(ShortlistCard);
