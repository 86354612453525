import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Interviews.css";

class InterviewsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            general: this.props.general,
            interview: this.props.interview,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                general: nextProps.general,
                interview: nextProps.interview,
            });
        }
    }

    renderSkillRating(skill, value) {
        return (
            <div className="skill-rating">
                <div className="skill-label">{this.formatSkillName(skill)}</div>
                <div className="rating-bar">
                    <div 
                        className="rating-fill" 
                        style={{ 
                            width: `${(value / 5) * 100}%`,
                            backgroundColor: this.getRatingColor(value)
                        }}
                    />
                </div>
                <div className="rating-value">{value}/5</div>
            </div>
        );
    }

    formatSkillName(skill) {
        return skill
            .replace(/([A-Z])/g, ' $1')
            .replace(/^./, str => str.toUpperCase());
    }

    getRatingColor(value) {
        if (value >= 4) return '#059669';
        if (value >= 3) return '#0EA5E9';
        if (value >= 2) return '#EAB308';
        return '#DC2626';
    }

    renderInterviews() {
        const { interview } = this.state;
        const { infoBox } = interview;

        const skillsToRender = [
            'adaptability', 'analyticalThinking', 'creativity', 'decisionMaking',
            'emotionalIntelligence', 'initiative', 'leadership', 'organizationalSkills',
            'professionalism', 'teamwork', 'timeManagement', 'verbalCommunication',
            'workEthic', 'writtenCommunication'
        ];

        return (
            <div className="interview-assessment">
                <div className="assessment-header">
                    <div className="assessment-type">
                        <FontAwesomeIcon 
                            icon={infoBox.general ? "chart-line" : "briefcase"} 
                            className="assessment-icon"
                        />
                        <h3>
                            {infoBox.general 
                                ? 'Average Assessment Ratings'
                                : <>{infoBox.postTitle} <span className="company-name">@{infoBox.companyName}</span></>
                            }
                        </h3>
                    </div>
                </div>

                <div className="skills-grid">
                    {skillsToRender.map(skill => (
                        <div key={skill} className="skill-card">
                            {this.renderSkillRating(skill, interview[skill])}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="interviews-content">
                {this.renderInterviews()}
            </div>
        );
    }
}

export default InterviewsCard;