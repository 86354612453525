import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import { useGoogleTagManager } from '../common/UseGoogleTagManager';
import { Button } from "antd";
import { ReactComponent as HeroIllustration } from '../../assets/images/hero-dots.svg';
import { ReactComponent as WaveShape } from '../../assets/images/wave-shape.svg';
import { faSync, faDollarSign, faFileAlt, faClock, faHistory, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

// Package image icons imports
import Earth from '../../assets/images/worldwide.png';
import Star from '../../assets/images/star.png';
import Galaxy from '../../assets/images/solar-system.png';
import Universe from '../../assets/images/universe.png';
import Problem from '../../assets/img/the_problem.png';
import Solution from '../../assets/img/the_solution.png';

import './Pricing.css';

function Pricing() {
    useGoogleTagManager('AW-16459515028');
    const navigate = useNavigate();

    const pricingPlans = [
        {
            title: "Individual",
            icon: Earth,
            price: "120,000",
            features: [
                "Sign up FREE today!",
                "Explore 50k+ sorted CVs.",
                "Regular platform updates",
                "Dedicated support team",
                "Industry-leading security"
            ]
        },
        {
            title: "Startup",
            icon: Star,
            price: "159,000",
            features: [
                "Includes Free Plan perks",
                "View profiles & contacts",
                "Automated CV filtering",
                "Basic analytics and reports",
                "Interview Assessment System"
            ]
        },
        {
            title: "Business",
            icon: Universe,
            price: "257,000",
            features: [
                "Includes Startup Plan perks.",
                "Advanced search filters",
                "Detailed analytics and demographics.",
                "Real-time candidate alerts"
            ]
        },
        {
            title: "Enterprise",
            icon: Galaxy,
            price: "699,000",
            features: [
                "Includes Business Plan perks",
                "AI-driven matching & ranking",
                "All features inclusive",
                "Premium support"
            ]
        }
    ];

    const renderPricingCard = (plan) => (
        <div className={'pricingCard'} key={plan.title}>
            <div className="card-header">
                <h3>{plan.title}</h3>
                <img src={plan.icon} alt={`${plan.title} illustration icon`} />
            </div>
            <div className="price-section">
                <h4>{plan.price}</h4>
                <h6>TSH</h6>
                <h5>/ month</h5>
            </div>
            <Button 
                type="primary" 
                size="large"
                onClick={() => navigate('/register')}
                className="subscribe-button"
                style={{ 
                    backgroundColor: "#8FBC44", 
                    width: "80%",
                    fontFamily: "ramblaRegular",
                    marginBottom: "1rem"
                }}
            >
                Sign up
            </Button>
            <div className="features-list">
                {plan.features.map((feature, index) => (
                    <div key={index} className="feature-row">
                        <div className="feature-check">
                            <FontAwesomeIcon 
                                icon={'check-circle'} 
                                size={'1x'} 
                                color={'#8FBC44'} 
                            /> 
                        </div>
                        <div className="feature-text" style={{ marginTop: "2px"}}>{feature}</div>
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <div className={'pricingContainer'}>
            <section className="hero-section">
                <div className="hero-background">
                    <HeroIllustration className="hero-dots" />
                    <WaveShape className="wave-shape" />
                </div>
                <div className={"priceContainerDetails"}>
                    <div className={"pricingBox"}>
                        <span className="highlight-badge">Recruitment Made Easy</span>
                        <h1 className="hero-title">
                            Find and Hire your 
                            <span className="highlight"> next employee</span>
                        </h1>
                        <p className="hero-description">
                            Nafasi allows recruiters to filter and categorize over 
                            <span className="highlight-number"> 50,000</span> CVs within seconds.
                            {' '}
                            Cuts down your recruitment expenses by over 
                            <span className="highlight-number"> 40%</span> each month.
                        </p>
                        <Button 
                            size={'large'} 
                            type="primary"
                            onClick={() => navigate('/register')} 
                            className={"cta-button"}
                        >
                            Sign up for free
                        </Button>
                    </div>
                    <div className={"pricingImagesBox"}>
                        <img src={Problem} alt={"the_problem"} className="problem-image"/>
                        <img src={Solution} alt={"the_solution"} className="solution-image"/>
                    </div>
                </div>
            </section>

            <section className="pricing-intro">
                <h2>Choose a plan that works for you.</h2>
                <p className="pricing-subtitle">
                    Get instant access to Tanzania's largest talent pool
                </p>
            </section>

            <section className={'pricingCardsBox'}>
                {pricingPlans.map(renderPricingCard)}
            </section>

            <section className="benefits-section">
                <h3 style={{ color: "#ffb400", marginBottom: "2rem", textAlign: "center" }}>
                    With Nafasi You Avoid
                </h3>
                <div className="benefits-list">
                    {[
                        {
                            title: "Slow Hiring Process",
                            description: "No more missing exceptional talent due to outdated methods that take weeks to identify candidates",
                            icon: faClock
                        },
                        {
                            title: "Manual CV Screening",
                            description: "Eliminate tedious sorting of CVs that don't meet your education and industry requirements",
                            icon: faFileAlt
                        },
                        {
                            title: "Starting From Scratch",
                            description: "Skip assessing candidates from scratch with our pre-vetted talent pool",
                            icon: faSync
                        },
                        {
                            title: "Outdated Information",
                            description: "Access our constantly updated CV database instead of old, static records",
                            icon: faHistory
                        },
                        {
                            title: "High Recruitment Costs",
                            description: "Save on expensive promotion fees for each vacant position",
                            icon: faDollarSign
                        },
                        {
                            title: "Poor Quality Matches",
                            description: "Avoid mismatched candidates with our AI-powered matching algorithm",
                            icon: faCheckCircle
                        }
                    ].map((benefit, index) => (
                        <div key={index} className="benefit-item">
                            <div className="benefit-icon">
                                <FontAwesomeIcon 
                                    icon={benefit.icon} 
                                    size={'2x'} 
                                    color={'#8FBC44'} 
                                />
                            </div>
                            <div className="benefit-content">
                                <h4 className="benefit-title">{benefit.title}</h4>
                                <p className="benefit-description">{benefit.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
}

export default Pricing;