import React, { useState } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';


// Image imports
import Universe from "../../assets/images/global.svg";
import DPI from "../../assets/images/shafi.png";
import DPII from "../../assets/images/justin.png";
import DPIII from "../../assets/images/islim.png";

// Component imports
import RoadMap from './cards/RoadMap';
import BenefitsPage from './cards/Benefits';
import ConversionPage from './sections/ConversionPage';
import UniverseSection from './sections/UniverseSection';
import LBColored from "../../assets/img/LB.png";
import LBGrayscale from "../../assets/img/LB-Grayscale.png";
import SeritanColored from "../../assets/img/Seritan.png";
import SeritanGrayscale from "../../assets/img/Seritan-Grayscale.png";
import CeylexColored from "../../assets/img/Ceylex.png";
import CeylexGrayscale from "../../assets/img/Ceylex-Grayscale.png";

// Animation variants
const fadeInLeft = {
    initial: { opacity: 0, x: -50 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 0.8 }
};

const fadeInRight = {
    initial: { opacity: 0, x: 50 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 0.8 }
};

const fadeInUp = {
    initial: { opacity: 0, y: 30 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8 }
};

// Add responsive styles
const styles = {
    header: {
        marginTop: "1px",
        paddingTop: "0",
        overflow: "hidden"
    },
    logo: {
        maxHeight: isMobile ? "6rem" : "10rem",
        marginBottom: isMobile ? "1rem" : "2rem"
    },
    mainHeading: {
        backgroundColor: '#f8f9fa',
        padding: '10px',
        borderRadius: '5px',
        fontSize: isMobile ? "2rem" : "3rem",
        lineHeight: isMobile ? "1.2" : "1"
    },
    getStartedButton: {
        backgroundColor: '#ffb400',
        color: '#fff',
        padding: isMobile ? "10px 20px" : "15px 25px",
        boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)',
        border: 'none',
        borderRadius: '5px',
        width: "auto",
        marginTop: isMobile ? "1rem" : "2rem"
    },
    buttonText: {
        color: "black",
        fontFamily: "ramblaBold",
        fontSize: isMobile ? "1.2rem" : "1.5rem"
    },
    imageContainer: {
        backgroundColor: '#e9ecef',
        padding: isMobile ? '5px' : '10px',
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%'
    },
    quoteText: {
        fontSize: isMobile ? "1.2rem" : "1.5rem",
        padding: isMobile ? "1rem" : "2rem"
    },
    universeSection: {
        padding: isMobile ? "2rem 1rem" : "0.2rem 1rem"
    },
    testimonialSection: {
        padding: '1.5rem 0',
        overflow: 'hidden',
        backgroundColor: 'rgba(255, 255, 255, 0.1)'
    },
    testimonialContainer: {
        display: 'flex',
        gap: '15px',
        padding: '5px 0',
        overflowX: 'auto',
        scrollBehavior: 'smooth',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    },
    testimonialCard: {
        backgroundColor: 'white',
        borderRadius: '10px',
        padding: '12px 15px',
        minWidth: '280px',
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    avatar: {
        width: '40px',
        height: '40px',
        flexShrink: 0
    },
    testimonialText: {
        fontSize: '0.9rem',
        marginBottom: '3px',
        color: '#333',
        lineHeight: '1.3',
        fontFamily: 'ramblaRegular',
    },
    testimonialName: {
        fontSize: '0.8rem',
        fontWeight: 'bold',
        marginBottom: '0',
        color: '#000',
        fontFamily: 'ramblaBold',
    },
    testimonialRole: {
        fontSize: '0.75rem',
        opacity: '0.7',
        color: '#666',
        fontFamily: 'ramblaRegular',
    },
    trustedBySection: {
        backgroundColor: '#f8f9fa',
        padding: '2.5rem 0',
        borderTop: '1px solid #eaeaea',
        borderBottom: '1px solid #eaeaea'
    },
    trustedByContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '2rem'
    },
    trustedByTitle: {
        color: '#666',
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        letterSpacing: '2px',
        fontWeight: 500
    },
    logoStrip: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '3.5rem',
        flexWrap: 'wrap',
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '0 20px'
    },
    logoContainer: {
        width: '70px',
        height: '28px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'all 0.3s ease',
        opacity: 0.6,
        '&:hover': {
            transform: 'scale(1.05)',
            opacity: 1
        }
    }
};

const PersonAvatar = (who) => {
    let dpFile = DPI;
    switch (who) {
        case 'islim':
            dpFile = DPIII;
            break;
        case 'justin':
            dpFile = DPII
            break;
        default:
            break;
    }
    return <img  src={dpFile} alt={"dp"} style={{ borderRadius: '50%', border: '3px solid #eaeaea' }} />
}

const CompanyLogo = ({ name }) => {
    const [isHovered, setIsHovered] = useState(false);

    const logos = {
        ceylex: {
            bw: (
                <div width="70" height="28">
                    <img src={CeylexGrayscale} alt="Ceylex Logo" />
                </div>
            ),
            color: (
                <div width="70" height="28">
                    <img src={CeylexColored} alt="Ceylex Logo" />
                </div>
            )
        },
        lb: {
            bw: (
                <div width="70" height="28">
                    <img src={LBGrayscale} alt="Google Logo" />
                </div>
            ),
            color: (
                <div width="70" height="28">
                    <img src={LBColored} alt="Google Logo" />
                </div>
            )
        },
        seritan: {
            bw: (
                <div width="70" height="28">
                    <img src={SeritanGrayscale} alt="Google Logo" />
                </div>
            ),
            color: (
                <div width="70" height="28">
                    <img src={SeritanColored} alt="Google Logo" />
                </div>
            )
        },
    };

    return (
        <div 
            style={styles.logoContainer}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {isHovered ? logos[name].color : logos[name].bw}
        </div>
    );
};

class SplitLanding extends React.Component {
    render() {
        return (
            <div id="page-top">
                <ConversionPage/>
                {/* Testimonials Section */}
                <aside style={styles.testimonialSection}>
                    <div className="container px-3">
                        <motion.div
                            style={styles.testimonialContainer}
                            {...fadeInUp}
                            viewport={{ once: true }}
                        >
                            {/* Testimonial 1 */}
                            <motion.div 
                                style={styles.testimonialCard}
                                initial={{ opacity: 0, x: 50 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: 0.1 }}
                            >
                                <div style={styles.avatar}>
                                    {PersonAvatar("shafi")}
                                </div>
                                <div>
                                    <p style={styles.testimonialText}>
                                        "I landed a job using Nafasi! The app applied on my behalf and sent me interview updates. Joining the Gold Room made the process faster and easier"
                                    </p>
                                    <p style={styles.testimonialName}>Shaffih Salim</p>
                                    <p style={styles.testimonialRole}>System Supervisor</p>
                                </div>
                            </motion.div>

                            {/* Testimonial 2 */}
                            <motion.div 
                                style={styles.testimonialCard}
                                initial={{ opacity: 0, x: 50 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: 0.2 }}
                            >
                                <div style={styles.avatar}>
                                    {PersonAvatar("justin")}
                                </div>
                                <div>
                                    <p style={styles.testimonialText}>
                                        "Nafasi changed my job search! Sending CVs daily was exhausting and costly."
                                    </p>
                                    <p style={styles.testimonialName}>Justin Libaba</p>
                                    <p style={styles.testimonialRole}>Administrator</p>
                                </div>
                            </motion.div>

                            {/* Testimonial 3 */}
                            <motion.div 
                                style={styles.testimonialCard}
                                initial={{ opacity: 0, x: 50 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                viewport={{ once: true }}
                                transition={{ delay: 0.3 }}
                            >
                                <div style={styles.avatar}>
                                    {PersonAvatar('islim')}
                                </div>
                                <div>
                                    <p style={styles.testimonialText}>
                                        "Nafasi imenisaidia kujifunza kupitia matokeo ya interview na kusambaza CV yangu kwenye fursa nyingi. Room ya Platinum ilinisaidia sana kuingia kwenye soko la kazi.""
                                    </p>
                                    <p style={styles.testimonialName}>Islim Hamad</p>
                                    <p style={styles.testimonialRole}>Data Collector</p>
                                </div>
                            </motion.div>
                        </motion.div>
                    </div>
                </aside>

                {/* Trusted By Section */}
                <section style={styles.trustedBySection}>
                    <motion.div 
                        style={styles.trustedByContainer}
                        initial={{ opacity: 0, y: 20 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.6 }}
                    >
                        <h3 style={styles.trustedByTitle}>Trusted By</h3>
                        <div style={styles.logoStrip}>
                            <CompanyLogo name="ceylex" />
                            <CompanyLogo name="lb" />
                            <CompanyLogo name="seritan" />
                        </div>
                    </motion.div>
                </section>

                <RoadMap />

                <section id="features">
                    <BenefitsPage />
                </section>

                {/* Universe Section */}
                <UniverseSection />
            </div>
        );
    }
}

export default connect(null, {})(SplitLanding);