import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { DatePicker, Select, Button, Table, message } from 'antd';
import { DownloadOutlined, BarChartOutlined } from '@ant-design/icons';
import firebase from 'firebase';
const XLSX = require('xlsx');

import './Reports.css';

const { RangePicker } = DatePicker;
const { Option } = Select;

class Reports extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            systemInfo: this.props.systemInfo,
            profile: {},
            dateRange: null,
            selectedJobPosting: 'all',
            selectedStatus: 'all',
            reportType: 'detailed',
            jobPostings: [],
            loading: false,
            data: [],
            error: null
        }
    }

    componentDidMount() {
        this.checkUserProfile();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                systemInfo: nextProps.systemInfo
            });
        }
    }

    checkUserProfile() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                firebase.firestore().collection("adminBucket").doc(user.uid)
                    .onSnapshot((doc) => {
                        if (doc.exists) {
                            const profile = doc.data();
                            this.setState({ profile }, () => {
                                // Only fetch job postings if user is authorized
                                if (profile.role === 'admin' || profile.role === 'superAdmin') {
                                    this.fetchJobPostings();
                                }
                            });
                        }
                    }, (error) => {
                        console.error("Error fetching user profile:", error);
                        message.error("Error fetching user profile");
                    });
            }
        });
    }

    fetchJobPostings() {
        this.setState({ loading: true });
        firebase.firestore().collection("posts")
            .get()
            .then((snapshot) => {
                const posts = [];
                snapshot.forEach((doc) => {
                    posts.push({ id: doc.id, ...doc.data() });
                });
                this.setState({ jobPostings: posts, loading: false });
            })
            .catch((error) => {
                console.error("Error fetching job postings:", error);
                message.error("Error fetching job postings");
                this.setState({ loading: false, error: error.message });
            });
    }

    generateReport = async (reportType) => {
        this.setState({ loading: true, error: null });
        const { dateRange, selectedJobPosting, selectedStatus } = this.state;
        
        try {
            // First check if user is still authenticated
            const user = firebase.auth().currentUser;
            if (!user) {
                throw new Error("User not authenticated");
            }

            let query = firebase.firestore().collection("candidates");
            
            if (dateRange) {
                query = query.where("timestamp", ">=", dateRange[0].toDate())
                           .where("timestamp", "<=", dateRange[1].toDate());
            }
            
            const snapshot = await query.get();
            let data = [];
            
            snapshot.forEach((doc) => {
                const candidate = doc.data();
                if (selectedJobPosting !== 'all' && candidate.jobPosting !== selectedJobPosting) return;
                if (selectedStatus !== 'all' && candidate.status !== selectedStatus) return;
                
                switch(reportType) {
                    case 'selected':
                        if (candidate.status === 'hired') data.push(candidate);
                        break;
                    case 'interviewed':
                        if (candidate.interviewed) data.push(candidate);
                        break;
                    case 'rejected':
                        if (candidate.status === 'rejected') data.push(candidate);
                        break;
                    case 'shortlisted':
                        if (candidate.status === 'shortlisted') data.push(candidate);
                        break;
                    case 'no-show':
                        if (candidate.noShow) data.push(candidate);
                        break;
                    case 'performance':
                        data.push({
                            ...candidate,
                            interviewScore: candidate.interviewScore || 'N/A',
                            skillMatch: candidate.skillMatch || 'N/A',
                            cultureFit: candidate.cultureFit || 'N/A'
                        });
                        break;
                    default:
                        data.push(candidate);
                }
            });
            
            this.setState({ data, loading: false });
            return data;
        } catch (error) {
            console.error("Error generating report:", error);
            message.error(error.message || "Failed to generate report");
            this.setState({ loading: false, error: error.message });
            return [];
        }
    }

    exportToExcel = async (reportType) => {
        try {
            const data = await this.generateReport(reportType);
            if (data.length === 0) {
                message.warning("No data available for export");
                return;
            }

            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, reportType);
            
            const date = new Date().toISOString().split('T')[0];
            const filename = `nafasi_${reportType}_report_${date}.xlsx`;
            
            XLSX.writeFile(workbook, filename);
            message.success("Report exported successfully");
        } catch (error) {
            console.error("Error exporting to Excel:", error);
            message.error("Failed to export report");
        }
    }

    checkUser() {
        const { profile, loading, error } = this.state;

        if(_.isEmpty(profile)) {
            return (
                <div className="unauthorized-container">
                    <h2>Sign in to view reports</h2>
                </div>
            );
        }

        if(profile.role !== "admin" && profile.role !== "superAdmin") {
            return (
                <div className="unauthorized-container">
                    <h3>Un-Authorized Access</h3>
                    <p>You need admin privileges to access this section</p>
                </div>
            );
        }

        return (
            <div className="reports-content">
                <div className="reports-header">
                    <BarChartOutlined className="reports-icon" />
                    <h1>Reports</h1>
                </div>

                {error && (
                    <div className="error-message">
                        <p>{error}</p>
                    </div>
                )}

                <div className="filters-container">
                    <RangePicker 
                        onChange={(dates) => this.setState({ dateRange: dates })}
                        className="date-picker"
                        disabled={loading}
                    />
                    <Select
                        defaultValue="all"
                        onChange={(value) => this.setState({ selectedJobPosting: value })}
                        className="job-select"
                        disabled={loading}
                    >
                        <Option value="all">All Job Postings</Option>
                        {this.state.jobPostings.map(post => (
                            <Option key={post.id} value={post.id}>{post.title}</Option>
                        ))}
                    </Select>
                    <Select
                        defaultValue="all"
                        onChange={(value) => this.setState({ selectedStatus: value })}
                        className="status-select"
                        disabled={loading}
                    >
                        <Option value="all">All Statuses</Option>
                        <Option value="shortlisted">Shortlisted</Option>
                        <Option value="interviewed">Interviewed</Option>
                        <Option value="hired">Hired</Option>
                        <Option value="rejected">Rejected</Option>
                    </Select>
                </div>

                <div className="reports-grid">
                    {[
                        { title: 'Selected Hires', type: 'selected', description: 'Candidates hired for specific roles' },
                        { title: 'Interviewed Candidates', type: 'interviewed', description: 'Detailed log of interviews conducted' },
                        { title: 'Rejected Offers', type: 'rejected', description: 'Candidates who declined offers' },
                        { title: 'Shortlist Report', type: 'shortlisted', description: 'Currently shortlisted candidates' },
                        { title: 'No-Show Report', type: 'no-show', description: 'Missed interview appointments' },
                        { title: 'Performance Metrics', type: 'performance', description: 'Candidate evaluation metrics' }
                    ].map(report => (
                        <div key={report.type} className="report-card">
                            <h3>{report.title}</h3>
                            <p>{report.description}</p>
                            <Button 
                                type="primary" 
                                icon={<DownloadOutlined />}
                                onClick={() => this.exportToExcel(report.type)}
                                loading={this.state.loading}
                                disabled={loading}
                            >
                                Export Report
                            </Button>
                        </div>
                    ))}
                </div>

                {this.state.data.length > 0 && (
                    <div className="table-container">
                        <Table 
                            dataSource={this.state.data}
                            columns={[
                                { title: 'Name', dataIndex: 'name', key: 'name' },
                                { title: 'Email', dataIndex: 'email', key: 'email' },
                                { title: 'Position', dataIndex: 'position', key: 'position' },
                                { title: 'Status', dataIndex: 'status', key: 'status' },
                                { title: 'Date', dataIndex: 'timestamp', key: 'timestamp',
                                    render: (text) => text ? new Date(text?.toDate()).toLocaleDateString() : 'N/A' }
                            ]}
                            loading={this.state.loading}
                        />
                    </div>
                )}
            </div>
        );
    }
    
    render() {
        return(
            <div className="reports-container">
                {this.checkUser()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { systemInfo } = state.system;
    return { systemInfo };
};

export default connect(mapStateToProps, { })(Reports);