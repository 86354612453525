import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Employment.css";

class EmploymentCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employment: this.props.employment,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                employment: nextProps.employment,
            });
        }
    }

    formatDate(timestamp) {
        if (!timestamp) return 'N/A';
        // Handle both Firestore timestamp and regular timestamp
        if (timestamp.toDate) {
            return moment(timestamp.toDate()).format('MMM YYYY');
        }
        if (timestamp.seconds || timestamp._seconds) {
            const seconds = timestamp.seconds || timestamp._seconds;
            return moment.unix(seconds).format('MMM YYYY');
        }
        return moment(timestamp).format('MMM YYYY');
    }

    getPositionLabel(position) {
        const positions = {
            'junior': 'Junior Level',
            'lead': 'Lead Level',
            'senior': 'Senior Level',
            'managerial': 'Managerial Level'
        };
        return positions[position] || position;
    }

    calculateDuration(fromDate, toDate, currentWork) {
        if (!fromDate) return { years: 0, months: 0 };
        
        const start = fromDate.toDate ? fromDate.toDate() : moment.unix(fromDate.seconds || fromDate._seconds);
        const end = currentWork ? moment() : (toDate?.toDate ? toDate.toDate() : moment.unix(toDate?.seconds || toDate?._seconds));
        
        const duration = moment.duration(moment(end).diff(moment(start)));
        return {
            years: Math.floor(duration.asYears()),
            months: Math.floor(duration.asMonths() % 12)
        };
    }

    renderEmployment() {
        const { employment } = this.state;

        console.log("employment", employment);
        return employment.map((emp, index) => (
            <div key={index} className="employment-item">
                <div className="timeline-dot"></div>
                <div className="employment-content">
                    <div className="employment-header">
                        <div className="company-info">
                            <h3>{emp.jobTitle?.title || emp.jobTitle || 'Untitled Position'}</h3>
                            <h4>{emp?.jobCompany || emp.jobCompany || 'Company Name'}</h4>
                            {emp.currentWork && (
                                <span className="current-status">Currently Employed</span>
                            )}
                        </div>
                        <div className="employment-duration">
                            <FontAwesomeIcon icon="calendar-alt" className="duration-icon" />
                            <span>
                                {this.formatDate(emp.timeOfWorkFrom)} - {emp.currentWork ? 'Present' : this.formatDate(emp.timeOfWorkTo)}
                            </span>
                        </div>
                    </div>

                    <div className="employment-details">
                        <div className="location">
                            <FontAwesomeIcon icon="map-marker-alt" />
                            <span>
                                {emp.location?.country || emp.location || 'Location N/A'}
                                {emp.location?.region && `, ${emp.location.region}`}
                            </span>
                        </div>
                        
                        <div className="experience-duration">
                            <FontAwesomeIcon icon="clock" />
                            {(() => {
                                const duration = this.calculateDuration(emp.timeOfWorkFrom, emp.timeOfWorkTo, emp.currentWork);
                                return <span>{duration.years} Years {duration.months} Months</span>;
                            })()}
                        </div>
                    </div>

                    {/* Positions Served Section */}
                    {emp.positions && emp.positions.length > 0 && (
                        <div className="positions-section">
                            <h5>Positions Served</h5>
                            <div className="positions-grid">
                                {emp.positions.map((pos, idx) => (
                                    <div key={idx} className="position-card">
                                        <div className="position-title">
                                            {this.getPositionLabel(pos.level)}
                                        </div>
                                        <div className="position-duration">
                                            <FontAwesomeIcon icon="calendar-alt" className="duration-icon-sm" />
                                            <span>
                                                {this.formatDate(pos.startDate)} - {pos.current ? 'Present' : this.formatDate(pos.endDate)}
                                            </span>
                                        </div>
                                        <div className="position-time">
                                            {pos.years || 0} Years {pos.months || 0} Months
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    {emp.description && (
                        <div className="job-description">
                            <h5>Job Description</h5>
                            <p>{typeof emp.description === 'object' ? emp.description.text : emp.description}</p>
                        </div>
                    )}

                    {emp.experiences && emp.experiences.length > 0 && (
                        <div className="experiences-section">
                            <h5>Key Experiences</h5>
                            <div className="experiences-grid">
                                {emp.experiences.map((exp, idx) => (
                                    <div key={idx} className="experience-card">
                                        <div className="experience-title">
                                            {typeof exp.experience === 'object' ? exp.experience.title : exp.experience}
                                        </div>
                                        <div className="experience-duration">
                                            {exp.experienceYears || 0} Years {exp.experienceMonths || 0} Months
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        ));
    }

    render() {
        return (
            <div className="employment-timeline">
                {this.renderEmployment()}
            </div>
        );
    }
}

export default EmploymentCard;