import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Space, Card, Input, Button, Form, Typography } from 'antd';
import { DiscountIcon } from "hugeicons-react";
import _ from "lodash";
import './PromoCodes.css';

//action imports
import { settingsFieldChanged, savePromoCode } from "../../../actions/SettingsActions";

const { Title } = Typography;

class AffiliateForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			profile: this.props.profile,
			email: this.props.email,
			affiliateCode: this.props.affiliateCode,
			bankName: this.props.bankName,
			bankCode: this.props.bankCode,
			accountNumber: this.props.accountNumber,
			accountName: this.props.accountName,
			commissionPercent: this.props.commissionPercent,
			discountPercent: this.props.discountPercent,
			promoCodeLoading: this.props.promoCodeLoading,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps !== this.props) {
			this.setState({
				profile: nextProps.profile,
				email: nextProps.email,
				affiliateCode: nextProps.affiliateCode,
				bankName: nextProps.bankName,
				bankCode: nextProps.bankCode,
				accountNumber: nextProps.accountNumber,
				accountName: nextProps.accountName,
				commissionPercent: nextProps.commissionPercent,
				discountPercent: nextProps.discountPercent,
				promoCodeLoading: nextProps.promoCodeLoading,
			})
		}
	}

	handleSave = () => {
		const {
			email,
			affiliateCode,
			bankName,
			bankCode,
			accountNumber,
			accountName,
			commissionPercent,
			discountPercent
		} = this.state;

		if (
			email &&
			affiliateCode &&
			bankName &&
			bankCode &&
			accountNumber &&
			accountName &&
			commissionPercent &&
			discountPercent
		) {
			this.props.savePromoCode({
				affiliateCode,
				accountName,
				accountNumber,
				bankCode,
				bankName,
				commissionPercent: parseFloat(commissionPercent),
				discountPercent: parseFloat(discountPercent),
				email
			});
		}
	};

	checkUser() {
		const { profile, promoCodeLoading } = this.state;

		if (!(_.isEmpty(profile))) {
			if (profile.role === "superAdmin") {
				return (
					<div className="nafasi-promo">
						<Space direction="vertical" style={{ width: '100%' }} size="middle">
							<Card size="small" className="nafasi-promo__header-card">
								<div className="nafasi-promo__header">
									<DiscountIcon size={24} className="nafasi-promo__icon" />
									<Title level={4} className="nafasi-promo__title">Promo Codes</Title>
								</div>
							</Card>

							<Card size="small" className="nafasi-promo__content">
								<Form layout="vertical" className="nafasi-promo__form">
									<div className="nafasi-promo__form-grid">
										<Form.Item 
											label="Email" 
											required
											className="nafasi-promo__form-item"
										>
											<Input
												size="small"
												placeholder="Enter email"
												onChange={e => this.props.settingsFieldChanged({prop: 'email', value: e.target.value})}
												value={this.state.email}
											/>
										</Form.Item>

										<Form.Item 
											label="Affiliate Code" 
											required
											className="nafasi-promo__form-item"
										>
											<Input
												size="small"
												placeholder="Enter affiliate code"
												onChange={e => this.props.settingsFieldChanged({prop: 'affiliateCode', value: e.target.value})}
												value={this.state.affiliateCode}
											/>
										</Form.Item>

										<Form.Item 
											label="Bank Name" 
											required
											className="nafasi-promo__form-item"
										>
											<Input
												size="small"
												placeholder="Enter bank name"
												onChange={e => this.props.settingsFieldChanged({prop: 'bankName', value: e.target.value})}
												value={this.state.bankName}
											/>
										</Form.Item>

										<Form.Item 
											label="Bank Code" 
											required
											className="nafasi-promo__form-item"
										>
											<Input
												size="small"
												placeholder="Enter bank code"
												onChange={e => this.props.settingsFieldChanged({prop: 'bankCode', value: e.target.value})}
												value={this.state.bankCode}
											/>
										</Form.Item>

										<Form.Item 
											label="Account Number" 
											required
											className="nafasi-promo__form-item"
										>
											<Input
												size="small"
												placeholder="Enter account number"
												onChange={e => this.props.settingsFieldChanged({prop: 'accountNumber', value: e.target.value})}
												value={this.state.accountNumber}
											/>
										</Form.Item>

										<Form.Item 
											label="Account Name" 
											required
											className="nafasi-promo__form-item"
										>
											<Input
												size="small"
												placeholder="Enter account name"
												onChange={e => this.props.settingsFieldChanged({prop: 'accountName', value: e.target.value})}
												value={this.state.accountName}
											/>
										</Form.Item>

										<Form.Item 
											label="Commission Percent" 
											required
											className="nafasi-promo__form-item"
										>
											<Input
												size="small"
												placeholder="Enter commission percentage"
												onChange={e => this.props.settingsFieldChanged({prop: 'commissionPercent', value: e.target.value})}
												value={this.state.commissionPercent}
												type="number"
											/>
										</Form.Item>

										<Form.Item 
											label="Discount Percent" 
											required
											className="nafasi-promo__form-item"
										>
											<Input
												size="small"
												placeholder="Enter discount percentage"
												onChange={e => this.props.settingsFieldChanged({prop: 'discountPercent', value: e.target.value})}
												value={this.state.discountPercent}
												type="number"
											/>
										</Form.Item>
									</div>

									<Form.Item className="nafasi-promo__form-submit">
										<Button
											type="primary"
											onClick={this.handleSave}
											loading={promoCodeLoading}
											className="nafasi-promo__submit-btn"
										>
											Save
										</Button>
									</Form.Item>
								</Form>
							</Card>
						</Space>
					</div>
				);
			}
		}
	}

	render() {
		return (
			<div className="nafasi-promo__container">
				{this.checkUser()}
			</div>
		);
	}
}

const mapStateToProps = state => {
	const { profile } = state.profile;
	const { 
		email, 
		affiliateCode, 
		bankName, 
		bankCode, 
		accountNumber, 
		accountName, 
		commissionPercent, 
		discountPercent, 
		promoCodeLoading 
	} = state.settings;

	return {
		profile,
		email,
		affiliateCode,
		bankName,
		bankCode,
		accountNumber,
		accountName,
		commissionPercent,
		discountPercent,
		promoCodeLoading,
	}
}

export default connect(mapStateToProps, { settingsFieldChanged, savePromoCode })(AffiliateForm);