import React from 'react';
import { message, Space, Card, Typography, Button } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { EyeIcon, Agreement01Icon } from "hugeicons-react";
import AddTermsAndConditions from "./AddTermsAndConditions";
import './Legal.css';

const { Title, Text } = Typography;

class ViewTermsAndConditions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fetchedTermsAndConditions: this.props.fetchedTermsAndConditions,
        }

        this.success = this.success.bind(this);
        this.error = this.error.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                fetchedTermsAndConditions: nextProps.fetchedTermsAndConditions
            })
        }
    }

    success = () => {
        message.success('Successfully sent');
    };

    error = () => {
        message.error("There's an error occurred");
    };

    renderTermsAndAgreement() {
        const { fetchedTermsAndConditions } = this.state;

        if (!(_.isEmpty(fetchedTermsAndConditions))) {
            const termsAndConditionsArray = _.map(fetchedTermsAndConditions, doc => doc);
            let terms = [];
            termsAndConditionsArray.forEach(doc => {
                if (doc && "termsUpdateTime" in doc) {
                    terms.push(doc)
                }
            });

            return (
                <div className="nafasi-terms__list">
                    {terms.map(doc => {
                        let seconds;
                        doc.termsUpdateTime.seconds ? seconds = doc.termsUpdateTime.seconds : seconds = doc.termsUpdateTime._seconds;
                        const time = moment.unix(seconds).format("ddd, Do MMM YYYY");

                        return (
                            <Card 
                                key={doc.docFileID} 
                                size="small" 
                                className="nafasi-terms__item"
                            >
                                <div className="nafasi-terms__item-content">
                                    <div className="nafasi-terms__item-info">
                                        <Agreement01Icon size={20} className="nafasi-terms__item-icon" />
                                        <div className="nafasi-terms__item-text">
                                            <span strong className="nafasi-terms__item-title">{doc.docTitle}</span>
                                            <Text type="secondary" className="nafasi-terms__item-date">Updated on {time}</Text>
                                        </div>
                                    </div>
                                    <Button 
                                        type="text"
                                        icon={<EyeIcon size={20} />}
                                        onClick={() => window.open(`${doc.docFile}`, 'name', 'width=700,height=600')}
                                        className="nafasi-terms__view-btn"
                                    />
                                </div>
                            </Card>
                        );
                    })}
                </div>
            );
        } else {
            return (
                <div className="nafasi-terms__empty">
                    <Text type="secondary">No terms and agreements yet</Text>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="nafasi-terms__container">
                <div className="nafasi-terms">
                    <Space direction="vertical" style={{ width: '100%' }} size="middle">
                        <Card size="small" className="nafasi-terms__header-card">
                            <div className="nafasi-terms__header">
                                <Agreement01Icon size={24} className="nafasi-terms__icon" />
                                <Title level={4} className="nafasi-terms__title">Terms and Conditions</Title>
                            </div>
                        </Card>

                        <Card size="small" className="nafasi-terms__content">
                            <div className="nafasi-terms__add">
                                <AddTermsAndConditions />
                            </div>

                            <div className="nafasi-terms__section">
                                <Title level={5} className="nafasi-terms__subtitle">
                                    Click the eye icon to read the terms
                                </Title>
                                {this.renderTermsAndAgreement()}
                            </div>

                            <div className="nafasi-terms__footer">
                                <Text type="secondary" className="nafasi-terms__disclaimer">
                                    By using Nafasi you consent to all the Nafasi terms and conditions and privacy policy
                                </Text>
                            </div>
                        </Card>
                    </Space>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { fetchedTermsAndConditions } = state.settings;
    return { fetchedTermsAndConditions }
};

export default connect(mapStateToProps, {})(ViewTermsAndConditions);