import React from 'react';
import { connect } from "react-redux";
import firebase from "firebase";
import {isMobile} from 'react-device-detect';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
    faAddressBook,
    faBell,
    faBirthdayCake,
    faBriefcase,
    faBriefcaseMedical,
    faCalculator,
    faCheckSquare,
    faChevronCircleDown,
    faChevronLeft,
    faChevronRight,
    faCircle,
    faClock,
    faCog,
    faEnvelope,
    faExclamationCircle,
    faEye,
    faFile,
    faFileSignature,
    faFolder,
    faGlassCheers,
    faGraduationCap,
    faHashtag,
    faHeart,
    faHeartbeat,
    faHistory,
    faIdCard,
    faLink,
    faListAlt,
    faMapMarkerAlt,
    faPhone,
    faSignOutAlt,
    faStopwatch,
    faTasks,
    faTimes,
    faToolbox,
    faUmbrellaBeach,
    faUserCircle,
    faUserCheck,
    faUsers,
    faCreditCard,
    faExclamationTriangle,
    faTicketAlt,
    faLockOpen,
    faLock,
    faTrash,
    faLongArrowAltRight,
    faSpinner,
    faBullhorn,
    faSmile,
    faEyeSlash,
    faUserEdit,
    faFileUpload,
    faCaretRight,
    faCheckCircle,
    faTimesCircle,
    faBaby,
    faLaptop,
    faDesktop,
    faMobile,
    faUserPlus,
    faPlus,
    faUserCog,
    faUser,
    faPrint,
    faMinus,
    faPhoneAlt,
    faCaretDown,
    faBuilding,
    faChevronDown,
    faStar,
} from '@fortawesome/free-solid-svg-icons';

// CSS imports
import './App.css';
import './ResponsiveApp.css';
import './styles.css';

// Components
import Main from './components/Main';
import Landing from './components/Landing';
import IsMobile from './components/IsMobile';
import { BeatLoader } from "react-spinners";

// Actions
import { fetchMyProfile } from "./actions/ProfileActions";
import { fetchNotifications } from './actions/NotificationsActions';
import { userLogOut } from "./actions/AuthenticationActions";
import { fetchSystemInfo } from "./actions/SystemActions";
import { fetchMonths, fetchPayments } from "./actions/SubscriptionActions";
import { 
    fetchAssets, 
    fetchTermsAndConditions, 
    fetchFeedback, 
    fetchAssessment, 
    fetchRecruitersRequests 
} from "./actions/SettingsActions";
import { 
    fetchRecentSearches 
} from "./actions/HomeActions";
import { 
    fetchPosts, 
    fetchShortlist, 
    fetchAdminPosts, 
    fetchUpcomingInterviews 
} from "./actions/PostsActions";
// ... (keep all your action imports)

// Add all FontAwesome icons to library
library.add(
    fab,
    faUserCircle,
    faCog,
    faBell,
    faChevronLeft,
    faChevronRight,
    faBriefcaseMedical,
    faUmbrellaBeach,
    faStopwatch,
    faTimes,
    faChevronCircleDown,
    faGraduationCap,
    faBirthdayCake,
    faLink,
    faIdCard,
    faBriefcase,
    faCircle,
    faClock,
    faUsers,
    faHistory,
    faPhone,
    faEnvelope,
    faHashtag,
    faMapMarkerAlt,
    faFile,
    faFolder,
    faCalculator,
    faSignOutAlt,
    faHeart,
    faHeartbeat,
    faGraduationCap,
    faListAlt,
    faCheckSquare,
    faAddressBook,
    faEye,
    faGlassCheers,
    faFileSignature,
    faExclamationCircle,
    faTasks,
    faToolbox,
    faCreditCard,
    faExclamationTriangle,
    faTicketAlt,
    faLockOpen,
    faLock,
    faTrash,
    faLongArrowAltRight,
    faSpinner,
    faBullhorn,
    faSmile,
    faEyeSlash,
    faUserEdit,
    faFileUpload,
    faCaretRight,
    faCheckCircle,
    faTimesCircle,
    faBaby,
    faLaptop,
    faDesktop,
    faMobile,
    faUserPlus,
    faPlus,
    faUserCog,
    faUser,
    faPrint,
    faMinus,
    faPhoneAlt,
    faCaretDown,
    faUserCheck,
    faBuilding,
    faChevronDown,
    faStar,
);

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            notificationFetchLimit: this.props.notificationFetchLimit,
            profile: this.props.profile,
            systemInfo: this.props.systemInfo,
            systemInfoIsEmpty: this.props.systemInfoIsEmpty,
        };
    }

    componentDidMount() {
        const { notificationFetchLimit } = this.state;
        
        const firebaseConfig = {
            apiKey: "AIzaSyDJugXJ593M37xMb_6FuxUPEZ-boG0Nsow",
            authDomain: "nafasiio.firebaseapp.com",
            projectId: "nafasiio",
            storageBucket: "nafasiio.appspot.com",
            messagingSenderId: "849321740104",
            appId: "1:849321740104:web:f898d624aa65370591d57d",
            measurementId: "G-LN0267W1TL"
        };

        if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfig);
        }

        this.props.fetchTermsAndConditions();

        firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                this.setState({ user: true }, () => {
                    this.props.fetchMyProfile();
                    this.props.fetchSystemInfo();
                    this.props.fetchMonths();
                    this.props.fetchPayments();
                    this.props.fetchRecentSearches();
                    this.props.fetchPosts();
                    this.props.fetchShortlist();
                    this.props.fetchAssets();
                    this.props.fetchFeedback();
                    this.props.fetchRecruitersRequests();
                    this.props.fetchAssessment();
                    this.props.fetchUpcomingInterviews();
                    this.props.fetchNotifications({ notificationFetchLimit: this.state.notificationFetchLimit });
                });
            } else {
                this.setState({ user: false }, () => {
                });
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.profile !== this.props.profile) {
            this.setState({ profile: nextProps.profile });
        }
        if (nextProps.systemInfo !== this.props.systemInfo) {
            this.setState({ systemInfo: nextProps.systemInfo });
        }
        if (nextProps.systemInfoIsEmpty !== this.props.systemInfoIsEmpty) {
            this.setState({ systemInfoIsEmpty: nextProps.systemInfoIsEmpty });
        }
    }

    renderMain() {
        return isMobile ? <IsMobile/> : <Main/>;
    }

    renderComponent() {
        switch (this.state.user) {
            case true:
                return <div>{this.renderMain()}</div>;
            case false:

                return <Landing />;
            default:

                return (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "50vh" }}>
                        <BeatLoader/>
                    </div>
                );
        }
    }

    render() {
        return (
            <div id={'outer-container'}>
                {this.renderComponent()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        notificationFetchLimit: state.notifications.notificationFetchLimit,
        profile: state.profile.profile,
        systemInfo: state.system.systemInfo,
        systemInfoIsEmpty: state.system.systemInfoIsEmpty,
    };
};

export default connect(mapStateToProps, {
    fetchMyProfile,
    userLogOut,
    fetchNotifications,
    fetchSystemInfo,
    fetchMonths,
    fetchPayments,
    fetchAssets,
    fetchTermsAndConditions,
    fetchRecentSearches,
    fetchPosts,
    fetchShortlist,
    fetchAdminPosts,
    fetchFeedback,
    fetchAssessment,
    fetchRecruitersRequests,
    fetchUpcomingInterviews
})(App);
