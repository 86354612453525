import firebase from 'firebase';
import {message} from 'antd';
import _ from "lodash";

//-> Message Alert Icons
import {fillWarning, smileyIcon} from '../components/common/MessageAlertIcons';

//-> import types
import {
    CREDENTIALS_CHANGED,
    INITIALIZE_STATE,
    LOG_OUT,
    LOGIN_FAILED,
    LOGIN_SUCCESSFUL,
    LOGIN_USER,
    LOGOUT_FAILED,
    LOGOUT_SUCCESSFULLY,
    SIGN_UP_USER_FAILED,
    SIGN_UP_USER_SUCCESSFUL,
} from './Types';

// -> property imports

export const credentialsChanged = ({ prop, value}) => {
    return {
        type: CREDENTIALS_CHANGED,
        payload: { prop, value}
    }
};

export const userSignUp = ({ email, password, firstName, lastName, phone, companyName, companyLocation, companyPhone, companyEmail, logo, navigate, google, login }) => {

    return async (dispatch) => {
        dispatch({ type: LOGIN_USER});

        const active = true;

        try {
            if (login) {
                const auth = firebase.auth();
                const googleProvider = new firebase.auth.GoogleAuthProvider();
                const result = await auth.signInWithPopup(googleProvider);
                const user = result.user;
                const additionalUserInfo = result.additionalUserInfo;
                const isNewUser = additionalUserInfo.isNewUser;

                if (isNewUser) {
                    //call login action from actions
                    signUpUserSuccess({ dispatch, user, firstName, lastName, phone, email: user.email, active, companyName, companyLocation, companyPhone, companyEmail, logo, navigate });
                } else {
                    //force user to sign out if there is a device has already logged in.
                    function sanitizeEmailForFirestore(e) {
                        const sanitizedEmail = e.replace(/[\/\.\.\_\~%]/g, '').substring(0, 1500);
                        return sanitizedEmail;
                    }
                    
                    const userEmail = sanitizeEmailForFirestore(user.email);

                    const docRef = firebase.firestore().collection('activeSessions').doc(userEmail);
                    const doc = await docRef.get();

                    if (doc.exists) {
                        firebase.auth().signOut()
                        .then(() => {
                            console.log('logged out');
                            navigate('/');
                            message.success({
                                content: "You are already logged in from another device. Please log out from there first.",
                                duration: 3,
                                icon: smileyIcon(),
                            }).then(() => null);
                        }).catch(function(error) {
                            // An error happened.
                            console.log('log out failed');
                            console.log(error);
                        });
                    } else {
                        await docRef.set({ count: 1 });
                    }
                }
            } else {
                if (google) {
                    const auth = firebase.auth();
                    const googleProvider = new firebase.auth.GoogleAuthProvider();
                    const result = await auth.signInWithPopup(googleProvider);
                    const user = result.user;
                    if (result.user) {
                        signUpUserSuccess({ dispatch, user, firstName, lastName, phone, email: user.email, active, companyName, companyLocation, companyPhone, companyEmail, logo, navigate });
                    }
                } else {
                    firebase.auth().createUserWithEmailAndPassword(email, password)
                        .then(() => {
                            firebase.auth().onAuthStateChanged(user => {
                                if (user) {
                                    signUpUserSuccess({ dispatch, user, firstName, lastName, phone, email, active, companyName, companyLocation, companyPhone, companyEmail, logo, navigate });
                                }
                            })
                        })
                        .catch((error) => signUpUserFail(dispatch, error))
                }
            }
        } catch (e) {
            signUpUserFail(dispatch, e)
        }
    };
};

const signUpUserSuccess = ({ dispatch, user, firstName, lastName, phone, email, active, companyName, companyLocation, companyPhone, companyEmail, logo, navigate  }) => {

    firebase.firestore().collection("users").doc("admins")
        .get()
        .then(doc => {
            if (doc.exists) {
                //check if user public path exists
                checkUserProfilePath({ dispatch, user, firstName, lastName, phone, email, active, companyName, companyLocation, companyPhone, companyEmail, logo, navigate });
            } else {
                //create clients path
                firebase.firestore().collection("users").doc("admins")
                    .set({})
                    .then(() => {
                        //create user public path
                        createUserPublicPath({ dispatch, user, firstName, lastName, phone, email, active, companyName, companyLocation, companyPhone, companyEmail, logo, navigate });
                    }).catch(e => console.log(e));
            }
        })
};

function checkUserProfilePath({ dispatch, user, firstName, lastName, phone, email, active, companyName, companyLocation, companyPhone, companyEmail, logo, navigate }) {
    firebase.firestore().collection("users").doc("admins").collection(user.uid).doc("public")
        .get()
        .then((doc) => {
            if (doc.exists) {
                //set user data
                setUserAcc({ dispatch, user, firstName, lastName, phone, email, active, companyName, companyLocation, companyPhone, companyEmail, logo, navigate });
            } else {
                //create user path
                createUserPublicPath({ dispatch, user, firstName, lastName, phone, email, active, companyName, companyLocation, companyPhone, companyEmail, logo, navigate });
            }
        }).catch(e => console.log(e));
}

function createUserPublicPath({ dispatch, user, firstName, lastName, phone, email, active, companyName, companyLocation, companyPhone, companyEmail, logo, navigate }) {
    firebase.firestore().collection("users").doc("admins").collection(user.uid).doc("public")
        .set({})
        .then(() => {
            //set user acc data
            setUserAcc({ dispatch, user, firstName, lastName, phone, email, active, companyName, companyLocation, companyPhone, companyEmail, logo, navigate });
        }).catch(e => console.log(e));
}

async function setUserAcc({ dispatch, user, firstName, lastName, phone, email, active, companyName, companyLocation, companyPhone, companyEmail, logo, navigate }) {

    //Get directorsID link
    let fileDownloadUrl = "";
    if (logo) {
        fileDownloadUrl = await uploadFile({ file: logo, path: "Logo" });
    }

    firebase.firestore().collection("users").doc("admins").collection(user.uid).doc("public").collection("account").doc("info")
        .set({
            paid: false,
            verified: false,
            disabled: false,
            attended: false,
            createdOn: new Date(),
            firstName,
            lastName,
            phone,
            email,
            role: "admin",
            companyName,
            companyLocation,
            companyPhone,
            companyEmail,
            logo: fileDownloadUrl,
            active,
            userID: user.uid,
            totalPayments: 0, interviewOffers: 0, acceptedOffers: 0, rejectedOffers: 0 }, { merge: true })
        .then(() => {
            firebase.firestore().collection("adminBucket").doc(user.uid)
                .set({
                    paid: false,
                    verified: false,
                    disabled: false,
                    attended: false,
                    createdOn: new Date(),
                    firstName,
                    lastName,
                    phone,
                    email,
                    role: "admin",
                    companyName,
                    companyLocation,
                    companyPhone,
                    companyEmail,
                    logo: fileDownloadUrl,
                    active,
                    userID: user.uid,
                    interviewOffers: 0, acceptedOffers: 0, rejectedOffers: 0 }, { merge: true })
                .then(() => {

                    sendCompanyEmail({
                        email,
                        firstName,
                        company: companyName,
                        templateId: "d-3eb9a95f96a446e99b3f960fb0965f58",
                    }).catch(e => console.log(e));

                    dispatch({
                        type: SIGN_UP_USER_SUCCESSFUL,
                        payload: user
                    });

                    navigate("/");
                })
                .catch(e => console.log(e));
        })
        .catch(e => console.log(e));
}

const sendCompanyEmail = async ({ email, firstName, company, templateId }) => {

    try {
        const response = await fetch("https://us-central1-nafasiio.cloudfunctions.net/sendCompanyVerificationEmail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email, firstName, company, templateId
            }),
        });

        if (response.ok) {
            //message.success("Email sent successful to candidate!");
        } else {
            //message.info("Interview offer is sent, however, could not send candidate an email");
        }
    } catch (error) {
        console.error("Error calling function:", error);
    } finally {
        //message.success("Email sent successful to candidate!");
    }
};

export const userLogin = ({ email, password, navigate }) => {
    return(dispatch) => {
        dispatch({ type: LOGIN_USER});
        firebase.firestore().collection("adminBucket").where("email", "==", email)
            .get()
            .then((snapshot) => {
                if (snapshot.size !== 0){
                    //check if pulled admin is a superAdmin
                    let admin = {};

                    snapshot.forEach(doc => {
                        const data = doc.data();
                        if (data.role === "superAdmin" || data.role === "admin") {
                            admin = data;
                        }
                    });

                    if (!(_.isEmpty(admin))) {
                        //send login details to firebase
                        firebase.auth().signInWithEmailAndPassword(email, password)
                            .then(() => {
                                //make sure to push to new page before reloading
                                navigate('/');
                                window.location.reload();
                                //if login is successful execute
                                dispatch({ type: LOGIN_SUCCESSFUL });

                                //window.location.reload();
                                message.success({
                                    content:"Welcome",
                                    duration: 3,
                                    icon: smileyIcon(),
                                }).then(() => null);

                                //if success, login user
                                dispatch({
                                    type: LOGIN_SUCCESSFUL
                                });

                                dispatch({
                                    type: INITIALIZE_STATE
                                });
                            })
                            .catch((error) => {
                                // Handle Errors here.
                                const errorCode = error.code;
                                const errorMessage = error.message;
                                // ...
                                console.log(errorCode);
                                console.log(errorMessage);
                                console.log("log in error");
                                dispatch ({ type: LOGIN_FAILED });
                                console.log(error);
                                message.error({
                                    content: `${error}`,
                                    duration: 5,
                                }).then(() => null);
                            })
                    } else {
                        //user is not allowed to login
                        dispatch({ type: LOGIN_FAILED });
                        message.warning({
                            content:`Sorry! Access denied, tempering with system will get your account blacklisted`,
                            duration: 3,
                            icon: fillWarning(),
                        }).then(() => null);
                    }
                } else {
                    //user is not allowed to login
                    dispatch({ type: LOGIN_FAILED });

                    message.warning({
                        content:`Sorry! Access denied`,
                        duration: 3,
                        icon: fillWarning(),
                    }).then(() => null);
                }
            })
            .catch(e => {
                dispatch({ type: LOGIN_FAILED });
                console.log(e);
                message.warning({
                    content: `Sorry! Login Failed`,
                    duration: 3,
                    icon: fillWarning(),
                }).then(() => null);
            });
    };
};

export const getLoading = () => {
    return(dispatch) => {
        dispatch({ type: LOGIN_USER});
    };
};

export const getLoadingFalse = () => {
    return(dispatch) => {
        dispatch({ type: LOGIN_FAILED});
    };
};


const signUpUserFail = (dispatch, error) => {

    message.error({
        content: `${error.message}`,
        duration: 5,
    })

    // Handle Errors here.
    const errorCode = error.code;
    //const errorMessage = error.message;

    switch (errorCode) {
        case "auth/invalid-email":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "Fill in valid email"
            });
            break;
        case "auth/account-exists-with-different-credential":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "account already exists"
            });
            break;
        case "auth/credential-already-in-use":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "Credential already in use"
            });
            break;
        case "auth/email-already-in-use":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "Email already in use"
            });
            break;
        case "auth/network-request-failed":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "There's a network error"
            });
            break;
        case "auth/too-many-requests":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "Detected unusual activity"
            });
            break;
        case "auth/user-token-expired":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "Please try again in a moment"
            });
            break;
        case "auth/weak-password":
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "Password should be at least 6 characters"
            });
            break;
        default:
            dispatch({
                type: SIGN_UP_USER_FAILED,
                payload: "Authentication failed"
            });
    }
};

export const userLogOut = (navigate) => {
    return async (dispatch) => {
        console.log('Starting logout process...');
        dispatch({type: LOG_OUT});

        try {
            // Force sign out regardless of current user state
            await firebase.auth().signOut();
            console.log('Firebase sign out successful');

            // Clear all states
            dispatch({type: LOGOUT_SUCCESSFULLY});
            dispatch({type: INITIALIZE_STATE});

            // Show success message
            message.success({
                content: "You are now signed out!",
                duration: 2,
                icon: smileyIcon(),
                style: {
                    marginTop: '20vh',
                },
            });

            // Navigate to landing page instead of sign-in
            console.log('Navigating to landing');
            navigate('/', { replace: true });

        } catch (error) {
            console.error('Logout error:', error);
            dispatch({type: LOGOUT_FAILED});
            
            // Show error message
            message.error({
                content: "Failed to sign out. Please try again.",
                duration: 2,
                style: {
                    marginTop: '20vh',
                },
            });
        }
    }
};

export const updateProfile = ({ firstName, lastName, phone, companyName, companyPhone, companyEmail, companyLocation, logo, logoChanged, adminIDs, companyRegDocument, navigate }) => {
    return async(dispatch) => {
        try {
            const userID = firebase.auth().currentUser.uid;
            let fileDownloadUrl = logo;
            let adminIDUrl = adminIDs;
            let companyRegDocUrl = companyRegDocument;

            // Only process logo if it's changed and is a base64 string
            if (logoChanged && logo && logo.startsWith('data:image')) {
                try {
                    fileDownloadUrl = await base64UploadFile({ 
                        base64Data: logo, 
                        fileType: logo.split(';')[0].split(':')[1],
                        path: "Logo" 
                    });
                } catch (error) {
                    console.error('Error uploading logo:', error);
                    message.error('Failed to upload logo. Please try again.');
                    return;
                }
            }

            // Update Firestore with new data
            const updateData = {
                firstName,
                lastName,
                phone,
                companyName,
                companyLocation,
                companyPhone,
                companyEmail,
                ...(fileDownloadUrl ? { logo: fileDownloadUrl } : {}),
                ...(adminIDUrl ? { adminIDs: adminIDUrl } : {}),
                ...(companyRegDocUrl ? { companyRegDocument: companyRegDocUrl } : {})
            };

            // Update both collections
            await firebase.firestore()
                .collection("users")
                .doc("admins")
                .collection(userID)
                .doc("public")
                .collection("account")
                .doc("info")
                .update(updateData);

            await firebase.firestore()
                .collection("adminBucket")
                .doc(userID)
                .update(updateData);

            dispatch({ type: SIGN_UP_USER_SUCCESSFUL });
            message.success('Profile updated successfully');
            
            // Navigate to Profile page
            navigate('/profile');

        } catch (error) {
            console.error('Error updating profile:', error);
            message.error('Failed to update profile. Please try again.');
        }
    }
}

async function uploadFile({ file, path }) {

    const reader = new FileReader();

    console.log("called logo");
    return new Promise((resolve, reject) => {
        reader.onloadend = async () => {
            try {
                const blob = new Blob([reader.result], { type: file.type });

                const response = await fetch(
                    `https://us-central1-nafasiio.cloudfunctions.net/upload${path}`,
                    {
                        method: "POST",
                        mode: "cors",
                        headers: { "Content-Type": file.type },
                        body: blob,
                    }
                );

                const url = await response.text();

                resolve(url);
            } catch (error) {
                console.log("Here's your error");
                console.log(error);

                reject(error);
            }
        };

        reader.readAsArrayBuffer(file);
    });
}

async function base64UploadFile({ base64Data, fileType, path }) {
    console.log(base64Data, fileType, path)
    try {
        const blob = base64ToBlob(base64Data, fileType);

        const response = await fetch(
            `https://us-central1-nafasiio.cloudfunctions.net/upload${path}`,
            {
                method: "POST",
                mode: "cors",
                headers: { "Content-Type": fileType },
                body: blob,
            }
        );

        return await response.text();
    } catch (error) {
        console.error("Here's your error");
        console.error(error);

        throw error;
    }
}

const base64ToBlob = (base64, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
};