import React, { Component } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { searchFieldChanged } from '../../../actions/SearchActions';

const { Option } = Select;

class StudiedAbroad extends Component {
	constructor(props) {
		super(props);
		this.state = {
			studiedAbroad: this.props.studiedAbroad
		}

		this.handleChange = this.handleChange.bind(this);
	}

	componentWillReceiveProps(nextProps, nextContext) {
		if (nextProps !== this.props) {
			this.setState({
				studiedAbroad: nextProps.studiedAbroad
			});
		}
	}

	handleChange(value) {
		if (value === undefined || value === "") {
			// Dispatch an action to set foreignJob to null
			this.props.searchFieldChanged({ prop: "studiedAbroad", value: null });
		} else {
			// Otherwise, proceed as usual
			this.props.searchFieldChanged({ prop: "studiedAbroad", value });
		}
	};


	render() {
		const { studiedAbroad } = this.state;

		return (
			<div className="variable-section">
				<p style={{color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left"}}>Studied
					Abroad</p>
				<Select value={studiedAbroad} allowClear onChange={this.handleChange} placeholder="Studied Abroad"
				        style={{width: "100%", borderColor: '#000'}}>
					<Option value={true}>Yes</Option>
					<Option value={false}>No</Option>
				</Select>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { studiedAbroad } = state.search;

	return {
		studiedAbroad
	};
};

const mapDispatchToProps = {
	searchFieldChanged, // Assuming you have an action creator to update the status
};

export default connect(mapStateToProps, mapDispatchToProps)(StudiedAbroad);