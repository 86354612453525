import React from 'react';
import { NavLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { motion } from 'framer-motion';
import Logo from "../../../assets/logos/taglineLogo.png";
import LandingImage from "../../../assets/images/nafasi_landing.png";

const styles = {
    platformWrapper: {
        position: 'relative',
        width: '90%',
        maxWidth: '540px',
        margin: '0 auto',
        background: 'linear-gradient(145deg, #2a2a2a, #1a1a1a)',
        borderRadius: '12px',
        padding: '12px',
        boxShadow: '0 20px 40px rgba(0,0,0,0.2)',
    },
    macFrame: {
        position: 'relative',
        width: '100%',
        borderRadius: '12px',
        background: '#1a1a1a',
        padding: '2px',
        boxShadow: 'inset 0 0 0 1px rgba(255,255,255,0.1)',
    },
    screen: {
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
        border: '1px solid rgba(255,255,255,0.1)',
        background: '#000',
        position: 'relative',
        overflow: 'hidden',
    },
    screenOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(120deg, rgba(255,255,255,0.07) 0%, rgba(255,255,255,0) 100%)',
        pointerEvents: 'none',
    },
    macTopBar: {
        position: 'absolute',
        top: '-3px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '20%',
        height: '6px',
        background: '#1a1a1a',
        borderRadius: '0 0 4px 4px',
        boxShadow: 'inset 0 -1px 0 rgba(255,255,255,0.1)',
    },
    camera: {
        position: 'absolute',
        top: '4px',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '6px',
        height: '6px',
        borderRadius: '50%',
        background: '#0f0f0f',
        boxShadow: 'inset 0 0 2px rgba(0,0,0,0.8)',
    },
    floatingIllustrations: {
        position: 'absolute',
        width: '120%',
        height: '120%',
        top: '-10%',
        left: '-10%',
        pointerEvents: 'none',
        zIndex: 2,
    }
};

const MarketingRecruiterSVG = () => (
    <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient id="screenGrad" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#f8f9fa"/>
                <stop offset="100%" stopColor="#e9ecef"/>
            </linearGradient>
            <linearGradient id="chartLine" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stopColor="#FFB400"/>
                <stop offset="100%" stopColor="#FFD700"/>
            </linearGradient>
        </defs>

        {/* Device Frame */}
        <motion.g
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
        >
            {/* Main Monitor */}
            <rect x="45" y="40" width="160" height="120" rx="8" fill="#2a2a2a"/>
            <rect x="50" y="45" width="150" height="110" rx="4" fill="url(#screenGrad)"/>
            
            {/* Monitor Stand */}
            <path d="M105 160h40v10h-40z" fill="#2a2a2a"/>
            <path d="M95 170h60l-10 20h-40z" fill="#1a1a1a"/>

            {/* Screen Content */}
            <g>
                {/* Chart Background */}
                <rect x="60" y="55" width="130" height="90" rx="4" fill="white"/>
                
                {/* Chart Grid Lines */}
                {[0, 1, 2, 3].map(i => (
                    <line
                        key={`grid-${i}`}
                        x1="70"
                        y1={75 + i * 20}
                        x2="180"
                        y2={75 + i * 20}
                        stroke="#e9ecef"
                        strokeWidth="1"
                    />
                ))}

                {/* Chart Line */}
                <motion.path
                    d="M70 125 Q90 85 110 105 T150 65"
                    stroke="url(#chartLine)"
                    strokeWidth="3"
                    strokeLinecap="round"
                    fill="none"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{ 
                        duration: 2,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatDelay: 3
                    }}
                />

                {/* Data Points */}
                {[
                    { cx: 90, cy: 85 },
                    { cx: 130, cy: 105 },
                    { cx: 150, cy: 65 }
                ].map((point, i) => (
                    <motion.circle
                        key={`point-${i}`}
                        cx={point.cx}
                        cy={point.cy}
                        r="4"
                        fill="#FFB400"
                        initial={{ scale: 0 }}
                        animate={{ scale: [0, 1.2, 1] }}
                        transition={{
                            duration: 0.5,
                            delay: 1 + (i * 0.2),
                            repeat: Infinity,
                            repeatDelay: 4.5
                        }}
                    />
                ))}
            </g>
        </motion.g>

        {/* Add monitor reflection */}
        <rect 
            x="50" 
            y="45" 
            width="150" 
            height="110" 
            rx="4" 
            fill="url(#screenGrad)"
            opacity="0.8"
        />
        <path
            d="M50 45h150"
            stroke="rgba(255,255,255,0.5)"
            strokeWidth="1"
        />
        
        {/* Add power button */}
        <circle
            cx="195"
            cy="155"
            r="3"
            fill="#40A9FF"
        >
            <animate
                attributeName="opacity"
                values="1;0.5;1"
                dur="2s"
                repeatCount="indefinite"
            />
        </circle>
    </svg>
);

const ContractSigningSVG = () => (
    <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <linearGradient id="paperGrad" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor="#ffffff"/>
                <stop offset="100%" stopColor="#f8f9fa"/>
            </linearGradient>
        </defs>

        <motion.g
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
        >
            {/* Enhanced paper stack effect */}
            <rect x="48" y="48" width="160" height="160" rx="4" fill="#e9ecef"/>
            <rect x="44" y="44" width="160" height="160" rx="4" fill="#f1f3f5"/>
            <rect x="40" y="40" width="160" height="160" rx="4" fill="url(#paperGrad)"/>
            
            {/* Add paper fold effect */}
            <path
                d="M40 40l20 -20"
                stroke="#e9ecef"
                strokeWidth="1"
                opacity="0.5"
            />
            
            {/* Add subtle shadow */}
            <rect
                x="40"
                y="40"
                width="160"
                height="160"
                rx="4"
                fill="rgba(0,0,0,0.05)"
                filter="blur(4px)"
            />

            {/* Document Content */}
            <g>
                {/* Header */}
                <rect x="60" y="60" width="80" height="8" rx="2" fill="#2997FF"/>
                
                {/* Text Lines */}
                {[0, 1, 2, 3].map(i => (
                    <rect
                        key={`line-${i}`}
                        x="60"
                        y={80 + (i * 20)}
                        width={120 - (i * 20)}
                        height="4"
                        rx="1"
                        fill="#e9ecef"
                    />
                ))}

                {/* Signature Line */}
                <line
                    x1="60"
                    y1="160"
                    x2="180"
                    y2="160"
                    stroke="#FFB400"
                    strokeWidth="2"
                    strokeDasharray="4 2"
                />

                {/* Animated Signature */}
                <motion.path
                    d="M70 160 C90 140, 110 180, 130 160 S150 140, 170 160"
                    stroke="#2997FF"
                    strokeWidth="2"
                    fill="none"
                    initial={{ pathLength: 0 }}
                    animate={{ pathLength: 1 }}
                    transition={{
                        duration: 2,
                        ease: "easeInOut",
                        repeat: Infinity,
                        repeatDelay: 3
                    }}
                />

                {/* Success Checkmark */}
                <motion.g
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{
                        delay: 2,
                        type: "spring",
                        stiffness: 200,
                        damping: 10
                    }}
                >
                    <circle cx="160" cy="80" r="15" fill="#FFB400"/>
                    <path
                        d="M153 80l5 5 10-10"
                        stroke="white"
                        strokeWidth="2"
                        fill="none"
                    />
                </motion.g>
            </g>
        </motion.g>
    </svg>
);

const ConversionPage = () => {
    return (
        <div className="apple-style-landing">
            <div className="hero-container">
                {/* Left Content */}
                <motion.div 
                    className="hero-left"
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.8 }}
                >
                    <img src={Logo} alt="Logo" className="nafasi-hero-logo" />
                    <div className="hero-content">
                        <motion.h1 
                            className="main-heading"
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.3 }}
                        >
                            Matching Recruiters with Top Talent
                        </motion.h1>
                        <motion.p
                            className="sub-heading"
                            initial={{ y: 20, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            transition={{ delay: 0.5 }}
                        >
                            Try <span className="highlight">Nafasi now</span> for better results.
                        </motion.p>
                        
                        <motion.div className="cta-container">
                            <NavLink to={isMobile ? '/register' : '/login'}>
                                <motion.button
                                    className="get-started-button"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    Get Started
                                </motion.button>
                            </NavLink>
                        </motion.div>
                    </div>
                </motion.div>

                {/* Right Content */}
                <motion.div className="hero-right">
                    <div style={styles.platformWrapper}>
                        <div style={styles.macTopBar}>
                            <div style={styles.camera}></div>
                        </div>
                        <div style={styles.macFrame}>
                            <div style={styles.screen}>
                                <img src={LandingImage} alt="Platform" className="platform-image" />
                                <div style={styles.screenOverlay}></div>
                            </div>
                        </div>
                        
                        <div style={styles.floatingIllustrations}>
                            <motion.div
                                style={{
                                    position: 'absolute',
                                    top: '-40px',
                                    right: '-30px',
                                    transform: 'scale(0.65)',
                                }}
                                animate={{ 
                                    y: [0, -8, 0],
                                    rotate: [0, 1.5, 0]
                                }}
                                transition={{
                                    duration: 4,
                                    repeat: Infinity,
                                    ease: "easeInOut"
                                }}
                            >
                                <MarketingRecruiterSVG />
                            </motion.div>
                            
                            <motion.div
                                style={{
                                    position: 'absolute',
                                    bottom: '-30px',
                                    left: '-20px',
                                    transform: 'scale(0.65)',
                                }}
                                animate={{ 
                                    y: [0, 8, 0],
                                    rotate: [0, -1.5, 0]
                                }}
                                transition={{
                                    duration: 4,
                                    repeat: Infinity,
                                    ease: "easeInOut",
                                    delay: 1
                                }}
                            >
                                <ContractSigningSVG />
                            </motion.div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </div>
    );
};

export default ConversionPage;