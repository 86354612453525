import React from 'react';
import {DatePicker, message} from 'antd';
import { connect } from 'react-redux';
import moment from "moment";

//action imports
import { searchFieldChanged } from '../../../actions/SearchActions';

class BirthdateFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startBirthDate: null,
            endBirthDate: null,
        };

        this.handleStartDateChange = this.handleStartDateChange.bind(this);
        this.handleEndDateChange = this.handleEndDateChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                startBirthDate: nextProps.startBirthDate,
                endBirthDate: nextProps.endBirthDate,
            });
        }
    }

    handleStartDateChange = (date) => {
        const { endBirthDate } = this.state;
        console.log("startBirthDate", date);
        if (endBirthDate !== null) {
            this.props.searchFieldChanged({ prop: "startBirthDate", value: date });
        } else {
            this.props.searchFieldChanged({ prop: "startBirthDate", value: date });
        }
    }

    handleEndDateChange = (date) => {
        const { startBirthDate } = this.state;
        if (startBirthDate !== null) {
            this.props.searchFieldChanged({ prop: "endBirthDate", value: date });
        } else {
            this.props.searchFieldChanged({ prop: "endBirthDate", value: date });
        }
    }

    handleFilter = () => {
        const { startBirthDate, endBirthDate } = this.state;
        // Perform filtering logic based on the selected birthdate range
        // You can access startDate and endDate values here and apply the necessary filtering
    }

    render() {
        const { startBirthDate, endBirthDate } = this.state;

        return (
            <div className="variable-section">
                <p style={{color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left"}}>Birthdate
                    Range</p>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <DatePicker
                        onChange={this.handleStartDateChange}
                        allowClear
                        placeholder="Start Date"
                        value={startBirthDate}
                        style={{width: "100%"}}
                    />
                    <DatePicker
                        onChange={this.handleEndDateChange}
                        allowClear
                        placeholder="End Date"
                        value={endBirthDate}
                        style={{width: "100%"}}
                    />
                    {/*<Button onClick={this.handleFilter} type="primary">Filter</Button>*/}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { startBirthDate, endBirthDate } = state.search;

    return {
        startBirthDate, endBirthDate
    };
};

const mapDispatchToProps = {
    searchFieldChanged, // Assuming you have an action creator to update packageIndex
};

export default connect(mapStateToProps, mapDispatchToProps)(BirthdateFilter);