import React from 'react';
import {connect} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import _ from "lodash";
import { message, Input, Select, Popconfirm, Steps, Button } from "antd";
import { HeartOutlined, CheckCircleOutlined, CloseCircleOutlined, CrownOutlined, ArrowLeftOutlined } from '@ant-design/icons';
//import 'antd/dist/antd.css';

//action imports
import { subscriptionFieldUpdated, makePayment, fetchPaymentStatus, makeForeignPayment, validateAffiliateCode } from "../../../../actions/SubscriptionActions";

//component imports

//file imports
//import Logo from '../../../../education/logos/logo.png';
import Earth from '../../../../assets/images/worldwide.png';
import Star from '../../../../assets/images/star.png';
import Galaxy from '../../../../assets/images/solar-system.png';
import Universe from '../../../../assets/images/universe.png';
import {Spinner} from "../../../common/Spinner";
import PaymentModal from "./PaymentModal";
import './ChoosePackage.css';
import airtelLogo from '../../../../assets/images/airtel.png';
import mixxByYas from '../../../../assets/images/yas.svg';
import mPesa from '../../../../assets/images/mpesa.png';
import haloPesa from '../../../../assets/images/halopesa.png';

//property declaration
const paymentPackages = [
    {
        benefits: [
            "Sign up FREE today!",
            "Explore 50k+ sorted CVs.",
            "Regular platform updates",
            "Dedicated support team",
            "Industry-leading security"
        ],
        packageID: "individual",
        packageIndex: 4,
        price: 120000,
        foreignPrice: 40,
        title: "Individual",
        index: 4,
        className: "individual",
        icon: Earth,
        recommended: false
    },
    {
        benefits: [
            "Includes Free Plan perks",
            "View profiles & contacts",
            "Automated CV filtering",
            "Basic analytics and reports",
            "Interview Assessment System"
        ],
        packageID: "startup",
        packageIndex: 3,
        price: 159000,
        foreignPrice: 59,
        title: "Startup",
        index: 3,
        className: "startup",
        icon: Star,
        recommended: true
    },
    {
        benefits: [
            "Includes Startup Plan perks.",
            "Advanced search filters",
            "Detailed analytics and demographics.",
            "Real-time candidate alerts"
        ],
        packageID: "business",
        packageIndex: 2,
        price: 257000,
        foreignPrice: 99,
        title: "Business",
        index: 2,
        className: "business",
        icon: Galaxy,
        recommended: false
    },
    {
        benefits: [
            "Includes Business Plan perks",
            "AI-driven matching & ranking",
            "All features inclusive",
            "Premium support"
        ],
        packageID: "enterprise",
        packageIndex: 1,
        price: 699000,
        foreignPrice: 269,
        title: "Enterprise",
        index: 1,
        className: "enterprise",
        icon: Universe,
        recommended: false
    }
];



class ChoosePackageBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            systemInfo: this.props.systemInfo,
            profile: this.props.profile,

            paymentPackage: this.props.paymentPackage,
            carrier: this.props.carrier,
            paymentPhone: this.props.paymentPhone,
            paymentMonths: this.props.paymentMonths,

            months: this.props.months,
            paymentPackages: this.props.paymentPackages,
            paymentLoading: this.props.paymentLoading,
            transID: this.props.transID,
            payment: this.props.payment,

            affiliateCode: this.props.affiliateCode,
            validCode: this.props.validCode,
            validCodeLoading: this.props.validCodeLoading,
            success: this.props.success,

            subTotal: 0,
            discount: 0,
            discountPercent: 0,
            processingFee: 0,
            total: 0,

            flwUrl: this.props.flwUrl,
            packageTitle: this.props.packageTitle,
            currentStep: 0,
            selectedPackage: null
        };

        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handlePaymentPackageChange = this.handlePaymentPackageChange.bind(this);
        this.handleSelectCarrier = this.handleSelectCarrier.bind(this);
        this.handleSelectMonth = this.handleSelectMonth.bind(this);
        this.onMakePayment = this.onMakePayment.bind(this);
        this.populatePhone = this.populatePhone.bind(this);
        this.handleAffiliateCode = this.handleAffiliateCode.bind(this);
        this.onApplyPromo = this.onApplyPromo.bind(this);
        this.cancel = this.cancel.bind(this);
        this.handleStepChange = this.handleStepChange.bind(this);
        this.handlePackageSelect = this.handlePackageSelect.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    componentDidMount() {
        this.populatePhone();
        this.populateTransID();
        this.onAlgorithm({ paymentPackage: this.state.paymentPackage, paymentMonths: this.props.paymentMonths, validCode: this.props.validCode })
    }

    componentWillUnmount() {
        this.props.subscriptionFieldUpdated({prop: 'affiliateCode', value: "" });
        this.props.subscriptionFieldUpdated({prop: 'validCode', value: {} });
        this.props.subscriptionFieldUpdated({prop: 'validCodeLoading', value: false });
        this.props.subscriptionFieldUpdated({prop: 'success', value: null });
    }

    populatePhone() {
        const { profile } = this.state;
        if (!(_.isEmpty(profile))) {
            const phone = profile.phone;
            this.props.subscriptionFieldUpdated({ prop: "paymentPhone", value: phone });
        }
    }

    populateTransID() {
        const { transID } = this.state;
        if (transID) {
            this.props.fetchPaymentStatus({ transactionID: transID });
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                systemInfo: nextProps.systemInfo,
                profile: nextProps.profile,

                paymentPackage: nextProps.paymentPackage,
                carrier: nextProps.carrier,
                paymentPhone: nextProps.paymentPhone,
                paymentMonths: nextProps.paymentMonths,

                months: nextProps.months,
                packageTitle: nextProps.packageTitle,
                paymentPackages: nextProps.paymentPackages,
                paymentLoading: nextProps.paymentLoading,
                transID: nextProps.transID,
                payment: nextProps.payment,
                flwUrl: nextProps.flwUrl,

                affiliateCode: nextProps.affiliateCode,
                validCode: nextProps.validCode,
                validCodeLoading: false,
                success: nextProps.validCode && Object.keys(nextProps.validCode).length > 0,

                subTotal: nextProps.subTotal,
                discount: nextProps.discount,
                discountPercent: nextProps.discountPercent,
                processingFee: nextProps.processingFee,
                total: nextProps.total,
            });

            if (nextProps.validCode !== this.props.validCode) {
                if (nextProps.validCode && Object.keys(nextProps.validCode).length > 0) {
                    message.success('Promo code applied successfully!');
                } else if (this.state.validCodeLoading) {
                    message.error('Invalid promo code');
                }
            }
        }

        if (nextProps.flwUrl !== this.props.flwUrl) {
            console.log({ flwUrl: nextProps.flwUrl });
        }

        if (nextProps.profile !== this.props.profile) {
            this.populatePhone();
        }

        if (nextProps.transID !== this.props.transID) {
            this.populateTransID();
        }

        this.onAlgorithm({ paymentPackage: nextProps.paymentPackage, paymentMonths: nextProps.paymentMonths, validCode: nextProps.validCode })
    }

    onApplyPromo = () => {
        const { affiliateCode } = this.state;
        if (!affiliateCode) {
            message.error('Please enter a promo code');
            return;
        }
        
        this.setState({ validCodeLoading: true });
        try {
            this.props.validateAffiliateCode({ affiliateCode })
                .then(() => {
                    if (this.state.success) {
                        message.success('Promo code applied successfully!');
                    } else {
                        message.error('Invalid promo code');
                    }
                });
        } catch (error) {
            this.setState({ 
                validCodeLoading: false 
            });
            message.error('Error validating promo code');
        }
    };

    onAlgorithm({ paymentPackage, paymentMonths, validCode }) {
        let subTotal = 0;
        let discountPercent = 0;
        let discount = 0;
        let processingFee = 0;
        const app = this.state.systemInfo || {}; // Assuming `app` is a prop

        if (!(_.isEmpty(paymentPackage))) {
            subTotal = paymentPackage.price;
        }
        if (!(_.isEmpty(paymentMonths))) {
            const payMonth = JSON.parse(paymentMonths);
            subTotal = subTotal * payMonth.value;
        }

        //COMPUTE AFFILIATE CODE
        if (!(_.isEmpty(validCode))) {
            try {
                //check if valid code has a discount percent
                if ("discountPercent" in validCode) {
                    discountPercent = validCode.discountPercent;
                    if (discountPercent > 0) {
                        discount  = subTotal * discountPercent;
                    } else {
                        if ("discountPercent" in app) {
                            discountPercent = app.discountPercent;
                            discount  = subTotal * discountPercent;
                        }
                    }
                } else {
                    if ("discountPercent" in app) {
                        discountPercent = app.discountPercent;
                        discount  = subTotal * discountPercent;
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }


        if ("processingFee" in app) {
            const processing = app.processingFee;
            processingFee = subTotal * processing;
        }

        const total = (subTotal + processingFee) - discount;

        this.props.subscriptionFieldUpdated({ prop: "discount", value: discount });
        this.props.subscriptionFieldUpdated({ prop: "discountPercent", value: discountPercent });
        this.props.subscriptionFieldUpdated({ prop: "subTotal", value: subTotal });
        this.props.subscriptionFieldUpdated({ prop: "processingFee", value: processingFee });
        this.props.subscriptionFieldUpdated({ prop: "total", value: total });
    }

    handlePhoneChange(e) {
        const value = e.target.value;

        if (value.length === 0) {
            this.props.subscriptionFieldUpdated({ prop: "paymentPhone", value });
        } else {
            const validateInput = (input) => {
                const regex = /^\d+$/; // Regex to match only digits
                const maxLength = 10;
                return regex.test(input) && input.length <= maxLength;
            };

            if (validateInput(value)) {
                this.props.subscriptionFieldUpdated({ prop: "paymentPhone", value });
            } else {
                message.warning("Enter a valid phone number");
            }
        }
    };

    handleAffiliateCode(e) {
        const value = e.target.value;
        this.props.subscriptionFieldUpdated({prop: 'affiliateCode', value: value.toUpperCase() });
        this.props.subscriptionFieldUpdated({prop: 'success', value: null} );
        this.props.subscriptionFieldUpdated({prop: 'validCode', value: {} });
    };

    handlePaymentPackageChange(value) {
        this.props.subscriptionFieldUpdated({ prop: "paymentPackage", value });
    };

    handleSelectCarrier(value) {
        this.props.subscriptionFieldUpdated({ prop: "carrier", value });
    };

    handleSelectMonth(value) {
        this.props.subscriptionFieldUpdated({ prop: "paymentMonths", value });
    };

    onMakePayment(paymentPackage) {
        const { profile, carrier, paymentMonths, paymentPhone, validCode, systemInfo } = this.state;

        try {
            if (paymentPackage.index === null) {
                return message.info("Already Subscribed");
            } else {
                if (!(_.isEmpty(profile))) {
                    const name = `${profile.firstName} ${profile.lastName}`;
                    const phone = profile.phone;
                    const email = profile.email;

                    let location = null;
                    if ("companyLocation" in profile) {
                        location = profile.companyLocation;
                    }

                    const paymentDate = new Date();

                    if (!(_.isEmpty(paymentPackage))) {
                        if (!(_.isEmpty(carrier))) {
                            if (!(_.isEmpty(paymentMonths))) {
                                let amount = 0;
                                let processingFee = 0;
                                if (!(_.isEmpty(paymentPackage))) {
                                    amount = paymentPackage.price;
                                }
                                if (!(_.isEmpty(paymentMonths))) {
                                    const payMonth = JSON.parse(paymentMonths);
                                    amount = amount * payMonth.value;
                                }

                                if (paymentPhone) {
                                    this.props.makePayment({
                                        name,
                                        email,
                                        phone,
                                        paymentPackage,
                                        paymentMonths: JSON.parse(paymentMonths),
                                        amount,
                                        validCode,
                                        app: systemInfo,
                                        processingFee,
                                        carrier: JSON.parse(carrier),
                                        paymentPhone,
                                        paymentDate,
                                        location
                                    });
                                } else {
                                    message.warning("Enter Phone #");
                                }
                            } else {
                                message.warning("Pick Months");
                            }
                        } else {
                            message.warning("Choose Wallet");
                        }
                    } else {
                        message.warning("Pick package");
                    }
                } else {
                    message.warning("Loading");
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    cancel() {
        this.props.subscriptionFieldUpdated({ prop: "packageTitle", value: '' });
        message.warning('Canceled');
    }

    onMakeForeignPayment(pack) {
        const { profile, paymentMonths, validCode, systemInfo } = this.state;
        console.log("we are here");
        console.log({ pack, profile });

        try {
            if (pack.index === null) {
                return message.info("Already Subscribed");
            } else {
                if (!(_.isEmpty(profile))) {
                    if (!(_.isEmpty(paymentMonths))) {
                        const name = `${profile.companyName}`;
                        const phone = profile.phone;
                        const email = profile.email;
                        const location = profile.companyLocation;
                        const paymentDate = new Date();

                        this.props.makeForeignPayment({
                            name,
                            email,
                            phone,
                            paymentPackage: pack,
                            paymentMonths,
                            amount: pack.foreignPrice,
                            validCode,
                            app: systemInfo,
                            processingFee: 0,
                            paymentPhone: phone,
                            paymentDate,
                            location,
                            currency: "NGN"
                        });
                    } else {
                        message.warning("Pick Months");
                    }
                } else {
                    alert("Loading");
                }
            }
        } catch (e) {
            console.error(e);
        }
    }

    handlePackageClick(title) {
        this.props.subscriptionFieldUpdated({ prop: "packageTitle", value: title });
    }

    renderPopUp(pack) {
        const { flwUrl, packageTitle } = this.state; 
        if (flwUrl && packageTitle === pack.title) { 
            try {
                return (
                    <div>
                        <PaymentModal
                            flwUrl={flwUrl}
                            visible={true}
                        />
                    </div>
                );
            } catch (e) {
                console.log(e);
            }
        }
    }

    renderInvoice = ({ dollar, amount, pack }) => {
        const { paymentMonths, validCode, systemInfo } = this.state;
        let subTotal = pack ? pack.price : 0;
        let discountPercent = 0;
        let discount = 0;
        let processingFee = 0;

        if (paymentMonths) {
            const payMonth = JSON.parse(paymentMonths);
            subTotal = subTotal * payMonth.value;
        }

        if (!_.isEmpty(validCode)) {
            if ("discountPercent" in validCode) {
                discountPercent = validCode.discountPercent;
                discount = subTotal * discountPercent;
            } else if (systemInfo?.discountPercent) {
                discountPercent = systemInfo.discountPercent;
                discount = subTotal * discountPercent;
            }
        }

        if (systemInfo?.processingFee) {
            processingFee = subTotal * systemInfo.processingFee;
        }

        const total = (subTotal + processingFee) - discount;

        return (
            <div className="nafasi-subscription__card-invoice">
                <div className="nafasi-subscription__card-invoice-row">
                    <span className="nafasi-subscription__card-invoice-label">Sub Total: </span>
                    <span className="nafasi-subscription__card-invoice-value">
                        {dollar ? "$" : "TSH."} {total.toLocaleString()}
                    </span>
                </div>
            </div>
        );
    };

    renderApplyPromoButton = () => {
        const { validCodeLoading } = this.state;
        
        return (
            <button 
                className={`nafasi-subscription__promo-button ${validCodeLoading ? 'nafasi-subscription__promo-button--loading' : ''}`}
                onClick={this.onApplyPromo}
                disabled={validCodeLoading}
            >
                {validCodeLoading ? <Spinner /> : 'Apply'}
            </button>
        );
    };

    handleStepChange(step) {
        this.setState({ currentStep: step });
    }

    handlePackageSelect(pack) {
        this.setState({ 
            selectedPackage: pack,
            currentStep: 1
        });
    }

    handleBack() {
        this.setState(prevState => ({
            currentStep: prevState.currentStep - 1
        }));
    }

    renderPackageSelection() {
        const { dollar } = this.state;
        
        return (
            <div className="nafasi-subscription__grid">
                {paymentPackages.map(pack => {
                    let amount = dollar ? pack.foreignPrice : pack.price;

                    return (
                        <div 
                            key={pack.packageID}
                            className={`nafasi-subscription__card ${pack.recommended ? 'nafasi-subscription__card--recommended' : ''} ${pack.className}`}
                            onClick={() => this.handlePackageSelect(pack)}
                        >
                            {pack.recommended && (
                                <div className="nafasi-subscription__card-badge">
                                    <CrownOutlined /> <span style={{ fontFamily: "ramblaRegular" }}>Most Popular</span>
                                </div>
                            )}
                            <div className="nafasi-subscription__card-header">
                                <img 
                                    src={pack.icon} 
                                    alt={pack.title} 
                                    className="nafasi-subscription__card-icon"
                                />
                                <h2 className="nafasi-subscription__card-title">{pack.title}</h2>
                            </div>
                            
                            <div className="nafasi-subscription__card-pricing">
                                <div className="nafasi-subscription__card-price">
                                    <span className="nafasi-subscription__card-currency">{dollar ? "$" : "TSH."}</span>
                                    <span className="nafasi-subscription__card-amount">{amount.toLocaleString()}</span>
                                </div>
                                <div className="nafasi-subscription__card-period">per month</div>
                            </div>

                            <div className="nafasi-subscription__card-benefits">
                                {pack.benefits.map((benefit, index) => (
                                    <div key={index} className="nafasi-subscription__card-benefit">
                                        <CheckCircleOutlined className="nafasi-subscription__card-benefit-icon" />
                                        <span style={{ marginTop: "1px", fontFamily: "ramblaRegular", fontSize: "0.9rem" }}>{benefit}</span>
                                    </div>
                                ))}
                            </div>

                            <button className={`nafasi-subscription__card-button ${pack.recommended ? 'nafasi-subscription__card-button--recommended' : ''}`}>
                                <span style={{ fontFamily: "ramblaRegular", fontSize: "0.9rem" }}>Select Plan</span>
                            </button>
                        </div>
                    );
                })}
            </div>
        );
    }

    renderSubscriptionDetails() {
        const { paymentMonths, selectedPackage, success, affiliateCode, dollar } = this.state;
        const { months } = this.props;

        if (!selectedPackage) return null;

        const newMonths = months.map(month => ({
            label: month.title,
            value: JSON.stringify(month),
            index: month.value
        }));
        const orderedMonths = _.orderBy(newMonths, ['index'], ['asc']);

        let amount = dollar ? selectedPackage.foreignPrice : selectedPackage.price;
        if (paymentMonths) {
            const payMonth = JSON.parse(paymentMonths);
            amount = amount * payMonth.value;
        }

        return (
            <div className="nafasi-subscription__step-content">
                <div className="nafasi-subscription__selected-package">
                    <div className="nafasi-subscription__selected-package-header">
                        <img src={selectedPackage.icon} alt={selectedPackage.title} />
                        <div>
                            <h3>{selectedPackage.title} Plan</h3>
                            <p>{dollar ? "$" : "TSH."} {amount.toLocaleString()}</p>
                        </div>
                    </div>
                </div>

                <div className="nafasi-subscription__form">
                    <div className="nafasi-subscription__form-group">
                        <label className="nafasi-subscription__label">Subscription Duration</label>
                        <Select
                            className="nafasi-subscription__select"
                            placeholder="Pick your subscription duration"
                            value={paymentMonths}
                            onChange={this.handleSelectMonth}
                            options={orderedMonths}
                        />
                    </div>

                    <div className="nafasi-subscription__form-group">
                        <label className="nafasi-subscription__label">Promo Code</label>
                        <div className="nafasi-subscription__promo">
                            <Input
                                allowClear
                                maxLength={10}
                                value={affiliateCode}
                                onChange={this.handleAffiliateCode}
                                placeholder="Enter promo code"
                                style={{ height: '45px' }}
                            />
                            <Button 
                                type="primary"
                                loading={this.state.validCodeLoading}
                                onClick={this.onApplyPromo}
                                style={{ minWidth: '80px' }}
                            >
                                Apply
                            </Button>
                        </div>
                        {success === true && (
                            <div className="nafasi-subscription__promo-success">
                                Promo code applied successfully!
                            </div>
                        )}
                    </div>

                    {this.renderInvoice({ dollar, amount, pack: selectedPackage })}

                    <div className="nafasi-subscription__actions">
                        <Button onClick={this.handleBack} icon={<ArrowLeftOutlined />}>
                            Back
                        </Button>
                        <Button 
                            type="primary" 
                            onClick={() => this.handleStepChange(2)}
                            disabled={!paymentMonths}
                        >
                            <span style={{ fontFamily: "ramblaRegular" }}>Continue to Payment</span>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }

    renderPaymentDetails() {
        const { paymentPhone, carrier, dollar, selectedPackage } = this.state;

        if (dollar) {
            return this.renderForeignPayment();
        }

        return (
            <div className="nafasi-subscription__step-content">
                <div className="nafasi-subscription__form">
                    <div className="nafasi-subscription__form-group">
                        <label className="nafasi-subscription__label">Phone Number</label>
                        <Input
                            className="nafasi-subscription__input"
                            allowClear
                            maxLength={10}
                            value={paymentPhone}
                            onChange={this.handlePhoneChange}
                            placeholder="Enter phone number"
                            prefix={<span className="nafasi-subscription__input-prefix">+255</span>}
                        />
                    </div>

                    <div className="nafasi-subscription__form-group">
                        <label className="nafasi-subscription__label">Payment Method</label>
                        <Select
                            className="nafasi-subscription__select"
                            placeholder="Select payment method"
                            value={carrier}
                            onChange={this.handleSelectCarrier}
                            options={[
                                {
                                    value: JSON.stringify({
                                        carrierID: "airtelMoney",
                                        title: "Airtel Money"
                                    }),
                                    label: (
                                        <div className="nafasi-subscription__select-option">
                                            <img 
                                                src={airtelLogo} 
                                                alt="Airtel Money" 
                                                className="nafasi-subscription__select-option-icon" 
                                            />
                                            <span>Airtel Money</span>
                                        </div>
                                    )
                                },
                                {
                                    value: JSON.stringify({
                                        carrierID: "tigoPesa",
                                        title: "Mixx By Yas"
                                    }),
                                    label: (
                                        <div className="nafasi-subscription__select-option">
                                            <img src={mixxByYas} alt="Mix BY Yas" className="nafasi-subscription__select-option-icon" />
                                            <span>Mixx By Yas</span>
                                        </div>
                                    )
                                },
                                {
                                    value: JSON.stringify({
                                        carrierID: "mPesa",
                                        title: "M-Pesa"
                                    }),
                                    label: (
                                        <div className="nafasi-subscription__select-option">
                                            <img src={mPesa} alt="M-Pesa" className="nafasi-subscription__select-option-icon" />
                                            <span>M-Pesa</span>
                                        </div>
                                    )
                                },
                                {
                                    value: JSON.stringify({
                                        carrierID: "haloPesa",
                                        title: "Halo Pesa"
                                    }),
                                    label: (
                                        <div className="nafasi-subscription__select-option">
                                            <img src={haloPesa} alt="Halo Pesa" className="nafasi-subscription__select-option-icon" />
                                            <span>Halo Pesa</span>
                                        </div>
                                    )
                                },
                                // ... other payment options ...
                            ]}
                        />
                    </div>

                    <div className="nafasi-subscription__actions">
                        <Button onClick={this.handleBack} icon={<ArrowLeftOutlined />}>
                            Back
                        </Button>
                        <Popconfirm
                            title="Unlock your phone to complete your subscription on the USSD popup"
                            icon={<HeartOutlined />}
                            onConfirm={() => this.onMakePayment(selectedPackage)}
                            onCancel={this.cancel}
                            okText="Confirm"
                            cancelText="No"
                        >
                            <Button type="primary" disabled={!carrier || !paymentPhone}>
                                Complete Payment
                            </Button>
                        </Popconfirm>
                    </div>
                </div>
                {this.renderPopUp(selectedPackage)}
            </div>
        );
    }

    renderForeignPayment() {
        const { selectedPackage } = this.state;
        
        return (
            <div className="nafasi-subscription__step-content">
                <div className="nafasi-subscription__form">
                    <div className="nafasi-subscription__payment-info">
                        <h3>Credit Card Payment</h3>
                        <p>You will be redirected to our secure payment gateway to complete your payment.</p>
                    </div>

                    <div className="nafasi-subscription__actions">
                        <Button onClick={this.handleBack} icon={<ArrowLeftOutlined />}>
                            Back
                        </Button>
                        <Popconfirm
                            title="Complete your subscription payment"
                            icon={<HeartOutlined />}
                            onConfirm={() => this.onMakeForeignPayment(selectedPackage)}
                            onCancel={this.cancel}
                            okText="Proceed to Payment"
                            cancelText="Cancel"
                        >
                            <Button type="primary">
                                Proceed to Payment
                            </Button>
                        </Popconfirm>
                    </div>
                </div>
                {this.renderPopUp(selectedPackage)}
            </div>
        );
    }

    render() {
        const { paymentLoading, currentStep } = this.state;

        if (paymentLoading) {
            return (
                <div className="nafasi-subscription__loading">
                    <p className="nafasi-subscription__loading-text">
                        Finish payment on your phone
                    </p>
                    <div className="nafasi-subscription__loading-spinner">
                        <Spinner />
                    </div>
                    <button 
                        className="nafasi-subscription__loading-cancel"
                        onClick={() => this.props.subscriptionFieldUpdated({ 
                            prop: "paymentLoading", 
                            value: false 
                        })}
                    >
                        Cancel
                    </button>
                </div>
            );
        }

        const steps = [
            {
                title: 'Choose Plan',
                description: 'Select your subscription plan'
            },
            {
                title: 'Subscription Details',
                description: 'Choose duration and apply promo'
            },
            {
                title: 'Payment',
                description: 'Complete your payment'
            }
        ];

        return (
            <div className="nafasi-subscription">
                <div className="nafasi-subscription__header">
                    <h1 className="nafasi-subscription__title">Choose Your Plan</h1>
                    <p className="nafasi-subscription__subtitle">
                        Select a subscription plan that best fits your needs.
                    </p>
                </div>

                <div className="nafasi-subscription__steps">
                    <Steps
                        current={currentStep}
                        items={steps}
                        onChange={this.handleStepChange}
                    />
                </div>

                <div className="nafasi-subscription__content">
                    {currentStep === 0 && this.renderPackageSelection()}
                    {currentStep === 1 && this.renderSubscriptionDetails()}
                    {currentStep === 2 && this.renderPaymentDetails()}
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    const { systemInfo } = state.system;
    const { profile } = state.profile;
    const { paymentPackage, carrier, paymentPhone, paymentMonths, months, packageTitle, paymentPackages, paymentLoading, transID, flwUrl,
        affiliateCode,
        validCode,
        validCodeLoading,
        success,

        subTotal,
        discount,
        discountPercent,
        processingFee,
        total } = state.subscription;

    return {
        systemInfo,
        profile,

        paymentPackage,
        carrier,
        paymentPhone,
        paymentMonths,

        months,
        packageTitle,
        paymentPackages,
        paymentLoading,
        transID,

        flwUrl,

        affiliateCode,
        validCode,
        validCodeLoading,
        success,

        subTotal,
        discount,
        discountPercent,
        processingFee,
        total,
    }
};

// Wrapper functional component to use hooks
function ChoosePackage(props) {
    const navigate = useNavigate();

    return <ChoosePackageBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, { subscriptionFieldUpdated, makePayment, fetchPaymentStatus, makeForeignPayment, validateAffiliateCode })(ChoosePackage);