import React, {useEffect, useState} from 'react';
import { Table, Button, Modal, message, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SecurityScanFilled } from '@ant-design/icons';
import { Spinner } from '../../common/Spinner';
import TruncatedText from '../modals/TruncatedText';
import TruncatedExperience from '../modals/TruncatedExperience';
import {useNavigate} from "react-router-dom";
import moment from 'moment';
import { AddSquareIcon } from 'hugeicons-react';

import { addToShortlist, recentViewSeeker } from "../../../actions/SearchActions";
import { postsFieldChanged, pushSeeker } from "../../../actions/PostsActions";

//component imports
import SelectPost from '../../shortlist/subComponents/SelectPost';

import './ResultCard.css';

const ResultCard = ({ profile, shortlistLoading, seekerHighlights, loading, total, onLoadMore }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedSeeker, setSelectedSeeker] = useState(null);
    const [isPopoverVisible, setPopoverVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const [state, setState] = useState({
        post: "",
        profile:{},
        results: [],
    });

    //extract app state
    const postState = useSelector((state) => state.posts);
    const profileState = useSelector((state) => state.profile);
    const searchState = useSelector(state => state.search);


    useEffect(() => {
        try {
            // Set initial state when the component mounts
            // Update state whenever dataFromRedux changes
            setState(prevState => ({
                ...prevState,
                post: postState.post,
                profile:profileState.profile,
                results: searchState.results,
            }));
        } catch (e) {
            console.error("error in updating make payment");
        }
    }, [postState, profileState, searchState]);

    const handleShortlist = (seeker, isCoverLetterRequested, shortlistInfo) => {
        try {
            dispatch(addToShortlist({ seeker, isCoverLetterRequested, shortlistInfo }));
            message.success('Added to shortlist');
            setIsModalVisible(false);
        } catch (error) {
            console.error('Error shortlisting candidate:', error);
            message.error('Failed to shortlist candidate');
        }
    };

    const onSelectPost =(value)=> {
        dispatch(postsFieldChanged({prop: 'post', value }))
    }

    const onSelectCandidate = async (seeker) => {
        console.log('paid', profile.paid);
        if ("paid" in profile) {
            console.log('paid', profile.paid);
            if (profile.paid) {
                try {
                    console.log('Seeker data before dispatch:', seeker);
                    
                    // Dispatch pushSeeker first
                    await dispatch(pushSeeker({ pushedSeeker: seeker }));
                    
                    // Get current state after push
                    const currentSearchState = searchState;
                    console.log('State after push:', currentSearchState.pushedSeeker);
                    
                    // Then dispatch recentViewSeeker
                    dispatch(recentViewSeeker(seeker));
                    
                    // Navigate immediately after dispatching
                    navigate("/seeker-profile");
                    
                } catch (error) {
                    console.error('Error dispatching seeker data:', error);
                    message.error('Error loading candidate profile');
                }
            } else {
                message.info("Upgrade your package to view candidate").then(() => null);
            }
        } else {
            message.info("Upgrade your package to view candidate").then(() => null);
        }
    };

    const showModal = (record) => {
        setSelectedSeeker(record);
        setIsModalVisible(true);
    };

    const justShortlist = (record) => {
        setSelectedSeeker(record);

        const isCoverLetterRequested = false;
        const shortlistInfo =  {};
        
        handleShortlist(record, isCoverLetterRequested, shortlistInfo)
    };

    const shortListAndRequestCoverLetter = (record) => {
        setSelectedSeeker(record);
         
        const shortlistInfo = {}
        const isCoverLetterRequested = true;
        shortlistInfo.companyName = state.profile.companyName;
        shortlistInfo.adminName = state.profile.firstName;
        shortlistInfo.post = state.post.label;
        shortlistInfo.isCoverLetterSent = false;
        shortlistInfo.adminID = state.profile.userID;

        console.log('the post', shortlistInfo)
        
        handleShortlist(record, isCoverLetterRequested, shortlistInfo)
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    let mapping = {};
    if (seekerHighlights && seekerHighlights.length > 0) {
        seekerHighlights.forEach(item1 => {
            if (!mapping[item1.seekerID]) {
                mapping[item1.seekerID] = {...item1 };
            }
        });
    }

    const newResults = state.results.map(item2 => {
        const item1 = mapping[item2.seekerID];
        let highlights = '';
        if (item1 && item1.shortlist) {
            highlights = 'Shorlisted';
        } else if (item1 && item1.recentShortlist) {
            highlights = 'Recent Shorlisted';
        } else if (item1 && item1.recentView) {
            highlights = 'Recent View';
        }
        return {...item2, highlights };
    });

    // console.log('newResults', newResults);

    let processedResults = newResults.map(result => {
        let seconds;
        result.birthdate.seconds? (seconds = result.birthdate.seconds) : (seconds = result.birthdate._seconds);
        
        const birthday = moment.unix(seconds);
        const today = moment();
        const age = today.diff(birthday, "years");
        
        const name = (result.firstName + " " + result.lastName).toUpperCase();
        const acceptanceRate = result.acceptanceRate;
        const responseRate = result.responseRate;
        const showUpRate = result.showUpRate;

        
        const formattedLocation = `${result.location.country}, ${result.location.region}`;

        let color = "#000";
        switch (result.packageIndex) {
            case 1:
                color = "#228b22";
                break;
            case 2:
                color = "#0793d9";
                break;
            default:
                break;
        }
        
        let indSentence = "";
        if (result.education.length!== 0) {
            const edIndustry = result.education.map((ind) => `${ind},`);
            indSentence = edIndustry.slice(0, -1).join(", ") + " " + edIndustry[edIndustry.length - 1];
        }
        
        return { ...result, indSentence, formattedLocation, name, age, color, acceptanceRate, showUpRate, responseRate};
    });


    const columns = [
        {
            title: 'Rank',
            dataIndex: 'rank',
            key: 'rank',
            width: 50,
            fixed: true,
            render: (_, record) => (
                <div style={{ color: record.color }}>
                    <SecurityScanFilled style={{ fontSize: '16px' }} />
                </div>
            ),
        },
        {
            title: 'Candidate',
            dataIndex: 'name',
            key: 'name',
            width: 180,
            fixed: true,
            render: (_, record) => (
                <div className="nafasi-result__candidate">
                    <div className="nafasi-result__candidate-info">
                        <div 
                            className="nafasi-result__candidate-name" 
                            title={record.name}
                        >
                            {record.name}
                        </div>
                        <div className="nafasi-result__candidate-details">
                            {record.gender}, {record.age}y
                        </div>
                        {record.highlights && (
                            <div className="nafasi-result__candidate-highlight">
                                {record.highlights.toUpperCase()}
                            </div>
                        )}
                    </div>
                </div>
            ),
        },
        {
            title: 'Location',
            dataIndex: 'formattedLocation',
            key: 'formattedLocation',
            width: 150,
            fixed: true,
            render: (text) => (
                <div className="nafasi-result__location" title={text}>
                    <FontAwesomeIcon icon="map-marker-alt" className="nafasi-result__location-icon" />
                    {text}
                </div>
            ),
        },
        {
            title: 'Experience',
            dataIndex: 'experience',
            key: 'experience',
            width: 180,
            fixed: true,
            render: (_, record) => (
                <div className="nafasi-result__truncated">
                    <TruncatedExperience seeker={record} maxLength={50}/>
                </div>
            ),
        },
        {
            title: 'Industries',
            dataIndex: 'indSentence',
            key: 'indSentence',
            width: 160,
            fixed: true,
            render: (text) => (
                <div className="nafasi-result__truncated">
                    <TruncatedText text={text} maxLength={20}/>
                </div>
            ),
        },
        {
            title: 'Response',
            dataIndex: 'responseRate',
            key: 'responseRate',
            width: 80,
            fixed: true,
            align: 'center',
            render: (value) => (
                <div className={`nafasi-result__stats-badge ${value > 80 ? 'nafasi-result__stats-badge--high' : value > 50 ? 'nafasi-result__stats-badge--medium' : ''}`}>
                    {value}%
                </div>
            ),
        },
        {
            title: 'Show-Up',
            dataIndex: 'showUpRate',
            key: 'showUpRate',
            width: 80,
            fixed: true,
            align: 'center',
            render: (value) => (
                <div className={`nafasi-result__stats-badge ${value > 80 ? 'nafasi-result__stats-badge--high' : value > 50 ? 'nafasi-result__stats-badge--medium' : ''}`}>
                    {value}%
                </div>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 120,
            fixed: true,
            render: (_, record) => (
                <div className="nafasi-result__actions">
                    <Popconfirm
                        title="Shortlisting this candidate will add them to your shortlist where you can make quick follow up on them"
                        onConfirm={() => showModal(record)}
                        onCancel={() => justShortlist(record)}
                        okText="Shortlist & Request Cover letter"
                        cancelText="Just Shortlist"
                        overlayStyle={{ width: "400px" }}
                    >
                        <button className="nafasi-result__action-button">
                            <AddSquareIcon size={32} color="#3294ff" />
                        </button>
                    </Popconfirm>
                    <button 
                        className="nafasi-result__action-button"
                        onClick={() => onSelectCandidate(record)}
                    >
                        <FontAwesomeIcon icon="user" size="lg" />
                    </button>
                </div>
            ),
        },
    ];

    const handlePageChange = (page, pageSize) => {
        console.log('Loading page:', page);
        if (page > 1 && !state.results.length) {
            message.warning('No more results available');
            return;
        }
        setCurrentPage(page);
        onLoadMore(page, pageSize);
    };

    return (
        <div className="nafasi-result">
            <div className="nafasi-result__container">
                <Table 
                    dataSource={processedResults} 
                    columns={columns} 
                    rowKey={(record) => record.seekerID}
                    scroll={{ x: 970 }}
                    loading={loading}
                    pagination={{
                        current: currentPage,
                        total: processedResults.length ? Math.max(total, processedResults.length + 20) : 0,
                        pageSize: 20,
                        onChange: handlePageChange,
                        showSizeChanger: false,
                        showTotal: (total, range) => processedResults.length ? `${range[0]}-${range[1]} of ${total}+ items` : '',
                        hideOnSinglePage: false,
                        showLessItems: false,
                        disabled: loading
                    }}
                />
                <Modal
                    title={`Shortlist ${selectedSeeker?.firstName || 'Candidate'}`}
                    visible={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                    width={500}
                    className="nafasi-result__modal"
                >
                    <div className="nafasi-result__modal-content">
                        <SelectPost
                            placeholder="Select job post"
                            label="Job Position"
                            value={state.post}
                            onChange={onSelectPost}
                        />
                        <div className="nafasi-result__modal-actions">
                            {shortlistLoading ? (
                                <Spinner />
                            ) : (
                                <Button 
                                    type="primary" 
                                    onClick={() => shortListAndRequestCoverLetter(selectedSeeker)}
                                    disabled={!state.post}
                                >
                                    Confirm & Request Cover Letter
                                </Button>
                            )}
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default ResultCard;