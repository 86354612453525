import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Languages.css";

class LanguageCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            languages: this.props.languages,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                languages: nextProps.languages,
            });
        }
    }

    getProficiencyLevel(level) {
        const levels = {
            'basic': { label: 'Basic', color: '#FEF3C7', textColor: '#92400E', icon: 'seedling' },
            'conversational': { label: 'Conversational', color: '#E0F2FE', textColor: '#075985', icon: 'comments' },
            'fluent': { label: 'Fluent', color: '#DCFCE7', textColor: '#166534', icon: 'check-double' },
            'native': { label: 'Native', color: '#F3E8FF', textColor: '#6B21A8', icon: 'star' }
        };
        return levels[level?.toLowerCase()] || { label: level, color: '#F3F4F6', textColor: '#374151', icon: 'language' };
    }

    renderLanguages() {
        const { languages } = this.state;

        if (!languages || languages.length === 0) {
            return (
                <div className="no-data-message">
                    <FontAwesomeIcon icon="language" className="no-data-icon" />
                    <p>No languages listed</p>
                </div>
            );
        }

        return (
            <div className="languages-grid">
                {languages.map((lang, index) => (
                    <div key={index} className="language-card">
                        <div className="language-header">
                            <div className="language-icon">
                                <FontAwesomeIcon 
                                    icon={this.getProficiencyLevel(lang.proficiency?.title).icon} 
                                />
                            </div>
                            <h4>{lang.title || lang.language}</h4>
                        </div>
                        <div 
                            className="proficiency-badge"
                            style={{ 
                                backgroundColor: this.getProficiencyLevel(lang.proficiency?.title).color,
                                color: this.getProficiencyLevel(lang.proficiency?.title).textColor,
                                fontFamily: "ramblaRegular"
                            }}
                        >
                            {this.getProficiencyLevel(lang.proficiency?.title).label}
                        </div>
                        {lang.description && (
                            <p className="language-description">{lang.description}</p>
                        )}
                    </div>
                ))}
            </div>
        );
    }

    render() {
        return (
            <div className="languages-container">
                {this.renderLanguages()}
            </div>
        );
    }
}

export default LanguageCard;