import React from "react";
import { Space, Card } from 'antd';
import { CrownIcon } from "hugeicons-react";
import './Subscriptions.css';

//-> component imports
import ChoosePackage from "./subComponents/ChoosePackage";

class Subscriptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return(
            <div className="nafasi-subscriptions__container">
                <div className="nafasi-subscriptions">
                    <Space direction="vertical" style={{ width: '100%' }} size="middle">
                        <Card size="small" className="nafasi-subscriptions__header-card">
                            <div className="nafasi-subscriptions__header">
                                <CrownIcon size={24} className="nafasi-subscriptions__icon" />
                                <h3 className="nafasi-subscriptions__title">Subscription Plans</h3>
                            </div>
                        </Card>
                        
                        <Card size="small" className="nafasi-subscriptions__content">
                            <div className="nafasi-subscriptions__packages">
                                <ChoosePackage />
                            </div>
                        </Card>
                    </Space>
                </div>
            </div>
        );
    }
}

export default Subscriptions;