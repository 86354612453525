import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import Logo from "../../assets/logos/logo.png";
import './Disclosure.css';

class Disclosure extends React.Component {
    renderLogo() {
        if(isMobile){
            return (
                <NavLink to={'/'}>
                    <img src={Logo} alt="Nafasi Logo" />
                </NavLink>
            );
        }
    }

    render() {
        return (
            <div className="disclosure-container">
                <div className="disclosure-intro-container">
                    <div className="disclosure-logo">
                        {this.renderLogo()}
                    </div>
                    <div className="disclosure-header">
                        <h4>Privacy and Data Usage</h4>
                    </div>
                    <div className="disclosure-content">
                        <h5>
                            Nafasi is committed to protecting your privacy and handling your data with transparency. 
                            We comply with the Google API Services User Data Policy, including Limited Use requirements, 
                            ensuring your information is handled securely and responsibly.
                            <br /><br />
                            For complete details about how we protect your data and comply with Google's guidelines, 
                            please review the <a 
                                href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" 
                                target="_blank" 
                                rel="noopener noreferrer"
                            >Google API Services User Data Policy</a>.
                        </h5>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {})(Disclosure);