import React from "react";
import moment from "moment";
import "./../Education.css"
import { Table, Button, Modal, message } from 'antd';

//-> component import
import CustomHeaderCard from "../../../search/cards/CustomHeaderCard";

class EducationCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            education: this.props.education,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                education: nextProps.education,
            });
        }
    }

    renderEducation() {
        const { education } = this.state;

        let processedResults = education.map(result => {
            let seconds;
            result.timeOfStudyFrom.seconds ? seconds = result.timeOfStudyFrom.seconds : seconds = result.timeOfStudyFrom._seconds;
            const studyFrom = moment.unix(seconds);

            let toSeconds;
            result.timeOfStudyTo.seconds ? toSeconds = result.timeOfStudyTo.seconds : toSeconds = result.timeOfStudyTo._seconds;
            const studyTo = moment.unix(toSeconds);

            const studyFromString = studyFrom.format('MMM YYYY');
            const studyToString = studyTo.format('MMM YYYY');

            const formattedLocation = `${result.location.country}, ${result.location.region}`;

            return { ...result, studyFromString, studyToString, formattedLocation };
        });

        const columns = [
            {
                title: "Status",
                dataIndex: 'currentEnrollment',
                key: 'currentEnrollment',
                width: '8%',
                render: (_, record) => (
                    <div className={`education-status ${record.currentEnrollment ? 'education-status--active' : 'education-status--completed'}`}>
                        {record.currentEnrollment ? "Active" : "Completed"}
                    </div>
                ),
            },
            {
                title: "Institution",
                dataIndex: 'institution',
                key: 'institution',
                width: '20%',
                render: (_, record) => (
                    <div className="education-cell">
                        <div className="education-cell__title">{record.institution}</div>
                        <div className="education-cell__subtitle">{record.formattedLocation}</div>
                    </div>
                ),
            },
            {
                title: "Level & Field",
                key: 'education',
                width: '25%',
                render: (_, record) => (
                    <div className="education-cell">
                        <div className="education-cell__title">{record.educationLevel.title}</div>
                        <div className="education-cell__subtitle">{record.educationField}</div>
                    </div>
                ),
            },
            {
                title: "Industry",
                dataIndex: 'industry.title',
                key: 'industry',
                width: '15%',
                render: (_, record) => (
                    <div className="education-cell">
                        {record.industry.title}
                    </div>
                ),
            },
            {
                title: "Duration",
                key: 'duration',
                width: '20%',
                render: (_, record) => (
                    <div className="education-cell">
                        <div className="education-duration">
                            <span style={{ fontFamily: 'ramblaBold', fontSize: '0.95rem' }}>{record.studyFromString}</span>
                            <span className="education-duration__separator">→</span>
                            <span style={{ fontFamily: 'ramblaBold', fontSize: '0.95rem' }}>{record.currentEnrollment ? "Present" : record.studyToString}</span>
                        </div>
                        {!record.currentEnrollment && (
                            <div className="education-cell__subtitle">
                                Completed: {record.yearOfCompletion}
                            </div>
                        )}
                    </div>
                ),
            }
        ];

        return (
            <div className="education-table-container">
                <Table 
                    dataSource={processedResults} 
                    columns={columns} 
                    rowKey="id"
                    pagination={false}
                    className="education-table"
                    rowClassName="education-table__row"
                />
            </div>
        );
    }

    render() {
        return(
            <div className="education-card">
                {this.renderEducation()}
            </div>
        );
    }
}

export default EducationCard;