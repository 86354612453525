import React from "react";
import { connect } from "react-redux";
import styled from 'styled-components';
import { fetchCandidatesYearOfExperience } from "../../../actions/PostsActions";
import { Column } from '@ant-design/charts';
import { Spinner } from "../../common/Spinner";
import { Tabs } from 'antd';

const { TabPane } = Tabs;

const PageWrapper = styled.div`
  padding: 1rem;
  font-family: 'ramblaBold', sans-serif;
`;

const DistributionContainer = styled.div`
  margin: 0 auto;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px) saturate(180%);
  -webkit-backdrop-filter: blur(20px) saturate(180%);
  border-radius: 30px;
  box-shadow: 0 20px 48px rgba(0, 0, 0, 0.06);
`;

const SectionTitle = styled.h2`
  font-family: 'ramblaBold', sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: #1d1d1f;
  margin-bottom: 0.5rem;
  text-align: center;
`;

const SectionSubtitle = styled.p`
  font-family: 'ramblaBold', sans-serif;
  font-size: 16px;
  color: #86868b;
  text-align: center;
  margin-bottom: 2rem;
  line-height: 1.4;
`;

const ChartContainer = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 2rem;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);
  min-height: 500px;

  .ant-tabs-nav {
    margin-bottom: 2rem;
  }

  .ant-tabs-tab {
    font-family: 'ramblaBold', sans-serif;
    font-size: 16px;
    padding: 12px 24px;
    transition: all 0.3s ease;

    &:hover {
      color: #147CE5;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #147CE5 !important;
      font-weight: bold;
    }
  }

  .ant-tabs-ink-bar {
    background: #147CE5;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  gap: 1rem;
  
  .loading-text {
    font-family: 'ramblaBold', sans-serif;
    color: #86868b;
    font-size: 16px;
  }
`;

class DistributionLevel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            candidateExperience: this.props.candidateExperience,
            candidateLevel: this.props.candidateLevel,
            distributionLoad: this.props.distributionLoad,
            activeTab: '1'
        };
    }

    componentDidMount() {
        this.props.fetchCandidatesYearOfExperience();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                candidateExperience: nextProps.candidateExperience,
                candidateLevel: nextProps.candidateLevel,
                distributionLoad: nextProps.distributionLoad
            });
        }
    }

    renderChart = () => {
        const { candidateExperience, candidateLevel, activeTab } = this.state;
        
        const config = {
            data: activeTab === '1' 
                ? candidateExperience.map(({ years, number }) => ({
                    category: `${years} Years`,
                    value: number,
                    type: 'Experience'
                  }))
                : candidateLevel.map(({ educationLevelTitle, number }) => ({
                    category: educationLevelTitle,
                    value: number,
                    type: 'Education'
                  })),
            xField: 'category',
            yField: 'value',
            columnWidthRatio: 0.4,
            color: '#147CE5',
            label: {
                position: 'top',
                style: {
                    fill: '#86868b',
                    fontSize: 12,
                    fontWeight: 500,
                    fontFamily: 'ramblaBold',
                },
            },
            xAxis: {
                label: {
                    style: {
                        fontSize: 12,
                        fontFamily: 'ramblaBold',
                        fill: '#86868b',
                    },
                    autoRotate: true,
                },
                line: {
                    style: {
                        stroke: '#f5f5f7',
                    },
                },
            },
            yAxis: {
                label: {
                    style: {
                        fontSize: 12,
                        fontFamily: 'ramblaBold',
                        fill: '#86868b',
                    },
                    formatter: (text) => `${text} Candidates`,
                },
                grid: {
                    line: {
                        style: {
                            stroke: '#f5f5f7',
                            lineDash: [4, 4],
                        },
                    },
                },
            },
            animation: {
                appear: {
                    animation: 'fade-in',
                    duration: 1000,
                },
            },
            tooltip: {
                domStyles: {
                    'g2-tooltip': {
                        backgroundColor: 'rgba(255, 255, 255, 0.9)',
                        backdropFilter: 'blur(10px)',
                        padding: '12px 16px',
                        border: 'none',
                        boxShadow: '0 4px 24px rgba(0, 0, 0, 0.04)',
                        borderRadius: '12px',
                        fontFamily: 'ramblaBold',
                    },
                },
            },
        };

        return <Column {...config} />;
    };

    render() {
        const { distributionLoad } = this.state;
        
        return (
            <PageWrapper>
                <DistributionContainer>
                    <SectionTitle>Distribution Analysis</SectionTitle>
                    <SectionSubtitle>
                        Comprehensive insights into candidate experience and education levels
                    </SectionSubtitle>
                    
                    {distributionLoad ? (
                        <LoaderContainer>
                            <Spinner />
                            <span className="loading-text">Loading analytics...</span>
                        </LoaderContainer>
                    ) : (
                        <ChartContainer>
                            <Tabs 
                                defaultActiveKey="1"
                                onChange={(key) => this.setState({ activeTab: key })}
                            >
                                <TabPane tab="Experience Distribution" key="1">
                                    {this.renderChart()}
                                </TabPane>
                                <TabPane tab="Education Distribution" key="2">
                                    {this.renderChart()}
                                </TabPane>
                            </Tabs>
                        </ChartContainer>
                    )}
                </DistributionContainer>
            </PageWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    const { candidateExperience, candidateLevel, distributionLoad } = state.posts;
    return {
        candidateExperience, 
        candidateLevel, 
        distributionLoad
    };
};

export default connect(mapStateToProps, { fetchCandidatesYearOfExperience })(DistributionLevel);

