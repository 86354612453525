import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "../Licenses.css";

class LicenseCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licenses: this.props.licenses,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                licenses: nextProps.licenses,
            });
        }
    }

    formatDate(timestamp) {
        if (!timestamp) return 'N/A';
        if (timestamp.toDate) {
            return moment(timestamp.toDate()).format('MMM YYYY');
        }
        if (timestamp.seconds || timestamp._seconds) {
            const seconds = timestamp.seconds || timestamp._seconds;
            return moment.unix(seconds).format('MMM YYYY');
        }
        return moment(timestamp).format('MMM YYYY');
    }

    getStatusBadge(isExpired, expirationDate) {
        if (!expirationDate) return { label: 'No Expiry', color: '#F3F4F6', textColor: '#374151' };
        if (isExpired) return { label: 'Expired', color: '#FEE2E2', textColor: '#991B1B' };
        
        const expiry = moment.unix(expirationDate.seconds || expirationDate._seconds);
        const now = moment();
        const monthsToExpiry = expiry.diff(now, 'months');

        if (monthsToExpiry <= 3) return { label: 'Expiring Soon', color: '#FEF3C7', textColor: '#92400E' };
        return { label: 'Valid', color: '#DCFCE7', textColor: '#166534' };
    }

    renderLicenses() {
        const { licenses } = this.state;

        console.log(licenses[0]);
        return (
            <div className="licenses-grid">
                {licenses.map((license, index) => {
                    const isExpired = license.expirationDate && 
                        moment().isAfter(moment.unix(license.expirationDate.seconds || license.expirationDate._seconds));
                    const status = this.getStatusBadge(isExpired, license.expirationDate || license.expirationDate._seconds);

                    return (
                        <div key={index} className="license-card">
                            <div className="license-header">
                                <div className="license-icon">
                                    <FontAwesomeIcon icon="id-badge" />
                                </div>
                                <div className="license-info">
                                    <h4>{license.licenseCategory.title || 'Untitled License'}</h4>
                                    <span className="license-issuer">
                                        {license.issuingAuthority || license.issuer || 'Unknown Issuer'}
                                    </span>
                                </div>
                            </div>

                            <div className="license-meta">
                                <div 
                                    className="status-badge"
                                    style={{ 
                                        backgroundColor: status.color,
                                        color: status.textColor
                                    }}
                                >
                                    {status.label}
                                </div>

                                <div className="date-info">
                                    <span style={{ fontFamily: "ramblaRegular"}}>Issued: {this.formatDate(license.issueDate)}</span>
                                    {license.expirationDate && (
                                        <span style={{ fontFamily: "ramblaRegular"}}>Expires: {this.formatDate(license.expirationDate)}</span>
                                    )}
                                </div>
                            </div>

                            {license.licenseNumber && (
                                <div className="license-number">
                                    License Number: {license.licenseNumber}
                                </div>
                            )}

                            {license.description && (
                                <p className="license-description">
                                    {license.description}
                                </p>
                            )}

                            {license.verificationUrl && (
                                <a 
                                    href={license.verificationUrl} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="verify-link"
                                >
                                    <FontAwesomeIcon icon="external-link-alt" />
                                    Verify License
                                </a>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        return (
            <div className="licenses-timeline">
                {this.renderLicenses()}
            </div>
        );
    }
}

export default LicenseCard; 