//-> Sign in types
export const CREDENTIALS_CHANGED = "credentials_changed";
export const LOGIN_USER = "login_user";
export const LOGIN_SUCCESSFUL = "login_successful";
export const LOGIN_FAILED = "login_failed";
export const INITIALIZE_STATE = "initialize_state";

export const LOG_OUT = "log_out";
export const LOGOUT_SUCCESSFULLY ="logout_successfully";
export const LOGOUT_FAILED = "logout_failed";

export const FETCH_PROFILE = "fetch_profile";
export const FETCH_PROFILE_SUCCESSFUL = "fetch_profile_successful";
export const FETCH_PROFILE_FAILED = "fetch_profile_failed";

//sign up
export const SIGN_UP_USER_SUCCESSFUL = "sign_up_user_successful";
export const SIGN_UP_USER_FAILED = "sign_up_user_failed";

export const SIGN_UP_MANAGED_USER_SUCCESSFUL = "sign_up_managed_user_successful";
export const SIGN_UP_MANAGED_USER_FAILED = "sign_up_managed_user_failed";


//Home Types
export const REQUEST_DAY_OFF_DETAILS_CHANGED = "request_day_off_details_changed";
export const DAY_OFF_BALANCE_DETAILS_CHANGED = "day_off_balance_details_changed";

export const FETCH_ANNOUNCEMENTS = "fetch_announcements";
export const FETCH_ANNOUNCEMENTS_SUCCESSFUL = "fetch_announcements_successful";
export const FETCH_ANNOUNCEMENTS_FAILED = "fetch_announcements-failed";

export const FETCH_CELEBRATIONS = "fetch_celebrations";
export const FETCH_CELEBRATIONS_SUCCESSFUL = "fetch_celebrations_successful";
export const FETCH_CELEBRATIONS_FAILED = "fetch_celebrations_failed";

export const FETCH_TRAININGS = "fetch_trainings";
export const FETCH_TRAININGS_SUCCESSFUL = "fetch_trainings_successful";
export const FETCH_TRAININGS_FAILED = "fetch_trainings_failed";

export const HOME_LOCATION_CHANGED = "home_location_changed";
export const SAVE_HOME_LOCATION = "save_home_location";
export const SAVE_HOME_LOCATION_SUCCESSFUL = "save_home_location_successful";
export const SAVE_HOME_LOCATION_FAILED = "save_home_location_failed";

export const UPLOAD_DOCTOR_CERTIFICATE_CHANGED = 'export_doctor_certificate_changed';

export const SAVE_DOCTOR_CERTIFICATE = "save_doctor_certificate";
export const SAVE_DOCTOR_CERTIFICATE_SUCCESSFUL = "save_doctor_certificate_successful";
export const SAVE_DOCTOR_CERTIFICATE_FAILED = "save_doctor_certificate_failed";


//My profile Types

export const FETCH_MY_PROFILE = "fetch_my_profile";
export const FETCH_MY_PROFILE_SUCCESSFUL = "fetch_my_profile_successful";
export const FETCH_MY_PROFILE_FAILED = "fetch_my_profile_failed";

export const SAVE_DAYS_OFF_REQUEST = "save_days_off_request";
export const SAVE_DAYS_OFF_REQUEST_SUCCESSFUL = "save_days_off_request_successful";
export const SAVE_DAYS_OFF_REQUEST_FAILED = "save_days_off_request_failed";

export const FETCH_MY_PROFILE_DAYS_OFF = "fetch_my_profile_days_off";
export const FETCH_MY_PROFILE_DAYS_OFF_SUCCESSFUL = "fetch_my_profile_days_off_successful";
export const FETCH_MY_PROFILE_DAYS_OFF_FAILED = "fetch_my_profile_days_off_failed";

export const FETCH_EMPLOYEE_DETAILS = "fetch_employee-details";
export const FETCH_EMPLOYEE_DETAILS_SUCCESSFUL = "fetch_employee_details_successful";
export const FETCH_EMPLOYEE_DETAILS_FAILED = "fetch_employee_details_failed";

export const FETCH_EMPLOYEE_DEPENDENTS = "fetch_employee_dependents";
export const FETCH_EMPLOYEE_DEPENDENTS_SUCCESSFUL = "fetch_employee_dependents_successful";
export const FETCH_EMPLOYEE_DEPENDENTS_FAILED = "fetch_employee_dependents_failed";

export const FETCH_EMPLOYEE_TRAININGS = "fetch_employee_trainings";
export const FETCH_EMPLOYEE_TRAININGS_SUCCESSFUL = "fetch_employee_trainings_successful";
export const FETCH_EMPLOYEE_TRAININGS_FAILED = "fetch_employee_trainings_failed";

export const FETCH_EMPLOYEE_ASSETS = "fetch_employee_assets";
export const FETCH_EMPLOYEE_ASSETS_SUCCESSFUL = "fetch_employee_assets_successful";
export const FETCH_EMPLOYEE_ASSETS_FAILED = "fetch_employee_assets_failed";

export const FETCH_EMPLOYEE_DOCUMENTS = "fetch_employee_documents";
export const FETCH_EMPLOYEE_DOCUMENTS_SUCCESSFUL = "fetch_employee_documents_successful";
export const FETCH_EMPLOYEE_DOCUMENTS_FAILED = "fetch_employee_documents_failed";

export const FETCH_EMPLOYEE_BENEFITS = "fetch_employee_benefits";
export const FETCH_EMPLOYEE_BENEFITS_SUCCESSFUL = "fetch_employee_benefits_successful";
export const FETCH_EMPLOYEE_BENEFITS_FAILED = "fetch_employee_benefits_failed";

export const FETCH_MY_LEAVE_REQUESTS = "fetch_my_leave_days";
export const FETCH_MY_LEAVE_REQUESTS_SUCCESSFUL = "fetch_my_leave_requests_successful";
export const FETCH_MY_LEAVE_REQUESTS_FAILED = "fetch_my_leave_requests_failed";



//People Types
export const UPDATE_CODE_GENERATION_FIELD = "update_code_generation_field";
export const GENERATE_EMPLOYEE_CODE = "generate_employee_code";
export const GENERATE_EMPLOYEE_CODE_SUCCESSFUL = "generate_employee_code_successful";
export const GENERATE_EMPLOYEE_CODE_FAILED = "generate_employee_code_failed";
export const CLEAR_EMPLOYEE_CODE = "clear_employee_code";
export const ADD_EMPLOYEE_DETAILS_CHANGED = "add_employee_details_changed";
export const EDIT_EMPLOYEE_DETAILS_CHANGED = "edit_employee_details_changed";

export const EXISTING_USER_DETAILS_CHANGED = "existing_user_details_changed";
export const SEARCH_EXISTING_USER = "search_existing_user";
export const SEARCH_EXISTING_USER_SUCCESSFUL = "search_existing_user";
export const SEARCH_EXISTING_USER_NO_RECORDS = "search_existing_user_no_records";
export const SEARCH_EXISTING_USER_FAILED = "search_existing_user_failed";

export const FETCH_USER_DETAILS = "fetch_user_details";
export const FETCH_USER_DETAILS_SUCCESSFUL = "fetch_user_details_successful";
export const FETCH_USER_DETAILS_FAILED = "fetch_user_details_failed";


export const ADD_NEW_EMPLOYEE = "add_new_employee";
export const ADD_NEW_EMPLOYEE_SUCCESSFUL = "add_new_employee_successful";
export const ADD_NEW_EMPLOYEE_FAILED = "add_new_employee_failed";

export const EDIT_EMPLOYEE_DETAILS = "edit_employee_details";
export const EDIT_EMPLOYEE_DETAILS_SUCCESSFUL = "edit_employee_details_successful";
export const EDIT_EMPLOYEE_DETAILS_FAILED = "edit_employee_details_failed";

export const FETCH_LEAVE_REQUESTS = "fetch_leave_requests";
export const FETCH_LEAVE_REQUESTS_SUCCESSFUL = "fetch_leave_requests_successful";
export const FETCH_LEAVE_REQUESTS_FAILED = "fetch_leave_requests_failed";

export const FETCH_SHORTLIST = "fetch_shortlist";
export const FETCH_SHORTLIST_SUCCESSFUL = "fetch_shortlist_successful";
export const FETCH_SHORTLIST_FAILED = "fetch_shortlist_failed";

export const DAYS_OFF_REQUEST_ACTION_CHANGED = "day_off_request_action_changed";

export const SHOW_DAY_OFF_MANAGE_CARD = "show_day_off_manage_card";
export const HIDE_DAY_OFF_MANAGE_CARD = "hide_day_off_manage_card";

export const LEAVE_REQUEST_RESPONSE = "leave_request_response";
export const LEAVE_REQUEST_RESPONSE_SUCCESSFUL = "leave_request_response_successful";
export const LEAVE_REQUEST_RESPONSE_FAILED = "leave_request_response_failed";

export const FETCH_SELECTED_CANDIDATE = "fetch_selected_candidate";
export const FETCH_SELECTED_CANDIDATE_SUCCESSFUL = "fetch_selected_candidate_successful";
export const FETCH_SELECTED_CANDIDATE_FAILED = "fetch_selected_candidate_failed";

export const FETCH_SEEKER = "fetch_seeker";
export const FETCH_SEEKER_SUCCESSFUL = "fetch_seeker_successful";
export const FETCH_SEEKER_FAILED = "fetch_seeker_failed";

export const FETCH_SEEKER_PATH = "fetch_seeker_path";
export const FETCH_SEEKER_PATH_SUCCESSFUL = "fetch_seeker_path_successful";
export const FETCH_SEEKER_PATH_FAILED = "fetch_seeker_path_failed";

export const PUSHED_SEEKER = "pushed_seeker";

export const RECENT_VIEW_SEEKER = "recent_view_seeker";
export const PUSH_SEEKER = "push_seeker";


export const EMPLOYEE_ASSETS_DETAILS_CHANGED = "employee_assets_details_changed";
export const ASSIGN_EMPLOYEE_ASSETS = "assign_employee_assets";
export const ASSIGN_EMPLOYEE_ASSETS_SUCCESSFUL = "assign_employee_assets_successful";
export const ASSIGN_EMPLOYEE_ASSETS_FAILED = "assign_employee_assets_failed";

export const DELETE_ASSIGNED_EMPLOYEE_ASSETS = "delete_assigned_employee_assets";
export const DELETE_ASSIGNED_EMPLOYEE_ASSETS_SUCCESSFUL = "delete_assigned_employee_assets_successful";
export const DELETE_ASSIGNED_EMPLOYEE_ASSETS_FAILED = "delete_assigned_employee_assets_failed";

export const DELETE_EMPLOYEE_ASSET_RETURN_DATE = "delete_employee_asset_return_date";
export const DELETE_EMPLOYEE_ASSET_RETURN_DATE_SUCCESSFUL = "delete_employee_asset_return_date_successful";
export const DELETE_EMPLOYEE_ASSET_RETURN_DATE_FAILED = "delete_employee_asset_return_date_failed";

export const DELETE_EMPLOYEE_CODE = "delete_employee_code";
export const DELETE_EMPLOYEE_CODE_SUCCESSFUL = "delete_employee_code_succcessful";
export const DELETE_EMPLOYEE_CODE_FAILED = "delete_employee_code_failed";


export const UPDATE_EMPLOYEE_ASSETS_DETAILS_CHANGED = "update_assets_details_changed";
export const UPDATE_EMPLOYEE_ASSETS = "update_employee_assets";
export const UPDATE_EMPLOYEE_ASSETS_SUCCESSFUL = "update_employee_assets_successful";
export const UPDATE_EMPLOYEE_ASSETS_FAILED = "update_employee_assets_failed";

export const CLEAR_EMPLOYEE_ASSETS_MESSAGES = "clear_employee_assets_messages";

export const FETCH_EMPLOYEE_ASSIGNED_ASSETS = "fetch_employee_assigned_assets";
export const FETCH_EMPLOYEE_ASSIGNED_ASSETS_SUCCESSFUL = "fetch_employee_assigned_assets_successful";
export const FETCH_EMPLOYEE_ASSIGNED_ASSETS_FAILED = "fetch_employee_assigned_assets_failed";

export const EXISTING_ASSET_DETAILS_CHANGED = "existing_asset_details_changed";
export const SEARCH_EXISTING_ASSET = "search_existing_asset";
export const SEARCH_EXISTING_ASSET_SUCCESSFUL = "search_existing_asset_successful";
export const SEARCH_EXISTING_ASSET_NO_RECORDS = "search_existing_asset_no_records";
export const SEARCH_EXISTING_ASSET_FAILED = "search_existing_asset_failed";

export const EMPLOYEE_BENEFITS_DETAILS_CHANGED = "employee_benefits_details_changed";
export const SAVE_EMPLOYEE_BENEFITS = "save_employee_benefits";
export const SAVE_EMPLOYEE_BENEFITS_SUCCESSFUL = "save_employee_benefits_successful";
export const SAVE_EMPLOYEE_BENEFITS_FAILED = "save_employee_benefits_failed";

export const DELETE_EMPLOYEE_BENEFIT = "delete_employee_benefit";
export const DELETE_EMPLOYEE_BENEFIT_SUCCESSFUL = "delete_employee_benefit_successful";
export const DELETE_EMPLOYEE_BENEFIT_FAILED = "delete_employee_benefit_failed";

export const BENEFITS_DEPENDENTS_DETAILS_CHANGED = "benefits_details_changed";

export const SAVE_BENEFITS_DEPENDENTS = "save_benefits_dependents";
export const SAVE_BENEFITS_DEPENDENTS_SUCCESSFUL = "save_benefits_dependents_successful";
export const SAVE_BENEFITS_DEPENDENTS_FAILED = "save_benefits_dependents_failed";

export const DELETE_BENEFIT_DEPENDENT = "delete_benefit_dependent";
export const DELETE_BENEFIT_DEPENDENT_SUCCESSFUL = "delete_benefit_dependent_successful";
export const DELETE_BENEFIT_DEPENDENT_FAILED = "delete_benefit_dependent_failed";

export const FETCH_EMPLOYEE_EARNED_BENEFITS = "fetch_employee_earned_benefits";
export const FETCH_EMPLOYEE_EARNED_BENEFITS_SUCCESSFUL = "fetch_employee_earned_benefits_successful";
export const FETCH_EMPLOYEE_EARNED_BENEFITS_FAILED = "fetch_employee_earned_benefits_failed";

export const FETCH_EMPLOYEE_BENEFITS_DEPENDENTS = "fetch_employee_benefits_dependents";
export const FETCH_EMPLOYEE_BENEFITS_DEPENDENTS_SUCCESSFUL = "fetch_employee_benefits_dependents_successful";
export const FETCH_EMPLOYEE_BENEFITS_DEPENDENTS_FAILED = "fetch_employee_benefits_dependents_failed";

export const DELETE_DOCUMENT = "delete_document";
export const DELETE_DOCUMENT_SUCCESSFUL= "delete_document_successful";
export const DELETE_DOCUMENT_FAILED = "delete_document_failed";


// Hiring Types
export const JOB_OPENING_DETAILS_CHANGED = "job_details_opening_changed";
export const SAVE_JOB_OPENING = "save_job_opening";
export const SAVE_JOB_OPENING_SUCCESSFUL = "save_job_opening_successful";
export const SAVE_JOB_OPENING_FAILED = "save_job_opening_failed";

export const JOB_OPENING_CANDIDATE_DETAILS_CHANGED = "job_opening_candidate_details_changed";

export const FETCH_JOB_OPENING_LIST = "fetch_job_opening_list";
export const FETCH_JOB_OPENING_LIST_SUCCESSFUL = "fetch_job_opening_list_successful";
export const FETCH_JOB_OPENING_LIST_FAILED = "fetch_job_opening_list_failed";

export const FETCH_POST_CANDIDATES = "fetch_job_opening_candidates";
export const FETCH_POST_CANDIDATES_SUCCESSFUL = "fetch_job_opening_candidates_successful";
export const FETCH_POST_CANDIDATES_FAILED = "fetch_job_opening_candidates_failed";

export const DELETE_JOB_POST = "delete_job_opening";
export const DELETE_JOB_POST_SUCCESSFUL = "delete_job_opening_successful";
export const DELETE_JOB_POST_FAILED = "delete_job_opening_failed";

export const WITHDRAW_CANDIDATE = "delete_job_opening_candidate";
export const WITHDRAW_CANDIDATE_SUCCESSFUL = "delete_job_opening_candidate_successful";
export const WITHDRAW_CANDIDATE_FAILED = "delete_job_opening_candidate_failed";

export const SELECTED_POST = "selected_post";
export const CLEAR_SELECTED_POST = "clear_selected_post";

export const CLEAR_POST_MESSAGES = "clear_job_opening_message";

export const SAVE_RATINGS = "save_ratings";
export const SAVE_RATINGS_SUCCESSFUL = "save_ratings_successful";
export const SAVE_RATINGS_FAILED = "save_ratings_failed";

export const FETCH_VETTING_RESULT = "fetch_vetting_result";
export const FETCH_VETTING_RESULT_SUCCESS = "fetch_vetting_result_success";
export const FETCH_VETTING_RESULT_FAIL = "fetch_vetting_result_fail";

export const FETCH_ADMIN_POSTS = 'fetch_admin_posts';
export const FETCH_ADMIN_POSTS_SUCCESS = 'fetch_admin_posts_success';
export const FETCH_ADMIN_POSTS_FAIL = 'fetch_admin_posts_fail';


//Reports Types


//payments
export const PAYMENT_FIELD_UPDATED = "payment_field_updated";

export const SAVE_PAYMENT = "save_payment";
export const SAVE_PAYMENT_SUCCESSFUL = "save_payment_successful";
export const SAVE_PAYMENT_FAILED = "save_payment_failed";

export const FETCH_PAYMENTS = "fetch_payments";
export const FETCH_PAYMENTS_SUCCESSFUL = "fetch_payments_successful";
export const FETCH_PAYMENTS_FAILED = "fetch_payments_failed";

export const DELETE_PAYMENTS = "delete_payments";
export const DELETE_PAYMENTS_SUCCESSFUL = "delete_payments_successful";
export const DELETE_PAYMENTS_FAILED = "delete_payments_failed";

export const UPDATE_REIMBURSEMENT_VALUE = "update_reimbursement_value";
export const UPDATE_REIMBURSEMENT_VALUE_SUCCESSFUL = "update_reimbursement_value_successful";
export const UPDATE_REIMBURSEMENT_VALUE_FAILED = "update_reimbursement_value_failed";

export const UPDATE_PAYMENT_STATUS = "update_payment_status";
export const UPDATE_PAYMENT_STATUS_SUCCESSFUL = "update_payment_status_successful";
export const UPDATE_PAYMENT_STATUS_FAILED = "update_payment_status_failed";

export const CLEAR_PAYMENTS_MESSAGES = "clear_payment_messages";

export const VALIDATE_AFFILIATE_CODE = "validate_affiliate_code";
export const VALIDATE_AFFILIATE_CODE_SUCCESSFUL = "validate_affiliate_code_successful";
export const VALIDATE_AFFILIATE_CODE_FAILED = "validate_affiliate_code_failed";


//Files Types
export const FILE_DETAILS_CHANGED = "files_details_changed";

export const UPLOAD_COMPANY_FILE = "upload_company_file";
export const UPLOAD_COMPANY_FILE_SUCCESSFUL = "upload_company_file_successful";
export const UPLOAD_COMPANY_FILE_FAILED = "upload_company_file_failed";

export const SAVE_COMPANY_FILE = "save_company_file";
export const SAVE_COMPANY_FILE_SUCCESSFUL = "save_company_file_successful";
export const SAVE_COMPANY_FILE_FAILED = "save_company_failed";

export const FETCH_COMPANY_FILES = "fetch_company_files";
export const FETCH_COMPANY_FILES_SUCCESSFUL = "fetch_company_files_successful";
export const FETCH_COMPANY_FILES_FAILED = "fetch_company_files_failed";

export const DELETE_COMPANY_FILE = "delete_company_file";
export const DELETE_COMPANY_FILE_SUCCESSFUL = "delete_company_file_successful";
export const DELETE_COMPANY_FILE_FAILED = "delete_company_file_failed";

export const CLEAR_COMPANY_FILES_MESSAGES = "clear_company_files_messages";


//employee files Types
export const EMPLOYEE_FILE_DETAILS_CHANGED = "employee_files_details_changed";

export const SAVE_EMPLOYEE_FILE = "save_employee_file";
export const SAVE_EMPLOYEE_FILE_SUCCESSFUL = "save_employee_file_successful";
export const SAVE_EMPLOYEE_FILE_FAILED = "save_employee_file_failed";

export const FETCH_EMPLOYEE_FILES = "fetch_employee_files";
export const FETCH_EMPLOYEE_FILES_SUCCESSFUL = "fetch_employee_files_successful";
export const FETCH_EMPLOYEE_FILES_FAILED = "fetch_employee_files_failed";

export const DELETE_EMPLOYEE_FILE = "delete_employee_file";
export const DELETE_EMPLOYEE_FILE_SUCCESSFUL = "delete_employee_file_successful";
export const DELETE_EMPLOYEE_FILE_FAILED = "delete_employee_file_failed";


//Notifications Types
export const NOTIFICATION_CHANGED = "notification_changed";
export const SAVE_NOTIFICATIONS = "save_notifications";
export const SAVE_NOTIFICATIONS_SUCCESSFUL = "save_notifications_successful";
export const SAVE_NOTIFICATIONS_FAILED = "save_notifications_failed";

export const FETCH_NOTIFICATIONS = "fetch_notifications";
export const FETCH_NOTIFICATIONS_SUCCESSFUL = "fetch_notifications_successful";
export const FETCH_NOTIFICATIONS_FAILED = "fetch_notifications_failed";

export const DELETE_NOTIFICATION = "delete_notification";
export const DELETE_NOTIFICATION_SUCCESSFUL = "delete_notification_successful";
export const DELETE_NOTIFICATION_FAILED = "delete_notification_failed";

export const CLEAR_NOTIFICATIONS_MESSAGES = "clear_notifications_messages";



//Settings Types
export const ACCOUNT_INFO_CHANGED = "account_info_changed";

export const SAVE_ACCOUNT_INFO = "save_account_info";
export const SAVE_ACCOUNT_INFO_SUCCESSFUL = "save_account_info_successful";
export const SAVE_ACCOUNT_INFO_FAILED = "save_account_info_failed";

export const FETCH_ACCOUNT_INFO = "fetch_account_info";
export const FETCH_ACCOUNT_INFO_SUCCESSFUL = "fetch_account_info_successful";
export const FETCH_ACCOUNT_INFO_FAILED = "fetch_account_info_failed";

export const HOLIDAY_DETAILS_CHANGED = "holiday_details_changed";
export const SAVE_HOLIDAY = "save_holiday";
export const SAVE_HOLIDAY_SUCCESSFUL = "save_holiday_successful";
export const SAVE_HOLIDAY_FAILED = "save_holiday_failed";

export const DELETE_HOLIDAY = "delete_holiday";
export const DELETE_HOLIDAY_SUCCESSFUL = "delete_holiday_successful";
export const DELETE_HOLIDAY_FAILED = "delete_holiday_failed";

export const TRAINING_DETAILS_CHANGED = "training_details_changed";
export const SAVE_NEW_TRAINING = "save_new_training";
export const SAVE_NEW_TRAINING_SUCCESSFUL = "save_new_training_successful";
export const SAVE_NEW_TRAINING_FAILED = "save_new_training_failed";

export const SAVE_CANDIDATE = "save_candidate";
export const SAVE_CANDIDATE_SUCCESSFUL = "save_candidate_successful";
export const SAVE_CANDIDATE_FAILED = "save_candidate_failed";

export const CANDIDATE_SELECTION = "candidate_selection";
export const CANDIDATE_SELECTION_SUCCESSFUL = "candidate_selection_successful";
export const CANDIDATE_SELECTION_FAILED = "candidate_selection_failed";


export const MAKE_FOREIGN_PAYMENT = "MAKE_FOREIGN_PAYMENT";
export const MAKE_FOREIGN_PAYMENT_SUCCESSFUL = "MAKE_FOREIGN_PAYMENT_SUCCESSFUL";
export const MAKE_FOREIGN_PAYMENT_FAILED = "MAKE_FOREIGN_PAYMENT_FAILED";

export const FETCH_TRAINEES = "fetch_trainees";
export const FETCH_TRAINEES_SUCCESSFUL = "fetch_trainees_successful";
export const FETCH_TRAINEES_FAILED = "fetch_trainees_failed";

export const DELETE_SELECTED_TRAINEE = "delete_selected_trainee";
export const DELETE_SELECTED_TRAINEE_SUCCESSFUL = "delete_selected_trainee_successful";
export const DELETE_SELECTED_TRAINEE_FAILED = "delete_selected_trainee_failed";

export const DELETE_COMPANY_TRAINING = "delete_company_training";
export const DELETE_COMPANY_TRAINING_SUCCESSFUL = "delete_company_training_successful";
export const DELETE_COMPANY_TRAINING_FAILED = "delete_company_training_failed";

export const TRAINING_EMPLOYEE_DETAILS_CHANGED = "training_employee_details_changed";
export const SELECTED_TRAINEES_CHANGED = "selected_trainees_changed";
export const SELECTED_TRAINING = "selected_training";

export const FETCH_COMPANY_EMPLOYEES = "fetch_company_employees";
export const FETCH_COMPANY_EMPLOYEES_SUCCESSFUL = "fetch_company_employees_successful";
export const FETCH_COMPANY_EMPLOYEES_FAILED = "fetch_company_employees_failed";

export const FETCH_HOLIDAYS = "fetch_holidays";
export const FETCH_HOLIDAYS_SUCCESSFUL = "fetch_holidays_successful";
export const FETCH_HOLIDAYS_FAILED = "fetch_holidays_failed";

export const FETCH_COMPANY_TRAININGS = "fetch_company_trainings";
export const FETCH_COMPANY_TRAININGS_SUCCESSFUL = "fetch_company_trainings_successful";
export const FETCH_COMPANY_TRAININGS_FAILED = "fetch_company_trainings_failed";

export const ASSETS_DETAILS_CHANGED = "assets_details_changed";
export const SAVE_COMPANY_ASSETS = "save_company_assets";
export const SAVE_COMPANY_ASSETS_SUCCESSFUL = "save_company_assets_successful";
export const SAVE_COMPANY_ASSETS_FAILED = "save_company_assets_failed";

export const FETCH_COMPANY_ASSETS = "fetch_company_assets";
export const FETCH_COMPANY_ASSETS_SUCCESSFUL = "fetch_company_assets_successful";
export const FETCH_COMPANY_ASSETS_FAILED = "fetch_company_assets_failed";

export const DELETE_COMPANY_ASSETS = "delete_company_assets";
export const DELETE_COMPANY_ASSETS_SUCCESSFUL = "delete_company_assets_successful";
export const DELETE_COMPANY_ASSETS_FAILED = "delete_company_assets_failed";

export const SETTINGS_FIELD_CHANGED = "settings_field_changed";
export const SAVE_BENEFIT_CATEGORY = "save_benefit_category";
export const SAVE_BENEFIT_CATEGORY_SUCCESSFUL = "save_benefit_category_successful";
export const SAVE_BENEFIT_CATEGORY_FAILED = "save_benefit_category_failed";

export const FETCH_BENEFIT_CATEGORIES = "fetch_benefit_categories";
export const FETCH_BENEFIT_CATEGORIES_SUCCESSFUL = "fetch_benefit_categories_successful";
export const FETCH_BENEFIT_CATEGORIES_FAILED = "fetch_benefit_categories_failed";

export const DELETE_BENEFIT_CATEGORY = "delete_benefit_category";
export const DELETE_BENEFIT_CATEGORY_SUCCESSFUL = "delete_benefit_category_successful";
export const DELETE_BENEFIT_CATEGORY_FAILED = "delete_benefit_category_failed";

export const SAVE_LOCATION = "save_location";
export const SAVE_LOCATION_SUCCESSFUL = "save_location_successful";
export const SAVE_LOCATION_FAILED = "save_location_failed";

export const FETCH_LOCATIONS = "fetch_locations";
export const FETCH_LOCATIONS_SUCCESSFUL = "fetch_locations_successful";
export const FETCH_LOCATIONS_FAILED = "fetch_locations_failed";

export const SAVE_PROMO_CODE = "save_promo_code";
export const SAVE_PROMO_CODE_SUCCESSFUL = "save_promo_code_successful";
export const SAVE_PROMO_CODE_FAILED = "save_promo_code_failed";


export const CLEAR_COMPANY_TRAINING_MESSAGES = "clear_company_training_messages";


export const CLEAR_COMPANY_ASSET_MESSAGE = "clear_company_asset_message";
export const CLEAR_COMPANY_BENEFIT_MESSAGE = "clear_company_benefit_message";

//support types
export const SUPPORT_DETAILS_CHANGED = "support_details_changed";

export const SEND_SUPPORT_TICKET = "send_support_ticket";
export const SEND_SUPPORT_TICKET_SUCCESSFUL = "send_support_ticket_successful";
export const SEND_SUPPORT_TICKET_FAILED = "send_support_ticket_failed";

export const FETCH_MY_SUPPORT_TICKETS = "fetch_my_support_tickets";
export const FETCH_MY_SUPPORT_TICKETS_SUCCESSFUL = "fetch_my_support_tickets_successful";
export const FETCH_MY_SUPPORT_TICKETS_FAILED = "fetch_my_support_tickets_failed";

export const FETCH_SUPPORT_TICKETS = "fetch_support_tickets";
export const FETCH_SUPPORT_TICKETS_SUCCESSFUL = "fetch_support_tickets_successful";
export const FETCH_SUPPORT_TICKETS_FAILED = "fetch_support_tickets_failed";

export const UPDATE_SUPPORT_TICKET = "update_support_ticket";
export const UPDATE_SUPPORT_TICKET_SUCCESSFUL = "update_support_ticket_successful";
export const UPDATE_SUPPORT_TICKET_FAILED = "update_support_ticket_failed";

export const REGISTER_READ_REPORT = "register_read_report";
export const REGISTER_READ_REPORT_SUCCESSFUL = "register_read_report_successful";
export const REGISTER_READ_REPORT_FAILED = "register_read_report_failed";


//payroll
export const PAYROLL_FIELD_CHANGED = "payroll_field_updated";
export const SAVE_PAYROLL_DEDUCTION = "save_payroll_deduction";
export const SAVE_PAYROLL_DEDUCTION_SUCCESSFUL = "save_payroll_deduction_successful";
export const SAVE_PAYROLL_DEDUCTION_FAILED = "save_payroll_deduction_failed";
export const FETCH_PAYROLL_DEDUCTIONS = "fetch_payroll_deductions";
export const FETCH_PAYROLL_DEDUCTIONS_SUCCESSFUL = "fetch_payroll_deductions_successful";
export const FETCH_PAYROLL_DEDUCTIONS_FAILED = "fetch_payroll_deductions_failed";
export const DELETE_PAYROLL_DEDUCTION = "delete_payroll_deduction";
export const DELETE_PAYROLL_DEDUCTION_SUCCESSFUL = "delete_payroll_deduction_successful";
export const DELETE_PAYROLL_DEDUCTION_FAILED = "delete_payroll_deduction_failed";


export const FETCH_EMPLOYEES_ON_PAYROLL = "fetch_employees_on_payroll";
export const FETCH_EMPLOYEES_ON_PAYROLL_SUCCESSFUL = "fetch_employees_on_payroll_successful";
export const FETCH_EMPLOYEES_ON_PAYROLL_FAILED = "fetch_employees_on_payroll_failed";

export const FILTER_EMPLOYEE_BY_DEPARTMENT = "filter_employee_by_bucket";
export const FILTER_EMPLOYEE_BY_DEPARTMENT_SUCCESSFUL = "filter_employee_by_bucket_successful";
export const FILTER_EMPLOYEE_BY_DEPARTMENT_FAILED = "filter_employee_by_bucket_failed";


export const FETCH_EARNED_BENEFITS_ON_PAYROLL = "fetch_earned_benefits_on_payroll";
export const FETCH_EARNED_BENEFITS_ON_PAYROLL_SUCCESSFUL = "fetch_earned_benefits_on_payroll_successful";
export const FETCH_EARNED_BENEFITS_ON_PAYROLL_FAILED = "fetch_earned_benefits_on_payroll_failed";

export const MANAGE_DEDUCTION_DETAILS_CHANGED = "manage_deduction_details_changed";

export const SAVE_EMPLOYEE_DEDUCTION = "save_employee_deduction";
export const SAVE_EMPLOYEE_DEDUCTION_SUCCESSFUL = "save_employee_deduction_successful";
export const SAVE_EMPLOYEE_DEDUCTION_FAILED = "save_employee_deduction_failed";

export const FETCH_EMPLOYEE_DEDUCTIONS = "fetch_employee_deductions";
export const FETCH_EMPLOYEE_DEDUCTIONS_SUCCESSFUL = "fetch_employee_deductions_successful";
export const FETCH_EMPLOYEE_DEDUCTIONS_FAILED = "fetch_employee_deductions_failed";

export const COMPUTE_EMPLOYEE_NET_PAY = "compute_employee_net_pay";
export const COMPUTE_EMPLOYEE_NET_PAY_SUCCESSFUL = "compute_employee_net_pay_successful";
export const COMPUTE_EMPLOYEE_NET_PAY_FAILED= "compute_employee_net_pay_failed";



//loan types
export const EMPLOYEE_LOAN_SAVE = "employee_loan_save";
export const EMPLOYEE_LOAN_SAVE_SUCCESSFUL = "employee_loan_save_successful";
export const EMPLOYEE_LOAN_SAVE_FAILED = "employee_loan_save_failed";

export const FETCH_EMPLOYEE_LOANS = "fetch_employee_loans";
export const FETCH_EMPLOYEE_LOANS_SUCCESSFUL = "fetch_employee_loans_successful";
export const FETCH_EMPLOYEE_LOANS_FAILED = "fetch_employee_loans_failed";

export const FETCH_EMPLOYEE_LOAN_PAYMENTS = "fetch_employee_loan_payments";
export const FETCH_EMPLOYEE_LOAN_PAYMENTS_SUCCESSFUL = "fetch_employee_loan_payments_successful";
export const FETCH_EMPLOYEE_LOAN_PAYMENTS_FAILED = "fetch_employee_loan_payments_failed";

//terms and condition types
export const TERMS_AND_CONDITIONS_CHANGED = "terms_and_conditions_changed";
export const SAVE_TERMS_AND_CONDITIONS = "terms_and_conditions_save";
export const SAVE_TERMS_AND_CONDITIONS_SUCCESSFUL = "terms_and_conditions_save_successful";
export const SAVE_TERMS_AND_CONDITIONS_FAILED = "terms_and_conditions_save_failed";
export const CLEAR_TERMS_AND_CONDITIONS_MESSAGES = "clear_terms_and_conditions_messages"

export const FETCH_TERMS_AND_CONDITIONS = "fetch_terms_and_conditions";
export const FETCH_TERMS_AND_CONDITIONS_SUCCESSFUL = "fetch_terms_and_conditions_successful";
export const FETCH_TERMS_AND_CONDITIONS_FAILED = "fetch_terms_and_conditions_failed";

//add subscription types
export const ADD_SUBSCRIPTION_FIELD_UPDATED = "add_subscription_field_updated";
export const ADD_CHANGE_DETAILS = "add_change_details";
export const ADD_PACKAGE_ACCOUNT = "add_package_account";
export const ADD_PACKAGE_ACCOUNT_SUCCESSFUL = "add_package_account_successful";
export const ADD_PACKAGE_ACCOUNT_FAILED = "add_package_account_failed";
export const ADD_PACKAGE_CHOSEN = "add_package_chosen";
export const ADD_SUBSCRIPTION_PAYMENT_PAID = "add_subscription_payment_paid";
export const ADD_REDIRECT_URI_FOUND = "add_redirect_uri_found";
export const ADD_SUBSCRIPTION_PAYMENT_PAID_FAILED = "add_subscription_payment_paid_failed";
export const ADD_TURN_OFF_REDIRECT_STATUS = "add_turn_off_redirect";

//subscription types
export const SUBSCRIPTION_FIELD_UPDATED = "subscription_field_updated";
export const CHANGE_DETAILS = "change_details";
export const ADD_ACCOUNT = "add_account";
export const ADD_ACCOUNT_SUCCESSFUL = "add_account_successful";
export const ADD_ACCOUNT_FAILED = "add_account_failed";

export const PACKAGE_CHOSEN = "package_chosen";
export const SUBSCRIPTION_PAYMENT_PAID = "subscription_payment_paid";
export const SUBSCRIPTION_PAYMENT_PAID_FAILED = "subscription_payment_paid_failed";
export const REDIRECT_URI_FOUND = "redirect_uri_found";
export const TURN_OFF_REDIRECT_STATUS = "turn_off_redirect_status";


//profile
export const EDIT_PROFILE_EMPLOYEE_DETAILS_CHANGED = "edit_profile_employee_details_changed";
export const EDIT_PROFILE_EMPLOYEE_DETAILS = "edit_profile_employee_details";
export const EDIT_PROFILE_EMPLOYEE_DETAILS_SUCCESSFUL = "edit_profile_employee_details_successful";
export const EDIT_PROFILE_EMPLOYEE_DETAILS_FAILED = "edit_profile_employee_details_failed";

export const FETCH_SELECTED_PROFILE_EMPLOYEE = "fetch_selected_profile_employee";
export const FETCH_SELECTED_PROFILE_EMPLOYEE_SUCCESSFUL = "fetch_selected_profile_employee_successful";
export const FETCH_SELECTED_PROFILE_EMPLOYEE_FAILED = "fetch_selected_profile_employee_failed";
export const TOGGLE_MENU = "toggle_menu";

//LANDING TYPE
export const CONTACT_US_DETAILS_CHANGED = 'contact_us_details_changed';

export const SAVE_CONTACT_DETAILS = 'save_contact_details';
export const SAVE_CONTACT_DETAILS_SUCCESSFUL = 'save_contact_details_successful';
export const SAVE_CONTACT_DETAILS_FAILED = 'save_contact_details_failed';

export const JOIN_US_CHANGED = "join_us_changed";
export const SAVE_JOIN_US = "save_join_us";
export const SAVE_JOIN_US_SUCCESSFUL = "save_join_us_successful";
export const SAVE_JOIN_US_FAILED = "save_join_us_failed";

//company types
export const COMPANY_FIELD_CHANGED = "company_field_changed";

//joinus
export const FETCH_JOIN_US = "fetch_join_us";
export const FETCH_JOIN_US_SUCCESSFUL= "fetch_join_us_successful";
export const FETCH_JOIN_US_FAILED = "fetch_join_us_failed";

//App Reports
export const FETCH_ALL_TICKETS_FOR_REPORT = "fetch_all_tickets_for_report";
export const FETCH_ALL_TICKETS_FOR_REPORT_SUCCESSFUL= "fetch_all_tickets_for_report_successful";
export const FETCH_ALL_TICKETS_FOR_REPORT_FAILED = "fetch_all_tickets_for_report_failed";


//system info
export const FETCH_SYSTEM_INFO = "fetch_system_info";
export const FETCH_SYSTEM_INFO_SUCCESSFUL= "fetch_system_info_successful";
export const FETCH_SYSTEM_INFO_EMPTY = "fetch_system_info_empty";
export const FETCH_SYSTEM_INFO_FAILED = "fetch_system_info_failed";

//subscriptions
export const FETCH_ALL_EMPLOYEES = "fetch_all_employees";
export const FETCH_ALL_EMPLOYEES_SUCCESSFUL = "fetch_all_employees_successful";
export const FETCH_ALL_EMPLOYEES_FAILED = "fetch_all_employees_failed";

export const FETCH_ALL_GENERATED_CODES = "fetch_all_generated_codes";
export const FETCH_ALL_GENERATED_CODES_SUCCESSFUL = "fetch_all_generated_codes_successful";
export const FETCH_ALL_GENERATED_CODES_FAILED = "fetch_all_generated_codes_failed";

//codes
export const FETCH_CODES = "fetch_codes";
export const FETCH_CODES_SUCCESSFUL = "fetch_codes_successful";
export const FETCH_CODES_FAILED = "fetch_codes_failed";

//payroll
export const DELETE_DEDUCTION = "delete_deduction";
export const DELETE_DEDUCTION_SUCCESSFUL = "delete_deduction_successful";
export const DELETE_DEDUCTION_FAILED = "delete_deduction_failed";

//posts
export const FETCH_CANDIDATES_BY_JOBID = "fetch_candidates_by_jobID";
export const FETCH_CANDIDATES_BY_JOBID_SUCCESSFUL = "fetch_candidates_by_jobID_successful";
export const FETCH_CANDIDATES_BY_JOBID_FAILED = "fetch_candidates_by_jobID_failed";

//general
export const SET_GENERAL_APP = "set_general_app";

//payment types
export const SAVE_PAYMENT_TYPE = "save_payment_type";
export const SAVE_PAYMENT_TYPE_SUCCESSFUL = "save_payment_type_successful";
export const SAVE_PAYMENT_TYPE_FAILED = "save_payment_type_failed";

export const DELETE_PAYMENT_TYPE = "delete_payment_type";
export const DELETE_PAYMENT_TYPE_SUCCESSFUL = "delete_payment_type_successful";
export const DELETE_PAYMENT_TYPE_FAILED = "delete_payment_type_failed";

export const FETCH_PAYMENT_TYPES = "fetch_payment_types";
export const FETCH_PAYMENT_TYPES_SUCCESSFUL = "fetch_payment_types_successful";
export const FETCH_PAYMENT_TYPES_FAILED = "fetch_payment_types_failed";

export const SAVE_DEPARTMENT = "save_department";
export const SAVE_DEPARTMENT_SUCCESSFUL = "save_department_successful";
export const SAVE_DEPARTMENT_FAILED = "save_department_failed";

export const FETCH_DEPARTMENTS = "fetch_departments";
export const FETCH_DEPARTMENTS_SUCCESSFUL = "fetch_departments_successful";
export const FETCH_DEPARTMENTS_FAILED = "fetch_departments_failed";




/////NEW
export const FETCH_PAYMENTS_PACKAGES = "fetch_payment_packages";
export const FETCH_PAYMENTS_PACKAGES_SUCCESSFUL = "fetch_payment_packages_successful";
export const FETCH_PAYMENTS_PACKAGES_FAILED = "fetch_payment_packages_failed";

export const FETCH_WALLET_CARRIERS = "fetch_wallet_carriers";
export const FETCH_WALLET_CARRIERS_SUCCESSFUL = "fetch_wallet_carriers_successful";
export const FETCH_WALLET_CARRIERS_FAILED = "fetch_wallet_carriers_failed";

export const FETCH_MY_PAYMENTS = "fetch_my_payments";
export const FETCH_MY_PAYMENTS_SUCCESSFUL = "fetch_my_payments_successful";
export const FETCH_MY_PAYMENTS_FAILED = "fetch_my_payments_failed";

export const FETCH_PAYMENT_STATUS = "fetch_payment_status";
export const FETCH_PAYMENT_STATUS_SUCCESSFUL = "fetch_payment_status_successful";
export const FETCH_PAYMENT_STATUS_FAILED = "fetch_payment_status_failed";

export const FETCH_MONTHS = "fetch_months";
export const FETCH_MONTHS_SUCCESSFUL = "fetch_months_successful";
export const FETCH_MONTHS_FAILED = "fetch_months_failed";

export const MAKE_PAYMENT = "make_payment";
export const MAKE_PAYMENT_SUCCESSFUL = "make_payment_successful";
export const MAKE_PAYMENT_FAILED = "make_payment_failed";

export const FETCH_RECENT_SEARCHES = "fetch_recent_searches";
export const FETCH_RECENT_SEARCHES_SUCCESSFUL = "fetch_recent_searches_successful";
export const FETCH_RECENT_SEARCHES_FAILED = "fetch_recent_searches_failed";

export const POSTS_FIELD_CHANGED = "posts_field_changed";

export const FETCH_POSTS = "fetch_posts";
export const FETCH_POSTS_SUCCESSFUL = "fetch_posts_successful";
export const FETCH_POSTS_FAILED = "fetch_posts_failed";

export const ADD_POST = "add_post";
export const ADD_POST_SUCCESSFUL = "add_post_successful";
export const ADD_POST_FAILED = "add_post_failed";

export const SEARCH = "search";
export const SEARCH_SUCCESSFUL = "search_successful";
export const SEARCH_FAILED = "search_failed";

export const SAVE_SEARCHES = "save_searches";
export const SAVE_SEARCHES_SUCCESS = "save_searches_success";
export const SAVE_SEARCHES_FAIL = "save_searches_fail";

export const SEARCH_FIELD_CHANGED = "search_field_changed";
export const CLEAR_SEARCH = "clear_search";


export const FETCH_EDUCATION_LEVELS = "fetch_education_levels";
export const FETCH_EDUCATION_LEVELS_SUCCESSFUL = "fetch_education_levels_successful";
export const FETCH_EDUCATION_LEVELS_FAILED = "fetch_education_levels_failed";

export const FETCH_EDUCATION_INDUSTRY = "fetch_education_industry";
export const FETCH_EDUCATION_INDUSTRY_SUCCESSFUL = "fetch_education_industry_successful";
export const FETCH_EDUCATION_INDUSTRY_FAILED = "fetch_education_industry_failed";

export const FETCH_JOB_TITLES = "fetch_job_titles";
export const FETCH_JOB_TITLES_SUCCESSFUL = "fetch_job_titles_successful";
export const FETCH_JOB_TITLES_FAILED = "fetch_job_titles_failed";

export const FETCH_SKILLS = "fetch_skills";
export const FETCH_SKILLS_SUCCESSFUL = "fetch_skills_successful";
export const FETCH_SKILLS_FAILED = "fetch_skills_failed";

export const FETCH_CERTIFICATES = "fetch_certificates";
export const FETCH_CERTIFICATES_SUCCESSFUL = "fetch_certificates_successful";
export const FETCH_CERTIFICATES_FAILED = "fetch_certificates_failed";

export const FETCH_LINKS = "fetch_links";
export const FETCH_LINKS_SUCCESSFUL = "fetch_links_successful";
export const FETCH_LINKS_FAILED = "fetch_links_failed";

export const FETCH_LANGUAGES = "fetch_languages";
export const FETCH_LANGUAGES_SUCCESSFUL = "fetch_languages_successful";
export const FETCH_LANGUAGES_FAILED = "fetch_languages_failed";

export const FETCH_LICENSES = "fetch_licenses";
export const FETCH_LICENSES_SUCCESSFUL = "fetch_licenses_successful";
export const FETCH_LICENSES_FAILED = "fetch_licenses_failed";

export const ADD_TO_SHORTLIST = "add_to_shortlist";
export const ADD_TO_SHORTLIST_SUCCESSFUL = "add_to_shortlist_successful";
export const ADD_TO_SHORTLIST_FAILED = "add_to_shortlist_failed";

export const DELETE_FROM_SHORTLIST = "delete_from_shortlist";
export const DELETE_FROM_SHORTLIST_SUCCESSFUL = "delete_from_shortlist_successful";
export const DELETE_FROM_SHORTLIST_FAILED = "delete_from_shortlist_failed";

export const SAVE_BLOG = "save_blog";
export const SAVE_BLOG_SUCCESSFUL = "save_blog_successful";
export const SAVE_BLOG_FAILED = "save_blog_failed";

export const FETCH_BLOGS = "fetch_blogs";
export const FETCH_BLOGS_SUCCESSFUL = "fetch_blogs_successful";
export const FETCH_BLOGS_FAILED = "fetch_blogs_failed";

export const EXTRACT_DATA = "extract_data";
export const EXTRACT_DATA_SUCCESSFUL = "extract_data_successful";
export const EXTRACT_DATA_FAILED = "extract_data_failed";

export const CLEAR_POST = "clear_post";
export const UPDATE_INTERVIEW_CANDIDATE_SUCCESSFUL = "update_interview_candidate_successful";
export const ADD_EVENT_TO_CALENDAR = "add_event_to_calendar"
export const ADD_EVENT_TO_CALENDAR_SUCCESSFUL = "add_event_to_calendar_sucessful"

export const FETCH_FEEDBACK = "fetch_feedback";
export const FETCH_FEEDBACK_SUCCESSFUL = "fetch_feedback_successful";
export const FETCH_FEEDBACK_FAILED = "fetch_feedback_failed";

export const FETCH_ASSESSMENT = "fetch_assessment";
export const FETCH_ASSESSMENT_SUCCESSFUL = "fetch_assessment_successful";
export const FETCH_ASSESSMENT_FAILED = "fetch_assessment_failed";

export const FETCH_RECRUITERS_REQUESTS = "fetch_recruiters_requests";
export const FETCH_RECRUITERS_REQUESTS_SUCCESSFUL = "fetch_recruiters_requests_successful";
export const FETCH_RECRUITERS_REQUESTS_FAILED = "fetch_recruiters_requests_failed";
export const FETCH_INDUSTRY_GENDER_COUNTS_SUCCESS = "fetch_industry_gender_count_sucess"
export const FETCH_GENDER_INTERVIEWS_DISTRIBUTION = "fetch_gender_interviews_distribution"
export const FETCH_COMMON_SKILLS = "fetch_most_common_skilld"
export const FETCH_COMMON_SKILLS_SUCCESS = "fetch_common_skills_success"
export const FETCH_JOB_POST_STATS = "fetch_job_post_stats"
export const FETCH_JOB_POST_STATS_SUCCESSFUL = "fetch_job_posts_successful"
export const FETCH_CANDIDATE_COUNTS = "fetch_candidate_counts"
export const UPDATE_CANDIDATE_COUNTS = "update_candidate_counts"
export const FETCH_AVERAGE_RESPONSE_TIME = "fetch_average_response_time"
export const SET_AVERAGE_RESPONSE_TIME = "set_average_response_time"


export const FETCH_CANDIDATES_EXPERIENCE = "fetch_candidates_experience";
export const FETCH_CANDIDATES_EXPERIENCE_SUCCESS = "fetch_candidates_experience_success";
export const FETCH_CANDIDATES_EXPERIENCE_FAIL = "fetch_candidates_experience_fail";

export const FETCH_CANDIDATE_GENDER = "fetch_candidate_gender";
export const FETCH_AVERAGE_TIME_TO_HIRE = "fetch_average_time_to_hire";

export const FETCH_ADMIN_HIGHLIGHTS = "fetch_admin_highlights";
export const FETCH_ADMIN_HIGHLIGHTS_SUCCESS = "fetch_admin_highlights_success";
export const FETCH_ADMIN_HIGHLIGHTS_FAIL = "fetch_admin_highlights_fail";

export const FETCHED_UPCOMING_INTERVIEWS_SUCESSFULLY = "fetched_upcoming_interviews_success"
export const FETCH_UPCOMING_INTERVIEWS = "fetch_upcoming_interviews"
export const FETCH_UPCOMING_INTERVIEWS_FAILED = "fetch_upcoming_interviews_failed"

export const DATA_FOUND = "data_found"
