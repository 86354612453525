import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {useNavigate} from "react-router-dom";

//action imports
import {clearSelectPost} from "../../actions/PostsActions";

//components import
import ShortlistCandidates from "./subComponents/ShortlistCandidates";
import {Spinner} from "../common/Spinner";
import { PackageAddIcon } from "hugeicons-react";

class ShortlistBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            shortlist: this.props.shortlist,
            loading: this.props.loading,
            profile: this.props.profile,
            isToggled:this.props.isToggled
        }
    }

    componentDidMount() {
        //clear select post so that perform interview option is hidden
        this.props.clearSelectPost();
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps !== this.props) {
            this.setState({
                shortlist: nextProps.shortlist,
                loading: nextProps.loading,
                profile: nextProps.profile,
                isToggled:nextProps.isToggled,
            });
        }
    }

    renderShortlist() {
        const { shortlist, isToggled } = this.state;

        if (shortlist.length !== 0) {
            let numberOfShortlist = shortlist.length;
            return (
                <div style={{
                    width: '100%',
                    marginBottom: '24px',
                }}>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '16px'
                    }}>
                        <h4 style={{ 
                            color: "#0066cc",
                            fontFamily: "ramblaBold",
                            fontSize: '18px',
                            margin: 0,
                            marginRight: '8px'
                        }}>
                            Candidates
                        </h4>
                        <h5 style={{ 
                            color: "#0066cc",
                            fontFamily: "ramblaBold",
                            fontSize: '16px',
                            margin: 0,
                            opacity: 0.8
                        }}>
                            ({numberOfShortlist})
                        </h5>
                    </div>

                    <div style={{
                        width: '100%',
                    }}>
                        <ShortlistCandidates shortlist={shortlist} isToggled={isToggled} />
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{
                    background: '#f5f5f7',
                    borderRadius: '16px',
                    padding: '48px 24px',
                    textAlign: 'center',
                    margin: '24px 0'
                }}>
                    <h5 style={{
                        fontFamily: 'ramblaBold',
                        color: '#86868b',
                        fontSize: '17px',
                        margin: 0,
                        lineHeight: 1.5
                    }}>
                        No candidates have been shortlisted yet, shortlist candidates from{' '}
                        <span 
                            style={{ 
                                color: "#0066cc",
                                cursor: "pointer",
                                textDecoration: 'none',
                                transition: 'opacity 0.2s ease'
                            }}
                            onClick={() => this.props.navigate("/search")}
                            onMouseEnter={(e) => e.target.style.opacity = '0.8'}
                            onMouseLeave={(e) => e.target.style.opacity = '1'}
                        >
                            Search
                        </span>
                    </h5>
                </div>
            );
        }
    }

    checkUser() {
        const { profile, loading } = this.state;

        if(!(_.isEmpty(profile))) {
            if (loading) {
                return <Spinner/>;
            } else {
                if ((profile.role === "admin") || (profile.role === "superAdmin")) {
                    return (
                        <div style={{
                            width: '100%',
                            maxWidth: 'auto',
                            margin: '0 0',
                            padding: '24px',
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '16px',
                                marginBottom: '24px'
                            }}>
                                <PackageAddIcon size={32} style={{ color: "#1d1d1f" }} />
                                <h3 style={{
                                    margin: 0,
                                    fontFamily: 'ramblaBold',
                                    fontSize: '24px',
                                    color: '#1d1d1f'
                                }}>
                                    Your Shortlist
                                </h3>
                            </div>

                            {this.renderShortlist()}
                        </div>
                    );
                } else {
                    return (
                        <div style={{
                            padding: '48px 24px',
                            textAlign: 'center'
                        }}>
                            <h3 style={{
                                fontFamily: 'ramblaBold',
                                color: '#ff3b30',
                                fontSize: '18px'
                            }}>
                                Un-Authorized Access
                            </h3>
                        </div>
                    );
                }
            }
        } else {
            return (
                <div style={{
                    padding: '48px 24px',
                    textAlign: 'center'
                }}>
                    <h3 style={{
                        fontFamily: 'ramblaBold',
                        color: '#86868b',
                        fontSize: '18px'
                    }}>
                        Profile hasn't loaded yet
                    </h3>
                </div>
            );
        }
    }

    render() {
        return (
            <div style={{
                minHeight: '100vh',
                background: '#fff',
                width: '100%',
                margin: 0,
                padding: 0,
            }}>
                {this.checkUser()}
            </div>
        );
    }
}

const mapStateTopProps = state => {
    const { shortlist, loading } = state.posts;
    const { profile } = state.profile;
    const { isToggled } = state.home;


    return {
        shortlist,
        loading,
        profile,
        isToggled
    }
};

// Wrapper functional component to use hooks
function Shortlist(props) {
    const navigate = useNavigate();

    return <ShortlistBase {...props} navigate={navigate} />;
}

export default connect(mapStateTopProps, { clearSelectPost })(Shortlist);
