import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import { Table, Popconfirm, message, Input, Button, Form, Card, Space } from 'antd';
import { CheckmarkBadge03Icon, PropertyDeleteIcon } from "hugeicons-react";
import './AddAssets.css';


//actions import
import {assetDetailsChanged, addCompanyAsset, deleteCompanyAsset} from "../../../actions/SettingsActions";


//components import
import {SelectAssetsCategory} from "../../common/SelectAssetsCategory";
import {ClipLoader} from "react-spinners";
import CustomHeaderCard from '../../search/cards/CustomHeaderCard';


class AddAssets extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
            assets: this.props.assets,
            assetCategory: this.props.assetCategory,
            assetTitle: this.props.assetTitle,

            saveAssetLoading: this.props.saveAssetLoading,
            saveAssetSuccessful: this.props.saveAssetSuccessful,
            saveAssetFailed: this.props.saveAssetFailed,
            deleteAssetLoading: this.props.deleteAssetLoading,
            deleteAssetSuccessful: this.props.deleteAssetSuccessful,
            deleteAssetFailed: this.props.deleteAssetFailed,
        }

        this.onSelectAssetCategory = this.onSelectAssetCategory.bind(this);
        this.renderAssets = this.renderAssets.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }


    componentWillReceiveProps(nextProps, nextContext) {

        if (nextProps !== this.props) {
            this.setState({
                profile: nextProps.profile,
                assets: nextProps.assets,
                assetCategory: nextProps.assetCategory,
                assetTitle: nextProps.assetTitle,

                saveAssetLoading: nextProps.saveAssetLoading,
                saveAssetSuccessful: nextProps.saveAssetSuccessful,
                saveAssetFailed: nextProps.saveAssetFailed,
                deleteAssetLoading: nextProps.deleteAssetLoading,
                deleteAssetSuccessful: nextProps.deleteAssetSuccessful,
                deleteAssetFailed: nextProps.deleteAssetFailed
            })
        }
    }

    renderDeleteSuccessMessage() {
        const {deleteAssetSuccessful} = this.state;
        if (deleteAssetSuccessful){
            message.success({
                content: "Asset deleted successfully",
                style: {
                    marginTop: '8vh',
                },
            });
        }
    }

    renderDeleteFailedMessage() {
        const {deleteAssetFailed} = this.state;
        if (deleteAssetFailed){
            message.error({
                content: "Failed to delete asset",
                style: {
                    marginTop: '8vh',
                },
            });
        }
    }

    onDelete(asset) {
        const assetID = asset.ID;

        this.props.deleteCompanyAsset({ assetID });
    }

    onCancel() {
        message.info("Deletion is canceled");
    }

    renderAssets() {
        const {assets} = this.state;

        const assetsArray = _.map(assets, asset => {
            return asset
        });

        const columns = [
            {
                title: <CustomHeaderCard>Category</CustomHeaderCard>,
                dataIndex: 'list',
                key: 'list',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.list}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Title</CustomHeaderCard>,
                dataIndex: 'title',
                key: 'title',
                render: (_, record) => (
                    <div style={{ fontSize: '0.9rem', fontFamily: 'ramblaRegular', color: record.color }}>{record.title}</div>
                ),
            },
            {
                title: <CustomHeaderCard>Delete</CustomHeaderCard>,
                dataIndex: 'remove',
                key: 'remove',
                render: (_, record) => (
                    <Popconfirm
                        title="Are you sure to delete this asset?"
                        onConfirm={() => this.onDelete(record)}
                        onCancel={() => this.onCancel()}
                        okText="Yes"
                        cancelText="No"
                    >
                        <PropertyDeleteIcon color="red" size={20} style={{cursor: 'pointer'}} />
                    </Popconfirm>
                ),
            },
        ];

        return (
            <Table dataSource={assetsArray} columns={columns} rowKey="id" />
        );
    }

    renderSuccessMessage() {
        const {saveAssetSuccessful} = this.state;
        if (saveAssetSuccessful){
            message.success({
                content: "Asset saved successfully",
                style: {
                    marginTop: '8vh',
                },
            });
        }
    }

    renderFailedMessage() {
        const {saveAssetFailed} = this.state;
        if (saveAssetFailed){
            message.error({
                content: "Failed to save asset",
                style: {
                    marginTop: '8vh',
                },
            });
        }
    }

    onSelectAssetCategory(value) {
        this.props.assetDetailsChanged({prop: 'assetCategory', value})
    }

    onSave() {
        //-> extract state
        const { profile } = this.state;
        const {assetCategory, assetTitle } = this.state;

        if (!(_.isEmpty(profile))) {
            if (assetCategory && assetTitle) {
                this.props.addCompanyAsset({assetCategory, assetTitle})
            } else {
                message.warning("Fill in all the fields");
            }
        }
    }

    checkUser() {
        const { profile } = this.state;

        if(!(_.isEmpty(profile))) {
            if (profile.role === "superAdmin") {
                return (
                    <div className="nafasi-add-assets">
                        <Space direction="vertical" style={{ width: '100%' }} size="large">
                            <Card title="Add New Asset" size="small">
                                <Form layout="vertical" className="nafasi-add-assets__form">
                                    <div className="nafasi-add-assets__form-content">
                                        <Form.Item label="Category" required>
                                            <SelectAssetsCategory
                                                onChange={this.onSelectAssetCategory}
                                                value={this.state.assetCategory}
                                                placeholder="Select category"
                                            />
                                        </Form.Item>
                                        <Form.Item label="Title" required>
                                            <Input
                                                size="small"
                                                placeholder="Enter title"
                                                onChange={e => this.props.assetDetailsChanged({prop: 'assetTitle', value: e.target.value})}
                                                value={this.state.assetTitle}
                                            />
                                        </Form.Item>

                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                size="small"
                                                onClick={() => this.onSave()}
                                                loading={this.state.saveAssetLoading}
                                            >
                                                <p style={{ fontFamily: 'ramblaRegular' }}>Save</p>
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </Card>

                            <Card size="small" className="nafasi-add-assets__table">
                                {this.renderAssets()}
                                {this.renderDeleteSuccessMessage()}
                                {this.renderDeleteFailedMessage()}
                            </Card>
                        </Space>
                    </div>
                );
            }
        }
    }

    render() {
        return (
            <div className="nafasi-add-assets__container">
                {this.checkUser()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    //-> extract state
    const { profile } = state.profile;
    const {assets, assetCategory, assetTitle, saveAssetLoading, saveAssetSuccessful, saveAssetFailed, deleteAssetLoading, deleteAssetSuccessful, deleteAssetFailed, } = state.settings;

    return{
        profile,
        assets,
        assetCategory,
        assetTitle,

        saveAssetLoading,
        saveAssetSuccessful,
        saveAssetFailed,
        deleteAssetLoading,
        deleteAssetSuccessful,
        deleteAssetFailed,
    }
}

export default connect(mapStateToProps, {assetDetailsChanged, addCompanyAsset, deleteCompanyAsset })(AddAssets);