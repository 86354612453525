import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import arraySort from "array-sort";
import { message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import "./PostReview.css";

//components import
import PostReviewCard from "../cards/PostReviewCard";

class PostReviewBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPost: this.props.selectedPost,
            candidates: this.props.candidates,
            candidateWithdrawn: this.props.candidateWithdrawn,
            candidateWithdrawnFailed: this.props.candidateWithdrawnFailed,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                selectedPost: nextProps.selectedPost,
                candidates: nextProps.candidates,
                candidateWithdrawn: nextProps.candidateWithdrawn,
                candidateWithdrawnFailed: nextProps.candidateWithdrawnFailed
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { candidateWithdrawn, candidateWithdrawnFailed } = this.state;
        
        if (candidateWithdrawn && prevProps.candidateWithdrawn !== candidateWithdrawn) {
            message.success({
                content: "Candidate has been withdrawn successfully",
                style: {
                    marginTop: '20vh',
                },
            });
        }

        if (candidateWithdrawnFailed && prevProps.candidateWithdrawnFailed !== candidateWithdrawnFailed) {
            message.error({
                content: "Offer not withdrawn",
                style: {
                    marginTop: '20vh',
                },
            });
        }
    }

    renderCandidateList() {
        const { candidates } = this.state;

        if (!(_.isEmpty(candidates))) {
            const candidateArray = _.map(candidates, candidate => candidate)
                .filter(candidate => typeof candidate !== 'undefined');

            const sortedCandidateList = arraySort(candidateArray, "name", { reverse: true });

            return (
                <div className="candidates-grid">
                    <PostReviewCard candidates={sortedCandidateList}/>
                </div>
            );
        }

        return (
            <div className="adminAlert">
                <h5>
                    Currently, there are no candidates! Invite candidates to interview from your
                    <span onClick={() => this.props.navigate("/shortlist")}> Shortlist</span>
                </h5>
            </div>
        );
    }

    render() {
        return (
            <div className="jobOpeningReviewContainer">
                <div className="topBarBox">
                    <div className="childHeaderBarBox">
                        <FontAwesomeIcon 
                            icon="file-signature" 
                            style={{
                                fontSize: '20px',
                                color: '#1d1d1f'
                            }} 
                        />
                        <h2>Job Post Review</h2>
                    </div>
                </div>
                
                <div className="candidateListBox">
                    <h4>Candidates List</h4>
                    {this.renderCandidateList()}
                </div>
            </div>
        );
    }
}

const mapStateTopProps = state => {
    const { candidates, selectedPost, candidateWithdrawn, candidateWithdrawnFailed } = state.posts;
    return {
        candidates,
        selectedPost,
        candidateWithdrawn,
        candidateWithdrawnFailed,
    }
};

function PostReview(props) {
    const navigate = useNavigate();
    return <PostReviewBase {...props} navigate={navigate} />;
}

export default connect(mapStateTopProps, {})(PostReview);
