import React, { Component } from 'react';
import firebase from 'firebase';
import { Card, Typography, message, Space, Skeleton, Empty } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { LicenseDraftIcon } from "hugeicons-react";
import './ViewBlogs.css';

const { Title, Text, Paragraph } = Typography;

class BlogListBase extends Component {
	state = {
		blogs: [],
		loading: true,
	};

	componentDidMount() {
		this.fetchBlogs();
	}

	fetchBlogs = () => {
		firebase.firestore().collection('blogs')
			.orderBy('createdAt', 'desc')
			.limit(10)
			.get()
			.then((querySnapshot) => {
				const blogs = [];
				querySnapshot.forEach((doc) => {
					blogs.push({ id: doc.id, ...doc.data() });
				});
				this.setState({ blogs, loading: false });
			})
			.catch((error) => {
				message.error("Error fetching blogs");
				this.setState({ loading: false });
				console.error("Error fetching blogs: ", error);
			});
	};

	navigateToBlog = (id) => {
		this.props.navigate(`/blog/${id}`);
	};

	deleteBlog = (id) => {
		this.setState({ loading: true });
		firebase.firestore().collection('blogs')
			.doc(id)
			.delete()
			.then(() => {
				message.success("Blog post deleted successfully");
				this.fetchBlogs();
			})
			.catch((error) => {
				message.error("Failed to delete blog post");
				this.setState({ loading: false });
				console.error("Error removing document: ", error);
			});
	};

	render() {
		const { blogs, loading } = this.state;

		return (
			<div className="nafasi-blogs__container">
				<div className="nafasi-blogs">
					<Space direction="vertical" style={{ width: '100%' }} size="middle">
						<Card size="small" className="nafasi-blogs__header-card">
							<div className="nafasi-blogs__header">
								<LicenseDraftIcon size={24} className="nafasi-blogs__icon" />
								<Title level={4} className="nafasi-blogs__title">Blog Posts</Title>
							</div>
						</Card>

						<div className="nafasi-blogs__content">
							{loading ? (
								<div className="nafasi-blogs__loading">
									{[...Array(3)].map((_, index) => (
										<Card key={index} className="nafasi-blogs__card">
											<Skeleton active avatar={false} paragraph={{ rows: 3 }} />
										</Card>
									))}
								</div>
							) : blogs.length === 0 ? (
								<Card className="nafasi-blogs__empty">
									<Empty
										description={
											<Text className="nafasi-blogs__empty-text">
												No blog posts found
											</Text>
										}
									/>
								</Card>
							) : (
								<div className="nafasi-blogs__grid">
									{blogs.map((blog) => (
										<Card
											key={blog.id}
											className="nafasi-blogs__card"
											onClick={() => this.navigateToBlog(blog.id)}
											actions={[
												<EditOutlined 
													key="edit" 
													className="nafasi-blogs__action-icon"
													onClick={(e) => {
														e.stopPropagation();
														this.props.navigate(`/edit-blog/${blog.id}`);
													}}
												/>,
												<DeleteOutlined 
													key="delete" 
													className="nafasi-blogs__action-icon nafasi-blogs__action-icon--delete"
													onClick={(e) => {
														e.stopPropagation();
														this.deleteBlog(blog.id);
													}}
												/>,
											]}
										>
											<div className="nafasi-blogs__card-content">
												<Title level={4} className="nafasi-blogs__card-title">
													{blog.title}
												</Title>
												<Text type="secondary" className="nafasi-blogs__card-date">
													{new Date(blog.createdAt.seconds * 1000).toLocaleDateString('en-US', {
														weekday: 'long',
														year: 'numeric',
														month: 'long',
														day: 'numeric'
													})}
												</Text>
												<Paragraph className="nafasi-blogs__card-description">
													{blog.description}
												</Paragraph>
											</div>
										</Card>
									))}
								</div>
							)}
						</div>
					</Space>
				</div>
			</div>
		);
	}
}

function BlogList(props) {
	const navigate = useNavigate();
	return <BlogListBase {...props} navigate={navigate} />;
}

export default BlogList;