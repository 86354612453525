import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "../Certificates.css";

class CertificateCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            certificates: this.props.certificates,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                certificates: nextProps.certificates,
            });
        }
    }

    formatDate(timestamp) {
        if (!timestamp) return 'N/A';
        if (timestamp.toDate) {
            return moment(timestamp.toDate()).format('MMM YYYY');
        }
        if (timestamp.seconds || timestamp._seconds) {
            const seconds = timestamp.seconds || timestamp._seconds;
            return moment.unix(seconds).format('MMM YYYY');
        }
        return moment(timestamp).format('MMM YYYY');
    }

    getStatusBadge(isExpired, expiryDate) {
        if (!expiryDate) return { label: 'No Expiry', color: '#F3F4F6', textColor: '#374151' };
        if (isExpired) return { label: 'Expired', color: '#FEE2E2', textColor: '#991B1B' };
        return { label: 'Valid', color: '#DCFCE7', textColor: '#166534' };
    }

    renderCertificates() {
        const { certificates } = this.state;
        console.log(certificates[0]);

        return (
            <div className="certificates-grid">
                {certificates.map((cert, index) => {
                    const isExpired = cert.expiryDate && moment().isAfter(moment(cert.expiryDate.toDate()));
                    const status = this.getStatusBadge(isExpired, cert.expiryDate);

                    return (
                        <div key={index} className="certificate-card">
                            <div className="certificate-header">
                                <div className="certificate-icon">
                                    <FontAwesomeIcon icon="award" />
                                </div>
                                <div className="certificate-info">
                                    <h4>{cert.title || 'Untitled Certificate'}</h4>
                                    <span className="certificate-issuer">
                                        {cert.issuingOrganization || cert.issuer || 'Unknown Issuer'}
                                    </span>
                                </div>
                            </div>

                            <div className="certificate-meta">
                                <div 
                                    className="status-badge"
                                    style={{ 
                                        backgroundColor: status.color,
                                        color: status.textColor
                                    }}
                                >
                                    {status.label}
                                </div>

                                <div className="date-info">
                                    <span>Issued: {this.formatDate(cert.issueDate)}</span>
                                    {cert.expiryDate && (
                                        <span>Expires: {this.formatDate(cert.expiryDate)}</span>
                                    )}
                                </div>
                            </div>

                            {cert.credentialID && (
                                <div className="credential-info">
                                    Credential ID: {cert.credentialID}
                                </div>
                            )}

                            {cert.description && (
                                <p className="certificate-description">
                                    {cert.description}
                                </p>
                            )}

                            {cert.credentialUrl && (
                                <a 
                                    href={cert.credentialUrl} 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="view-link"
                                >
                                    View Certificate
                                </a>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        return (
            <div className="certificates-timeline">
                {this.renderCertificates()}
            </div>
        );
    }
}

export default CertificateCard; 