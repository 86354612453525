import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Skills.css';

// Import the action
import { fetchSeekerPath } from '../../../actions/PostsActions';

//components import
import { Spinner } from "../../common/Spinner";
import SkillsCard from "./cards/SkillsCard";

class Skills extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCandidate: this.props.selectedCandidate,
            skills: this.props.skills,
            skillsLoading: this.props.skillsLoading,
            profile: this.props.profile,
        }

        this.renderSkills = this.renderSkills.bind(this);
    }

    componentDidMount() {
        const { selectedCandidate } = this.props;
        if (selectedCandidate && selectedCandidate.id) {
            this.props.fetchSeekerPath(selectedCandidate.id, "skills");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedCandidate !== this.props.selectedCandidate) {
            if (this.props.selectedCandidate && this.props.selectedCandidate.id) {
                this.props.fetchSeekerPath(this.props.selectedCandidate.id, "skills");
            }
        }

        if (prevProps !== this.props) {
            this.setState({
                selectedCandidate: this.props.selectedCandidate,
                skills: this.props.skills,
                skillsLoading: this.props.skillsLoading,
                profile: this.props.profile,
            });
        }
    }

    renderSkills() {
        const { skills, skillsLoading, selectedCandidate } = this.state;

        if (skillsLoading) {
            return <Spinner />
        }

        if (_.isEmpty(selectedCandidate)) {
            return <p style={{ color: "#000", margin: "2rem 0" }}>Select a candidate to view</p>;
        }

        if (!skills || skills.length === 0) {
            return (
                <div className="no-data-message">
                    <FontAwesomeIcon icon="lightbulb" className="no-data-icon" />
                    <p>No skills listed</p>
                </div>
            );
        }

        return (
            <div className="skills-timeline">
                <SkillsCard skills={skills}/>
            </div>
        );
    }

    checkUser() {
        const { profile } = this.state;

        if(_.isEmpty(profile)) {
            return <h2 style={{ color: "#000" }}>Sign in to view</h2>;
        }

        if(profile.role !== "admin" && profile.role !== "superAdmin") {
            return <h3>Un-Authorized Access</h3>;
        }

        return (
            <div className="section-container">
                <div className="section-header">
                    <h4>
                        <FontAwesomeIcon icon="code" className="section-icon" />
                        Professional Skills
                    </h4>
                    <div className="section-summary">
                        <span className="total-count">
                            {this.state.skills?.length || 0} Skills Listed
                        </span>
                    </div>
                </div>
                <div className="section-content">
                    {this.renderSkills()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="skills-container">
                {this.checkUser()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedCandidate, skills, skillsLoading } = state.posts;
    const { profile } = state.profile;

    return {
        selectedCandidate,
        skills,
        skillsLoading,
        profile
    }
}

export default connect(mapStateToProps, { fetchSeekerPath })(Skills);