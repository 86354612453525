import React from "react";
import moment from "moment";
import { Table } from 'antd';
import './PaymentCard.css';

import CustomHeaderCard from "../../../search/cards/CustomHeaderCard";
import DescriptionModal from './DescriptionModal';

class PaymentCard extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            payments: this.props.payments,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                payments: nextProps.payments,
            });
        }
    }

    renderPayments(){
        const { payments } = this.state;

        let processedResults = payments.map(result => {
            let seconds;
            result.paymentDate.seconds ? seconds = result.paymentDate.seconds : seconds = result.paymentDate._seconds;
            const paymentMoment = moment.unix(seconds);
    
            const paymentString = paymentMoment.format('HH:mm | DD MMM YYYY');
            return { ...result, paymentString};
        })

        const columns = [
            {
                title: <CustomHeaderCard>Package</CustomHeaderCard>,
                dataIndex: 'paymentPackage.title',
                key: 'paymentPackage.title',
                render: (_, record) => (
                    <div className="nafasi-payment-card__cell">
                        {record.paymentPackage.title}
                    </div>
                ),
            },
            {
                title: <CustomHeaderCard>Details</CustomHeaderCard>,
                dataIndex: 'details',
                key: 'details',
                render: (_, record) => (
                    <div className="nafasi-payment-card__cell nafasi-payment-card__cell--details">
                        <DescriptionModal record={record}/>
                    </div>
                ),
            },
            {
                title: <CustomHeaderCard>Date</CustomHeaderCard>,
                dataIndex: 'paymentString',
                key: 'paymentString',
                render: (_, record) => (
                    <div className="nafasi-payment-card__cell">
                        {record.paymentString}
                    </div>
                ),
            },
        ];

        return (
            <div className="nafasi-payment-card__table">
                <Table 
                    dataSource={processedResults} 
                    columns={columns} 
                    rowKey="id"
                    pagination={{
                        pageSize: 10,
                        position: ['bottomCenter'],
                        size: 'small'
                    }}
                />
            </div>
        )
    }

    render() {
        return(
            <div className="nafasi-payment-card">
                {this.renderPayments()}
            </div>
        );
    }
}

export default PaymentCard;