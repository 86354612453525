import {
    CREDENTIALS_CHANGED,
    LOGIN_SUCCESSFUL,
    LOGIN_USER,
    LOGIN_FAILED, SIGN_UP_USER_FAILED, SIGN_UP_USER_SUCCESSFUL,
    LOG_OUT,
    LOGOUT_SUCCESSFULLY,
    LOGOUT_FAILED,
    INITIALIZE_STATE
} from '../actions/Types'; 

const INITIAL_STATE = {
    email: '',
    password: '',

    firstName: '',
    lastName: '',
    phone: '',
    companyName: '',
    companyPhone: '',
    companyEmail: '',
    logo: '',
    logoChanged: false,
    country: {},
    region: {},
    address: '',
    postalCode: '',
    companyRegDocument: '',
    adminIDs: '',


    user: null,

    loading: false,
    loginSuccess: false,
    loginFailed: false,

    signUpError: "",

    error: false,
    errorMessage: '',
};

const authenticationReducer = ( state = INITIAL_STATE, action) => {
    switch (action.type) {

        //-> sign in reducers
        case CREDENTIALS_CHANGED:
            return { ...state, [action.payload.prop]: action.payload.value, loginFailed: false, loginSuccess: false, error: false };
        case LOGIN_USER:
            return  { ...state, loading: true, signUpError: "" };
        case LOGIN_SUCCESSFUL:
            return { ...state, user: true, email: "", password: "", privateID: "",loading: false, loginSuccess: true, loginFailed: false, error: false };
        case LOGIN_FAILED:
            return  { ...state, user: false, loginFailed: true, loading: false, loginSuccess: false, error: false };

            //sign up reducers
        case SIGN_UP_USER_SUCCESSFUL:
            return { ...state,
                email: '',
                password: '',

                firstName: '',
                lastName: '',
                phone: '',
                companyName: '',
                companyPhone: '',
                companyEmail: '',
                logo: '',
                adminIDs: '',
                companyRegDocument: '',
                logoChanged: false,
                country: {},
                region: {},
                address: '',
                postalCode: '',
                loading:false,
                loginSuccess: true, loginFailed: false, error: false, signUpError: "" };
        case SIGN_UP_USER_FAILED:
            return INITIAL_STATE;

        case LOG_OUT:
            return { ...state, loading: true };
        case LOGOUT_SUCCESSFULLY:
            return {
                ...INITIAL_STATE,
                loading: false
            };
        case LOGOUT_FAILED:
            return { 
                ...state, 
                loading: false, 
                error: action.payload 
            };

        case INITIALIZE_STATE:
            return {
                ...INITIAL_STATE
            };

        default:
            return state;
    }
};

export default authenticationReducer;