import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Space, Card, Typography, Skeleton } from 'antd';
import { AccessIcon } from "hugeicons-react";
import firebase from 'firebase';
import { SEO } from "../../common";
import './Blog.css';

const { Title, Text } = Typography;

class BlogPostBase extends Component {
	state = {
		title: '',
		content: '',
		description: '',
		createdAt: null,
		loading: true,
	};

	componentDidMount() {
		const { id } = this.props;
		this.fetchBlog(id);
	}

	fetchBlog = (id) => {
		firebase.firestore().collection('blogs').doc(id).get()
			.then((doc) => {
				if (doc.exists) {
					this.setState({ 
						...doc.data(),
						loading: false 
					});
				} else {
					console.log("No such document!");
					this.setState({ loading: false });
				}
			})
			.catch((error) => {
				console.log("Error getting document:", error);
				this.setState({ loading: false });
			});
	};

	render() {
		const { title, content, description, createdAt, loading } = this.state;
		
		return (
			<div className="nafasi-blog__container">
				<SEO title={title} description={description}/>
				<div className="nafasi-blog">
					<Space direction="vertical" style={{ width: '100%' }} size="middle">
						<Card size="small" className="nafasi-blog__header-card">
							<div className="nafasi-blog__header">
								<AccessIcon size={24} className="nafasi-blog__icon" />
								<Title level={4} className="nafasi-blog__title">Blog Post</Title>
							</div>
						</Card>

						<Card size="small" className="nafasi-blog__content">
							{loading ? (
								<div className="nafasi-blog__skeleton">
									<Skeleton active paragraph={{ rows: 4 }} />
								</div>
							) : (
								<>
									<div className="nafasi-blog__post-header">
										<Title level={2} className="nafasi-blog__post-title">
											{title}
										</Title>
										{createdAt && (
											<Text type="secondary" className="nafasi-blog__post-date">
												{new Date(createdAt.seconds * 1000).toLocaleDateString('en-US', {
													weekday: 'long',
													year: 'numeric',
													month: 'long',
													day: 'numeric'
												})}
											</Text>
										)}
									</div>

									<div 
										className="nafasi-blog__post-content"
										dangerouslySetInnerHTML={{ __html: content }} 
									/>
								</>
							)}
						</Card>
					</Space>
				</div>
			</div>
		);
	}
}

// Wrapper functional component to use hooks
function BlogPost(props) {
	const navigate = useNavigate();
	const { id } = useParams();

	return <BlogPostBase {...props} navigate={navigate} id={id} />;
}

export default BlogPost;