import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import "../Military.css";

class MilitaryCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            military: this.props.military,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                military: nextProps.military,
            });
        }
    }

    formatDate(timestamp) {
        if (!timestamp) return 'N/A';
        if (timestamp.toDate) {
            return moment(timestamp.toDate()).format('MMM YYYY');
        }
        if (timestamp.seconds || timestamp._seconds) {
            const seconds = timestamp.seconds || timestamp._seconds;
            return moment.unix(seconds).format('MMM YYYY');
        }
        return moment(timestamp).format('MMM YYYY');
    }

    renderMilitary() {
        const { military } = this.state;

        return (
            <div className="military-grid">
                {military.map((service, index) => (
                    <div key={index} className="military-card">
                        <div className="military-header">
                            <div className="military-icon">
                                <FontAwesomeIcon icon="medal" />
                            </div>
                            <div className="military-info">
                                <h4>{service.branch || 'Military Branch'}</h4>
                                <span className="military-rank">
                                    {service.rank || 'Rank N/A'}
                                </span>
                            </div>
                            {service.currentlyServing && (
                                <div className="active-badge">
                                    Currently Serving
                                </div>
                            )}
                        </div>

                        <div className="service-details">
                            <div className="service-period">
                                <FontAwesomeIcon icon="calendar-alt" className="period-icon" />
                                <span style={{ fontFamily: "ramblaRegular"}}>
                                    {this.formatDate(service.serveFrom)} - {service.currentlyServing ? 'Present' : this.formatDate(service.serveTo)}
                                </span>
                            </div>

                            {service.location && (
                                <div className="service-location">
                                    <FontAwesomeIcon icon="map-marker-alt" className="location-icon" />
                                    <span style={{ fontFamily: "ramblaRegular"}}>{service.location.country}{service.location.region && `, ${service.location.region}`}</span>
                                </div>
                            )}
                        </div>

                        {service.description && (
                            <p className="service-description">
                                {service.description}
                            </p>
                        )}

                        {service.commendations && service.commendations.length > 0 && (
                            <div className="commendations">
                                <h5>Commendations</h5>
                                <div className="commendations-list">
                                    {service.commendations.map((commendation, idx) => (
                                        <span key={idx} className="commendation-badge">
                                            {commendation}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    }

    render() {
        return (
            <div className="military-timeline">
                {this.renderMilitary()}
            </div>
        );
    }
}

export default MilitaryCard;