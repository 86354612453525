import React from 'react';
import { Input, Select } from 'antd';
import { connect } from 'react-redux';
import { searchFieldChanged, fetchJobTitles } from '../../../actions/SearchActions';

class JobTitleFilter extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			jobTitles: this.props.jobTitles
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps !== this.props) {
			this.setState({
				jobTitles: nextProps.jobTitles
			});
		}
	}

	handleSearch = (value) => {
		this.props.searchFieldChanged({ prop: "jobTitlePrimeSearchQuery", value });
		this.props.fetchJobTitles({ searchQuery: value });
	};

	handleChange = (value) => {
		this.props.searchFieldChanged({ prop: "positionPrime", value });
	};

	render() {
		const { jobTitles } = this.state;
		const { positionPrime } = this.props;

		return (
			<div className="variable-section">
				<p style={{color: "#000", fontFamily: "ramblaBold", marginBottom: 3, textAlign: "left"}}>Positional
					Experience <span style={{color: "gray", fontFamily: "ramblaRegular"}}> (ex. Head of Marketing)</span>
					</p>
					<Select
					mode="multiple"
					placeholder="Search Job Title"
					value={positionPrime}
					onChange={this.handleChange}
					onSearch={this.handleSearch}
					allowClear
					showSearch
					style={{width: "100%", borderColor: '#000'}}
					optionFilterProp="label"
					filterOption={false}
				>
					{jobTitles.map((option) => (
						<Select.Option key={option.objectID} value={option.objectID} label={option.title}>
							{option.title}
						</Select.Option>
					))}
				</Select>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { positionPrime, jobTitles, jobTitlePrimeSearchQuery } = state.search;
	return { positionPrime, jobTitles, jobTitlePrimeSearchQuery };
};

const mapDispatchToProps = {
	searchFieldChanged, fetchJobTitles,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobTitleFilter);