import React, { Component } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { searchFieldChanged } from '../../../actions/SearchActions';

const { Option } = Select;

class MaritalStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maritalStatus: this.props.maritalStatus
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                maritalStatus: nextProps.maritalStatus
            });
        }
    }

    handleChange(value) {
        if (value === undefined || value === "") {
            this.props.searchFieldChanged({ prop: "maritalStatus", value: null });
        } else {
            this.props.searchFieldChanged({ prop: "maritalStatus", value });
        }
    };


    render() {
        const { maritalStatus } = this.state;

        return (
            <div className="variable-section">
                <p style={{color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left"}}>Marital
                    Status</p>
                <Select value={maritalStatus} allowClear onChange={this.handleChange} placeholder="Marital Status"
                        style={{width: "100%", borderColor: '#000'}}>
                    <Option value={"Single"}>Single</Option>
                    <Option value={"Married"}>Married</Option>
                    <Option value={"Divorced"}>Divorced</Option>
                    <Option value={"Widowed"}>Widowed</Option>
                </Select>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { maritalStatus } = state.search;

    return {
        maritalStatus
    };
};

const mapDispatchToProps = {
    searchFieldChanged, // Assuming you have an action creator to update the status
};

export default connect(mapStateToProps, mapDispatchToProps)(MaritalStatus);
