import React from "react";
import { connect } from "react-redux";
import { NotificationOutlined, BellOutlined, ClockCircleOutlined } from '@ant-design/icons';
import _ from "lodash";
import { Button, Empty, Spin, Badge } from 'antd';

//action imports
import { fetchNotifications } from "../../actions/NotificationsActions";

//component imports
import ManageNotifications from './subComponents/ManageNotifications';
import ViewNotifications from './subComponents/ViewNotifications';

import './Notifications.css';

class Notifications extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notificationFetchLimit: this.props.notificationFetchLimit,
            profile: this.props.profile,
            isLoading: false,
            activeTab: 'all' // 'all' | 'unread' | 'read'
        };
    }

    componentDidMount() {
        const { notificationFetchLimit, profile } = this.state;

        if(!(_.isEmpty(profile))) {
            this.setState({ isLoading: true });
            this.props.fetchNotifications({ notificationFetchLimit })
                .finally(() => this.setState({ isLoading: false }));
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                notificationFetchLimit: nextProps.notificationFetchLimit,
                profile: nextProps.profile
            });
        }
    }

    handleLoadMore = () => {
        const { notificationFetchLimit, profile } = this.state;
        const addedLimit = notificationFetchLimit + 10;
        
        if(!(_.isEmpty(profile))) {
            this.setState({ isLoading: true });
            this.props.fetchNotifications({ notificationFetchLimit: addedLimit })
                .finally(() => this.setState({ isLoading: false }));
        }
    }

    renderEmptyState() {
        return (
            <div className="nafasi-notifications__empty">
                <Empty
                    image={<BellOutlined style={{ fontSize: 48, color: '#86868b' }} />}
                    description={
                        <span className="nafasi-notifications__empty-text">
                            No notifications yet
                        </span>
                    }
                />
            </div>
        );
    }

    render() {
        const { isLoading, activeTab } = this.state;

        return(
            <div className="nafasi-notifications">
                <div className="nafasi-notifications__header">
                    <div className="nafasi-notifications__header-content">
                        <div className="nafasi-notifications__icon-wrapper">
                            <NotificationOutlined className="nafasi-notifications__icon" />
                        </div>
                        <h1 className="nafasi-notifications__title">Notifications</h1>
                    </div>
                </div>

                <div className="nafasi-notifications__content">
                    <div className="nafasi-notifications__tabs">
                        <button 
                            className={`nafasi-notifications__tab ${activeTab === 'all' ? 'nafasi-notifications__tab--active' : ''}`}
                            onClick={() => this.setState({ activeTab: 'all' })}>
                            All
                        </button>
                        <button 
                            className={`nafasi-notifications__tab ${activeTab === 'unread' ? 'nafasi-notifications__tab--active' : ''}`}
                            onClick={() => this.setState({ activeTab: 'unread' })}>
                            <Badge dot offset={[-8, 0]}>Unread</Badge>
                        </button>
                        <button 
                            className={`nafasi-notifications__tab ${activeTab === 'read' ? 'nafasi-notifications__tab--active' : ''}`}
                            onClick={() => this.setState({ activeTab: 'read' })}>
                            Read
                        </button>
                    </div>

                    <div className="nafasi-notifications__manage">
                        <ManageNotifications />
                    </div>

                    <div className="nafasi-notifications__list">
                        <ViewNotifications />
                        
                        {isLoading ? (
                            <div className="nafasi-notifications__loading">
                                <Spin />
                            </div>
                        ) : (
                            <div className="nafasi-notifications__load-more">
                                <Button
                                    type="default"
                                    icon={<ClockCircleOutlined />}
                                    onClick={this.handleLoadMore}
                                    className="nafasi-notifications__load-more-btn"
                                >
                                    Load More
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { notificationFetchLimit } = state.notifications;
    const { profile } = state.profile;
    return { notificationFetchLimit, profile };
}

export default connect(mapStateToProps, { fetchNotifications })(Notifications);