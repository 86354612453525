import React from 'react';
import { connect } from 'react-redux';
import arraySort from 'array-sort';
import _ from 'lodash';
import { FaBullhorn, FaChevronRight, FaTimes } from 'react-icons/fa';
import moment from 'moment';
import './Announcements.css';

class Announcements extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            notifications: this.props.notifications,
            accountInfo: this.props.accountInfo,
            selectedAnnouncement: null,
            showPopover: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                notifications: nextProps.notifications,
                accountInfo: nextProps.accountInfo,
            });
        }
    }

    handleAnnouncementClick = (notification) => {
        this.setState({
            selectedAnnouncement: notification,
            showPopover: true
        });
    }

    closePopover = () => {
        this.setState({
            showPopover: false,
            selectedAnnouncement: null
        });
    }

    renderPopover() {
        const { selectedAnnouncement, showPopover } = this.state;
        if (!showPopover || !selectedAnnouncement) return null;

        console.log("selectedAnnouncement", selectedAnnouncement);
        return (
            <div className="announcement-popover-overlay" onClick={this.closePopover}>
                <div className="announcement-popover" onClick={e => e.stopPropagation()}>
                    <div className="announcement-popover-header">
                        <div className="announcement-popover-title">
                            <FaBullhorn size={20} className="popover-icon" />
                            <h3>{selectedAnnouncement.title}</h3>
                        </div>
                        <button className="close-button" onClick={this.closePopover}>
                            <FaTimes size={16} />
                        </button>
                    </div>
                    <div className="announcement-popover-content">
                        <p className="announcement-message">{selectedAnnouncement.description}</p>
                        <div className="announcement-meta">
                            <span className="announcement-time">
                                {selectedAnnouncement.date ? 
                                    moment.unix(selectedAnnouncement.date.seconds).format('MMMM D, YYYY [at] h:mm A') :
                                    'Date unknown'
                                }
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderAnnouncements() {
        const { notifications } = this.state;

        if (_.isEmpty(notifications)) {
            return (
                <div className="announcements-empty">
                    <FaBullhorn size={24} className="bullhorn-icon" />
                    <p>No announcements at the moment</p>
                </div>
            );
        }

        const notificationsArray = _.map(notifications, notification => notification);
        const sortedNotifications = arraySort(notificationsArray, "notificationTime", { reverse: true });
        const recentAnnouncements = sortedNotifications.slice(0, 6);

        return (
            <div className="announcements-list">
                {recentAnnouncements.map(notification => (
                    <div 
                        key={notification.notificationID} 
                        className="announcement-item"
                        onClick={() => this.handleAnnouncementClick(notification)}
                    >
                        <div className="announcement-item-icon">
                            <FaBullhorn size={16} />
                        </div>
                        <div className="announcement-item-content">
                            <div className="announcement-item-primary">
                                {notification.title}
                                {notification.date && moment().diff(moment.unix(notification.date.seconds), 'hours') < 24 && (
                                    <span className="new-badge">New</span>
                                )}
                            </div>
                            <div className="announcement-item-secondary">
                                <span>{notification.message}</span>
                                <span className="dot">•</span>
                                <span>{notification.date ? 
                                    moment.unix(notification.date.seconds).fromNow() : 
                                    'Time unknown'
                                }</span>
                            </div>
                        </div>
                        <FaChevronRight className="chevron-icon" size={12} />
                    </div>
                ))}
            </div>
        );
    }

    render() {
        const { notifications } = this.state;
        const notificationCount = _.size(notifications);

        return (
            <>
                <div className="announcements-container">
                    <div className="announcements-header">
                        <h2>What's happening</h2>
                        {notificationCount > 0 && (
                            <span className="notification-count">{notificationCount}</span>
                        )}
                    </div>
                    {this.renderAnnouncements()}
                </div>
                {this.renderPopover()}
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    notifications: state.notifications.notifications,
    accountInfo: state.settings.accountInfo,
});

export default connect(mapStateToProps)(Announcements);