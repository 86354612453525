import React from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import { Space, Card } from 'antd';
import { AddressBookIcon } from "hugeicons-react";
import './Assets.css';

//components import
import AddAssets from "./AddAssets";

class Assets extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.profile !== this.props.profile ) {
            this.setState({ profile: nextProps.profile })
        }
    }

    checkUser() {
        const { profile } = this.state;

        if(!(_.isEmpty(profile))) {
            if (profile.role === "superAdmin") {
                return (
                    <div className="nafasi-assets">
                        <Space direction="vertical" style={{ width: '100%' }} size="middle">
                            <Card size="small" className="nafasi-assets__header-card">
                                <div className="nafasi-assets__header">
                                    <AddressBookIcon size={24} className="nafasi-assets__icon" />
                                    <h3 className="nafasi-assets__title">Company Assets</h3>
                                </div>
                            </Card>
                            
                            <Card size="small" className="nafasi-assets__content">
                                <div className="nafasi-assets__form">
                                    <AddAssets />
                                </div>
                            </Card>
                        </Space>
                    </div>
                );
            }
        } else {
            return (
                <div className="nafasi-assets__unauthorized">
                    <h2>Sign in to view</h2>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="nafasi-assets__container">
                {this.checkUser()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.profile;
    return { profile }
};

export default connect(mapStateToProps, {})(Assets);
