import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
    SearchOutlined,
    BarChartOutlined,
    UserOutlined,
    ScheduleOutlined,
    EyeOutlined,
    CommentOutlined,
    StarOutlined,
    CheckCircleOutlined,
    TeamOutlined,
    UserSwitchOutlined
} from '@ant-design/icons';

// File imports
import './Benefits.css';
import interview from "../../../assets/images/Recruiter.PNG";
import candidate from "../../../assets/images/JobSeeker.PNG";

const TabContent = ({ icon: Icon, text }) => (
    <motion.div 
        className="benefit-item"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.4, ease: "easeOut" }}
        whileHover={{ 
            scale: 1.02,
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.1)"
        }}
        whileTap={{ scale: 0.98 }}
    >
        <Icon style={{ 
            fontSize: '24px', 
            color: '#0071e3',
            opacity: 0.9 
        }} />
        <span className="benefit-text" style={{ fontFamily: 'ramblaBold', fontSize: '1rem', marginLeft: "0.3rem" }}>{text}</span>
    </motion.div>
);

const IllustrationWrapper = ({ isActive, children }) => (
    <motion.div 
        className="illustration-wrapper"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ 
            opacity: isActive ? 1 : 0,
            scale: isActive ? 1 : 0.8,
            y: [0, -15, 0]
        }}
        transition={{ 
            opacity: { duration: 0.5 },
            scale: { duration: 0.5 },
            y: { 
                duration: 4,
                repeat: Infinity,
                ease: "easeInOut"
            }
        }}
    >
        {children}
    </motion.div>
);

const BenefitsPage = () => {
    const [activeTab, setActiveTab] = useState('recruiter');

    const recruiterBenefits = [
        { text: "Advanced Search", icon: SearchOutlined },
        { text: "Insightful Analytics", icon: BarChartOutlined },
        { text: "Candidate Profiles", icon: UserOutlined },
        { text: "Interview Scheduling", icon: ScheduleOutlined },
    ];

    const applicantBenefits = [
        { text: "Visibility Platform", icon: EyeOutlined },
        { text: "Resume Feedback", icon: CommentOutlined },
        { text: "Assessment Ratings", icon: StarOutlined },
        { text: "Effortless Process", icon: CheckCircleOutlined }
    ];

    return (
        <div className="benefit-container">
            <motion.h1 
                className="benefit-section-title"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut" }}
            >
                Unlock Your Potential with Premium Features
            </motion.h1>

            <div className="benefit-tabs-container">
                <div className="benefit-tab-buttons">
                    <motion.button
                        className={`benefit-tab-button ${activeTab === 'recruiter' ? 'active' : ''}`}
                        onClick={() => setActiveTab('recruiter')}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                    >
                        <TeamOutlined />
                        <span>Recruiter</span>
                    </motion.button>

                    <motion.button
                        className={`benefit-tab-button ${activeTab === 'applicant' ? 'active' : ''}`}
                        onClick={() => setActiveTab('applicant')}
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                    >
                        <UserSwitchOutlined />
                        <span>Job Applicant</span>
                    </motion.button>
                </div>

                <div className="benefit-tab-content">
                    <div className="benefit-content-wrapper">
                        <div className="benefit-list">
                            {(activeTab === 'recruiter' ? recruiterBenefits : applicantBenefits)
                                .map((benefit, index) => (
                                    <TabContent 
                                        key={index}
                                        icon={benefit.icon}
                                        text={benefit.text}
                                    />
                                ))}
                        </div>

                        <IllustrationWrapper isActive={true}>
                            <img 
                                src={activeTab === 'recruiter' ? interview : candidate} 
                                alt={activeTab === 'recruiter' ? "Recruiter" : "Job Applicant"}
                                className="benefit-image"
                            />
                        </IllustrationWrapper>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BenefitsPage;
