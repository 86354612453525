import React from 'react';
import { Input, Select } from 'antd';
import { connect } from 'react-redux';
import { searchFieldChanged, fetchJobTitles } from '../../../actions/SearchActions';

const { Option } = Select;

class JobTitleFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            jobTitlesSearchQuery: this.props.jobTitlesSearchQuery,
            jobTitles: this.props.jobTitles
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                jobTitlesSearchQuery: nextProps.jobTitlesSearchQuery,
                jobTitles: nextProps.jobTitles
            });
        }
    }

    handleSearch = (value) => {
        this.props.searchFieldChanged({ prop: "jobTitlesSearchQuery", value });
        this.props.fetchJobTitles({ searchQuery: value });
    };

    handleSelect = (value) => {
        this.props.searchFieldChanged({ prop: "jobTitlesSearchQuery", value });
        this.props.searchFieldChanged({ prop: "jobTitle", value });
    };

    handleClear = () => {
        this.props.searchFieldChanged({ prop: "jobTitlesSearchQuery", value: "" });
        this.props.searchFieldChanged({ prop: "jobTitle", value: null });
    }

    render() {
        const { jobTitlesSearchQuery, jobTitles } = this.state;

        return (
            <div className="variable-section">
                <p style={{color: "#000", fontFamily: "ramblaBold", marginBottom: 3, textAlign: "left"}}>Desired Job Title</p>
                <Select
                    showSearch
                    value={jobTitlesSearchQuery}
                    placeholder="Search Job Title"
                    onSearch={this.handleSearch}
                    onChange={this.handleSelect}
                    onClear={this.handleClear}
                    allowClear
                    style={{width: "100%", borderColor: '#000'}}
                    optionFilterProp="label"
                    filterOption={false}
                >
                    {jobTitles.map((option) => (
                        <Option key={option.objectID} value={option.objectID} label={option.title}>
                            {option.title}
                        </Option>
                    ))}
                </Select>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { jobTitlesSearchQuery, jobTitles } = state.search;
    return {
        jobTitlesSearchQuery, jobTitles
    };
};

const mapDispatchToProps = {
    searchFieldChanged, fetchJobTitles,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobTitleFilter);
