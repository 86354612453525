import React from "react";
import { connect } from "react-redux";
import { Select, Form } from 'antd';
import countryRegionData from "country-region-data/dist/data-umd";
import './CommonSelect.css';

//action imports
import { credentialsChanged } from "../../actions/AuthenticationActions";
import { postsFieldChanged } from "../../actions/PostsActions";

const { Option } = Select;

class CountryRegionPicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            country: "",
            region: "",
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState({
                country: nextProps.country,
                region: nextProps.region,
            });
        }
    }

    handleCountryChange = (value) => {
        const selectedCountry = JSON.parse(value);
        this.props.credentialsChanged({prop: 'country', value: selectedCountry });
        this.props.credentialsChanged({prop: 'region', value: selectedCountry.regions && selectedCountry.regions.length > 0 ? selectedCountry.regions[0] : "" });
        this.props.postsFieldChanged({prop: 'country', value: selectedCountry });
        this.props.postsFieldChanged({prop: 'region', value: selectedCountry.regions && selectedCountry.regions.length > 0 ? selectedCountry.regions[0] : "" });
    };

    handleRegionChange = (value) => {
        const selectedRegion = JSON.parse(value);
        this.props.credentialsChanged({prop: 'region', value: selectedRegion});
        this.props.postsFieldChanged({prop: 'region', value: selectedRegion});
    };

    render() {
        const { country, region } = this.state;

        return (
            <div className="form-row">
                <Form.Item label="Country" className="compact-form-item" style={{ flex: 1 }}>
                    <Select
                        value={country ? JSON.stringify(country) : undefined}
                        onChange={this.handleCountryChange}
                        placeholder="Select country"
                        showSearch
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {countryRegionData.map((country) => (
                            <Option key={country.countryName} value={JSON.stringify(country)}>
                                {country.countryName} ({country.countryCode})
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Region" className="compact-form-item" style={{ flex: 1 }}>
                    <Select
                        value={region ? JSON.stringify(region) : undefined}
                        onChange={this.handleRegionChange}
                        placeholder="Select region"
                        showSearch
                        disabled={!country || !country.regions}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {country && country.regions && country.regions.map((region) => (
                            <Option key={region.name} value={JSON.stringify(region)}>
                                {region.name} ({region.shortCode})
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    country: state.authentication.country,
    region: state.authentication.region
});

export default connect(mapStateToProps, { credentialsChanged, postsFieldChanged })(CountryRegionPicker);