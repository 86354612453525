import React from 'react';
import { Select } from 'antd';
import { connect } from "react-redux";
import arraySort from "array-sort";
import './CommonSelect.css';

const { Option } = Select;

class SelectDepartment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            departments: this.props.departments
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.departments !== this.props.departments) {
            this.setState({ departments: nextProps.departments });
        }
    }

    render() {
        const { departments } = this.state;
        const sortedDepartments = arraySort(departments, "title", { reverse: false });

        return (
            <div className="inputTypeSelect">
                <Select
                    showSearch
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value="">All</Option>
                    {sortedDepartments.map(department => (
                        <Option key={department.departmentID} value={department.departmentID}>
                            {department.title}
                        </Option>
                    ))}
                </Select>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    departments: state.settings.departments
});

export default connect(mapStateToProps)(SelectDepartment);
