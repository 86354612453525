import React from "react";
import { connect } from "react-redux";
import { Modal, Typography, Popconfirm, message, Switch, Badge } from "antd";
import { EyeOutlined } from '@ant-design/icons';
import { updateVerification } from "../../../../actions/SettingsActions";
import './DescriptionModal.css';

class DescriptionModal extends React.Component {
    state = {
        isModalVisible: false,
        verifyVisible: false,
        checked: this.props.record?.verified || false
    };

    handleToggleModal = () => {
        this.setState(prevState => ({
            isModalVisible: !prevState.isModalVisible
        }));
    };

    handleToggle = () => {
        this.setState({ verifyVisible: true });
    };

    handleVerifyCancel = () => {
        this.setState({ verifyVisible: false });
    };

    handleConfirm = (item) => {
        this.setState(prevState => ({
            verifyVisible: false,
            checked: !prevState.checked
        }), () => {
            this.props.updateVerification({ recruiter: item, verified: this.state.checked })
                .then(() => {
                    message.success({
                        content: `Account has been ${this.state.checked ? 'verified' : 'unverified'}.`,
                        className: 'nafasi-recruiter-modal__message'
                    });
                })
                .catch((error) => {
                    console.error('Failed to update verification:', error);
                    message.error({
                        content: 'An error occurred while updating the verification status.',
                        className: 'nafasi-recruiter-modal__message'
                    });
                });
        });
    };

    renderStatusBadge = (status, text) => (
        <Badge 
            status={status ? 'success' : 'warning'} 
            text={text} 
            className="nafasi-recruiter-modal__badge"
        />
    );

    render() {
        const { record } = this.props;
        const { isModalVisible, verifyVisible, checked } = this.state;

        return (
            <div className="nafasi-recruiter-modal">
                <button className="nafasi-recruiter-modal__trigger" onClick={this.handleToggleModal}>
                    <EyeOutlined />
                </button>

                <Modal
                    title={
                        <div className="nafasi-recruiter-modal__header">
                            <div className="nafasi-recruiter-modal__title">
                                {record.companyName}
                                <span className="nafasi-recruiter-modal__location">
                                    {record.companyLocation.region}, {record.companyLocation.country}
                                </span>
                            </div>
                            <div className="nafasi-recruiter-modal__status-row">
                                {this.renderStatusBadge(record.verified, record.verified ? 'Verified' : 'Not Verified')}
                                {this.renderStatusBadge(record.active, record.active ? 'Active' : 'Inactive')}
                                {this.renderStatusBadge(record.paid, record.paid ? 'Paid' : 'Unpaid')}
                                {this.renderStatusBadge(!record.disabled, record.disabled ? 'Disabled' : 'Enabled')}
                            </div>
                        </div>
                    }
                    open={isModalVisible}
                    onCancel={this.handleToggleModal}
                    width={600}
                    className="nafasi-recruiter-modal__container"
                    footer={
                        <div className="nafasi-recruiter-modal__footer">
                            <p style={{ fontFamily: 'ramblaRegular', fontSize: '0.9rem' }}>Verify Account</p>
                            <Popconfirm
                                title={`Are you sure you want to ${checked ? 'unverify' : 'verify'} this account?`}
                                visible={verifyVisible}
                                onConfirm={() => this.handleConfirm(record)}
                                onCancel={this.handleVerifyCancel}
                                okText="Yes"
                                cancelText="No"
                                placement="topRight"
                            >
                                <Switch
                                    checked={checked}
                                    onChange={this.handleToggle}
                                    className="nafasi-recruiter-modal__switch"
                                />
                            </Popconfirm>
                        </div>
                    }
                >
                    <div className="nafasi-recruiter-modal__content">
                        <div className="nafasi-recruiter-modal__section">
                            <h3 className="nafasi-recruiter-modal__section-title">Company Details</h3>
                            <div className="nafasi-recruiter-modal__grid">
                                <div className="nafasi-recruiter-modal__info">
                                    <span className="nafasi-recruiter-modal__label">Address</span>
                                    <span className="nafasi-recruiter-modal__value">
                                        {record.companyLocation.streetAddress}
                                    </span>
                                </div>
                                <div className="nafasi-recruiter-modal__info">
                                    <span className="nafasi-recruiter-modal__label">Postal Code</span>
                                    <span className="nafasi-recruiter-modal__value">
                                        {record.companyLocation.postalCode}
                                    </span>
                                </div>
                                <div className="nafasi-recruiter-modal__info">
                                    <span className="nafasi-recruiter-modal__label">Email</span>
                                    <span className="nafasi-recruiter-modal__value">{record.companyEmail}</span>
                                </div>
                                <div className="nafasi-recruiter-modal__info">
                                    <span className="nafasi-recruiter-modal__label">Phone</span>
                                    <span className="nafasi-recruiter-modal__value">{record.companyPhone}</span>
                                </div>
                            </div>
                        </div>

                        <div className="nafasi-recruiter-modal__section">
                            <h3 className="nafasi-recruiter-modal__section-title">Admin Details</h3>
                            <div className="nafasi-recruiter-modal__grid">
                                <div className="nafasi-recruiter-modal__info">
                                    <span className="nafasi-recruiter-modal__label">Name</span>
                                    <span className="nafasi-recruiter-modal__value">
                                        {record.firstName} {record.lastName}
                                    </span>
                                </div>
                                <div className="nafasi-recruiter-modal__info">
                                    <span className="nafasi-recruiter-modal__label">Email</span>
                                    <span className="nafasi-recruiter-modal__value">{record.email}</span>
                                </div>
                                <div className="nafasi-recruiter-modal__info">
                                    <span className="nafasi-recruiter-modal__label">Phone</span>
                                    <span className="nafasi-recruiter-modal__value">{record.phone}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default connect(null, { updateVerification })(DescriptionModal);
