import React from 'react';
import { connect } from 'react-redux';
import { message, Upload, Popconfirm } from 'antd';
import _ from "lodash";
import { BarLoader } from 'react-spinners';
import CountryRegionPicker from "../../common/CountryRegionPicker";
import { credentialsChanged, updateProfile } from '../../../actions/AuthenticationActions';
import './EditProfile.css';
import { InboxOutlined, FilePdfOutlined, DeleteOutlined } from '@ant-design/icons';
import firebase from '../../../firebase/config';
import { useNavigate } from 'react-router-dom';

class EditProfile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            phone: this.props.phone,
            companyName: this.props.companyName,
            companyPhone: this.props.companyPhone,
            companyEmail: this.props.companyEmail,
            logo: this.props.logo,
            logoChanged: this.props.logoChanged,
            country: this.props.country,
            region: this.props.region,
            address: this.props.address,
            postalCode: this.props.postalCode,

            signUpError: this.props.signUpError,
            loading: this.props.loading,
            isPasswordVisible: false,

            companyRegDocument: this.props.companyRegDocument,
            adminIDs: this.props.adminIDs
        };

        this.onUpdatePress = this.onUpdatePress.bind(this);
        this.renderSignUpError = this.renderSignUpError.bind(this);
    };

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                firstName: nextProps.firstName,
                lastName: nextProps.lastName,
                phone: nextProps.phone,
                companyName: nextProps.companyName,
                companyPhone: nextProps.companyPhone,
                companyEmail: nextProps.companyEmail,
                logo: nextProps.logo,
                logoChanged: nextProps.logoChanged,
                country: nextProps.country,
                region: nextProps.region,
                address: nextProps.address,
                postalCode: nextProps.postalCode,
                signUpError: nextProps.signUpError,
                loading: nextProps.loading,
                companyRegDocument: nextProps.companyRegDocument,
                adminIDs: nextProps.adminIDs
            });
        }


        if (nextProps.loading !== this.props.loading) {
            this.setState({  });
        }
    };

    onUpdatePress() {
        const {
            firstName, lastName, phone, companyName, companyPhone, companyEmail,
            logo, country, region, address, postalCode, companyRegDocument, adminIDs
        } = this.state;

        // Validate required fields
        if (!firstName || !lastName || !phone || !companyName || !companyPhone || !companyEmail) {
            message.error('Please fill in all required fields');
            return;
        }

        // Validate location fields
        if (!country || !region || !address) {
            message.error('Please fill in all location fields');
            return;
        }

        // Format company location data
            const companyLocation = {
                country: country.countryName,
                countryCode: country.countryShortCode,
                region: region.name,
                regionCode: region.shortCode,
                streetAddress: address,
            postalCode: postalCode || ''
        };

        // Prepare the profile data
        const profileData = {
            firstName,
            lastName,
            phone,
            companyName,
            companyPhone,
            companyEmail,
            companyLocation,
            // Handle logo field
            ...(logo ? {
                logo: logo.startsWith('data:image') ? logo : logo.url || logo,
                logoChanged: this.state.logoChanged
            } : {}),
            // Handle documents
            ...(companyRegDocument ? { 
                companyRegDocument: typeof companyRegDocument === 'string' ? 
                    companyRegDocument : companyRegDocument.url 
            } : {}),
            ...(adminIDs ? { 
                adminIDs: typeof adminIDs === 'string' ? 
                    adminIDs : adminIDs.url 
            } : {}),
            navigate: this.props.navigate
        };

        this.props.updateProfile(profileData);
    }

    keyPressed = (event) => {
        if (event.key === "Enter") {
            this.onUpdatePress();
        }
    };

    renderSignUpError() {
        if (this.state.signUpError) {
            return (
                <div className="nafasi-edit-profile__error">
                    {this.state.signUpError}
                </div>
            );
        }
    }

    async handleOrganizationLogo(event) {
        const file = event.target.files[0];
        const maxSize = 2 * 1024 * 1024; // 2MB maximum size

        if (!file) {
            return;
        }

        if (file.size > maxSize) {
            message.error('File size exceeds the maximum limit of 2MB.');
            event.target.value = null;
            return;
        }

        try {
            // Upload to Firebase Storage
            const userID = firebase.auth().currentUser.uid;
            const storageRef = firebase.storage().ref(`companyLogos/${userID}`);
            
            await storageRef.put(file);
            const downloadURL = await storageRef.getDownloadURL();

            // Update Firestore
            await firebase.firestore()
                .collection('users')
                .doc('admins')
                .collection(userID)
                .doc('public')
                .collection('account')
                .doc('info')
                .update({
                    logo: downloadURL
                });

            // Update Redux state
            this.props.credentialsChanged({ 
                prop: 'logo', 
                value: downloadURL
            });
            this.props.credentialsChanged({ prop: 'logoChanged', value: true });
            
            message.success('Logo uploaded successfully');
            
        } catch (error) {
            console.error('Error processing logo:', error);
            message.error('Failed to process the image. Please try again.');
        }
    }

    renderUpdateButton() {
        const {loading} = this.state;

        if(loading){
            return (
                <div>
                    <BarLoader
                        size={20}
                    />
                </div>
            );
        }else{
            return (
                <div className={"allLeaveRequestsButtonBox"}>
                    <Button
                        text={"Update"}
                        onPress={() => this.onUpdatePress()}
                    />
                </div>
            );
        }
    }

    handleCompanyDocumentUpload = async (file) => {
        const maxSize = 5 * 1024 * 1024; // 5MB maximum size
        
        if (file.size > maxSize) {
            message.error('File size must be smaller than 5MB!');
            return false;
        }

        if (file.type !== 'application/pdf') {
            message.error('You can only upload PDF files!');
            return false;
        }

        try {
            const docId = 'eZnmtaZdvjecwk4itDXLKlAHZ3q1'; // Replace with actual user ID
            const storageRef = firebase.storage().ref(`companyRegDocument/${docId}`);
            
            await storageRef.put(file);
            const downloadURL = await storageRef.getDownloadURL();
            
            // Update Firestore
            await firebase.firestore()
                .collection('users')
                .doc('admins')
                .collection(docId)
                .doc('public')
                .collection('account')
                .doc('info')
                .update({
                    companyRegDocument: downloadURL
                });

            this.props.credentialsChanged({ 
                prop: 'companyRegDocument', 
                value: {
                    name: file.name,
                    url: downloadURL
                }
            });

            message.success('Document uploaded successfully');
            return false; // Prevent default upload behavior
        } catch (error) {
            message.error('Upload failed. Please try again.');
            console.error('Upload error:', error);
            return false;
        }
    };

    handleAdminIDsUpload = async (file) => {
        const maxSize = 5 * 1024 * 1024; // 5MB maximum size
        
        if (file.size > maxSize) {
            message.error('File size must be smaller than 5MB!');
            return false;
        }

        if (file.type !== 'application/pdf') {
            message.error('You can only upload PDF files!');
            return false;
        }

        try {
            const docId = 'eZnmtaZdvjecwk4itDXLKlAHZ3q1'; // Replace with actual user ID
            const storageRef = firebase.storage().ref(`companyadminIDs/${docId}`);
            
            await storageRef.put(file);
            const downloadURL = await storageRef.getDownloadURL();
            
            // Update Firestore
            await firebase.firestore()
                .collection('users')
                .doc('admins')
                .collection(docId)
                .doc('public')
                .collection('account')
                .doc('info')
                .update({
                    adminIDs: downloadURL
                });

            this.props.credentialsChanged({ 
                prop: 'adminIDs', 
                value: {
                    name: file.name,
                    url: downloadURL
                }
            });

            message.success('Document uploaded successfully');
            return false; // Prevent default upload behavior
        } catch (error) {
            message.error('Upload failed. Please try again.');
            console.error('Upload error:', error);
            return false;
        }
    };

    handleDocumentDelete = async (documentType) => {
        try {
            const docId = firebase.auth().currentUser.uid;
            const storageRef = firebase.storage().ref();
            const fileRef = storageRef.child(`${documentType === 'companyRegDocument' ? 'companyRegDocument' : 'companyadminIDs'}/${docId}`);
            
            // Delete from Storage
            await fileRef.delete();
            
            // Update Firestore
            const userDocRef = firebase.firestore()
                .collection('users')
                .doc('admins')
                .collection(docId)
                .doc('public')
                .collection('account')
                .doc('info');

            await userDocRef.update({
                [documentType]: firebase.firestore.FieldValue.delete()
            });

            // Update local state via Redux
            this.props.credentialsChanged({ 
                prop: documentType, 
                value: null
            });

            message.success('Document deleted successfully');
        } catch (error) {
            console.error('Error deleting document:', error);
            message.error('Failed to delete document. Please try again.');
        }
    };

    handleLogoDelete = async () => {
        try {
            // Extract docId from logo URL if it exists
            let docId;
            let userID = firebase.auth().currentUser.uid;
            if (this.state.logo && this.state.logo.includes('companyLogos/')) {
                docId = this.state.logo.split('companyLogos/')[1].split('?')[0];
            } else {
                docId = firebase.auth().currentUser.uid;
            }

            const storageRef = firebase.storage().ref();
            const fileRef = storageRef.child(`companyLogos/${docId}`);
            
            // Delete from Storage
            await fileRef.delete();
            
            // Update Firestore
            const userDocRef = firebase.firestore()
                .collection('users')
                .doc('admins')
                .collection(userID)
                .doc('public')
                .collection('account')
                .doc('info');

            await userDocRef.update({
                logo: firebase.firestore.FieldValue.delete()
            });

            // Update Redux state
            this.props.credentialsChanged({ prop: 'logo', value: null });
            this.props.credentialsChanged({ prop: 'logoChanged', value: true });

            message.success('Logo deleted successfully');
        } catch (error) {
            console.error('Error deleting logo:', error);
            message.error('Failed to delete logo. Please try again.');
        }
    };

    renderDocumentPreview = (document, type) => {
        if (!document) return null;

        // Handle both string URLs and object formats
        const documentUrl = typeof document === 'string' ? document : document.url;
        const documentName = typeof document === 'string' ? 'View Document' : document.name;

        return (
            <div className="nafasi-edit-profile__document-preview">
                <FilePdfOutlined className="nafasi-edit-profile__document-icon" />
                <div className="nafasi-edit-profile__document-info">
                    <span className="nafasi-edit-profile__document-name">{documentName}</span>
                    <div className="nafasi-edit-profile__document-actions">
                        <a 
                            href={documentUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="nafasi-edit-profile__document-view"
                        >
                            View
                        </a>
                        <Popconfirm
                            title="Delete Document"
                            description="Are you sure you want to delete this document?"
                            onConfirm={() => this.handleDocumentDelete(type)}
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            okButtonProps={{ 
                                style: { 
                                    background: '#ff3b30',
                                    borderColor: '#ff3b30',
                                    margin: "0.5rem"
                                } 
                            }}
                        >
                            <button 
                                className="nafasi-edit-profile__document-remove"
                            >
                                <DeleteOutlined />
                            </button>
                        </Popconfirm>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className="nafasi-edit-profile">
                <div className="nafasi-edit-profile__container">
                    <h2 className="nafasi-edit-profile__title">Edit Profile</h2>
                    
                    <div className="nafasi-edit-profile__form">
                        <div className="nafasi-edit-profile__section">
                            <h3 className="nafasi-edit-profile__section-title">Admin Information</h3>
                            
                            <div className="nafasi-edit-profile__form-group">
                    <input
                                    type="text"
                                    className="nafasi-edit-profile__input"
                                    placeholder="Admin First Name"
                        onChange={e => this.props.credentialsChanged({ prop: 'firstName', value: e.target.value })}
                        value={this.state.firstName}
                        onKeyDown={this.keyPressed}
                    />
                </div>

                            <div className="nafasi-edit-profile__form-group">
                    <input
                                    type="text"
                                    className="nafasi-edit-profile__input"
                                    placeholder="Admin Last Name"
                        onChange={e => this.props.credentialsChanged({ prop: 'lastName', value: e.target.value })}
                        value={this.state.lastName}
                        onKeyDown={this.keyPressed}
                    />
                </div>

                            <div className="nafasi-edit-profile__form-group">
                    <input
                                    type="text"
                                    className="nafasi-edit-profile__input"
                                    placeholder="Admin Phone"
                                    onChange={e => this.props.credentialsChanged({prop: 'phone', value: e.target.value})}
                        value={this.state.phone}
                        onKeyDown={this.keyPressed}
                    />
                </div>
                        </div>

                        <div className="nafasi-edit-profile__section">
                            <h3 className="nafasi-edit-profile__section-title">Company Information</h3>
                            
                            <div className="nafasi-edit-profile__form-group">
                    <input
                                    type="text"
                                    className="nafasi-edit-profile__input"
                                    placeholder="Company Name"
                                    onChange={e => this.props.credentialsChanged({prop: 'companyName', value: e.target.value})}
                        value={this.state.companyName}
                        onKeyDown={this.keyPressed}
                    />
                </div>

                            <div className="nafasi-edit-profile__form-group">
                    <input
                                    type="text"
                                    className="nafasi-edit-profile__input"
                                    placeholder="Company Phone"
                                    onChange={e => this.props.credentialsChanged({prop: 'companyPhone', value: e.target.value})}
                        value={this.state.companyPhone}
                        onKeyDown={this.keyPressed}
                    />
                </div>

                            <div className="nafasi-edit-profile__form-group">
                    <input
                                    type="text"
                                    className="nafasi-edit-profile__input"
                                    placeholder="Company Email"
                                    onChange={e => this.props.credentialsChanged({prop: 'companyEmail', value: e.target.value})}
                        value={this.state.companyEmail}
                        onKeyDown={this.keyPressed}
                    />
                </div>
                </div>

                        <div className="nafasi-edit-profile__section">
                            <h3 className="nafasi-edit-profile__section-title">Location</h3>
                            
                            {this.state.country && (
                                <p className="nafasi-edit-profile__location-text">
                                    {this.state.country.countryName}, {this.state.region.name}
                                </p>
                            )}
                            
                            <CountryRegionPicker/>

                            <div className="nafasi-edit-profile__form-group">
                    <input
                                    type="text"
                                    className="nafasi-edit-profile__input"
                                    placeholder="Company Address"
                                    onChange={e => this.props.credentialsChanged({prop: 'address', value: e.target.value})}
                        value={this.state.address}
                        onKeyDown={this.keyPressed}
                    />
                </div>

                            <div className="nafasi-edit-profile__form-group">
                    <input
                                    type="text"
                                    className="nafasi-edit-profile__input"
                                    placeholder="Postal Code"
                                    onChange={e => this.props.credentialsChanged({prop: 'postalCode', value: e.target.value})}
                        value={this.state.postalCode}
                        onKeyDown={this.keyPressed}
                    />
                </div>
                        </div>

                        <div className="nafasi-edit-profile__section">
                            <h3 className="nafasi-edit-profile__section-title">Documents</h3>
                            
                            <div className="nafasi-edit-profile__upload-group">
                                <label className="nafasi-edit-profile__upload-label">Company Registration Document</label>
                                {this.state.companyRegDocument ? (
                                    this.renderDocumentPreview(this.state.companyRegDocument, 'companyRegDocument')
                                ) : (
                                    <Upload.Dragger
                                        name="companyRegDocument"
                                        accept=".pdf"
                                        showUploadList={false}
                                        beforeUpload={this.handleCompanyDocumentUpload}
                                        className="nafasi-edit-profile__upload-dragger"
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag company registration document to upload</p>
                                        <p className="ant-upload-hint">Support for PDF files only. Max size: 5MB</p>
                                    </Upload.Dragger>
                                )}
                </div>

                            <div className="nafasi-edit-profile__upload-group">
                                <label className="nafasi-edit-profile__upload-label">Admin ID's</label>
                                {this.state.adminIDs ? (
                                    this.renderDocumentPreview(this.state.adminIDs, 'adminIDs')
                                ) : (
                                    <Upload.Dragger
                                        name="adminIDs"
                                        accept=".pdf"
                                        showUploadList={false}
                                        beforeUpload={this.handleAdminIDsUpload}
                                        className="nafasi-edit-profile__upload-dragger"
                                    >
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag admin ID's to upload</p>
                                        <p className="ant-upload-hint">Support for PDF files only. Max size: 5MB</p>
                                    </Upload.Dragger>
                                )}
                </div>

                            <div className="nafasi-edit-profile__upload-group">
                                <label className="nafasi-edit-profile__upload-label">Organization Logo</label>
                                <div className="nafasi-edit-profile__upload-container">
                                    {this.state.logo ? (
                                        <div className="nafasi-edit-profile__logo-preview">
                        <img
                            src={this.state.logo}
                                                className="nafasi-edit-profile__preview"
                                                alt="Organization Logo"
                                            />
                                            <Popconfirm
                                                title="Delete Logo"
                                                description="Are you sure you want to delete the logo?"
                                                onConfirm={this.handleLogoDelete}
                                                okText="Yes"
                                                cancelText="No"
                                                placement="topRight"
                                                okButtonProps={{ 
                                                    style: { 
                                                        background: '#ff3b30',
                                                        borderColor: '#ff3b30',
                                                        margin: "0.5rem"
                                                    } 
                                                }}
                                            >
                                                <button className="nafasi-edit-profile__logo-remove">
                                                    <DeleteOutlined />
                                                </button>
                                            </Popconfirm>
                                        </div>
                                    ) : (
                                        <Upload.Dragger
                                            name="logo"
                                            accept="image/png,image/jpeg,image/jpg"
                                            showUploadList={false}
                                            beforeUpload={(file) => {
                                                this.handleOrganizationLogo({ target: { files: [file] } });
                                                return false;
                                            }}
                                            className="nafasi-edit-profile__upload-dragger"
                                        >
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Click or drag logo to upload</p>
                                            <p className="ant-upload-hint">Support for PNG, JPEG files only. Max size: 2MB</p>
                                        </Upload.Dragger>
                                    )}
                                </div>
                            </div>
                        </div>

                        

                        {this.state.signUpError && (
                            <div className="nafasi-edit-profile__error">
                                {this.state.signUpError}
                            </div>
                        )}

                        <div className="nafasi-edit-profile__actions">
                            {this.state.loading ? (
                                <div className="nafasi-edit-profile__loader">
                                    <BarLoader size={20} />
                                </div>
                            ) : (
                                <button
                                    className="nafasi-edit-profile__button"
                                    onClick={() => this.onUpdatePress()}
                                >
                                    Update Profile
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// Wrap the component with withRouter to get access to navigation
const EditProfileWithRouter = (props) => {
    const navigate = useNavigate();
    return <EditProfile {...props} navigate={navigate} />;
};

const mapStateToProps = (state) => {
    const {
        firstName, lastName, phone, companyName, companyPhone, companyEmail, companyRegDocument, adminIDs,
        logo, logoChanged, country, region, address, postalCode, signUpError, loading } = state.authentication;
    return {
        firstName,
        lastName,
        phone,
        companyName,
        companyPhone,
        companyEmail,
        logo,
        logoChanged,
        country,
        region,
        address,
        postalCode,

        signUpError,
        loading,
        companyRegDocument,
        adminIDs,

    }
};

export default connect(mapStateToProps, { credentialsChanged, updateProfile })(EditProfileWithRouter);