import React from 'react';
import { Modal, Button } from 'antd';
import { EyeIcon } from "hugeicons-react";
import './DescriptionModal.css';

class DescriptionModal extends React.Component {
    state = {
        visible: false,
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { record } = this.props;
        return (
            <div className="nafasi-description-modal">
                <Button 
                    type="text" 
                    onClick={this.showModal}
                    className="nafasi-description-modal__trigger"
                >
                    <EyeIcon size={16} className="nafasi-description-modal__icon" />
                    <span className="nafasi-description-modal__text">View Details</span>
                </Button>
                <Modal
                    title={
                        <div className="nafasi-description-modal__header">
                            <h3 className="nafasi-description-modal__title">Payment Details</h3>
                        </div>
                    }
                    open={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button 
                            key="close" 
                            onClick={this.handleCancel}
                            className="nafasi-description-modal__button"
                        >
                            Close
                        </Button>
                    ]}
                    className="nafasi-description-modal__modal"
                >
                    <div className="nafasi-description-modal__content">
                        <div className="nafasi-description-modal__section">
                            <h4 className="nafasi-description-modal__label">Package</h4>
                            <p className="nafasi-description-modal__value">{record.paymentPackage.title}</p>
                        </div>
                        <div className="nafasi-description-modal__section">
                            <h4 className="nafasi-description-modal__label">Description</h4>
                            <p className="nafasi-description-modal__value">{record.paymentPackage.description}</p>
                        </div>
                        <div className="nafasi-description-modal__section">
                            <h4 className="nafasi-description-modal__label">Amount</h4>
                            <p className="nafasi-description-modal__value">{record.paymentPackage.amount} KES</p>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default DescriptionModal;