import firebase from "firebase";
import "firebase/firestore";
import {
    FETCH_MY_PROFILE,
    FETCH_MY_PROFILE_FAILED,
    FETCH_MY_PROFILE_SUCCESSFUL,
    EDIT_PROFILE_EMPLOYEE_DETAILS_CHANGED,
    EDIT_PROFILE_EMPLOYEE_DETAILS,
    EDIT_PROFILE_EMPLOYEE_DETAILS_SUCCESSFUL,
    EDIT_PROFILE_EMPLOYEE_DETAILS_FAILED,
} from "./Types";

import {message} from "antd";


//------------------------------------------------------------------------ PROFILE ------------------------------------------------------------------------------

//changes on some information on the profile
export const profileFieldChanged = ({prop, value}) => {

    return {
        type: EDIT_PROFILE_EMPLOYEE_DETAILS_CHANGED,
        payload: {prop, value}
    }
};


export const fetchMyProfile = () => {
    return (dispatch) => {
        dispatch({ type: FETCH_MY_PROFILE });

        try {
            const user = firebase.auth().currentUser;
            
            if (user) {
                const userID = user.uid;
                
                // Set up real-time listener for profile data
                return firebase.firestore()
                    .collection("users")
                    .doc("admins")
                    .collection(userID)
                    .doc("public")
                    .collection("account")
                    .doc("info")
                    .onSnapshot(
                        (doc) => {
                            if (doc.exists) {
                                const profile = doc.data();
                                dispatch({
                                    type: FETCH_MY_PROFILE_SUCCESSFUL,
                                    payload: profile
                                });
                            } else {
                                console.log("Account doc doesn't exist");
                                dispatch({ 
                                    type: FETCH_MY_PROFILE_FAILED,
                                    payload: "Profile not found" 
                                });
                            }
                        },
                        (error) => {
                            console.error('Error fetching profile:', error);
                            dispatch({
                                type: FETCH_MY_PROFILE_FAILED,
                                payload: error.message
                            });
                        }
                    );
            }
        } catch (error) {
            console.error('Error setting up profile listener:', error);
            dispatch({
                type: FETCH_MY_PROFILE_FAILED,
                payload: error.message
            });
        }
    };
};

export const updateProfileDetails = ({ userID, bankName,accountName,accountNumber, address, phone,homePhone, workEmail, email, emergencyName, emergencyAddress,emergencyPhoneNumber, emergencyRelationship, linkedinUrl }) => {

    return(dispatch) => {

        dispatch({type: EDIT_PROFILE_EMPLOYEE_DETAILS});

        firebase.firestore().collection("users").doc("admins").collection(userID).doc("public").collection("account").doc("info")
            .update({ bankName, accountName, accountNumber, address, phone, homePhone, workEmail, email, emergencyName, emergencyAddress, emergencyPhoneNumber, emergencyRelationship, linkedinUrl })
            .then(() => {
                firebase.firestore().collection("adminBucket").doc(userID)
                .update({  email })
                .then(() => {
                    dispatch({type: EDIT_PROFILE_EMPLOYEE_DETAILS_SUCCESSFUL});
                    message.success("updated profile info");
                })
            }).catch((error) => {
                    console.log(error);
                    dispatch({ type: EDIT_PROFILE_EMPLOYEE_DETAILS_FAILED, payload: `Failed to create ${userID}'s account` });
        });
    }
};