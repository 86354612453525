import React from 'react';
import { connect } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { Modal, Button } from "antd";
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { fetchSelectedSeeker, fetchSeekerProfile, fetchSeekerPath } from "../../../actions/PostsActions";
import GeneratePDF from './GeneratePDF';
import './PDFCV.css';

class PDFCV extends React.Component {
	state = {
		visible: false,
		selectedCandidateProfile: {},
		selectedCandidate: {},
		skills: [],
		hobbies: [],
		employment: [],
		education: [],
		military: [],
		awards: [],
		licenses: [],
		links: [],
		languages: [],
		certificates: [],
		referees: [],
		isLoading: false
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps !== this.props) {
			this.setState({
				selectedCandidateProfile: nextProps.selectedCandidateProfile,
				selectedCandidate: nextProps.selectedCandidate,
				skills: nextProps.skills,
				hobbies: nextProps.hobbies,
				employment: nextProps.employment,
				education: nextProps.education,
				military: nextProps.military,
				awards: nextProps.awards,
				licenses: nextProps.licenses,
				links: nextProps.links,
				languages: nextProps.languages,
				certificates: nextProps.certificates,
				referees: nextProps.referees,
			});
		}
	}

	handlePDFPress = () => {
		const { userID } = this.props.candidate;
		const seekerID = userID;
		
		this.setState({ isLoading: true });
		
		// Fetch all necessary data
		Promise.all([
			this.props.fetchSelectedSeeker({ seekerID }),
			this.props.fetchSeekerProfile({ seekerID }),
			this.props.fetchSeekerPath({ seekerID, path: "interviews" }),
			this.props.fetchSeekerPath({ seekerID, path: "certificates" }),
			this.props.fetchSeekerPath({ seekerID, path: "education" }),
			this.props.fetchSeekerPath({ seekerID, path: "employment" }),
			this.props.fetchSeekerPath({ seekerID, path: "languages" }),
			this.props.fetchSeekerPath({ seekerID, path: "awards" }),
			this.props.fetchSeekerPath({ seekerID, path: "licenses" }),
			this.props.fetchSeekerPath({ seekerID, path: "hobbies" }),
			this.props.fetchSeekerPath({ seekerID, path: "military" }),
			this.props.fetchSeekerPath({ seekerID, path: "skills" }),
			this.props.fetchSeekerPath({ seekerID, path: "links" }),
			this.props.fetchSeekerPath({ seekerID, path: "referees" })
		]).then(() => {
			this.setState({ visible: true, isLoading: false });
		}).catch(error => {
			console.error('Error fetching data:', error);
			this.setState({ isLoading: false });
		});
	};

	handleCancel = () => {
		this.setState({ visible: false });
	};

	render() {
		const { 
			visible, isLoading,
			selectedCandidateProfile, selectedCandidate, skills, hobbies, employment, 
			education, military, awards, licenses, links, languages, certificates, referees 
		} = this.state;

		const pdfData = {
			selectedCandidateProfile,
			selectedCandidate,
			skills,
			hobbies,
			employment,
			education,
			military,
			awards,
			licenses,
			links,
			languages,
			certificates,
			referees
		};

		return (
			<div className="nafasi-cv">
				<button 
					className="nafasi-cv-button" 
					onClick={this.handlePDFPress}
					disabled={isLoading}
				>
					<div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
						<EyeOutlined className="nafasi-cv-button__icon" />
						<span style={{ fontFamily: 'ramblaRegular', fontSize: '0.9rem' }}>
							{isLoading ? 'Loading...' : 'View CV'}
						</span>
					</div>
				</button>

				<Modal
					title="View & Download CV"
					open={visible}
					onCancel={this.handleCancel}
					width={800}
					className="nafasi-cv-modal"
					footer={[
						<Button key="close" onClick={this.handleCancel}>
							Close
						</Button>,
						<PDFDownloadLink
							key="download"
							document={<GeneratePDF data={pdfData} />}
							fileName="cv.pdf"
							className="nafasi-cv-download"
						>
							{({ loading }) => (
								<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
									<DownloadOutlined className="nafasi-cv-download__icon" />
									{loading ? 'Preparing PDF...' : 'Download PDF'}
								</div>
							)}
						</PDFDownloadLink>
					]}
				>
					<div className="nafasi-cv-preview">
						<GeneratePDF data={pdfData} />
					</div>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = state => {
	const {
		selectedCandidateProfile, selectedCandidate, skills, hobbies, employment, education, military,
		awards, licenses, links, languages, certificates, referees
	} = state.posts;

	return {
		selectedCandidateProfile,
		selectedCandidate,
		skills,
		hobbies,
		employment,
		education,
		military,
		awards,
		licenses,
		links,
		languages,
		certificates,
		referees,
	};
};

export default connect(mapStateToProps, { 
	fetchSelectedSeeker, 
	fetchSeekerProfile, 
	fetchSeekerPath 
})(PDFCV);