import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import { FaSearch, FaChevronRight } from 'react-icons/fa';
import './RecentSearches.css';

class RecentSearches extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            profile: this.props.profile,
            recentSearches: this.props.recentSearches,
            recentSearchesLoading: this.props.recentSearchesLoading,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                profile: nextProps.profile,
                recentSearches: nextProps.recentSearches,
                recentSearchesLoading: nextProps.recentSearchesLoading,
            });
        }
    }

    renderRecentSearches() {
        const { recentSearches } = this.state;

        if (recentSearches.length === 0) {
            return(
                <div className="recent-searches-empty">
                    <FaSearch size={24} className="search-icon" />
                    <p>Your recent searches will appear here</p>
                </div>
            );
        }

        return (
            <div className="recent-searches-list">
                {recentSearches.map((item, index) => (
                    <div key={index} className="search-item">
                        <div className="search-item-icon">
                            <FaSearch size={16} />
                        </div>
                        <div className="search-item-content">
                            <div className="search-item-primary">
                                {item.country || 'All Countries'}
                                {item.skill && ` • ${item.skill}`}
                            </div>
                            <div className="search-item-secondary">
                                {[
                                    item.certificateID && `Cert: ${item.certificateID}`,
                                    item.industryID && `Industry: ${item.industryID}`
                                ].filter(Boolean).join(' • ')}
                            </div>
                        </div>
                        <FaChevronRight className="chevron-icon" size={12} />
                    </div>
                ))}
            </div>
        );
    }

    render() {
        return (
            <div className="recent-searches-container">
                <div className="recent-searches-header">
                    <h2>Recent Searches</h2>
                </div>
                {this.renderRecentSearches()}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    profile: state.profile.profile,
    recentSearches: state.home.recentSearches,
    recentSearchesLoading: state.home.recentSearchesLoading
});

export default connect(mapStateToProps)(RecentSearches);