import React from 'react';
import { Modal } from 'antd';
import './ProfileModal.css';

const ProfileModal = ({ visible, onConfirm }) => {
    return (
        <Modal 
            title={null}
            visible={visible}
            footer={null}
            centered
            className="nafasi-profile-modal"
            width={480}
            closable={false}
        >
            <div className="nafasi-profile-modal__content">
                <div className="nafasi-profile-modal__icon">
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <circle cx="24" cy="24" r="24" fill="#0071E3" fillOpacity="0.1"/>
                        <path d="M24 14V26M24 30V34" stroke="#0071E3" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </div>
                <h3 className="nafasi-profile-modal__title">Complete Your Profile</h3>
                <p className="nafasi-profile-modal__text">
                    Your profile information is incomplete. Please fill out all necessary details to continue.
                </p>
                <button 
                    className="nafasi-profile-modal__button"
                    onClick={onConfirm}
                >
                    Go to Profile Details
                </button>
            </div>
        </Modal>
    );
};

export default ProfileModal;
