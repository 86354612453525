import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { message, Tabs, Button, Modal, Table } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '../../common/Spinner';
import { library } from '@fortawesome/fontawesome-svg-core';
import _ from 'lodash';
import { 
    faUser, 
    faUserCircle,
    faMapMarkerAlt,
    faGraduationCap,
    faEnvelope,
    faPhone,
    faBirthdayCake,
    faVenusMars,
    faRing,
    faPlaneDeparture,
    faBriefcase,
    faMoneyBill,
    faIndustry,
    faClock,
    faChartBar,
    faCheckCircle,
    faUserPlus,
    faPrint,
    faEye,
    faChartLine,
    faAddressCard
} from '@fortawesome/free-solid-svg-icons';
import { fetchSeekerPath, fetchSeekerProfile, fetchSelectedSeeker } from '../../../actions/PostsActions';
import currencyCodes from 'currency-codes';

// Add icons to library
library.add(
    faUser,
    faUserCircle,
    faMapMarkerAlt,
    faGraduationCap,
    faEnvelope,
    faPhone,
    faBirthdayCake,
    faVenusMars,
    faRing,
    faPlaneDeparture,
    faBriefcase,
    faMoneyBill,
    faIndustry,
    faClock,
    faChartBar,
    faCheckCircle,
    faUserPlus,
    faPrint,
    faEye,
    faChartLine,
    faAddressCard
);

// Import all card components
import Employment from '../employment/Employment';
import Certificates from '../certificates/Certificates';
import Licenses from '../licences/Licenses';
import Awards from '../awards/Awards';
import Languages from '../languages/Languages';
import Military from '../military/Military';
import Links from '../links/Links';
import Skills from '../skills/Skills';
import Referees from '../referees/Referees';
import SeekerDocuments from '../documents/SeekerDocuments';
import Interviews from '../interviews/Interviews';
import InviteForInterview from '../subComponents/InviteForInterview';
import Education from '../education/Education';

import '../SeekerProfile.css';


const { TabPane } = Tabs;

const SeekerProfile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [age, setAge] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    
    const searchSeeker = useSelector(state => state.search.pushedSeeker);
    const postsSeeker = useSelector(state => state.posts.pushedSeeker);
    const pushedSeeker = searchSeeker || postsSeeker;
    
    const showModal = () => setIsModalVisible(true);
    const handleOk = () => setIsModalVisible(false);
    const handleCancel = () => setIsModalVisible(false);
    
    useEffect(() => {
        console.log('SeekerProfile mounted');
        console.log('Search Seeker:', searchSeeker);
        console.log('Posts Seeker:', postsSeeker);
        
        const timer = setTimeout(() => {
            setIsLoading(false);
            
            if (!pushedSeeker) {
                console.log('No pushedSeeker found in either state');
                message.error('No candidate data found');
                navigate('/search');
                return;
            }

            if (!(_.isEmpty(pushedSeeker))) {
                const seekerID = pushedSeeker.userID;
                dispatch(fetchSelectedSeeker({ seekerID }));
                dispatch(fetchSeekerProfile({ seekerID }));
                dispatch(fetchSeekerPath({ seekerID, path: "interviews" }));
                dispatch(fetchSeekerPath({ seekerID, path: "certificates" }));
                dispatch(fetchSeekerPath({ seekerID, path: "education" }));
                dispatch(fetchSeekerPath({ seekerID, path: "employment" }));
                dispatch(fetchSeekerPath({ seekerID, path: "languages" }));
                dispatch(fetchSeekerPath({ seekerID, path: "awards" }));
                dispatch(fetchSeekerPath({ seekerID, path: "licenses" }));
                dispatch(fetchSeekerPath({ seekerID, path: "hobbies" }));
                dispatch(fetchSeekerPath({ seekerID, path: "military" }));
                dispatch(fetchSeekerPath({ seekerID, path: "skills" }));
                dispatch(fetchSeekerPath({ seekerID, path: "links" }));
                dispatch(fetchSeekerPath({ seekerID, path: "referees" }));
            }

            if (pushedSeeker.birthdate) {
                const seconds = pushedSeeker.birthdate.seconds || pushedSeeker.birthdate._seconds;
                const birthday = moment.unix(seconds);
                const today = moment();
                setAge(today.diff(birthday, "years"));
            }
        }, 500);

        return () => clearTimeout(timer);
    }, [searchSeeker, postsSeeker, navigate, dispatch]);

    if (isLoading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
                <Spinner size="large" />
            </div>
        );
    }

    if (!pushedSeeker) {
        return null;
    }

    const renderPersonalDetails = () => {
        return (
            <div className="details-section">
                {/* Profile Header */}
                <div className="profile-header-section">
                    <div className="profile-header-left">
                        <div className="profile-avatar">
                            {pushedSeeker.profileImage ? (
                                <img 
                                    src={pushedSeeker.profileImage} 
                                    alt="Profile" 
                                    className="profile-image"
                                />
                            ) : (
                                <FontAwesomeIcon icon="user-circle" size="4x" color="#056899" />
                            )}
                            <div className="online-status" 
                                 title={`Last active: ${moment(pushedSeeker.lastActive?.toDate()).fromNow()}`}>
                                <span className={`status-dot ${pushedSeeker.isOnline ? 'online' : 'offline'}`}></span>
                            </div>
                        </div>
                        <div className="profile-basic-info">
                            <div className="name-section">
                                <h2>{pushedSeeker.firstName} {pushedSeeker.lastName}</h2>
                                {pushedSeeker.verified && (
                                    <FontAwesomeIcon 
                                        icon="check-circle" 
                                        className="verified-icon" 
                                        title="Verified Profile"
                                    />
                                )}
                            </div>
                            <p className="profile-title" style={{textAlign: 'left', fontFamily: "ramblaBold" }}>
                                {pushedSeeker.desiredJobTitle?.title || 'Job Seeker'}  |
                                <FontAwesomeIcon icon="map-marker-alt" style={{marginLeft: '8px' }}/>
                                <span style={{marginLeft: '4px', fontFamily: "ramblaRegular"}}>  {pushedSeeker.location.country}, {pushedSeeker.location.region}</span>

                                {pushedSeeker.yearsOfExperience && (
                                    <span className="experience-badge">
                                        {pushedSeeker.yearsOfExperience}+ years
                                    </span>
                                )}
                            </p>
                            <div className="profile-location">
                                
                            </div>
                            <div className="profile-tags">
                                {pushedSeeker.topSkills?.slice(0, 3).map((skill, index) => (
                                    <span key={index} className="skill-tag">{skill}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="profile-header-right">
                        <div className="action-buttons">
                            <Button 
                                type="primary"
                                onClick={showModal}
                                icon={<FontAwesomeIcon icon="user-plus" />}
                                className="interview-button"
                            >
                                Invite for Interview
                            </Button>
                            <Button
                                onClick={() => window.print()}
                                icon={<FontAwesomeIcon icon="print" />}
                                className="print-button"
                            >
                                Print Profile
                            </Button>
                        </div>
                        {/* <div className="profile-stats">
                            <div className="stat" title="Profile Views">
                                <FontAwesomeIcon icon="eye" />
                                <span>{pushedSeeker.profileViews || 0}</span>
                            </div>
                            <div className="stat" title="Interview Success Rate">
                                <FontAwesomeIcon icon="chart-line" />
                                <span>{Math.round((pushedSeeker.acceptedInterviews || 0) / 
                                    (pushedSeeker.interviewRequests || 1) * 100)}%</span>
                            </div>
                        </div> */}
                    </div>
                </div>

                {/* Contact Information */}
                <div className="info-section">
                    <h4>
                        <FontAwesomeIcon icon="address-card" className="section-icon" />
                        Contact Information
                    </h4>
                    <div className="info-grid">
                        <div className="info-item">
                            <FontAwesomeIcon icon="envelope" className="info-icon" si/>
                            <div>
                                <label>Email:</label>
                                <span>{pushedSeeker.email}</span>
                            </div>
                        </div>
                        <div className="info-item">
                            <FontAwesomeIcon icon="phone" className="info-icon" />
                            <div>
                                <label>Phone:</label>
                                <span>{pushedSeeker.phone}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Personal Information */}
                <div className="info-section">
                    <h4>
                        <FontAwesomeIcon icon="user" className="section-icon" />
                        Personal Information
                    </h4>
                    <div className="info-grid">
                        <div className="info-item">
                            <FontAwesomeIcon icon="birthday-cake" className="info-icon" />
                            <div>
                                <label>Birth Date:</label>
                                <span>{moment.unix(pushedSeeker.birthdate.seconds || pushedSeeker.birthdate._seconds).format('DD MMM YYYY')} ({age} years)</span>
                            </div>
                        </div>
                        <div className="info-item">
                            <FontAwesomeIcon icon="venus-mars" className="info-icon" />
                            <div>
                                <label>Gender:</label>
                                <span>{pushedSeeker.gender}</span>
                            </div>
                        </div>
                        <div className="info-item">
                            <FontAwesomeIcon icon="ring" className="info-icon" />
                            <div>
                                <label>Marital Status:</label>
                                <span>{pushedSeeker.maritalStatus || 'None'}</span>
                            </div>
                        </div>
                        <div className="info-item">
                            <FontAwesomeIcon icon="plane-departure" className="info-icon" />
                            <div>
                                <label>Open For Relocation:</label>
                                <span className={`status-badge ${pushedSeeker.openForRelocation ? 'status-yes' : 'status-no'}`}>
                                    {pushedSeeker.openForRelocation ? 'Yes' : 'No'}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Professional Preferences */}
                <div className="info-section">
                    <h4>
                        <FontAwesomeIcon icon="briefcase" className="section-icon" />
                        Professional Preferences
                    </h4>
                    <div className="info-grid">
                        <div className="info-item">
                            <FontAwesomeIcon icon="money-bill" className="info-icon" />
                            <div>
                                <label>Desired Pay <i style={{fontSize: '12px', fontFamily: 'ramblaRegular'}}>(Monthly)</i>:</label>
                                <span className="highlight-text">
                                    {(() => {
                                        
                                        const getCountryCurrency = (countryCode) => {
                                            try {
                                                
                                                // Get currency data for the country
                                                const currencies = currencyCodes.country(countryCode);
                                                if (!currencies || currencies.length === 0) return 'TZS';
                                                
                                                // Return the first currency code for the country
                                                return currencies[0].code;
                                            } catch (error) {
                                                console.error('Error getting currency for country:', error);
                                                return 'TZS'; // Fallback to TZS
                                            }
                                        };
                                        
                                        const currency = getCountryCurrency(pushedSeeker?.country);
                                        
                                        return (
                                            <>{currency} {pushedSeeker.minimumPay?.toLocaleString()}</>
                                        );
                                    })()}
                                </span>
                            </div>
                        </div>
                        <div className="info-item">
                            <FontAwesomeIcon icon="industry" className="info-icon" />
                            <div>
                                <label>Industries:</label>
                                <div className="tags-container">
                                    {(pushedSeeker.industries || []).map((industry, index) => (
                                        <span key={index} className="tag">{industry}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="info-item full-width">
                            <FontAwesomeIcon icon="clock" className="info-icon" />
                            <div>
                                <label>Job Types:</label>
                                <div className="tags-container">
                                    {(pushedSeeker.jobType || []).map((type, index) => (
                                        <span key={index} className="tag">{type}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Interview Statistics */}
                <div className="info-section">
                    <h4>
                        <FontAwesomeIcon icon="chart-bar" className="section-icon" />
                        Interview Statistics
                    </h4>
                    <div className="stats-grid">
                        <div className="stat-card">
                            <div className="stat-number">{pushedSeeker.acceptedInterviews || 0}</div>
                            <div className="stat-label">Accepted</div>
                        </div>
                        <div className="stat-card">
                            <div className="stat-number">{pushedSeeker.rejectedInterviews || 0}</div>
                            <div className="stat-label">Rejected</div>
                        </div>
                        <div className="stat-card">
                            <div className="stat-number">{pushedSeeker.interviewRequests || 0}</div>
                            <div className="stat-label">Received</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

                return (
        <div className="seeker-profile">
            <Tabs 
                defaultActiveKey="1"
                type="card"
                className="compact-tabs"
            >
                <TabPane 
                    tab={
                        <span className="tab-label">
                            <FontAwesomeIcon icon="user" className="tab-icon" />
                            Personal Details
                        </span>
                    } 
                    key="1"
                >
                    {renderPersonalDetails()}
                            </TabPane>
                <TabPane tab="Education" key="2">
                                <Education/>
                            </TabPane>
                <TabPane tab="Employment" key="3">
                                <Employment/>
                            </TabPane>
                <TabPane tab="Skills" key="4">
                                <Skills/>
                            </TabPane>
                <TabPane tab="Certificates" key="5">
                                <Certificates/>
                            </TabPane>
                <TabPane tab="Awards" key="6">
                                <Awards/>
                                
                            </TabPane>
                <TabPane tab="Military Service" key="7">
                                <Military/>
                            </TabPane>
                <TabPane tab="Licenses" key="8">
                
                                <Licenses/>
                            </TabPane>
                <TabPane tab="Links" key="9">
                <Links/>
                            </TabPane>
                <TabPane tab="Languages" key="10">
                <Languages/>
                            </TabPane>
                <TabPane tab="Documents" key="11">
                <SeekerDocuments />
                            </TabPane>
                            
                <TabPane tab="Interviews" key="12">
                <Interviews />
                            </TabPane>
                            
                <TabPane tab="Referees" key="13">
                                <Referees/>
                            </TabPane>
                        </Tabs>

            <Modal
                title="Invite for Interview"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                width={900}
            >
                <InviteForInterview />
            </Modal>
            </div>
        );
};

export default SeekerProfile;