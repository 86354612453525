import React from 'react';
import { connect } from 'react-redux';
import { message, DatePicker, Modal, Input, Form, Button } from 'antd';
import _ from 'lodash';
import {ClipLoader} from "react-spinners";
import moment from "moment";

//actions import
import { postsFieldChanged, editPost } from "../../../actions/PostsActions";

//components import
import SelectJobStatus from "../../common/SelectJobStatus";
import SelectEmploymentType from "../../common/SelectEmploymentType";
import SelectDepartment from "../../common/SelectDepartment";
import CountryRegionPicker from "../../common/CountryRegionPicker";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Import CSS
import './EditJobPost.css';

const { TextArea } = Input;

class EditJobPost extends React.Component {

	constructor(props) {
		super(props);

		this.state = {

			visible: false,
			jobTitle: this.props.jobTitle,
			jobStatus: this.props.jobStatus,
			department: this.props.department,
			employmentType: this.props.employmentType,
			minimumExperience: this.props.minimumExperience,
			jobDescription: this.props.jobDescription,
			address: this.props.address,
			postalCode: this.props.postalCode,
			compensation: this.props.compensation,
			country: this.props.country,
			region: this.props.region,
			file: this.props.file,
			companyName: this.props.companyName,
			email: this.props.email,
			phone: this.props.phone,
			deadline: this.props.deadline,


			addPostLoading: this.props.addPostLoading,
			postSaved: this.props.postSaved,
			postFailed: this.props.postFailed,

			profile: this.props.profile,
		};

		this.onSelectDepartment = this.onSelectDepartment.bind(this);
		this.onSelectEmploymentType = this.onSelectEmploymentType.bind(this);
		this.onSelectJobStatus = this.onSelectJobStatus.bind(this);
		this.handleFile = this.handleFile.bind(this);
		this.onDeadlineChange = this.onDeadlineChange.bind(this);
		this.onSave = this.onSave.bind(this);
	}

	componentWillReceiveProps(nextProps, nextContext) {

		if (nextProps !== this.props) {
			this.setState({
				profile: nextProps.profile,
				jobTitle: nextProps.jobTitle,
				jobStatus: nextProps.jobStatus,
				department: nextProps.department,
				employmentType: nextProps.employmentType,
				minimumExperience: nextProps.minimumExperience,
				jobDescription: nextProps.jobDescription,
				address: nextProps.address,
				postalCode: nextProps.postalCode,
				compensation: nextProps.compensation,
				addPostLoading: nextProps.addPostLoading,
				postSaved: nextProps.postSaved,
				postFailed: nextProps.postFailed,
				country: nextProps.country,
				region: nextProps.region,
				file: nextProps.file,
				companyName: nextProps.companyName,
				email: nextProps.email,
				phone: nextProps.phone,
				deadline: nextProps.deadline,
			});
		}
	}

	onSelectJobStatus(value) {
		this.props.postsFieldChanged({prop: 'jobStatus', value})
	}

	onSelectDepartment(value) {
		this.props.postsFieldChanged({prop: 'department', value})
	}

	onSelectEmploymentType(value) {
		this.props.postsFieldChanged({prop: 'employmentType', value})
	}

	onDeadlineChange(date) {
		this.props.postsFieldChanged({prop: 'deadline', value: date });
	};

	renderButton() {
		const {addPostLoading} = this.state;

		if (addPostLoading) {
			return (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<ClipLoader size={20} color="#0071e3" />
				</div>
			);
		} else {
			return (
				<Button type="primary" onClick={this.onSave}>
					<p style={{fontFamily: "ramblaBold"}}>Save</p>
				</Button>
			);
		}
	}

	renderSavedMessage() {
		const {postSaved} = this.state;
		if (postSaved) {
			return <p className="success-message">{message.success("Post saved successfully")}</p>
		}
		return null;
	}

	renderFailedMessage() {
		const {postFailed} = this.state;
		if (postFailed) {
			return <p className="error-message">{message.error("Post not saved")}</p>
		}
		return null;
	}

	onSave() {
		//extract state
		const { jobTitle, jobStatus, department, employmentType, minimumExperience, jobDescription, country, region, address, postalCode, compensation,
			companyName, email, phone, file, deadline, profile } = this.state;

		if(!(_.isEmpty(profile))){
			if (jobTitle && jobStatus && employmentType && minimumExperience && jobDescription && country && region &&
				address && compensation && companyName && email && phone && file && deadline ) {

				const companyLocation = {
					country: country.countryName ? country.countryName : country.country,
					countryCode: country.countryShortCode ? country.countryShortCode : country.countryCode,
					region: region.name ? region.name : region.region,
					regionCode: region.shortCode ? region.shortCode : region.regionCode,
					streetAddress: address,
					postalCode
				}

				this.props.editPost({ postID: this.props.post.postID, jobTitle, status: jobStatus, department, jobType: employmentType, minimumExperience: parseInt(minimumExperience), jobDescription, compensation: parseInt(compensation), companyLocation, companyName, email, phone, deadline: deadline.toDate(), file });
			} else {
				message.warning("Kindly fill all fields");
			}
		}
	}

	async handleFile(event) {
		//files is an array, so pick first element
		const file = event.target.files[0];
		const maxSize = 10 * 1024 * 1024; // 2MB maximum size (in bytes)

		if (file && file.size > maxSize) {
			message.info('File size exceeds the maximum limit of 10MB.');
			// Optionally, you can reset the file input value
			event.target.value = null;
		} else {
			// File size is within the limit, you can proceed with the upload
			// ...
			const fileToBase64 = (file) => {
				return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => resolve(reader.result);
					reader.onerror = (error) => reject(error);
				});
			};
			const fileData = await fileToBase64(file);
			const { name, type, size } = file;
			this.props.postsFieldChanged({ prop: 'file', value: { name, type, size, data: fileData } });
		}
	}

	showModal() {
		const { postTitle, status, department, jobType, minimumExperience, description, compensation,
			companyLocation, file, companyName, email, phone, deadline } = this.props.post;

		let seconds;
		deadline.seconds ? seconds = deadline.seconds : seconds = deadline._seconds;
		const dateMoment = moment.unix(seconds);

		this.setState({
			visible: true,
		});

		this.props.postsFieldChanged({prop: 'jobTitle', value: postTitle });
		this.props.postsFieldChanged({prop: 'jobStatus', value: status });
		this.props.postsFieldChanged({prop: 'department', value: department });
		this.props.postsFieldChanged({prop: 'employmentType', value: jobType });
		this.props.postsFieldChanged({prop: 'minimumExperience', value: minimumExperience });
		this.props.postsFieldChanged({prop: 'jobDescription', value: description });
		this.props.postsFieldChanged({prop: 'address', value: companyLocation.streetAddress });
		this.props.postsFieldChanged({prop: 'postalCode', value: companyLocation.postalCode });
		this.props.postsFieldChanged({prop: 'compensation', value: compensation });
		this.props.postsFieldChanged({prop: 'file', value: file });
		this.props.postsFieldChanged({prop: 'companyName', value: companyName });
		this.props.postsFieldChanged({prop: 'email', value: email });
		this.props.postsFieldChanged({prop: 'phone', value: phone });
		this.props.postsFieldChanged({prop: 'deadline', value: dateMoment });

		this.props.postsFieldChanged({
			prop: 'country',
			value: {
				country: companyLocation.country,
				countryCode: companyLocation.countryCode
			}
		});
		this.props.postsFieldChanged({
			prop: 'region',
			value: {
				region: companyLocation.region,
				regionCode: companyLocation.regionCode
			}
		});
	};

	handleOk = () => {
		this.setState({
			visible: false,
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	};

	checkAccess() {
		const { profile, country, region, deadline, department } = this.state;

		if(!(_.isEmpty(profile))){
			const { role } = this.state.profile;

			if( role === 'superAdmin' || role === "admin"){
				return (
					<div className="addJobOpeningBox">
						<Form layout="vertical" className="addJobOpeningInfoBox">
							<div className="form-section">
								<h3 className="section-title">Basic Information</h3>
								<div className="section-content">
									<Form.Item label="Posting Title" className="compact-form-item">
										<Input
											placeholder="Finance Officer"
											onChange={e => this.props.postsFieldChanged({
												prop: 'jobTitle',
												value: e.target.value
											})}
											value={this.state.jobTitle}
										/>
									</Form.Item>
									<div className="form-row">
										<Form.Item label="Job Status" className="compact-form-item" style={{flex: 1}}>
											<SelectJobStatus
												placeholder="Select Job Status"
												onChange={this.onSelectJobStatus}
												value={this.state.jobStatus}
											/>
										</Form.Item>
										<Form.Item label="Employment Type" className="compact-form-item"
										           style={{flex: 1}}>
											<SelectEmploymentType
												placeholder="Employment Type"
												onChange={this.onSelectEmploymentType}
												value={this.state.employmentType}
											/>
										</Form.Item>
									</div>

									<div className="form-row">
										<Form.Item label="Department" className="compact-form-item" style={{flex: 1}}>
											<SelectDepartment
												placeholder="Select Department"
												onChange={this.onSelectDepartment}
												value={this.state.department}
											/>
										</Form.Item>
										<Form.Item label="Minimum Experience (Years)" className="compact-form-item"
										           style={{flex: 1}}>
											<Input
												type="number"
												onChange={e => this.props.postsFieldChanged({
													prop: 'minimumExperience',
													value: e.target.value
												})}
												value={this.state.minimumExperience}
											/>
										</Form.Item>
									</div>
								</div>
							</div>

							{/* Job Details & Requirements */}
							<div className="form-section">
								<h3 className="section-title">Job Details & Requirements</h3>
								<div className="section-content">
									<Form.Item label="Job Summary" className="compact-form-item">
										<TextArea
											onChange={e => this.props.postsFieldChanged({prop: 'jobDescription', value: e.target.value})}
											value={this.state.jobDescription}
											maxLength={500}
											rows={5}
											placeholder="Describe the role and responsibilities"
											style={{ resize: 'vertical' }}
										/>
									</Form.Item>

									<Form.Item label="Job Description Document" className="compact-form-item">
										<div className="file-input-wrapper">
											<input
												type="file"
												id="job-description-file"
												onChange={(e) => this.handleFile(e)}
												accept="application/pdf"
											/>
											<label htmlFor="job-description-file" className="file-input-label">
												<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M12 16V8M12 8L9 11M12 8L15 11" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
													<path d="M3 15V16C3 18.2091 4.79086 20 7 20H17C19.2091 20 21 18.2091 21 16V15" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
												</svg>
												<span>{this.state.file ? this.state.file.name : 'Choose PDF file'}</span>
											</label>
										</div>
										<div className="form-hint">Maximum file size: 10MB (PDF only)</div>
									</Form.Item>

									<Form.Item label="Application Deadline" className="compact-form-item">
										<DatePicker
											showTime={{ format: 'HH:mm' }}
											format="DD-MM-YYYY HH:mm"
											onChange={this.onDeadlineChange}
											style={{ width: '100%' }}
											placeholder="Select deadline date and time"
										/>
									</Form.Item>
								</div>
							</div>

							{/* Contact & Company Information */}
							<div className="form-section">
								<h3 className="section-title">Contact & Company Information</h3>
								<div className="section-content">
									<Form.Item label="Company Name" className="compact-form-item">
										<Input
											placeholder="Enter company name"
											onChange={e => this.props.postsFieldChanged({prop: 'companyName', value: e.target.value})}
											value={this.state.companyName}
										/>
									</Form.Item>

									<div className="form-row">
										<Form.Item label="Email" className="compact-form-item" style={{ flex: 1 }}>
											<Input
												placeholder="contact@company.com"
												onChange={e => this.props.postsFieldChanged({prop: 'email', value: e.target.value})}
												value={this.state.email}
											/>
										</Form.Item>
										<Form.Item label="Phone" className="compact-form-item" style={{ flex: 1 }}>
											<Input
												placeholder="Phone number"
												onChange={e => this.props.postsFieldChanged({prop: 'phone', value: e.target.value})}
												value={this.state.phone}
												maxLength={10}
											/>
										</Form.Item>
									</div>
								</div>
							</div>

							{/* Location & Compensation */}
							<div className="form-section">
								<h3 className="section-title">Location & Compensation</h3>
								<div className="section-content">
									<Form.Item label="Location" className="compact-form-item">
										{
											country && !(_.isEmpty(country)) && region && !(_.isEmpty(region)) &&
											<div className="current-location">
												<p>{country.country}</p>
												<p>{region.region}</p>
											</div>
										}
										<CountryRegionPicker/>
										<Input
											placeholder="Street Address"
											onChange={e => this.props.postsFieldChanged({prop: 'address', value: e.target.value})}
											value={this.state.address}
											style={{ marginTop: '8px' }}
										/>
										<Input
											placeholder="Postal Code"
											onChange={e => this.props.postsFieldChanged({prop: 'postalCode', value: e.target.value})}
											value={this.state.postalCode}
											style={{ marginTop: '8px' }}
										/>
									</Form.Item>

									<Form.Item label="Annual Compensation" className="compact-form-item">
										<Input
											type="number"
											placeholder="Enter amount in local currency"
											onChange={e => this.props.postsFieldChanged({prop: 'compensation', value: e.target.value})}
											value={this.state.compensation}
										/>
									</Form.Item>
								</div>
							</div>
						</Form>

						{this.renderSavedMessage()}
						{this.renderFailedMessage()}
					</div>
				)
			}
		}
	}

	render() {
		return (
			<div className="edit-job-post-container">
				<FontAwesomeIcon
					icon={faEdit}
					size={"1x"}
					className="edit-icon"
					onClick={() => this.showModal()}
				/>
				<Modal
					title={"Edit Job Post"}
					open={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={
						<div className="modal-footer">
							<button className="cancel-button" onClick={this.handleCancel}>
								<p style={{fontFamily: "ramblaBold"}}>Cancel</p>
							</button>
							{this.renderButton()}
						</div>
					}
					width={1200}
				>
					{this.checkAccess()}
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => {

	const {profile} = state.profile;

	const {
		jobTitle,
		jobStatus,
		department,
		employmentType,
		minimumExperience,
		jobDescription,
		address,
		postalCode,
		compensation,
		addPostLoading,
		postSaved,
		postFailed,
		country,
		region,
		file,
		companyName,
		email,
		phone,
		deadline
	} = state.posts;

	return {
		jobTitle,
		jobStatus,
		department,
		employmentType,
		minimumExperience,
		jobDescription,
		postalCode,
		address,
		compensation,
		country, region, file, companyName, email, phone, deadline,
		addPostLoading,
		postSaved,
		postFailed,

		profile,
	}

};

export default connect(mapStateToProps, {postsFieldChanged, editPost})(EditJobPost);