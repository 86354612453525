import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';
import { News01Icon } from 'hugeicons-react';
import { Spinner } from "../../common/Spinner";
import PostCard from "../cards/PostCard";
import './Posts.css';

class Posts extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            profile: this.props.profile,
            posts: this.props.posts,
            postsLoading: this.props.postsLoading,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                profile: nextProps.profile,
                posts: nextProps.posts,
                postsLoading: nextProps.postsLoading,
            });
        }
    }

    renderPosts() {
        const { posts, postsLoading } = this.state;

        if (postsLoading) {
            return <Spinner/>;
        }

        if (posts.length === 0) {
            return (
                <div className="posts-empty">
                    <News01Icon size={32} className="news-icon" />
                    <h3>No Posts Yet</h3>
                    <p>New job posts will appear here</p>
                </div>
            );
        }

        const recentPosts = posts.slice(0, 4);
        return (
            <div className="posts-grid">
                {recentPosts.map(post => (
                    <div key={post.postID} className="post-item">
                        <PostCard post={post}/>
                    </div>
                ))}
            </div>
        );
    }

    render(){
        return (
            <div className="posts-container">
                <div className="posts-header">
                    <div className="posts-title">
                        <News01Icon size={24} className="header-icon" />
                        <h2>Latest Posts</h2>
                    </div>
                    <NavLink to="/posts" className="view-all-link">
                        View All
                        <FaChevronRight size={12} className="chevron-icon" />
                    </NavLink>
                </div>
                {this.renderPosts()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    posts: state.posts.posts,
    postsLoading: state.posts.postsLoading,
    profile: state.profile.profile,
});

export default connect(mapStateToProps)(Posts);
