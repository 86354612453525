import React, { useState } from "react";
import { Modal, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EyeOutlined } from '@ant-design/icons';
import PDFCV from "../PDFCV";
import './DescriptionModal.css';

const DescriptionModal = ({ record }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleToggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const renderSection = (label, value) => (
        <div className="nafasi-assessment-modal__section">
            <div className="nafasi-assessment-modal__label">{label}</div>
            <div className="nafasi-assessment-modal__value">{value}</div>
        </div>
    );

    return (
        <div className="nafasi-assessment-modal">
            <EyeOutlined 
                className="nafasi-assessment-modal__trigger"
                onClick={handleToggleModal}
            />
            
            <Modal
                title="Assessment Details"
                open={isModalVisible}
                onCancel={handleToggleModal}
                width={800}
                className="nafasi-assessment-modal"
                footer={[
                    <Button key="close" type="primary" onClick={handleToggleModal}>
                        Close
                    </Button>
                ]}
            >
                {renderSection("Full Name", `${record.firstName} ${record.lastName}`)}
                {renderSection("Email Address", record.email)}
                {renderSection("Phone Number", record.phone)}
                {renderSection("Gender", record.gender)}
                {renderSection("Location", `${record.location.region}, ${record.location.country}`)}
                {renderSection("Date of Birth", record.birthday)}
                {renderSection("Assessment Status", record.attended ? "Attended" : "Not Attended")}
                {renderSection("Assessment Date", record.time)}
                
                <div className="nafasi-assessment-modal__section">
                    <div className="nafasi-assessment-modal__label">Nafasi CV</div>
                    <div className="nafasi-assessment-modal__value">
                        <PDFCV candidate={record}/>
                    </div>
                </div>

                {record.cv && (
                    <div className="nafasi-assessment-modal__section">
                        <div className="nafasi-assessment-modal__label">Uploaded CV</div>
                        <div 
                            className="nafasi-assessment-modal__cv"
                            onClick={() => window.open(record.cv, '_blank', 'width=1500,height=800')}
                        >
                            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                            <FontAwesomeIcon 
                                icon="eye" 
                                className="nafasi-assessment-modal__cv-icon"
                            />
                            <p style={{ fontFamily: 'ramblaRegular', fontSize: '0.9rem', marginTop: '6px' }}>View CV</p>
                        
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default DescriptionModal;