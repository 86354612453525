import { 
    FETCH_MY_PROFILE,
    FETCH_MY_PROFILE_SUCCESSFUL,
    FETCH_MY_PROFILE_FAILED,
    INITIALIZE_STATE
} from '../actions/Types';

const INITIAL_STATE = {
    profile: null,
    loading: false,
    error: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_MY_PROFILE:
            return { ...state, loading: true };
        case FETCH_MY_PROFILE_SUCCESSFUL:
            return { ...state, loading: false, profile: action.payload };
        case FETCH_MY_PROFILE_FAILED:
            return { ...state, loading: false, error: action.payload };
        case INITIALIZE_STATE:
            return INITIAL_STATE;
        default:
            return state;
    }
};