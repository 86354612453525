import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { Table, Modal, message, Popconfirm } from 'antd';
import { PropertyDeleteIcon } from "hugeicons-react";
import "./PostReviewCard.css";

//-> action imports
import { pushSeeker, withdrawCandidate, postsFieldChanged } from "../../../actions/PostsActions";

//-> component import
import EditInviteForInterview from "../../shortlist/subComponents/EditInviteForInterview";

class PostReviewCardBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedPost: this.props.selectedPost,
            visible: false,
            inviteVisible: false,
            candidates: this.props.candidates,
            showLoading: false
        };

        this.onSelectCandidate = this.onSelectCandidate.bind(this);
        this.onWithdrawCandidate = this.onWithdrawCandidate.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                selectedPost: nextProps.selectedPost,
                candidates: nextProps.candidates,
            });
        }
    }

    componentWillUnmount() {
        this.setState({ showLoading: false });
    }

    showModal() {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onSelectCandidate(candidate) {
        const {navigate} = this.props;

        this.setState({ showLoading: true });
        this.props.pushSeeker({ pushedSeeker: candidate });
        navigate("/seeker-profile");
    }

    onWithdrawCandidate(candidate) {
        const { selectedPost } = this.state;
        const interviewID = candidate.interviewID;
        const candidateID = candidate.userID;
        const status = candidate.withdraw;
        const postID = selectedPost.postID;

        this.props.withdrawCandidate({ interviewID, candidateID, postID, status: !status });
    }

    onCancel() {
        message.info("offer withdraw is canceled");
    }

    handleInterviewOk = () => {
        this.setState({
            inviteVisible: false,
        });
    };

    handleInterviewCancel = () => {
        this.setState({
            inviteVisible: false,
        });
    };

    editModal(candidate) {
        let seconds;
        candidate.interviewDate.seconds ? seconds = candidate.interviewDate.seconds : seconds = candidate.interviewDate._seconds;
        const dateMoment = moment.unix(seconds);

        this.props.postsFieldChanged({ prop: "interviewDate", value: dateMoment });
        this.props.postsFieldChanged({ prop: "inviteComment", value: candidate.inviteComment });

        this.setState({ inviteVisible: true });
    }

    renderCandidate() {
        const { candidates } = this.state;

        let processedCandidates = candidates.map(candidate => {
            let seconds = candidate.statusDate.seconds || candidate.statusDate._seconds;
            const statusDate = moment.unix(seconds).format('MMM D, YYYY');

            let interviewSeconds = candidate.interviewDate.seconds || candidate.interviewDate._seconds;
            const interviewDateFormat = moment.unix(interviewSeconds).format('MMM D, YYYY');
            
            return { ...candidate, statusDate, interviewDateFormat };
        });

        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                render: (text) => (
                    <span className="table-cell name">{text}</span>
                ),
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text) => (
                    <span className={`status-indicator ${text.toLowerCase() === 'active' ? 'status-done' : 'status-pending'}`}>
                        {text}
                    </span>
                ),
            },
            {
                title: 'Status Date',
                dataIndex: 'statusDate',
                key: 'statusDate',
                render: (text) => (
                    <span className="table-cell">{text}</span>
                ),
            },
            {
                title: 'Interview Date',
                key: 'interviewDateFormat',
                render: (_, record) => (
                    <>
                        <span 
                            className="interview-date"
                            onClick={() => this.editModal(record)}
                        >
                            {record.interviewDateFormat}
                        </span>
                        <Modal
                            title="Invite for Interview"
                            open={this.state.inviteVisible}
                            onOk={this.handleInterviewOk}
                            onCancel={this.handleInterviewCancel}
                            footer={null}
                            width={900}
                        >
                            <EditInviteForInterview selectedCandidate={record} />
                        </Modal>
                    </>
                ),
            },
            {
                title: 'Interviewed',
                dataIndex: 'interviewed',
                key: 'interviewed',
                render: (interviewed) => (
                    <span className={`status-indicator ${interviewed ? 'status-done' : 'status-pending'}`}>
                        {interviewed ? "Done" : "Pending"}
                    </span>
                ),
            },
            {
                title: 'Selected',
                dataIndex: 'selected',
                key: 'selected',
                render: (selected) => (
                    <span className={`status-indicator ${selected ? 'status-done' : 'status-pending'}`}>
                        {selected ? "Yes" : "No"}
                    </span>
                ),
            },
            {
                title: 'Acc. Int',
                dataIndex: 'acceptedInterviews',
                key: 'acceptedInterviews',
                render: (text) => (
                    <span className="table-cell">{text}</span>
                ),
                align: 'center',
            },
            {
                title: 'Rej. Int',
                dataIndex: 'rejectedInterviews',
                key: 'rejectedInterviews',
                render: (text) => (
                    <span className="table-cell">{text}</span>
                ),
                align: 'center',
            },
            {
                title: 'I.A.S Rating',
                dataIndex: 'iasRating',
                key: 'iasRating',
                render: (iasRating) => (
                    <span className={`status-indicator ${iasRating ? 'status-done' : 'status-pending'}`}>
                        {iasRating ? "Done" : "Pending"}
                    </span>
                ),
            },
            {
                title: 'Profile',
                key: 'profile',
                render: (_, record) => (
                    <button 
                        className="action-button edit"
                        onClick={() => this.onSelectCandidate(record)}
                    >
                        <FontAwesomeIcon icon="user" />
                    </button>
                ),
            },
            {
                title: 'Actions',
                key: 'actions',
                render: (_, record) => (
                    <Popconfirm
                        title={record.withdraw ? 
                            "Are you sure to reinstate this offer?" : 
                            "Are you sure to withdraw this offer?"}
                        onConfirm={() => this.onWithdrawCandidate(record)}
                        onCancel={() => this.onCancel()}
                        okText="Yes"
                        cancelText="No"
                    >
                        <button className="action-button delete">
                            <PropertyDeleteIcon 
                                size={20} 
                                color={record.withdraw ? "#4CAF50" : "#FF3B30"}
                            />
                        </button>
                    </Popconfirm>
                ),
            },
        ];

        return (
            <div className="post-review-table">
                <Table 
                    dataSource={processedCandidates} 
                    columns={columns} 
                    rowKey="id"
                    pagination={{
                        pageSize: 10,
                        hideOnSinglePage: true,
                        position: ['bottomCenter'],
                        style: { marginTop: '24px' }
                    }}
                    scroll={{ x: 'max-content' }}
                />
            </div>
        );
    }

    render() {
        return this.renderCandidate();
    }
}

const mapStateToProps = ( state ) => {
    const { selectedPost } = state.posts;

    return {
        selectedPost
    }
};

// Wrapper functional component to use hooks
function PostReviewCard(props) {
    const navigate = useNavigate();

    return <PostReviewCardBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps,{withdrawCandidate, pushSeeker, postsFieldChanged})(PostReviewCard);