import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Certificates.css';

// Import the action
import { fetchSeekerPath } from '../../../actions/PostsActions';

//components import
import { Spinner } from "../../common/Spinner";
import CertificateCard from "./cards/CertificateCard";

class Certificates extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCandidate: this.props.selectedCandidate,
            certificates: this.props.certificates,
            certificatesLoading: this.props.certificatesLoading,
            profile: this.props.profile,
        }

        this.renderCertificates = this.renderCertificates.bind(this);
    }

    componentDidMount() {
        const { selectedCandidate } = this.props;
        if (selectedCandidate && selectedCandidate.id) {
            this.props.fetchSeekerPath(selectedCandidate.id, "certificates");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedCandidate !== this.props.selectedCandidate) {
            if (this.props.selectedCandidate && this.props.selectedCandidate.id) {
                this.props.fetchSeekerPath(this.props.selectedCandidate.id, "certificates");
            }
        }

        if (prevProps !== this.props) {
            this.setState({
                selectedCandidate: this.props.selectedCandidate,
                certificates: this.props.certificates,
                certificatesLoading: this.props.certificatesLoading,
                profile: this.props.profile,
            });
        }
    }

    renderCertificates() {
        const { certificates, certificatesLoading, selectedCandidate } = this.state;

        if (certificatesLoading) {
            return <Spinner />
        }

        if (_.isEmpty(selectedCandidate)) {
            return <p style={{ color: "#000", margin: "2rem 0" }}>Select a candidate to view</p>;
        }

        if (!certificates || certificates.length === 0) {
            return (
                <div className="no-data-message">
                    <FontAwesomeIcon icon="certificate" className="no-data-icon" />
                    <p>No certificates listed</p>
                </div>
            );
        }

        return (
            <div className="certificates-timeline">
                <CertificateCard certificates={certificates}/>
            </div>
        );
    }

    checkUser() {
        const { profile } = this.state;

        if(_.isEmpty(profile)) {
            return <h2 style={{ color: "#000" }}>Sign in to view</h2>;
        }

        if(profile.role !== "admin" && profile.role !== "superAdmin") {
            return <h3>Un-Authorized Access</h3>;
        }

        return (
            <div className="section-container">
                <div className="section-header">
                    <h4>
                        <FontAwesomeIcon icon="certificate" className="section-icon" />
                        Professional Certificates
                    </h4>
                    <div className="section-summary">
                        <span className="total-count">
                            {this.state.certificates?.length || 0} Certificates Listed
                        </span>
                    </div>
                </div>
                <div className="section-content">
                    {this.renderCertificates()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="certificates-container">
                {this.checkUser()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedCandidate, certificates, certificatesLoading } = state.posts;
    const { profile } = state.profile;

    return {
        selectedCandidate,
        certificates,
        certificatesLoading,
        profile
    }
}

export default connect(mapStateToProps, { fetchSeekerPath })(Certificates);