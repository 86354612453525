import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Links.css";

class LinksCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            links: this.props.links,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                links: nextProps.links,
            });
        }
    }

    getLinkIcon(type) {
        const icons = {
            'github': 'github',
            'linkedin': 'linkedin',
            'portfolio': 'globe',
            'website': 'globe',
            'blog': 'blog',
            'other': 'link'
        };
        return icons[type?.toLowerCase()] || 'link';
    }

    getLinkColor(type) {
        const colors = {
            'github': { bg: '#f6f8fa', color: '#24292e' },
            'linkedin': { bg: '#e8f4f9', color: '#0a66c2' },
            'portfolio': { bg: '#f0fdf4', color: '#166534' },
            'website': { bg: '#f0f9ff', color: '#0369a1' },
            'blog': { bg: '#fef3c7', color: '#92400e' },
            'other': { bg: '#f3f4f6', color: '#374151' }
        };
        return colors[type?.toLowerCase()] || colors.other;
    }

    renderLinks() {
        const { links } = this.state;

        return (
            <div className="links-grid">
                {links.map((link, index) => {
                    const linkColors = this.getLinkColor(link.type);
                    
                    return (
                        <div key={index} className="link-card">
                            <div className="link-header">
                                <div 
                                    className="link-icon"
                                    style={{ 
                                        background: linkColors.bg,
                                        color: linkColors.color 
                                    }}
                                >
                                    <FontAwesomeIcon icon={['fab', this.getLinkIcon(link.type)]} />
                                </div>
                                <div className="link-info">
                                    <h4>{link.title || 'Untitled Link'}</h4>
                                    <span className="link-type">
                                        {link.type || 'Other'}
                                    </span>
                                </div>
                            </div>

                            {link.description && (
                                <p className="link-description">
                                    {link.description}
                                </p>
                            )}

                            <a 
                                href={link.url} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="visit-link"
                                style={{ 
                                    background: linkColors.bg,
                                    color: linkColors.color 
                                }}
                            >
                                <FontAwesomeIcon icon="external-link-alt" />
                                Visit Link
                            </a>
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        return (
            <div className="links-timeline">
                {this.renderLinks()}
            </div>
        );
    }
}

export default LinksCard;