import React from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import { Tabs } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccountSetting03Icon } from "hugeicons-react";
import './Settings.css';

//action imports

//components import
import Assets from "./assets/Assets";
import Subscriptions from "./subscriptions/Subscriptions";
import Payments from "./payments/Payments";
import ViewTermsAndConditions from "./legal/ViewTermsAndConditions";
import PromoCodes from "./promoCodes/PromoCodes";
import BlogEditor from "./blogs/BlogEditor";
import ViewBlogs from "./blogs/ViewBlogs";
import Extracts from "./extracts/Extracts";
import Feedback from "./feedback/Feedback";
import RecruitersRequests from "./recruitersRequests/RecruitersRequests";
import Assessment from "./assessment/Assessment";
import AdminPost from "./posts/AdminPost";


class Settings extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            profile: this.props.profile,
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.profile !== this.props.profile){
            this.setState({profile: nextProps.profile})
        }
    }

    callBack() {};

    checkAccess(){
        const { profile } = this.state;
        const { TabPane } = Tabs;

        if(!(_.isEmpty(profile))) {
            const {role} = this.state.profile;

            if (role === 'admin' || role === "superAdmin") {
                return (
                    <div className="nafasi-settings__content">
                        <Tabs
                            onChange={this.callBack()}
                            defaultActiveKey="1"
                            type="line"
                            tabBarStyle={{
                                marginBottom: '32px',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
                            }}
                            className="nafasi-settings__tabs"
                        >
                            {role === "superAdmin" && (
                                <TabPane 
                                    tab={<span className="nafasi-settings__tab-label">Assets</span>} 
                                    key="1"
                                >
                                    <Assets/>
                                </TabPane>
                            )}
                            <TabPane 
                                tab={<span className="nafasi-settings__tab-label">Payments</span>} 
                                key="2"
                            >
                                <Payments/>
                            </TabPane>
                            <TabPane 
                                tab={<span className="nafasi-settings__tab-label">Subscriptions</span>} 
                                key="3"
                            >
                                <Subscriptions/>
                            </TabPane>
                            <TabPane 
                                tab={<span className="nafasi-settings__tab-label">Legal</span>} 
                                key="4"
                            >
                                <ViewTermsAndConditions/>
                            </TabPane>
                            {role === "superAdmin" && (
                                <>
                                    <TabPane 
                                        tab={<span className="nafasi-settings__tab-label">Promo Codes</span>} 
                                        key="5"
                                    >
                                        <PromoCodes/>
                                    </TabPane>
                                    <TabPane 
                                        tab={<span className="nafasi-settings__tab-label">Blogs</span>} 
                                        key="6"
                                    >
                                        <div className="nafasi-settings__blogs">
                                            <BlogEditor/>
                                            <ViewBlogs/>
                                        </div>
                                    </TabPane>
                                    <TabPane 
                                        tab={<span className="nafasi-settings__tab-label">Extracts</span>} 
                                        key="7"
                                    >
                                        <Extracts/>
                                    </TabPane>
                                    <TabPane 
                                        tab={<span className="nafasi-settings__tab-label">Feedback</span>} 
                                        key="8"
                                    >
                                        <Feedback/>
                                    </TabPane>
                                    <TabPane 
                                        tab={<span className="nafasi-settings__tab-label">Recruiters</span>} 
                                        key="9"
                                    >
                                        <RecruitersRequests/>
                                    </TabPane>
                                    <TabPane 
                                        tab={<span className="nafasi-settings__tab-label">Assessment</span>} 
                                        key="10"
                                    >
                                        <Assessment/>
                                    </TabPane>
                                    <TabPane 
                                        tab={<span className="nafasi-settings__tab-label">Job Posts</span>} 
                                        key="11"
                                    >
                                        <AdminPost/>
                                    </TabPane>
                                </>
                            )}
                        </Tabs>
                    </div>
                );
            }
        }
    }

    render() {
        return (
            <div className="nafasi-settings">
                <div className="nafasi-settings__header">
                    <div className="nafasi-settings__header-content">
                        <AccountSetting03Icon size={24} className="nafasi-settings__icon" />
                        <h2 className="nafasi-settings__title">Settings</h2>
                    </div>
                </div>

                {this.checkAccess()}
                
                <div className="nafasi-settings__footer">
                    <span className="nafasi-settings__version">Version 2.0.0b</span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { profile } = state.profile;

    return {
        profile,
    }
};

export default connect(mapStateToProps, { })(Settings);
