import React from "react";
import { connect } from "react-redux";
import {message, Select} from "antd";

// Action imports
import { searchFieldChanged, fetchEducationIndustry } from "../../../actions/SearchActions";

//property declarations
const { Option } = Select;

class IndustryFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            industryPrime: this.props.industryPrime,
            educationIndustries: this.props.educationIndustries,
        }

        this.handleScheduleChange = this.handleScheduleChange.bind(this);
    }

    componentDidMount() {
        this.props.fetchEducationIndustry();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                industryPrime: nextProps.industryPrime,
                educationIndustries: nextProps.educationIndustries
            });
        }
    }

    handleScheduleChange = (selectedSchedules) => {
        if (selectedSchedules.length === 0) {
            // Dispatch an action to set skill to null
            this.props.searchFieldChanged({ prop: "industryPrime", value: null });
        } else {
            // Otherwise, proceed as usual
            this.props.searchFieldChanged({ prop: "industryPrime", value: selectedSchedules });
        }
    };


    render() {
        const { industryPrime, educationIndustries } = this.state;

        return (
            <div className="variable-section">
                <p style={{color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left"}}>Experience
                    Industry</p>
                <Select
                    mode="multiple"
                    placeholder="Search Employment Industry"
                    value={industryPrime}
                    allowClear
                    onChange={this.handleScheduleChange}
                    style={{width: "100%", borderColor: '#000'}}
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {educationIndustries.map((option) => (
                        <Option key={option.objectID} value={option.objectID} label={option.title}>
                            {option.title}
                        </Option>
                    ))}
                </Select>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { educationIndustries, industryPrime } = state.search;

    return {
        educationIndustries,
        industryPrime
    };
};

export default connect(mapStateToProps, { searchFieldChanged, fetchEducationIndustry })(IndustryFilter);
