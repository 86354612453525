import React from 'react';
import {Routes, Route, NavLink, useNavigate, Navigate} from 'react-router-dom';
import { connect } from 'react-redux';

// Third party libraries
import _ from 'lodash';
import firebase from 'firebase';
import moment from "moment";

// Icons
import { AlignLeftIcon } from "hugeicons-react";
import { 
  AccountSetting03Icon, 
  AnalyticsUpIcon,
  CustomerService02Icon,
  Folder02Icon,
  Home01Icon,
  LicenseDraftIcon,
  Logout03Icon,
  NotificationCircleIcon,
  PackageAddIcon,
  SearchVisualIcon,
  UserSharingIcon 
} from 'hugeicons-react';

// Actions
import {homeFieldChanged, toggleMenu} from '../actions/HomeActions';
import {userLogOut} from "../actions/AuthenticationActions";
import {fetchSystemInfo} from "../actions/SystemActions";

// Assets
import Logo from '../assets/logos/logo.png';
import taglineLogo from "../assets/logos/taglineLogo.png";

// Components
import {Spinner} from "./common/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollToTop from './common/ScrollToTop';
import Footer from "./common/Footer";
import {VerifyEmail} from "./common/VerifyEmail";
import {VerifyUser} from "./common/VerifyUser";
import Subscriptions from "./settings/subscriptions/Subscriptions";
import SubscriptionInvoice from "./settings/subscriptions/invoice/SubscriptionInvoice";
import AddTermsAndConditions from "./settings/legal/AddTermsAndConditions";
import ViewTermsAndConditions from "./settings/legal/ViewTermsAndConditions";
import PrintInvoice from "./settings/subscriptions/invoice/subComponents/PrintInvoice";
import AddPrintInvoice from "./settings/subscriptions/additionalInvoice/subComponents/AddPrintInvoice";
import ChoosePackage from "./settings/subscriptions/subComponents/ChoosePackage";
import Reference from "./landing/Reference";
import Landing from './Landing';
import Home from './home/Home';
import Profile from './profile/Profile';
import Shortlist from './shortlist/Shortlist';
import Posts from './posts/Posts';
import Download from './download/Download';
import Settings from './settings/Settings';
import PostReview from './posts/subComponents/PostReview';
import IASInterview from './posts/subComponents/IASInterview';
import SignIn from './authentication/SignIn';
import SignUp from './authentication/SignUp';
import Notifications from './notifications/Notifications';
import Search from './search/Search';
import Analytics from './analytics/Analytics';
import Support from './support/Support';
import Reports from './reports/Reports';
import SeekerProfile from './shortlist/subComponents/SeekerProfile';
import EditProfile from './profile/subComponents/EditProfile';
import Blog from './settings/blogs/Blog';
// ... rest of component imports
import '../styles/Sidebar.css';

const SidebarItem = ({ to, icon, text }) => (
  <li>
    <NavLink 
      to={to} 
      className={({ isActive }) => isActive ? 'active' : ''} 
      data-tooltip={text}
    >
      <div className="sidebar-icon">
        {icon}
      </div>
      <span className="sidebar-text">
        {text}
      </span>
    </NavLink>
  </li>
);

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      systemInfo: props.systemInfo,
      systemInfoIsEmpty: props.systemInfoIsEmpty
    };
  }

  onLogOut = () => {
    try {
        const { navigate } = this.props;
        if (navigate) {
            this.props.userLogOut(navigate);
        } else {
            console.error('Navigate function not available');
        }
    } catch (error) {
        console.error('Logout error:', error);
    }
  };

  ontoggleMenu = () => {
    this.props.toggleMenu();
  };

  routeAccessLevel() {
    const { profile } = this.props;

    if (!(_.isEmpty(profile))) {
        const { role } = profile;

        if (role === 'admin' || role === "superAdmin") {
            return (
                <Routes>
                    <Route exact path="/" element={<Home/>} />
                    <Route exact path="/profile" element={<Profile/>} />
                    <Route exact path="/edit-profile" element={<EditProfile/>} />
                    <Route exact path="/shortlist" element={<Shortlist/>} />
                    <Route exact path="/posts" element={<Posts/>} />
                    <Route exact path="/download" element={<Download/>} />
                    <Route exact path="/settings" element={<Settings/>} />
                    <Route exact path="/post-review" element={<PostReview/>} />
                    <Route exact path="/perform-interview" element={<IASInterview/>} />
                    <Route exact path="/sign-in" element={<SignIn/>} />
                    <Route exact path="/sign-up" element={<SignUp/>} />
                    <Route exact path="/notifications" element={<Notifications/>} />
                    <Route exact path="/search" element={<Search/>} />
                    <Route exact path="/analytics" element={<Analytics/>} />
                    <Route exact path="/reports" element={<Reports/>} />
                    <Route exact path="/support" element={<Support/>} />
                    <Route path="/seeker-profile" element={<SeekerProfile />} />
                    <Route path="/blog/:id" element={<Blog />} />
                </Routes>
            );
        }
    }

    return (
        <div style={{ 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            margin: "40vh" 
        }}>
            <Spinner/>
        </div>
    );
  }

  renderSidebar() {
    const { isToggled, profile, profileLoading } = this.props;
    
    if (profileLoading || !profile) {
        return null;
    }

    return (
      <div className={`sidebar ${isToggled ? 'adjustedSidebar' : ''}`}>
        <div className="sidebar-header">
          <img src={Logo} alt="Logo" />
          <button 
            className="toggle-button"
            onClick={() => {
              this.props.toggleMenu();
            }}
            aria-label="Toggle Sidebar"
          >
            {isToggled ? 
              <AlignLeftIcon size={16} /> : 
              <AlignLeftIcon size={16} style={{transform: 'rotate(180deg)'}} />
            }
          </button>
        </div>

        <div className="sidebar-sections">
          <div className="sidebar-section">
            <div className="section-title">Main</div>
            <ul className="nav-items">
              <li className="nav-item">
                <NavLink to="/" className="nav-link" data-tooltip="Home">
                  <Home01Icon className="nav-icon" />
                  <span className="nav-text">Home</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/search" className="nav-link" data-tooltip="Search">
                  <SearchVisualIcon className="nav-icon" />
                  <span className="nav-text">Search</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/analytics" className="nav-link" data-tooltip="Analytics">
                  <AnalyticsUpIcon className="nav-icon" />
                  <span className="nav-text">Analytics</span>
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="sidebar-section">
            <div className="section-title">Workspace</div>
            <ul className="nav-items">
              <li className="nav-item">
                <NavLink to="/posts" className="nav-link" data-tooltip="Posts">
                  <LicenseDraftIcon className="nav-icon" />
                  <span className="nav-text">Posts</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/shortlist" className="nav-link" data-tooltip="Shortlist">
                  <PackageAddIcon className="nav-icon" />
                  <span className="nav-text">Shortlist</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/reports" className="nav-link" data-tooltip="Reports">
                  <Folder02Icon className="nav-icon" />
                  <span className="nav-text">Reports</span>
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="sidebar-section">
            <div className="section-title">Account</div>
            <ul className="nav-items">
              <li className="nav-item">
                <NavLink to="/profile" className="nav-link" data-tooltip="Profile">
                  <UserSharingIcon className="nav-icon" />
                  <span className="nav-text">Profile</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/notifications" className="nav-link" data-tooltip="Notifications">
                  <NotificationCircleIcon className="nav-icon" />
                  <span className="nav-text">Notifications</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/settings" className="nav-link" data-tooltip="Settings">
                  <AccountSetting03Icon className="nav-icon" />
                  <span className="nav-text">Settings</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/support" className="nav-link" data-tooltip="Support">
                  <CustomerService02Icon className="nav-icon" />
                  <span className="nav-text">Support</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        <div className="sidebar-footer">
          <button 
            className="logout-button" 
            onClick={() => {
              const {navigate} = this.props;
              if (!navigate) {
                console.error('Navigate is not available:', navigate);
                return;
              }
              this.props.userLogOut(navigate);
            }}
          >
            <Logout03Icon className="nav-icon" />
            <span className="nav-text">Sign Out</span>
          </button>
        </div>
      </div>
    );
  }

  renderProfile() {
    const { profile, profileLoading } = this.props;

    if (profileLoading) {
        return <Spinner/>;
    }

    if (!(_.isEmpty(profile))) {
        const name = `${profile.firstName} ${profile.lastName}`;
        switch (profile.role) {
            case "admin":
                return <h4 style={{fontFamily: "ramblaBold"}}>{name} <span style={{fontFamily: "ramblaRegular" }}>| Admin</span></h4>;
            case "superAdmin":
                return <h4 style={{fontFamily: "ramblaBold"}}>{name} <span style={{fontFamily: "ramblaRegular" }}>| Super Admin</span></h4>;
            default:
                return <h4>Admin</h4>;
        }
    } else {
        return <Spinner/>;
    }
  }

  renderAccountStatus() {
    const { profile, profileLoading } = this.props;
    
    if (profileLoading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "40vh" }}>
                <Spinner/>
            </div>
        );
    }

    if (!(_.isEmpty(profile))) {
        if ("disabled" in profile) {
            const disabled = profile.disabled;
            if (disabled) {
                return (
                    <div className="bodyContainer">
                        <div style={{
                            backgroundColor: '#cd6d6d',
                            height: '10vh',
                            marginTop: "3vh",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            paddingTop: "0.5rem",
                        }}>
                            <p style={{color: '#ffffff'}}>Dear customer, Sorry your licence has been disabled. Thank you for choosing Nafasi.</p>
                        </div>
                        <div className="content">
                            <p style={{
                                color: "#1f2937", 
                                textAlign: "center", 
                                marginTop: "5rem", 
                                fontFamily: "ramblaBold", 
                                fontSize: "1.5rem" 
                            }}>
                                Account Disabled
                            </p>
                        </div>
                    </div>
                );
            } else {
                if ('active' in profile) {
                    const active = profile.active;
                    if (active) {
                        return (
                            <div>
                                <VerifyEmail/>
                                <VerifyUser/>
                                {this.routeAccessLevel()}
                            </div>
                        );
                    } else {
                        return (
                            <div className="bodyContainer">
                                <Routes className="bodyContainer">
                                    <Route exact path="/" element={<Subscriptions/>} />
                                    <Route exact path="/subscription" element={<SubscriptionInvoice/>} />
                                    <Route exact path="/legal" element={<AddTermsAndConditions/>} />
                                    <Route exact path="/_termsAndConditions" element={<ViewTermsAndConditions/>} />
                                    <Route exact path="/invoice" element={<SubscriptionInvoice/>} />
                                    <Route exact path="/print-invoice" element={<PrintInvoice/>} />
                                    <Route exact path="/add-print-invoice" element={<AddPrintInvoice/>} />
                                    <Route exact path="/change-package" element={<ChoosePackage/>} />
                                    <Route exact path="/reference/:id" element={<Reference/>} />
                                    <Route exact path="/blog/:id" element={<Blog/>} />
                                </Routes>
                            </div>
                        );
                    }
                } else {
                    return (
                        <div className="content">
                            <p style={{
                                color: "#1f2937", 
                                textAlign: "center", 
                                marginTop: "5rem", 
                                fontFamily: "ramblaBold", 
                                fontSize: "1.5rem" 
                            }}>
                                Profile is not active
                            </p>
                        </div>
                    );
                }
            }
        } else {
            return (
                <div className="content">
                    <p style={{
                        color: "#1f2937", 
                        textAlign: "center", 
                        marginTop: "5rem", 
                        fontFamily: "ramblaBold", 
                        fontSize: "1.5rem" 
                    }}>
                        Kindly contact customer support to enable account
                    </p>
                </div>
            );
        }
    } else {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "40vh" }}>
                <Spinner/>
            </div>
        );
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profile !== this.props.profile) {

    }
    if (prevProps.isToggled !== this.props.isToggled) {

    }
  }

  handleSearchClick = () => {
    this.props.navigate('/search');
  };

  render() {
    const { isToggled, profile } = this.props;
    
    return (
        <div className="main-container">
            <div className="content-container">
                {this.renderSidebar()}
                
                <div className={`content-area ${isToggled ? 'adjusted' : ''}`}>
                    <div className="top-bar">
                        <div className="top-bar__left">
                            <div className="top-bar__breadcrumb">
                                <img src={taglineLogo} alt="Logo"/>
                            </div>
                        </div>

                        <div className="top-bar__center">
                            <div className="top-bar__search" onClick={this.handleSearchClick}>
                                <SearchVisualIcon className="top-bar__search-icon" />
                                <input 
                                    type="text" 
                                    placeholder="Search Nafasi..." 
                                    className="top-bar__search-input"
                                    readOnly
                                />
                            </div>
                        </div>

                        <div className="top-bar__right">
                            <div className="top-bar__actions">
                                <NavLink to="/notifications" className="top-bar__action-btn">
                                    <NotificationCircleIcon className="top-bar__icon" />
                                    <span className="top-bar__notification-badge">
                                        {Object.keys(this.props.notifications).length}
                                    </span>
                                </NavLink>
                                
                                <div 
                                    className="top-bar__profile"
                                    onClick={() => this.props.navigate('/profile')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {this.renderProfile()}
                                    <div className="top-bar__status">
                                        <FontAwesomeIcon icon="check-circle" className="top-bar__status-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="main-content">
                        {this.renderAccountStatus()}
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.profile,
    profileLoading: state.profile.loading,
    systemInfo: state.system.systemInfo,
    systemInfoIsEmpty: state.system.systemInfoIsEmpty,
    isToggled: state.home.isToggled,
    notifications: state.notifications.notifications
  };
};

// Wrapper functional component to use hooks
function MainWithNavigate(props) {
  const navigate = useNavigate();
  return <Main {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, {
  userLogOut,
  homeFieldChanged,
  fetchSystemInfo,
  toggleMenu
})(MainWithNavigate);

    