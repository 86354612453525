import React from "react";
import moment from "moment";
import { FaMapMarkerAlt, FaBuilding } from 'react-icons/fa';
import { Calendar01Icon } from "hugeicons-react";
import './PostCard.css';

class PostCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            post: this.props.post
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                post: nextProps.post
            });
        }
    }

    getStatusInfo() {
        const { datePosted, deadline } = this.state.post;
        const startSeconds = datePosted.seconds || datePosted._seconds;
        const endSeconds = deadline.seconds || deadline._seconds;
        const start = moment.unix(startSeconds);
        const end = moment.unix(endSeconds);
        const today = moment();

        if(start.isAfter(today)) {
            return { label: 'Upcoming', className: 'status-upcoming' };
        }
        if(today.isSameOrAfter(start) && today.isSameOrBefore(end)) {
            return { label: 'Active', className: 'status-active' };
        }
        return { label: 'Closed', className: 'status-closed' };
    }

    render() {
        const { post } = this.state;
        const deadlineDate = moment.unix(post.deadline.seconds || post.deadline._seconds).format("MMM D, YYYY");
        const status = this.getStatusInfo();

        return (
            <div className="post-card">
                <div className="post-card-content">
                    <div className="post-card-header">
                        <h3 className="post-title">{post.postTitle}</h3>
                        <span className={`status-badge ${status.className}`}>
                            {status.label}
                        </span>
                    </div>

                    <div className="post-company">
                        <FaBuilding className="company-icon" />
                        <span className="company-name">{post.companyName}</span>
                    </div>

                    <div className="post-meta">
                        <div className="meta-item">
                            <FaMapMarkerAlt className="meta-icon" />
                            <span>{post.companyLocation.country}, {post.companyLocation.region}</span>
                        </div>
                        <div className="meta-item">
                            <Calendar01Icon className="meta-icon" />
                            <span>Deadline: {deadlineDate}</span>
                        </div>
                    </div>
                </div>
                
                <div className="post-card-footer">
                    <button className="apply-button">View Details</button>
                </div>
            </div>
        );
    }
}

export default PostCard;