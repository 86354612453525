import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Education.css';

// Import the action
import { fetchSeekerPath } from '../../../actions/PostsActions';

//components import
import { Spinner } from "../../common/Spinner";
import EducationCard from "./cards/EducationCard";

class Education extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCandidate: this.props.selectedCandidate,
            education: this.props.education,
            educationLoading: this.props.educationLoading,
            profile: this.props.profile,
        }

        this.renderEducation = this.renderEducation.bind(this);
    }

    componentDidMount() {
        const { selectedCandidate } = this.props;
        console.log('Component mounted with candidate:', selectedCandidate);
        if (selectedCandidate && selectedCandidate.id) {
            this.props.fetchSeekerPath(selectedCandidate.id, "education");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedCandidate !== this.props.selectedCandidate) {
            console.log('Selected candidate changed:', this.props.selectedCandidate);
            if (this.props.selectedCandidate && this.props.selectedCandidate.id) {
                this.props.fetchSeekerPath(this.props.selectedCandidate.id, "education");
            }
        }

        if (prevProps !== this.props) {
            this.setState({
                selectedCandidate: this.props.selectedCandidate,
                education: this.props.education,
                educationLoading: this.props.educationLoading,
                profile: this.props.profile,
            });
        }
    }

    renderEducation() {
        const { education, educationLoading, selectedCandidate } = this.state;

        console.log('Education Data:', education);
        console.log('Loading:', educationLoading);
        console.log('Selected Candidate:', selectedCandidate);

        if (educationLoading) {
            return <Spinner />
        }

        if (_.isEmpty(selectedCandidate)) {
            return <p style={{ color: "#000", margin: "2rem 0" }}>Select a candidate to view</p>;
        }

        if (!education || education.length === 0) {
            return <p>No education records found</p>;
        }

        return (
            <div>
                <EducationCard education={education}/>
            </div>
        );
    }

    checkUser() {
        const { profile } = this.state;

        if(_.isEmpty(profile)) {
            return <h2 style={{ color: "#000" }}>Sign in to view</h2>;
        }

        if(profile.role !== "admin" && profile.role !== "superAdmin") {
            return <h3>Un-Authorized Access</h3>;
        }

        return (
            <div className="section-container">
                <div className="section-header">
                    <h4>
                        <FontAwesomeIcon icon="graduation-cap" className="section-icon" />
                        Education
                    </h4>
                </div>
                <div className="section-content">
                    {this.renderEducation()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="education-container">
                {this.checkUser()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedCandidate, education, educationLoading } = state.posts;
    const { profile } = state.profile;

    return {
        selectedCandidate,
        education,
        educationLoading,
        profile
    }
}

export default connect(mapStateToProps, { fetchSeekerPath })(Education);