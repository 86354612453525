import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import { Space, Card } from 'antd';
import { AccountSetting01Icon } from "hugeicons-react";
import './Payments.css';

//common imports
import {Spinner} from "../../common/Spinner";

//components import
import PaymentCard from "./cards/PaymentCard";

class Payments extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            payments: this.props.payments,
            paymentsLoading: this.props.paymentsLoading,
            profile: this.props.profile,
        }

        this.renderPayments = this.renderPayments.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                payments: nextProps.payments,
                paymentsLoading: nextProps.paymentsLoading,
                profile: nextProps.profile,
            })
        }
    }

    renderPayments() {
        const {payments, paymentsLoading} = this.state;

        if (paymentsLoading) {
            return <Spinner/>
        } else {
            return <PaymentCard payments={payments}/>;
        }
    }

    checkUser() {
        const { profile } = this.state;

        if(!(_.isEmpty(profile))) {
            if((profile.role === "admin") || profile.role === "superAdmin") {
                return (
                    <div className="nafasi-payments">
                        <Space direction="vertical" style={{ width: '100%' }} size="middle">
                            <Card size="small" className="nafasi-payments__header-card">
                                <div className="nafasi-payments__header">
                                    <AccountSetting01Icon size={24} className="nafasi-payments__icon" />
                                    <h3 className="nafasi-payments__title">Payments</h3>
                                </div>
                            </Card>
                            
                            <Card size="small" className="nafasi-payments__content">
                                {this.renderPayments()}
                            </Card>
                        </Space>
                    </div>
                );
            }
        } else {
            return (
                <div className="nafasi-payments__unauthorized">
                    <h2>Sign in to view</h2>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="nafasi-payments__container">
                {this.checkUser()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const { payments, paymentsLoading } = state.subscription;
    const { profile } = state.profile;

    return{
        payments, paymentsLoading,
        profile
    }
}

export default connect(mapStateToProps, {})(Payments);