import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Skills.css";

class SkillsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            skills: this.props.skills,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                skills: nextProps.skills,
            });
        }
    }

    getSkillLevel(level) {
        const levels = {
            'beginner': { label: 'Beginner', color: '#FEF3C7', textColor: '#92400E' },
            'intermediate': { label: 'Intermediate', color: '#E0F2FE', textColor: '#075985' },
            'expert': { label: 'Expert', color: '#DCFCE7', textColor: '#166534' },
            'advanced': { label: 'Advanced', color: '#F3E8FF', textColor: '#6B21A8' }
        };
        return levels[level?.toLowerCase()] || { label: level, color: '#F3F4F6', textColor: '#374151' };
    }

    groupSkillsByCategory(skills) {
        const grouped = {
            technical: [],
            soft: []
        };

        skills.forEach(skill => {
            if (skill.category === 'technicalSkill') {
                grouped.technical.push(skill);
            } else {
                grouped.soft.push(skill);
            }
        });

        return grouped;
    }

    renderSkillGroup(skills, title, icon) {
        if (!skills || skills.length === 0) return null;

        return (
            <div className="skill-group">
                <div className="skill-group-header">
                    {/* <FontAwesomeIcon icon={icon} className="group-icon" /> */}
                    <h3>{title} <span className="group-count">({skills.length})</span></h3>
                </div>
                <div className="skills-grid">
                    {skills.map((skill, index) => (
                        <div key={index} className="skill-card">
                            <div className="skill-header">
                                {/* <div className="skill-icon">
                                    <FontAwesomeIcon icon={skill.category === 'technicalSkill' ? 'code' : 'user'} />
                                </div> */}
                                <div className="skill-info">
                                    <h4>{skill.title || skill.skill}</h4>
                                    {skill.yearsOfExperience && (
                                        <span className="experience-years">
                                            {skill.yearsOfExperience} {skill.yearsOfExperience === 1 ? 'year' : 'years'} experience
                                        </span>
                                    )}
                                </div>
                            </div>
                            {/* {skill.level && (
                                <div 
                                    className="skill-level"
                                    style={{ 
                                        backgroundColor: this.getSkillLevel(skill.level).color,
                                        color: this.getSkillLevel(skill.level).textColor
                                    }}
                                >
                                    {this.getSkillLevel(skill.level).label}
                                </div>
                            )}
                            {skill.description && (
                                <p className="skill-description">{skill.description}</p>
                            )} */}
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    renderSkills() {
        const { skills } = this.state;

        if (!skills || skills.length === 0) {
            return (
                <div className="no-data-message">
                    <FontAwesomeIcon icon="lightbulb" className="no-data-icon" />
                    <p>No skills listed yet</p>
                </div>
            );
        }

        const groupedSkills = this.groupSkillsByCategory(skills);

        return (
            <div className="skills-container">
                {this.renderSkillGroup(groupedSkills.technical, 'Technical Skills', 'code')}
                {this.renderSkillGroup(groupedSkills.soft, 'Soft Skills', 'user')}
            </div>
        );
    }

    render() {
        return (
            <div className="skills-timeline">
                {this.renderSkills()}
            </div>
        );
    }
}

export default SkillsCard;