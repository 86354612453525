import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import arraySort from "array-sort";
import { 
    Button, 
    Input, 
    Select, 
    Upload, 
    Table, 
    Tag, 
    Modal, 
    Form, 
    message, 
    Tabs,
    Timeline,
    Drawer,
    Badge
} from 'antd';
import { 
    PlusOutlined, 
    InboxOutlined, 
    FilterOutlined,
    SortAscendingOutlined,
    ClockCircleOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    MessageOutlined,
    CustomerServiceOutlined
} from '@ant-design/icons';
import firebase from 'firebase';
import moment from 'moment';

import './Support.css';

const { TextArea } = Input;
const { Option } = Select;
const { Dragger } = Upload;
const { TabPane } = Tabs;

class Support extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: {},
            tickets: [],
            loading: false,
            createModalVisible: false,
            responseDrawerVisible: false,
            selectedTicket: null,
            fileList: [],
            responseFileList: [],
            filters: {
                status: 'all',
                priority: 'all',
                date: 'all'
            },
            sortBy: 'date',
            sortOrder: 'desc'
        };
    }

    componentDidMount() {
        this.checkUserProfile();
    }

    checkUserProfile() {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                firebase.firestore().collection("adminBucket").doc(user.uid)
                    .onSnapshot((doc) => {
                        if (doc.exists) {
                            const profile = doc.data();
                            this.setState({ profile }, () => {
                                this.fetchTickets();
                            });
                        }
                    }, (error) => {
                        console.error("Error fetching user profile:", error);
                        message.error("Error fetching user profile");
                    });
            }
        });
    }

    fetchTickets = () => {
        const { profile } = this.state;
        this.setState({ loading: true });

        let query = firebase.firestore().collection("supportTickets");

        // Filter based on role
        if (profile.role === 'admin') {
            query = query.where('createdBy', '==', firebase.auth().currentUser.uid);
        }

        query.onSnapshot((snapshot) => {
            const tickets = [];
            snapshot.forEach((doc) => {
                tickets.push({ id: doc.id, ...doc.data() });
            });
            this.setState({ tickets, loading: false });
        }, (error) => {
            console.error("Error fetching tickets:", error);
            message.error("Error fetching tickets");
            this.setState({ loading: false });
        });
    }

    handleCreateTicket = async (values) => {
        const { fileList } = this.state;
        this.setState({ loading: true });

        try {
            const user = firebase.auth().currentUser;
            const ticketData = {
                title: values.title,
                description: values.description,
                priority: values.priority,
                status: 'open',
                createdBy: user.uid,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                attachments: [],
                responses: [],
                audit: [{
                    action: 'created',
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    by: user.uid
                }]
            };

            // Upload attachments if any
            if (fileList.length > 0) {
                const attachments = await Promise.all(
                    fileList.map(async (file) => {
                        const ref = firebase.storage().ref(`support-tickets/${user.uid}/${file.name}`);
                        await ref.put(file.originFileObj);
                        const url = await ref.getDownloadURL();
                        return { name: file.name, url };
                    })
                );
                ticketData.attachments = attachments;
            }

            await firebase.firestore().collection("supportTickets").add(ticketData);
            message.success("Ticket created successfully");
            this.setState({ createModalVisible: false, fileList: [] });
        } catch (error) {
            console.error("Error creating ticket:", error);
            message.error("Failed to create ticket");
        } finally {
            this.setState({ loading: false });
        }
    }

    handleTicketResponse = async (values) => {
        const { selectedTicket, responseFileList } = this.state;
        this.setState({ loading: true });

        try {
            const user = firebase.auth().currentUser;
            const response = {
                comment: values.comment,
                status: values.status,
                attachments: [],
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                createdBy: user.uid
            };

            // Upload response attachments if any
            if (responseFileList.length > 0) {
                const attachments = await Promise.all(
                    responseFileList.map(async (file) => {
                        const ref = firebase.storage().ref(`support-tickets/${selectedTicket.id}/responses/${file.name}`);
                        await ref.put(file.originFileObj);
                        const url = await ref.getDownloadURL();
                        return { name: file.name, url };
                    })
                );
                response.attachments = attachments;
            }

            const ticketRef = firebase.firestore().collection("supportTickets").doc(selectedTicket.id);
            await ticketRef.update({
                status: values.status,
                responses: firebase.firestore.FieldValue.arrayUnion(response),
                audit: firebase.firestore.FieldValue.arrayUnion({
                    action: 'responded',
                    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    by: user.uid,
                    details: `Status updated to ${values.status}`
                })
            });

            // Send notification to admin
            if (selectedTicket.createdBy !== user.uid) {
                await firebase.firestore().collection("notifications").add({
                    userId: selectedTicket.createdBy,
                    type: 'ticket_update',
                    ticketId: selectedTicket.id,
                    message: `Your ticket "${selectedTicket.title}" has been updated`,
                    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                    read: false
                });
            }

            message.success("Response added successfully");
            this.setState({ responseDrawerVisible: false, responseFileList: [] });
        } catch (error) {
            console.error("Error responding to ticket:", error);
            message.error("Failed to respond to ticket");
        } finally {
            this.setState({ loading: false });
        }
    }

    getFilteredAndSortedTickets = () => {
        const { tickets, filters, sortBy, sortOrder } = this.state;
        
        let filteredTickets = tickets.filter(ticket => {
            if (filters.status !== 'all' && ticket.status !== filters.status) return false;
            if (filters.priority !== 'all' && ticket.priority !== filters.priority) return false;
            if (filters.date !== 'all') {
                const ticketDate = moment(ticket.createdAt.toDate());
                const today = moment();
                switch (filters.date) {
                    case 'today':
                        return ticketDate.isSame(today, 'day');
                    case 'week':
                        return ticketDate.isAfter(today.subtract(7, 'days'));
                    case 'month':
                        return ticketDate.isAfter(today.subtract(30, 'days'));
                    default:
                        return true;
                }
            }
            return true;
        });

        return arraySort(filteredTickets, [ticket => {
            switch (sortBy) {
                case 'priority':
                    return ['low', 'medium', 'high'].indexOf(ticket.priority);
                case 'status':
                    return ['closed', 'in_progress', 'open'].indexOf(ticket.status);
                default:
                    return ticket.createdAt.toDate();
            }
        }], { reverse: sortOrder === 'desc' });
    }

    renderTicketList() {
        const { loading, profile } = this.state;
        const tickets = this.getFilteredAndSortedTickets();

        const columns = [
            {
                title: 'Title',
                dataIndex: 'title',
                key: 'title',
                render: (text, record) => (
                    <a onClick={() => this.setState({ selectedTicket: record, responseDrawerVisible: true })}>
                        {text}
                    </a>
                )
            },
            {
                title: 'Priority',
                dataIndex: 'priority',
                key: 'priority',
                render: priority => (
                    <Tag color={
                        priority === 'high' ? 'red' : 
                        priority === 'medium' ? 'orange' : 
                        'green'
                    }>
                        {priority.toUpperCase()}
                    </Tag>
                )
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: status => (
                    <Tag color={
                        status === 'open' ? 'blue' : 
                        status === 'in_progress' ? 'orange' : 
                        'green'
                    }>
                        {status.replace('_', ' ').toUpperCase()}
                    </Tag>
                )
            },
            {
                title: 'Created',
                dataIndex: 'createdAt',
                key: 'createdAt',
                render: date => moment(date.toDate()).format('MMM DD, YYYY')
            },
            {
                title: 'Last Update',
                key: 'lastUpdate',
                render: record => {
                    const lastResponse = record.responses[record.responses.length - 1];
                    return lastResponse ? 
                        moment(lastResponse.createdAt.toDate()).format('MMM DD, YYYY') : 
                        'No updates';
                }
            }
        ];

            return (
            <>
                <div className="tickets-header">
                    <div className="filters">
                        <Select
                            defaultValue="all"
                            style={{ width: 120 }}
                            onChange={value => this.setState({ filters: { ...this.state.filters, status: value }})}>
                            <Option value="all">All Status</Option>
                            <Option value="open">Open</Option>
                            <Option value="in_progress">In Progress</Option>
                            <Option value="closed">Closed</Option>
                        </Select>
                        <Select
                            defaultValue="all"
                            style={{ width: 120 }}
                            onChange={value => this.setState({ filters: { ...this.state.filters, priority: value }})}>
                            <Option value="all">All Priority</Option>
                            <Option value="low">Low</Option>
                            <Option value="medium">Medium</Option>
                            <Option value="high">High</Option>
                        </Select>
                        <Select
                            defaultValue="all"
                            style={{ width: 120 }}
                            onChange={value => this.setState({ filters: { ...this.state.filters, date: value }})}>
                            <Option value="all">All Time</Option>
                            <Option value="today">Today</Option>
                            <Option value="week">This Week</Option>
                            <Option value="month">This Month</Option>
                        </Select>
                    </div>
                    {profile.role === 'admin' && (
                        <Button 
                            type="primary" 
                            icon={<PlusOutlined />}
                            onClick={() => this.setState({ createModalVisible: true })}>
                            Create Ticket
                        </Button>
                    )}
                </div>

                <Table 
                    columns={columns}
                    dataSource={tickets}
                    loading={loading}
                    rowKey="id"
                />

                <Modal
                    title="Create Support Ticket"
                    visible={this.state.createModalVisible}
                    onCancel={() => this.setState({ createModalVisible: false })}
                    footer={null}
                    width={700}>
                    <Form onFinish={this.handleCreateTicket} layout="vertical">
                        <Form.Item
                            name="title"
                            label="Title"
                            rules={[{ required: true, message: 'Please enter a title' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            label="Description"
                            rules={[{ required: true, message: 'Please enter a description' }]}>
                            <TextArea rows={4} />
                        </Form.Item>
                        <Form.Item
                            name="priority"
                            label="Priority"
                            rules={[{ required: true, message: 'Please select priority' }]}>
                            <Select>
                                <Option value="low">Low</Option>
                                <Option value="medium">Medium</Option>
                                <Option value="high">High</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="Attachments">
                            <Dragger
                                multiple
                                fileList={this.state.fileList}
                                onChange={({ fileList }) => this.setState({ fileList })}
                                beforeUpload={() => false}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag files to attach</p>
                            </Dragger>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={this.state.loading}>
                                Create Ticket
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

                <Drawer
                    title="Ticket Details"
                    placement="right"
                    width={700}
                    visible={this.state.responseDrawerVisible}
                    onClose={() => this.setState({ responseDrawerVisible: false })}
                    destroyOnClose>
                    {this.state.selectedTicket && (
                        <div className="ticket-details">
                            <div className="ticket-header">
                                <h2>{this.state.selectedTicket.title}</h2>
                                <div className="ticket-meta">
                                    <Tag color={
                                        this.state.selectedTicket.priority === 'high' ? 'red' : 
                                        this.state.selectedTicket.priority === 'medium' ? 'orange' : 
                                        'green'
                                    }>
                                        {this.state.selectedTicket.priority.toUpperCase()}
                                    </Tag>
                                    <Tag color={
                                        this.state.selectedTicket.status === 'open' ? 'blue' : 
                                        this.state.selectedTicket.status === 'in_progress' ? 'orange' : 
                                        'green'
                                    }>
                                        {this.state.selectedTicket.status.replace('_', ' ').toUpperCase()}
                                    </Tag>
                                </div>
                            </div>

                            <Tabs defaultActiveKey="discussion">
                                <TabPane 
                                    tab={<span><MessageOutlined />Discussion</span>}
                                    key="discussion">
                                    <div className="ticket-description">
                                        <h4>Description</h4>
                                        <p>{this.state.selectedTicket.description}</p>
                                        {this.state.selectedTicket.attachments.length > 0 && (
                                            <div className="attachments">
                                                <h4>Attachments</h4>
                                                {this.state.selectedTicket.attachments.map((file, index) => (
                                                    <a 
                                                        key={index} 
                                                        href={file.url} 
                                                        target="_blank" 
                                                        rel="noopener noreferrer">
                                                        {file.name}
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                    </div>

                                    <div className="responses">
                                        {this.state.selectedTicket.responses.map((response, index) => (
                                            <div key={index} className="response">
                                                <div className="response-header">
                                                    <span className="response-meta">
                                                        {moment(response.createdAt.toDate()).format('MMM DD, YYYY HH:mm')}
                                                    </span>
                                                    <Tag color={
                                                        response.status === 'open' ? 'blue' : 
                                                        response.status === 'in_progress' ? 'orange' : 
                                                        'green'
                                                    }>
                                                        {response.status.replace('_', ' ').toUpperCase()}
                                                    </Tag>
                                                </div>
                                                <p>{response.comment}</p>
                                                {response.attachments.length > 0 && (
                                                    <div className="attachments">
                                                        {response.attachments.map((file, fileIndex) => (
                                                            <a 
                                                                key={fileIndex} 
                                                                href={file.url} 
                                                                target="_blank" 
                                                                rel="noopener noreferrer">
                                                                {file.name}
                                                            </a>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>

                                    {(profile.role === 'superAdmin' || 
                                        this.state.selectedTicket.status !== 'closed') && (
                                        <Form onFinish={this.handleTicketResponse} layout="vertical">
                                            <Form.Item
                                                name="comment"
                                                rules={[{ required: true, message: 'Please enter a comment' }]}>
                                                <TextArea rows={4} placeholder="Add a response..." />
                                            </Form.Item>
                                            <Form.Item
                                                name="status"
                                                rules={[{ required: true, message: 'Please select status' }]}>
                                                <Select placeholder="Update status">
                                                    <Option value="open">Open</Option>
                                                    <Option value="in_progress">In Progress</Option>
                                                    <Option value="closed">Closed</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item>
                                                <Dragger
                                                    multiple
                                                    fileList={this.state.responseFileList}
                                                    onChange={({ fileList }) => this.setState({ responseFileList: fileList })}
                                                    beforeUpload={() => false}>
                                                    <p className="ant-upload-drag-icon">
                                                        <InboxOutlined />
                                                    </p>
                                                    <p className="ant-upload-text">Click or drag files to attach</p>
                                                </Dragger>
                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" loading={this.state.loading}>
                                                    Submit Response
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    )}
                                </TabPane>

                                <TabPane 
                                    tab={<span><ClockCircleOutlined />Audit Trail</span>}
                                    key="audit">
                                    <Timeline>
                                        {this.state.selectedTicket.audit.map((entry, index) => (
                                            <Timeline.Item 
                                                key={index}
                                                dot={
                                                    entry.action === 'created' ? <PlusOutlined /> :
                                                    entry.action === 'responded' ? <MessageOutlined /> :
                                                    <CheckCircleOutlined />
                                                }>
                                                <p>
                                                    <strong>{entry.action.toUpperCase()}</strong>
                                                    {entry.details && ` - ${entry.details}`}
                                                </p>
                                                <small>
                                                    {moment(entry.timestamp.toDate()).format('MMM DD, YYYY HH:mm')}
                                                </small>
                                            </Timeline.Item>
                                        ))}
                                    </Timeline>
                                </TabPane>
                            </Tabs>
                        </div>
                    )}
                </Drawer>
            </>
        );
    }

    render() {
        const { profile } = this.state;

        if(_.isEmpty(profile)) {
            return (
                <div className="unauthorized-container">
                    <h2>Sign in to view support</h2>
                </div>
            );
        }

        if(profile.role !== "admin" && profile.role !== "superAdmin") {
            return (
                <div className="unauthorized-container">
                    <h3>Un-Authorized Access</h3>
                    <p>You need admin privileges to access this section</p>
                </div>
            );
        }

        return (
            <div className="support-container">
                <div className="support-header">
                    <div className="header-content">
                        <CustomerServiceOutlined style={{ fontSize: '2.5rem', color: '#0066cc' }} />
                        <h1>Support Center</h1>
                    </div>
                </div>
                {this.renderTicketList()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps, {})(Support);