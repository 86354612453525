import React from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { searchFieldChanged, fetchLanguages } from '../../../actions/SearchActions';

const { Option } = Select;

class LanguagesFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            languagesSearchQuery: this.props.languagesSearchQuery,
            languages: this.props.languages
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                languagesSearchQuery: nextProps.languagesSearchQuery,
                languages: nextProps.languages
            });
        }
    }

    handleSearch = (value) => {
        this.props.searchFieldChanged({ prop: "languagesSearchQuery", value });
        this.props.fetchLanguages({ searchQuery: value });
    };

    handleSelect = (value) => {
        this.props.searchFieldChanged({ prop: "languagesSearchQuery", value });
        this.props.searchFieldChanged({ prop: "language", value });
    };

    handleClear = () => {
        this.props.searchFieldChanged({ prop: "languagesSearchQuery", value: "" });
        this.props.searchFieldChanged({ prop: "language", value: null });
    }

    render() {
        const { languagesSearchQuery, languages } = this.state;

        return (
            <div className="variable-section">
                <p style={{color: "#000", fontFamily: "ramblaBold", marginBottom: 3, textAlign: "left"}}>Languages</p>
                <Select
                    showSearch
                    value={languagesSearchQuery}
                    placeholder="Search Languages"
                    onSearch={this.handleSearch}
                    onChange={this.handleSelect}
                    onClear={this.handleClear}
                    allowClear
                    style={{width: "100%", borderColor: '#000'}}
                    optionFilterProp="label"
                    filterOption={false}
                >
                    {languages.map((option) => (
                        <Option key={option.objectID} value={option.objectID} label={option.title}>
                            {option.title}
                        </Option>
                    ))}
                </Select>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { languagesSearchQuery, languages } = state.search;
    return {
        languagesSearchQuery, languages
    };
};

const mapDispatchToProps = {
    searchFieldChanged, fetchLanguages,
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesFilter);
