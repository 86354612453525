import React, { useState } from "react";
import { Modal } from "antd";
import { EyeOutlined } from '@ant-design/icons';
import './DescriptionModal.css';

const DescriptionModal = ({ record }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleToggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const renderSection = (label, value, fullWidth = false, isRating = false) => {
        const getRatingClass = (rating) => {
            if (rating >= 4.5) return 'excellent';
            if (rating >= 3.5) return 'good';
            if (rating >= 2.5) return 'neutral';
            if (rating >= 1.5) return 'poor';
            return 'very-poor';
        };

        if (isRating) {
            const ratingClass = getRatingClass(value.index);
            return (
                <div className={`nafasi-feedback-modal__section ${fullWidth ? 'nafasi-feedback-modal__section--full' : ''}`}>
                    <div className="nafasi-feedback-modal__label">{label}</div>
                    <div className={`nafasi-feedback-modal__value--rating ${ratingClass}`}>
                        {value.title}
                    </div>
                </div>
            );
        }
        return (
            <div className={`nafasi-feedback-modal__section ${fullWidth ? 'nafasi-feedback-modal__section--full' : ''}`}>
                <div className="nafasi-feedback-modal__label">{label}</div>
                <div className="nafasi-feedback-modal__value">{value}</div>
            </div>
        );
    };

    const renderConsentSection = () => (
        <div className="nafasi-feedback-modal__section">
            <div className="nafasi-feedback-modal__label">Consent</div>
            <div className={`nafasi-feedback-modal__value--consent ${record.consent ? 'allowed' : 'denied'}`}>
                {record.consent ? "Reach Out" : "Don't Reach Out"}
            </div>
        </div>
    );

    return (
        <div className="nafasi-feedback-modal">
            <button className="nafasi-feedback-modal__trigger" onClick={handleToggleModal}>
                <EyeOutlined />
            </button>

            <Modal
                title="Feedback Details"
                open={isModalVisible}
                onOk={handleToggleModal}
                onCancel={handleToggleModal}
                width={800}
                className="nafasi-feedback-modal__container"
                footer={null}
            >
                <div className="nafasi-feedback-modal__content">
                    {renderConsentSection()}
                    {renderSection('Name', record.name)}
                    {renderSection('Gender', record.gender)}
                    {renderSection('Email', record.email)}
                    {renderSection('Phone', record.phone)}
                    {renderSection('Ease of Use', record.easeOfUse, false, true)}
                    {renderSection('Overall Experience', record.overallExperience, false, true)}
                    {renderSection('Registration Process', record.registrationProcess, false, true)}
                    {renderSection('First Impression', record.firstImpression, true)}
                    {renderSection('Most Liked Feature', record.mostLikedFeature, true)}
                    {renderSection('Least Liked Feature', record.leastLikedFeature, true)}
                    {renderSection('Suggestions', record.suggestions, true)}
                    {renderSection('Additional Feature', record.additionalFeature, true)}
                    {renderSection('Additional Comments', record.additionalComments, true)}
                </div>
            </Modal>
        </div>
    );
};

export default DescriptionModal;