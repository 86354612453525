import React from 'react';
import { Card, Col, Row, Progress } from 'antd';
import { Spinner } from '../../common/Spinner';
import "./cards/JobStatsCard.css";

const GeneralTab = ({
  totalJobPosts,
  postsPerPosition,
  offeredInterviews,
  interviewedCandidates,
  fetchingCandidatesCount,
  generalAverageTimeToHire,
  candidatesGender,
  averageResponseTime,
  isFetchingTime
}) => {
    
  const maleValue = candidatesGender.filter(item => item.gender === 'Male').map(item => item.value)[0];
  const femaleValue = candidatesGender.filter(item => item.gender === 'Female').map(item => item.value)[0];
  const percentage = ((interviewedCandidates / offeredInterviews) * 100).toFixed(1);
  const responseDays = averageResponseTime.toFixed(0);

  const cardStyle = {
    borderRadius: '20px',
    border: 'none',
    background: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(20px)',
    boxShadow: '0 4px 24px rgba(0, 0, 0, 0.04)',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    cursor: 'pointer',
    fontFamily: 'ramblaBold',
  };

  const cardHeadStyle = {
    borderBottom: 'none',
    fontSize: '16px',
    color: '#1D1D1F',
    fontFamily: 'ramblaBold',
  };

  const valueStyle = {
    color: '#1D1D1F',
    fontSize: '28px',
    fontWeight: '600',
    fontFamily: 'ramblaBold',
    margin: 0,
  };

  const iconStyle = {
    width: "60px",
    height: "60px",
  };

  const containerStyle = {
    padding: '24px',
    background: 'linear-gradient(145deg, #f5f5f7 0%, #ffffff 100%)',
    borderRadius: '30px',
  };

  return (
    <div style={containerStyle}>
      <Row gutter={[24, 24]}>
        <Col xs={24} sm={12} lg={8}>
          <Card 
            title="Job Posts" 
            bordered={false} 
            style={cardStyle} 
            headStyle={cardHeadStyle}
            hoverable
          >
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <p style={valueStyle}>{totalJobPosts}</p>
              <lord-icon
                src="https://cdn.lordicon.com/wwmtsdzm.json"
                trigger="hover"
                colors="primary:#147CE5,secondary:#147CE5"
                style={iconStyle}>
              </lord-icon>
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={12} lg={8}>
          <Card 
            title="Interviews Offered" 
            bordered={false} 
            style={cardStyle} 
            headStyle={cardHeadStyle}
            hoverable
          >
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              {fetchingCandidatesCount ? (
                <Spinner />
              ) : (
                <p style={valueStyle}>{offeredInterviews}</p>
              )}
              <lord-icon
                src="https://cdn.lordicon.com/kndkiwmf.json"
                trigger="hover"
                colors="primary:#147CE5,secondary:#147CE5"
                style={iconStyle}>
              </lord-icon>
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={12} lg={8}>
          <Card 
            title="Interviewed Candidates" 
            bordered={false} 
            style={cardStyle} 
            headStyle={cardHeadStyle}
            hoverable
          >
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <p style={valueStyle}>{interviewedCandidates}</p>
              {fetchingCandidatesCount ? (
                <Spinner />
              ) : (
                <Progress 
                  type="circle" 
                  size={60} 
                  percent={percentage}
                  strokeColor="#147CE5"
                  strokeWidth={10}
                />
              )}
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={12} lg={8}>
          <Card 
            title="Average Time To Hire" 
            bordered={false} 
            style={cardStyle} 
            headStyle={cardHeadStyle}
            hoverable
          >
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <p style={valueStyle}>{generalAverageTimeToHire}</p>
              <lord-icon
                src="https://cdn.lordicon.com/dndbceuc.json"
                trigger="hover"
                colors="primary:#147CE5,secondary:#147CE5"
                style={iconStyle}>
              </lord-icon>
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={12} lg={8}>
          <Card 
            title="Male Hired" 
            bordered={false} 
            style={cardStyle} 
            headStyle={cardHeadStyle}
            hoverable
          >
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <p style={valueStyle}>{maleValue}</p>
              <lord-icon
                src="https://cdn.lordicon.com/xzalkbkz.json"
                trigger="hover"
                colors="primary:#147CE5,secondary:#147CE5"
                style={iconStyle}>
              </lord-icon>
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={12} lg={8}>
          <Card 
            title="Female Hired" 
            bordered={false} 
            style={cardStyle} 
            headStyle={cardHeadStyle}
            hoverable
          >
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <p style={valueStyle}>{femaleValue}</p>
              <lord-icon
                src="https://cdn.lordicon.com/qitvuzec.json"
                trigger="hover"
                colors="primary:#147CE5,secondary:#147CE5"
                style={iconStyle}>
              </lord-icon>
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={12} lg={8}>
          <Card 
            title="Average Response Time" 
            bordered={false} 
            style={cardStyle} 
            headStyle={cardHeadStyle}
            hoverable
          >
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              {isFetchingTime ? (
                <Spinner />
              ) : (
                <p style={valueStyle}>{responseDays} Days</p>
              )}
              <lord-icon
                src="https://cdn.lordicon.com/ofwxettw.json"
                trigger="hover"
                colors="primary:#147CE5,secondary:#147CE5"
                style={iconStyle}>
              </lord-icon>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default GeneralTab;
