import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { message, Popconfirm } from "antd";
import { NotificationCircleIcon, PropertyDeleteIcon } from 'hugeicons-react';

//action imports
import { deleteNotification } from "../../../actions/NotificationsActions";

import './NotificationCard.css';

class NotificationCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notification: this.props.notification,
            profile: this.props.profile
        }

        this.deleteNotification = this.deleteNotification.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                notification: nextProps.notification,
                profile: nextProps.profile
            });
        }
    }

    deleteNotification(notificationID) {
        const { profile } = this.state;
        if(!(_.isEmpty(profile))) {
            this.props.deleteNotification({ notificationID });
            message.success('Notification deleted successfully');
        }
    }

    onCancel() {
        message.info("Deletion cancelled");
    }

    checkAccess() {
        const { profile, notification } = this.state;

        if(!(_.isEmpty(profile))) {
            const { role } = profile;

            if(role === "admin") {
                return (
                    <Popconfirm
                        title="Delete this notification?"
                        description="This action cannot be undone."
                        onConfirm={() => this.deleteNotification(notification.notificationID)}
                        onCancel={() => this.onCancel()}
                        okText="Delete"
                        cancelText="Cancel"
                    >
                        <button className="notification-card__delete">
                            <PropertyDeleteIcon 
                                size={18}
                                className="notification-card__delete-icon"
                            />
                        </button>
                    </Popconfirm>
                );
            }
        }
        return null;
    }

    render() {
        const { notification } = this.state;

        if (!(_.isEmpty(notification))) {
            let seconds;
            notification.date.seconds ? seconds = notification.date.seconds : seconds = notification.date._seconds;
            const time = moment.unix(seconds);

            return(
                <div className="notification-card">
                    <div className="notification-card__icon-wrapper">
                        <NotificationCircleIcon 
                            size={20} 
                            className="notification-card__icon"
                        />
                    </div>

                    <div className="notification-card__content">
                        <h4 className="notification-card__title">{notification.notificationTitle}</h4>
                        <p className="notification-card__description">{notification.description}</p>
                        <p className="notification-card__date">{time.format("ddd, MMM D, YYYY")}</p>
                    </div>

                    {this.checkAccess()}
                </div>
            );
        }
        return null;
    }
}

const mapStateToProps = state => {
    const { profile } = state.profile;
    return { profile };
};

export default connect(mapStateToProps, { deleteNotification })(NotificationCard);