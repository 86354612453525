import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Documents.css';

// Import the action
import { fetchSeekerPath } from '../../../actions/PostsActions';

//components import
import { Spinner } from "../../common/Spinner";
import PDFViewer from "../../common/PDFViewer";

class SeekerDocuments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCandidate: this.props.selectedCandidate,
            documents: this.props.documents,
            documentsLoading: this.props.documentsLoading,
            profile: this.props.profile,
            selectedCandidateProfile: this.props.selectedCandidateProfile,
            pushedSeeker: this.props.pushedSeeker,
            cvVisible: false,
            coverLetterVisible: false,
            visibleDocuments: new Set()
        }
    }

    componentDidMount() {
        const { selectedCandidate } = this.props;
        if (selectedCandidate && selectedCandidate.id) {
            this.props.fetchSeekerPath(selectedCandidate.id, "documents");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedCandidate !== this.props.selectedCandidate) {
            if (this.props.selectedCandidate && this.props.selectedCandidate.id) {
                this.props.fetchSeekerPath(this.props.selectedCandidate.id, "documents");
            }
        }

        if (prevProps !== this.props) {
            this.setState({
                selectedCandidate: this.props.selectedCandidate,
                documents: this.props.documents,
                documentsLoading: this.props.documentsLoading,
                profile: this.props.profile,
                selectedCandidateProfile: this.props.selectedCandidateProfile,
                pushedSeeker: this.props.pushedSeeker,
            });
        }
    }

    toggleDocumentVisibility = (documentId) => {
        this.setState(prevState => ({
            visibleDocuments: prevState.visibleDocuments.has(documentId)
                ? new Set([...prevState.visibleDocuments].filter(id => id !== documentId))
                : new Set([...prevState.visibleDocuments, documentId])
        }));
    }

    toggleCV = () => {
        this.setState(prevState => ({
            cvVisible: !prevState.cvVisible
        }));
    }

    toggleCoverLetter = () => {
        this.setState(prevState => ({
            coverLetterVisible: !prevState.coverLetterVisible
        }));
    }

    renderDocuments() {
        const { 
            documents, 
            documentsLoading, 
            selectedCandidate, 
            selectedCandidateProfile,
            pushedSeeker,
            cvVisible,
            coverLetterVisible,
            visibleDocuments
        } = this.state;

        if (documentsLoading) {
            return <Spinner />
        }

        if (_.isEmpty(selectedCandidate)) {
            return <p style={{ color: "#000", margin: "2rem 0" }}>Select a candidate to view</p>;
        }

        return (
            <div className="documents-content">
                {/* CV Section */}
                {selectedCandidateProfile?.cv && (
                    <div className="document-section">
                        <div className="document-section-header">
                            <div className="document-icon cv-icon">
                                <FontAwesomeIcon icon="file-user" />
                            </div>
                            <h3>Curriculum Vitae</h3>
                            <button 
                                className="toggle-view-button"
                                onClick={this.toggleCV}
                            >
                                <FontAwesomeIcon icon={cvVisible ? "eye-slash" : "eye"} />
                                <span style={{ fontFamily: "ramblaRegular"}}>{cvVisible ? 'Hide' : 'Show'} CV</span>
                            </button>
                        </div>
                        {cvVisible && (
                            <div className="pdf-container">
                                <PDFViewer pdfUrl={selectedCandidateProfile.cv} />
                            </div>
                        )}
                    </div>
                )}

                {/* Cover Letter Section */}
                {pushedSeeker?.coverLetterURL && (
                    <div className="document-section">
                        <div className="document-section-header">
                            <div className="document-icon cover-letter-icon">
                                <FontAwesomeIcon icon="envelope" />
                            </div>
                            <h3>Cover Letter</h3>
                            <button 
                                className="toggle-view-button"
                                onClick={this.toggleCoverLetter}
                            >
                                <FontAwesomeIcon icon={coverLetterVisible ? "eye-slash" : "eye"} />
                                <span style={{ fontFamily: "ramblaRegular"}}>{coverLetterVisible ? 'Hide' : 'Show'} Cover Letter</span>
                            </button>
                        </div>
                        {coverLetterVisible && (
                            <div className="pdf-container">
                                <PDFViewer pdfUrl={pushedSeeker.coverLetterURL} />
                            </div>
                        )}
                    </div>
                )}

                {/* Other Documents Section */}
                {documents && documents.length > 0 && (
                    <div className="document-section">
                        <div className="document-section-header">
                            <div className="document-icon other-docs-icon">
                                <FontAwesomeIcon icon="folder-open" />
                            </div>
                            <h3>Additional Documents</h3>
                        </div>
                        <div className="additional-documents">
                            {documents.map((doc, index) => (
                                <div key={index} className="additional-document-item">
                                    <div className="document-info">
                                        <h4>{doc.title || doc.name}</h4>
                                        <div className="document-actions">
                                            <button 
                                                className="action-button view-button"
                                                onClick={() => this.toggleDocumentVisibility(index)}
                                            >
                                                <FontAwesomeIcon icon={visibleDocuments.has(index) ? "eye-slash" : "eye"} />
                                                {visibleDocuments.has(index) ? 'Hide' : 'View'}
                                            </button>
                                            <a 
                                                href={doc.url} 
                                                download 
                                                className="action-button download-button"
                                            >
                                                <FontAwesomeIcon icon="download" /> Download
                                            </a>
                                        </div>
                                    </div>
                                    {visibleDocuments.has(index) && (
                                        <div className="pdf-container">
                                            <PDFViewer pdfUrl={doc.url} />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {!selectedCandidateProfile?.cv && !pushedSeeker?.coverLetterURL && (!documents || documents.length === 0) && (
                    <div className="no-data-message">
                        <FontAwesomeIcon icon="file" className="no-data-icon" />
                        <p>No documents uploaded</p>
                    </div>
                )}
            </div>
        );
    }

    checkUser() {
        const { profile, selectedCandidateProfile, pushedSeeker } = this.state;

        if(_.isEmpty(profile)) {
            return <h2 style={{ color: "#000" }}>Sign in to view</h2>;
        }

        if(profile.role !== "admin" && profile.role !== "superAdmin") {
            return <h3>Un-Authorized Access</h3>;
        }

        return (
            <div className="section-container">
                <div className="section-header">
                    <h4>
                        <FontAwesomeIcon icon="folder-open" className="section-icon" />
                        Documents
                    </h4>
                    <div className="section-summary">
                        <span className="total-count">
                            {(
                                (selectedCandidateProfile?.cv ? 1 : 0) + 
                                (pushedSeeker?.coverLetterURL ? 1 : 0) + 
                                (this.state.documents?.length || 0)
                            )} Documents Listed
                        </span>
                    </div>
                </div>
                <div className="section-content">
                    {this.renderDocuments()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="documents-container">
                {this.checkUser()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { 
        selectedCandidate, 
        documents, 
        documentsLoading,
        selectedCandidateProfile,
        pushedSeeker
    } = state.posts;
    const { profile } = state.profile;

    return {
        selectedCandidate,
        documents,
        documentsLoading,
        profile,
        selectedCandidateProfile,
        pushedSeeker
    }
}

export default connect(mapStateToProps, { fetchSeekerPath })(SeekerDocuments);