import React from 'react';
import { connect } from 'react-redux';
import { message, Modal } from "antd";
import { ClipLoader } from "react-spinners";
import { FileTextOutlined, UploadOutlined } from '@ant-design/icons';
import _ from 'lodash';

import { termsAndConditionsChanged, saveTermsAndConditions } from "../../../actions/SettingsActions";
import './AddTermsAndConditions.css';

class AddTermsAndConditions extends React.Component {
    state = {
        visible: false,
        docTitle: this.props.docTitle,
        docFile: this.props.docFile,
        profile: this.props.profile,
        fetchedTermsAndConditions: this.props.fetchedTermsAndConditions,
        fileName: ''
    };

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                profile: this.props.profile,
                docTitle: this.props.docTitle,
                docFile: this.props.docFile,
                termsLoading: this.props.termsLoading,
                termsSuccess: this.props.termsSuccess,
                termsFailed: this.props.termsFailed,
                fetchedTermsAndConditions: this.props.fetchedTermsAndConditions
            });
        }
    }

    handleToggleModal = () => {
        this.setState(prevState => ({
            visible: !prevState.visible
        }));
    };

    handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            this.setState({ fileName: file.name });
            this.props.termsAndConditionsChanged({ prop: 'docFile', value: file });
        }
    };

    handleSave = () => {
        const { docTitle, docFile } = this.state;

        if (docTitle && docFile) {
            this.props.saveTermsAndConditions({ docTitle, docFile });
        } else {
            message.warning({
                content: "Please fill in all required fields",
                className: 'nafasi-legal-add__message'
            });
        }
    };

    renderForm = () => {
        const { termsLoading } = this.state;

        return (
            <div className="nafasi-legal-add__content">
                <h2 className="nafasi-legal-add__title">Add Terms and Conditions</h2>
                
                <div className="nafasi-legal-add__form">
                    <div className="nafasi-legal-add__input-group">
                        <label className="nafasi-legal-add__label">Document Title</label>
                        <input
                            type="text"
                            className="nafasi-legal-add__input"
                            placeholder="Enter document title"
                            value={this.state.docTitle}
                            onChange={e => this.props.termsAndConditionsChanged({
                                prop: 'docTitle',
                                value: e.target.value
                            })}
                        />
                    </div>

                    <div className="nafasi-legal-add__file-input">
                        <label className="nafasi-legal-add__file-label">
                            <input
                                type="file"
                                accept="application/pdf"
                                onChange={this.handleFileChange}
                            />
                            <UploadOutlined />
                            <span style={{ fontFamily: 'ramblaRegular', fontSize: '0.9rem' }}>{this.state.fileName || 'Choose PDF file'}</span>
                        </label>
                    </div>

                    <div className="nafasi-legal-add__actions">
                        <button
                            className={`nafasi-legal-add__button nafasi-legal-add__button--primary ${termsLoading ? 'nafasi-legal-add__button--loading' : ''}`}
                            onClick={this.handleSave}
                            disabled={termsLoading}
                        >
                            {termsLoading ? <ClipLoader size={16} color="#ffffff" /> : 'Save'}
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { profile } = this.state;
        const isSuperAdmin = !_.isEmpty(profile) && profile.role === 'superAdmin';

        if (!isSuperAdmin) {
            return null;
        }

        return (
            <div className="nafasi-legal-add">
                <button className="nafasi-legal-add__trigger" onClick={this.handleToggleModal}>
                    <FileTextOutlined />
                    Add Legal File
                </button>

                <Modal
                    title="Add Terms & Conditions"
                    open={this.state.visible}
                    onCancel={this.handleToggleModal}
                    className="nafasi-legal-add__container"
                    footer={null}
                    width={700}
                >
                    {this.renderForm()}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    docTitle: state.settings.docTitle,
    profile: state.profile.profile,
    termsLoading: state.settings.termsLoading,
    termsSuccess: state.settings.termsSuccess,
    termsFailed: state.settings.termsFailed,
    docFile: state.settings.docFile,
    fetchedTermsAndConditions: state.settings.fetchedTermsAndConditions,
});

export default connect(mapStateToProps, {
    termsAndConditionsChanged,
    saveTermsAndConditions
})(AddTermsAndConditions);