import React from 'react';
import { motion } from 'framer-motion';
import { isMobile } from 'react-device-detect';
import Universe from "../../../assets/images/global.svg";

const styles = {
    section: {
        background: '#ffffff',
        padding: '4rem 0',
        overflow: 'hidden',
    },
    container: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '0 1.5rem',
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '4rem',
        flexWrap: 'wrap',
        '@media (max-width: 768px)': {
            justifyContent: 'center',
            textAlign: 'center',
        }
    },
    textContent: {
        flex: '1',
        minWidth: '300px',
        maxWidth: '600px',
        paddingRight: isMobile ? 0 : '2rem',
        textAlign: isMobile ? 'center' : 'left',
    },
    title: {
        color: '#1a1a1a',
        fontSize: isMobile ? '2rem' : '2.5rem',
        fontFamily: 'ramblaBold, sans-serif',
        marginBottom: '1.5rem',
        lineHeight: '1.2',
        textAlign: isMobile ? 'center' : 'left',
    },
    description: {
        color: '#4a4a4a',
        fontSize: isMobile ? '1rem' : '1.1rem',
        fontFamily: 'ramblaRegular, sans-serif',
        marginBottom: '1rem',
        lineHeight: '1.6',
        textAlign: isMobile ? 'center' : 'left',
    },
    highlight: {
        color: '#FFB400',
        fontWeight: 'bold',
    },
    imageWrapper: {
        flex: '0 0 35%',
        position: 'relative',
        minWidth: '280px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 768px)': {
            width: '100%',
            maxWidth: '400px',
            margin: '0 auto',
        }
    },
    orbitRing: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '120%',
        height: '120%',
        border: '2px dashed #FFB400',
        borderRadius: '50%',
        opacity: 0.2,
    },
    floatingElement: {
        position: 'absolute',
        borderRadius: '50%',
        background: 'linear-gradient(45deg, #FFB400, #FFD700)',
        filter: 'blur(1px)',
    },
    glowEffect: {
        position: 'absolute',
        width: '150%',
        height: '150%',
        background: 'radial-gradient(circle, rgba(255,180,0,0.1) 0%, rgba(255,180,0,0) 70%)',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '50%',
        zIndex: 0,
    },
    statsContainer: {
        display: 'flex',
        gap: '3rem',
        marginTop: '3rem',
        flexWrap: 'wrap',
        borderTop: '1px solid rgba(0,0,0,0.1)',
        paddingTop: '2rem',
        justifyContent: isMobile ? 'center' : 'flex-start',
        alignItems: 'center',
        width: '100%',
    },
    statItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: isMobile ? 'center' : 'flex-start',
    },
    statNumber: {
        fontSize: '2rem',
        fontFamily: 'ramblaBold, sans-serif',
        color: '#FFB400',
        marginBottom: '0.5rem',
    },
    statLabel: {
        fontSize: '0.9rem',
        color: '#666',
        fontFamily: 'ramblaRegular, sans-serif',
    },
    sunEffect: {
        position: 'absolute',
        width: '140%',
        height: '140%',
        background: 'radial-gradient(circle, rgba(255,180,0,0.15) 0%, rgba(255,215,0,0.05) 30%, rgba(255,215,0,0) 70%)',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: '50%',
        zIndex: 0,
        filter: 'blur(10px)',
    },
    imageContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        perspective: '1000px',
        borderRadius: "50px",
    },
};

const mobileStyles = {
    content: {
        justifyContent: 'center',
        textAlign: 'center',
    },
    textContent: {
        paddingRight: 0,
        paddingLeft: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        textAlign: 'center',
    },
    statsContainer: {
        justifyContent: 'center',
        width: '100%',
    },
    statItem: {
        alignItems: 'center',
    },
    imageWrapper: {
        width: '100%',
        maxWidth: '400px',
        margin: '0 auto',
    },
};

const AnimatedStat = ({ number, label }) => (
    <motion.div style={{
        ...styles.statItem,
        ...(isMobile ? mobileStyles.statItem : {})
    }}>
        <motion.span
            style={styles.statNumber}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
        >
            <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
            >
                {number}+
            </motion.span>
        </motion.span>
        <motion.span
            style={styles.statLabel}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8, delay: 0.2 }}
        >
            {label}
        </motion.span>
    </motion.div>
);

const UniverseSection = () => {
    return (
        <section style={styles.section}>
            <div style={styles.container}>
                <div style={{
                    ...styles.content,
                    ...(isMobile ? mobileStyles.content : {})
                }}>
                    <motion.div 
                        style={styles.textContent}
                        initial={{ opacity: 0, x: -50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }}
                    >
                        <h2 style={styles.title}>
                            Local & International Opportunities
                        </h2>
                        <p style={styles.description}>
                            Tired of endless searches and getting lost in a sea of resume applications?
                        </p>
                        <p style={styles.description}>
                            With just <span style={styles.highlight}>ONE tap</span>, access thousands of options 
                            tailored to your preferences. From full-time jobs, and part-time gigs to internships, 
                            student fieldwork, and more, we have it all.
                        </p>
                        
                        {/* <div style={styles.statsContainer}>
                            <AnimatedStat number="1000" label="Active Jobs" />
                            <AnimatedStat number="500" label="Companies" />
                            <AnimatedStat number="10K" label="Job Seekers" />
                        </div> */}
                    </motion.div>

                    <motion.div 
                        style={styles.imageWrapper}
                        initial={{ opacity: 0, x: 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.8 }}
                    >
                        <div style={styles.sunEffect} />

                        <div style={styles.imageContainer}>
                            <img
                                src={Universe}
                                alt="Global opportunities illustration"
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    position: 'relative',
                                    zIndex: 1,
                                    filter: 'drop-shadow(0 10px 20px rgba(0,0,0,0.15))',
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                    aspectRatio: '1/1',
                                }}
                            />
                        </div>
                    </motion.div>
                </div>
            </div>
        </section>
    );
};

export default UniverseSection; 