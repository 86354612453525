import React from 'react';
import { Select } from 'antd';
import './CommonSelect.css';

const { Option } = Select;

class SelectJobStatus extends React.Component {
    render() {
        return (
            <div className="inputTypeSelect">
                <Select
                    showSearch
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value="draft">Draft</Option>
                    <Option value="open">Open</Option>
                    <Option value="onHold">On Hold</Option>
                    <Option value="filled">Filled</Option>
                    <Option value="canceled">Canceled</Option>
                    <Option value="deleted">Deleted</Option>
                </Select>
            </div>
        );
    }
}

export default SelectJobStatus;
