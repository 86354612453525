import React from 'react';
import { Table } from 'antd';
import moment from "moment";
import { EyeOutlined } from '@ant-design/icons';
import DescriptionModal from './DescriptionModal';
import './FeedbackCard.css';

class FeedbackCard extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			items: this.props.items
		};
	}

	getRatingClass = (rating) => {
		if (rating >= 4.5) return 'excellent';
		if (rating >= 3.5) return 'good';
		if (rating >= 2.5) return 'neutral';
		if (rating >= 1.5) return 'poor';
		return 'very-poor';
	}

	renderRatingCell = (record, field) => {
		const rating = record[field];
		const ratingClass = this.getRatingClass(rating.index);
		return (
			<div className={`nafasi-feedback-card__cell--rating ${ratingClass}`}>
				{rating.title}
			</div>
		);
	}

	render() {
		const { items } = this.state;
		const processedResults = items.map(result => {
			const seconds = result.timestamp.seconds || result.timestamp._seconds;
			const time = moment.unix(seconds).format("DD/MM/YYYY | HH:mm");
			return { ...result, time };
		});

		const columns = [
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
				render: (_, record) => (
					<div className="nafasi-feedback-card__cell">
						{record.name}
					</div>
				),
			},
			{
				title: 'Gender',
				dataIndex: 'gender',
				key: 'gender',
				render: (_, record) => (
					<div className="nafasi-feedback-card__cell">
						{record.gender}
					</div>
				),
			},
			{
				title: 'Ease of Use',
				dataIndex: 'easeOfUse',
				key: 'easeOfUse',
				render: (_, record) => this.renderRatingCell(record, 'easeOfUse'),
			},
			{
				title: 'Overall Experience',
				dataIndex: 'overallExperience',
				key: 'overallExperience',
				render: (_, record) => this.renderRatingCell(record, 'overallExperience'),
			},
			{
				title: 'Registration Process',
				dataIndex: 'registrationProcess',
				key: 'registrationProcess',
				render: (_, record) => this.renderRatingCell(record, 'registrationProcess'),
			},
			{
				title: 'Time',
				dataIndex: 'time',
				key: 'time',
				render: (_, record) => (
					<div className="nafasi-feedback-card__cell nafasi-feedback-card__cell--time">
						{record.time}
					</div>
				),
			},
			{
				title: 'View',
				dataIndex: 'view',
				key: 'view',
				render: (_, record) => (
					<div className="nafasi-feedback-card__cell">
						<DescriptionModal record={record} />
					</div>
				),
			},
		];

		return (
			<div className="nafasi-feedback-card">
				<Table 
					dataSource={processedResults} 
					columns={columns} 
					rowKey="id"
					className="nafasi-feedback-card__table"
					pagination={{
						pageSize: 10,
						hideOnSinglePage: true,
						position: ['bottomCenter']
					}}
				/>
			</div>
		);
	}
}

export default FeedbackCard;