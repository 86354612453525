import React from 'react';
import {Select} from "antd";


export class SelectAssetsCategory extends React.Component {

    render() {
        return (
            <div className={"inputTypeSelect"}>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <option value="certificate">Certificate</option>
                    <option value="educationLevel">Education Level</option>
                    <option value="jobTitle">Job Title</option>
                    <option value="license">License</option>
                    <option value="language">Language</option>
                    <option value="educationIndustry">Education Industry</option>
                    <option value="link">Link</option>
                    <option value="skill">Skill</option>
                    <option value="hobby">Hobby</option>
                </Select>
            </div>
        );
    }
}