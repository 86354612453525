import React, { Component } from 'react'
import { Collapse , Table} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link, NavLink} from 'react-router-dom';
import {isMobile} from "react-device-detect";
import { connect } from 'react-redux';
import {Space, Alert} from 'antd';
import _ from 'lodash';



//component imports
import RegistrationModal from './cards/RegistrationModal';
import LoginModal from './cards/LoginModal';
import "./Developers.css"


//file imports
import Logo from "../../assets/logos/logo.png";


 class Developers extends Component {
    constructor(props){
        super(props);
        this.state = {
            dataFound:this.props.dataFound,
            fetchedData:this.props.fetchedData
            
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                dataFound: nextProps.dataFound,
                fetchedData:nextProps.fetchedData

            });
        }
    }

    renderLogo() {
        if(isMobile){
            return  <NavLink to={'/'}><img src={Logo} alt={"nafasi icon logo"}/></NavLink>;
        }
    }

    renderUserInfo(){
    const {dataFound, fetchedData} = this.state
    const apiKey = fetchedData.apiKey
    if (!_.isEmpty(this.state.fetchedData)) {

        return (
            <Space direction="vertical" style={{ width: '100%' }}>
                      <Alert 
                        message="YOUR API KEY"  
                        type="success"        
                        description={apiKey}
                        className="api-key-alert"
                      />
            </Space>
        )    
    }


 }

 getSampleResponse() {
    return `jobs:[
 {
    "postID": <span class="api-number">163827</span>,
    "jobType": <span class="api-string">"Internship"</span>,
    "postTitle": <span class="api-string">"Cybersecurity Consultant"</span>,
    "companyName": <span class="api-string">"State Government"</span>,
    "minimumExperience:<span class="api-string">"1"</span>,
    "status": <span class="api-string">"open"</span>,
     ...
  }
]`;
};

getSampleScript () {
    return `const axios = require('axios');

const options = {
  method: <span class="api-string">'GET'</span>,
  url: <span class="api-url">'https://nafasi.io/api/jobs'</span>,
  headers: {
    'Content-Type': <span class="api-string">'application/json'</span>,
    'x-api-key': <span class="api-string">'YOUR_API_KEY'</span>
  }
};

axios.request(options)
  .then(response => {
    <span class="api-url">console.log(response.data);</span> 
  })
  .catch(error => {
    console.error(<span class="api-keyword">error</span>) 
  });`;
  };

getSamplePHPScript() {
    return `&lt;?php
  $curl = curl_init();
  curl_setopt_array($curl, array(
    CURLOPT_URL => <span class="api-url">'https://nafasi.io/api/jobs'</span>,
    CURLOPT_RETURNTRANSFER => true,
    CURLOPT_ENCODING => '',
    CURLOPT_MAXREDIRS => 10,
    CURLOPT_TIMEOUT => 0,
    CURLOPT_FOLLOWLOCATION => true,
    CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
    CURLOPT_CUSTOMREQUEST => <span class="api-string">'GET'</span>,
    CURLOPT_HTTPHEADER => array(
      'Content-Type: ' . <span class="api-string">'application/json'</span>,
      'x-api-key ' . <span class="api-string">'YOUR_API_KEY'</span>
    ),
  ));
  
  $response = curl_exec($curl);
  
  curl_close($curl);
  echo $response;
  
  ?&gt;`;
  }

   getSampleJavaScriptScript() {
    return `const url = <span class="api-url">'https://nafasi.io/api/jobs'</span>;
  
  const options = {
    method: <span class="api-string">'GET'</span>,
    headers: {
      'Content-Type': <span class="api-string">'application/json'</span>,
      'x-api-key': <span class="api-string">'YOUR_API_KEY'</span>
    }
  };
  
  fetch(url, options)
    .then(response => response.json())
    .then(data => {
      <span class="api-url">console.log(data);</span>
    })
    .catch(error => {
      console.error(<span class="api-keyword">error</span>);
    });`;
  }
  
  
  

  render(){
        const { Panel } = Collapse;
        const {dataFound, fetchedData} = this.state
        console.log('fetched data', fetchedData)

        return (
            <div className={'developerContainer'}>
                <div className="api-header">
                    <div className="api-header-content">
                        <h1 className="api-title">Nafasi Jobs API</h1>
                        <p className="api-subtitle">Access job listings programmatically through our REST API</p>
                        
                        {/* {_.isEmpty(fetchedData) ? (
                            <div className="auth-buttons">
                                <RegistrationModal />
                                <LoginModal />
                            </div>
                        ) : (
                            this.renderUserInfo()
                        )} */}
                    </div>
                </div>

                <div className="api-section">
                    <h2 className="api-heading">
                        <FontAwesomeIcon icon="key" className="section-icon" />
                        Authentication
                    </h2>
                    <p>All API requests require authentication using an API key in the headers.</p>
                    
                    <Table
                        columns={columns}
                        dataSource={data}
                        pagination={false}
                        className="customTable"
                    />
                </div>

                <div className="api-section">
                    <h2 className="api-heading">
                        <FontAwesomeIcon icon="code" className="section-icon" />
                        Sample Code
                    </h2>
                    <div className="api-tabs">
                        <button className="api-tab active">Node.js</button>
                        <button className="api-tab">PHP</button>
                        <button className="api-tab">JavaScript</button>
                    </div>
                    <pre className="api-code-block">
                        <code className="api-code-text" 
                            dangerouslySetInnerHTML={{ __html: this.getSampleScript() }} 
                        />
                    </pre>
                </div>

                <div className="api-section">
                    <h2 className="api-heading">
                        <FontAwesomeIcon icon="reply" className="section-icon" />
                        Response Format
                    </h2>
                    <p>The API returns JSON responses in the following format:</p>
                    <pre className="api-code-block">
                        <code className="api-code-text" 
                            dangerouslySetInnerHTML={{ __html: this.getSampleResponse() }} 
                        />
                    </pre>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {

    const {  dataFound,
        fetchedData} = state.landing;
  

    return {
        dataFound,
        fetchedData
    }
};

export default connect(mapStateToProps, { })(Developers);



const columns = [
    {
        title: 'Field',
        dataIndex: 'field',
        key: 'field',
    },
    {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
    },
];

const data = [
    {
        key: '1',
        field: 'x-api-key',
        type: 'String',
        description: 'Token for authentication',
    },
    {
        key: '2',
        field: 'Content-Type',
        type: 'String',
        description: 'Type of the content being sent, application/json',
    },
  
];

