import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import './SearchPage.css'

import {Input, message, notification} from 'antd';
import { 
    SearchVisualIcon
} from 'hugeicons-react';

//-> action import
import { searchDB, clearSearch } from "../../actions/SearchActions";
import { fetchAdminHighlights } from "../../actions/SearchActions";
import { addToShortlist } from '../../actions/SearchActions';

//common imports
import { Button } from '../common/Button';
import { Spinner } from '../common/Spinner';


//components import
import ResultCard from "./cards/ResultCard";
import RelocationFilter from "./subComponents/RelocationFilter";
import CountryFilter from "./subComponents/CountryFilter";
import JobTypeFilter from "./subComponents/JobTypeFilter";
import WorkScheduleFilter from "./subComponents/WorkScheduleFilter";
import GenderFilter from "./subComponents/GenderFilter";
import MaritalStatus from "./subComponents/MaritalStatus";
import BirthdateFilter from "./subComponents/BirthdateFilter";
import JobTitleFilter from "./subComponents/JobTitleFilter";
import EducationLevelFilter from "./subComponents/EducationLevelFilter";
import EducationIndustryFilter from "./subComponents/IndustryFilter";
import SkillsFilter from "./subComponents/SkillsFilter";
import CertificatesFilter from "./subComponents/CertificatesFilter";
import LanguagesFilter from "./subComponents/LanguagesFilter";
import LicensesFilter from "./subComponents/LicensesFilter";
import LinksFilter from "./subComponents/LinksFilter";
import YearOfCompletion from "./subComponents/YearOfCompletion";
import CurrentEducationEnrollment from "./subComponents/CurrentEducationEnrollment";
import ExperienceFilter from "./subComponents/ExperienceFilter";
import InternationalExperience from "./subComponents/InternationalExperience";
import StudiedAbroad from "./subComponents/StudiedAbroad";
import ExperienceIndustryPrime from "./subComponents/ExperienceIndustryPrime";
import ExperienceIndustrySec from "./subComponents/ExperienceIndustrySec";
import PositionExperiencePrime from "./subComponents/PositionExperiencePrime";
import PositionExperienceSec from "./subComponents/PositionExperienceSec";

class Search extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            packageIndex: this.props.packageIndex,
            openForRelocation: this.props.openForRelocation,
            country: this.props.country,
            region: this.props.region,
            jobTypeArray: this.props.jobTypeArray,
            workScheduleArray: this.props.workScheduleArray,
            gender: this.props.gender,
            maritalStatus: this.props.maritalStatus,
            startBirthDate: this.props.startBirthDate,
            endBirthDate: this.props.endBirthDate,
            certificateID: this.props.certificateID,
            jobTitle: this.props.jobTitle,
            educationLevel: this.props.educationLevel,
            educationIndustry: this.props.educationIndustry,
            currentEnrollment: this.props.currentEnrollment,
            studiedAbroad: this.props.studiedAbroad,
            yearOfCompletion: this.props.yearOfCompletion,
            industryID: this.props.industryID,
            currentAtWork: this.props.currentAtWork,
            experienceID: this.props.experienceID,
            experienceYears: this.props.experienceYears,
            foreignJob: this.props.foreignJob,
            language: this.props.language,
            license: this.props.license,
            militaryCountry: this.props.militaryCountry,
            industryPrime: this.props.industryPrime,
            industrySec: this.props.industrySec,
            positionPrime: this.props.positionPrime,
            positionSec: this.props.positionSec,
            skill: this.props.skill,
            link: this.props.link,
            results: this.props.results,
            loading: this.props.loading,
            profile: this.props.profile,
            resultsSearchLimit: this.props.resultsSearchLimit,
            search: true,
            moreFilters: false,
            seekerHighlights: this.props.seekerHighlights,
            activeFilter: null,
            showDevTools: false,
            lastDoc: this.props.lastDoc,
            searchCacheKey: this.props.searchCacheKey,
            hasMore: this.props.hasMore,
            total: this.props.total,
            lastDocs: {},
            currentPage: 1
        };

        this.renderResults = this.renderResults.bind(this);
        this.renderSearchButton = this.renderSearchButton.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    componentDidMount(){
        this.props.fetchAdminHighlights();
    }

    componentWillReceiveProps(nextProps){
        if (nextProps !== this.props) {
            this.setState({
                leaveRequests: nextProps.leaveRequests,
                packageIndex: nextProps.packageIndex,
                openForRelocation: nextProps.openForRelocation,
                country: nextProps.country,
                region: nextProps.region,
                jobTypeArray: nextProps.jobTypeArray,
                workScheduleArray: nextProps.workScheduleArray,
                gender: nextProps.gender,
                maritalStatus: nextProps.maritalStatus,
                startBirthDate: nextProps.startBirthDate,
                endBirthDate: nextProps.endBirthDate,
                certificateID: nextProps.certificateID,
                jobTitle: nextProps.jobTitle,
                educationLevel: nextProps.educationLevel,
                educationIndustry: nextProps.educationIndustry,
                currentEnrollment: nextProps.currentEnrollment,
                studiedAbroad: nextProps.studiedAbroad,
                yearOfCompletion: nextProps.yearOfCompletion,
                industryID: nextProps.industryID,
                currentAtWork: nextProps.currentAtWork,
                experienceID: nextProps.experienceID,
                experienceYears: nextProps.experienceYears,
                foreignJob: nextProps.foreignJob,
                language: nextProps.language,
                license: nextProps.license,
                militaryCountry: nextProps.militaryCountry,
                industryPrime: nextProps.industryPrime,
                industrySec: nextProps.industrySec,
                positionPrime: nextProps.positionPrime,
                positionSec: nextProps.positionSec,
                skill: nextProps.skill,
                link: nextProps.link,
                results: nextProps.results,
                loading: nextProps.loading,
                profile: nextProps.profile,
                resultsSearchLimit: nextProps.resultsSearchLimit,
                seekerHighlights: nextProps.seekerHighlights,
                lastDoc: nextProps.lastDoc,
                searchCacheKey: nextProps.searchCacheKey,
                hasMore: nextProps.hasMore,
                total: nextProps.total,
                lastDocs: nextProps.lastDocs || {}
            });
        }
    }

    handleFilterSelect = (filterType, value) => {
        console.log('Filter selected:', filterType, value);
        this.setState({ [filterType]: value }, () => {
            console.log('Updated state:', this.state[filterType]);
        });
    };

    async onSearch(page = 1, pageSize = 20) {
        try {
            const { profile } = this.state;

            if (_.isEmpty(profile)) {
                message.error('Please login to search');
                return;
            }

            // Reset pagination state for new searches
            if (page === 1) {
                this.setState({
                    lastDocs: {},
                    currentPage: 1
                });
            }

            // Prepare search parameters
            const searchParams = this.prepareSearchParams(page, pageSize);
            console.log('Search params for page', page, ':', searchParams);

            if (this.validateSearchParams(searchParams)) {
                try {
                    await this.props.searchDB(searchParams);
                } catch (error) {
                    console.error('Search execution error:', error);
                    message.error(error.message || 'Search failed. Please try again.');
                }
            }
        } catch (error) {
            console.error('onSearch error:', error);
            message.error('An unexpected error occurred. Please try again.');
        }
    }

    // Add a new method to prepare search parameters
    prepareSearchParams(page, pageSize) {
        const {
            packageIndex,
            openForRelocation,
            country,
            region,
            jobTypeArray,
            workScheduleArray,
            gender,
            maritalStatus,
            startBirthDate,
            endBirthDate,
            certificateID,
            jobTitle,
            educationLevel,
            currentEnrollment,
            studiedAbroad,
            yearOfCompletion,
            industryID,
            currentAtWork,
            experienceID,
            experienceYears,
            foreignJob,
            language,
            license,
            militaryCountry,
            industryPrime,
            industrySec,
            positionPrime,
            positionSec,
            skill,
            link,
            lastDocs,
            searchCacheKey
        } = this.state;

        // Get the correct lastDoc for the requested page
        const currentLastDoc = page > 1 ? lastDocs[page - 1] : null;

        console.log('Preparing search params for page:', page, 'with lastDoc:', currentLastDoc);

        // Format experience object
        let experience = null;
        if (experienceID !== null || experienceYears !== null) {
            experience = { ID: experienceID, years: experienceYears };
        }

        return {
            packageIndex,
            openForRelocation,
            country,
            region,
            jobTypeArray,
            workScheduleArray,
            gender,
            maritalStatus,
            startBirthDate,
            endBirthDate,
            certificateID,
            jobTitle,
            educationLevel,
            currentEnrollment,
            studiedAbroad,
            yearOfCompletion,
            industryID,
            currentAtWork,
            experience,
            foreignJob,
            language,
            license,
            militaryCountry,
            industryPrime,
            industrySec,
            positionPrime,
            positionSec,
            skill,
            link,
            pageSize,
            lastDoc: currentLastDoc,
            searchCacheKey: page > 1 ? searchCacheKey : null,
            append: page > 1
        };
    }

    renderSearchButton(){
        const {loading} = this.state;

        if (loading) {
            return (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "10vh" }}>
                    <Spinner />
                </div>
            );
        } else {
            return (
                <div className={"allLeaveRequestsButtonBox"} style={{ display: "grid", gridTemplate: "1fr / 2fr 1fr", gridGap: "1rem", marginBottom: "1rem" }}>
                    <Button
                        text={"Search"}
                        onPress={() => this.onSearch(false)}
                    />
                    <Button
                        text={"Clear Search"}
                        onPress={() => this.props.clearSearch()}
                    />
                </div>
            );
        }
    }

    handleSearchResponse = (response, page) => {
        if (response && response.seekers) {
            // Store the lastDoc for the current page
            const newLastDocs = {
                ...this.state.lastDocs,
                [page]: response.lastDoc
            };

            this.setState({
                results: page === 1 ? response.seekers : [...this.state.results, ...response.seekers],
                lastDoc: response.lastDoc,
                lastDocs: newLastDocs,
                searchCacheKey: response.cacheKey,
                hasMore: response.hasMore,
                total: response.total,
                loading: false,
                currentPage: page
            });
        }
    };

    onLoadMore = async (page, pageSize) => {
        console.log('Loading page:', page);
        
        // Check if we have a lastDoc for the previous page
        if (page > 1 && !this.state.lastDocs[page - 1]) {
            console.log('No lastDoc available for page:', page);
            message.warning('No more results available');
            return;
        }

        // Check if we have results and hasMore flag
        if (page > 1 && (!this.state.results.length || !this.state.hasMore)) {
            console.log('No more results available');
            message.warning('No more results available');
            return;
        }

        await this.onSearch(page, pageSize);
    };

    renderResults() {
        return (
            <div className="search-results-wrapper">
                <ResultCard
                    profile={this.state.profile}
                    shortlistLoading={this.state.shortlistLoading}
                    seekerHighlights={this.state.seekerHighlights}
                    loading={this.state.loading}
                    total={this.state.total}
                    onLoadMore={this.onLoadMore}
                    results={this.state.results}
                    currentPage={this.state.currentPage}
                />
            </div>
        );
    }

    handleSearch() {
        this.setState({search: !this.state.search});
    };

    toggleFilter = (filterName, e) => {
        e?.stopPropagation();
        this.setState(prevState => ({
            activeFilter: prevState.activeFilter === filterName ? null : filterName,
            filterKey: Date.now()
        }), () => {
            // Force update of date values when filter opens
            if (filterName === 'basic') {
                this.forceUpdate();
            }
        });
    };

    closeFilter = (e) => {
        e.stopPropagation();
        this.setState({ activeFilter: null });
    };

    handleFilterInteraction = (e) => {
        e.stopPropagation();
        // Don't close the popover when interacting with filter components
    };

    toggleDevTools = () => {
        this.setState(prevState => ({
            showDevTools: !prevState.showDevTools
        }));
    };

    handleAddToShortlist = (data) => {
        this.props.addToShortlist(data);
    };

    handleBirthdateChange = (startDate, endDate) => {
        // Add debug logging
        console.log('Received dates:', {
            startDate: startDate?.format('DD/MM/YYYY'),
            endDate: endDate?.format('DD/MM/YYYY')
        });

        this.setState({
            startBirthDate: startDate ? moment(startDate).startOf('day') : null,
            endBirthDate: endDate ? moment(endDate).startOf('day') : null
        }, () => {
            console.log('Updated birthdate range:', {
                startBirthDate: this.state.startBirthDate?.format('DD/MM/YYYY'),
                endBirthDate: this.state.endBirthDate?.format('DD/MM/YYYY')
            });
        });
    };

    validateSearchParams(params) {
        // Basic validation
        if (!params) {
            console.error('No search parameters provided');
            return false;
        }

        // Check if at least one search criteria is provided
        const hasSearchCriteria = Object.entries(params).some(([key, value]) => {
            // Skip pagination and technical params
            if (['pageSize', 'lastDoc', 'searchCacheKey', 'userID'].includes(key)) {
                return false;
            }
            
            // Check if the value is not null/undefined and not empty
            if (Array.isArray(value)) {
                return value.length > 0;
            }
            return value !== null && value !== undefined && value !== '';
        });

        if (!hasSearchCriteria) {
            message.warning('Please select at least one search criteria');
            return false;
        }

        // Validate date range if both dates are present
        if (params.startBirthDate && params.endBirthDate) {
            const startBD = params.startBirthDate.startOf('day');
            const endBD = params.endBirthDate.startOf('day');
            
            // Debug logging
            console.log('Date Validation:', {
                startBD: startBD.format('YYYY-MM-DD'),
                endBD: endBD.format('YYYY-MM-DD'),
                isValid: startBD.isValid() && endBD.isValid(),
                comparison: endBD.isAfter(startBD)
            });

            if (!startBD.isValid() || !endBD.isValid()) {
                notification.warning({
                    message: 'Invalid Date Format',
                    description: 'Please ensure both dates are valid',
                    duration: 5,
                });
                return false;
            }

            if (!endBD.isAfter(startBD)) {
                notification.warning({
                    message: 'Invalid Birthdate Range',
                    description: 'End date must be after start date',
                    duration: 5,
                });
                return false;
            }
        }

        // When formatting dates for the API request
        if (params.startBirthDate) {
            params.startBirthDate = params.startBirthDate.startOf('day').toDate();
        }
        if (params.endBirthDate) {
            params.endBirthDate = params.endBirthDate.endOf('day').toDate();
        }

        // Format country and region
        if (params.country) {
            params.country = params.country.countryShortCode;
        }
        if (params.region) {
            params.region = params.region.shortCode;
        }

        // Ensure arrays are initialized
        params.jobTypeArray = params.jobTypeArray || [];
        params.workScheduleArray = params.workScheduleArray || [];

        // Add default values for pagination if not present
        params.resultsSearchLimit = params.resultsSearchLimit || 50;
        params.pageSize = params.pageSize || 20;

        return true;
    }

    render() {
        const { activeFilter } = this.state;
        
        // More comprehensive selection checks
        const hasBasicSelection = Boolean(
            this.state.gender || 
            this.state.maritalStatus || 
            this.state.startBirthDate || 
            this.state.endBirthDate
        );

        const hasLocationSelection = Boolean(
            this.state.country || 
            this.state.openForRelocation !== null
        );

        const hasExperienceSelection = Boolean(
            this.state.jobTypeArray?.length || 
            this.state.workScheduleArray?.length ||
            this.state.experienceID ||
            this.state.foreignJob !== null ||
            this.state.industryPrime ||
            this.state.industrySec ||
            this.state.positionPrime ||
            this.state.positionSec ||
            this.state.jobTitle
        );

        const hasEducationSelection = Boolean(
            this.state.educationLevel ||
            this.state.educationIndustry ||
            this.state.currentEnrollment !== null ||
            this.state.yearOfCompletion ||
            this.state.studiedAbroad !== null
        );

        const hasSkillsSelection = Boolean(
            this.state.skill?.length ||
            this.state.language?.length ||
            this.state.certificateID?.length ||
            this.state.license?.length ||
            this.state.link?.length
        );

        return (
            <div className="searchContainer">
                {/* Search Header */}
                <div className="search-header">
                    <div className="search-header-left">
                        <SearchVisualIcon size={24} style={{ color: "#4f46e5" }} />
                        <h3>Candidate Search</h3>
                    </div>
                    <div className="search-header-right">
                        <button className="button-secondary">Save Search</button>
                        <button className="button-primary">New Search</button>
                    </div>
                </div>

                {/* Search Bar Section */}
                <div className="search-bar-section">
                    <div className="search-bar-container" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Input.Search
                            placeholder="Search candidates..."
                            size="large"
                            onSearch={this.handleSearch}
                            style={{ width: '100%', maxWidth: '600px' }}
                        />
                    </div>

                    {/* Filter Groups */}
                    <div className="filter-groups" ref={ref => this.filterRef = ref}>
                        {/* Basic Info Group */}
                        <div className="filter-group">
                            <button 
                                className={`filter-button ${activeFilter === 'basic' ? 'active' : ''} ${hasBasicSelection ? 'has-selection' : ''}`}
                                onClick={(e) => this.toggleFilter('basic', e)}
                            >
                                <FontAwesomeIcon icon="user" size="sm" />
                                Basic Info
                            </button>
                            {activeFilter === 'basic' && (
                                <div 
                                    className="filter-popover show"
                                    onClick={this.handleFilterInteraction}
                                >
                                    <button className="filter-close" onClick={this.closeFilter}>
                                        <FontAwesomeIcon icon="times" />
                                    </button>
                                    <div className="filter-section">
                                        <div className="filter-section-title">Personal Information</div>
                                        <GenderFilter 
                                            value={this.state.gender}
                                            onChange={(value) => this.handleFilterSelect('gender', value)}
                                        />
                                        <MaritalStatus 
                                            value={this.state.maritalStatus}
                                            onChange={(value) => this.handleFilterSelect('maritalStatus', value)}
                                        />
                                        <BirthdateFilter 
                                            key={`birthdate-filter-${this.state.activeFilter === 'basic' ? this.state.filterKey : 'default'}`}
                                            startDate={this.state.startBirthDate}
                                            endDate={this.state.endBirthDate}
                                            onChange={this.handleBirthdateChange}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Location Group */}
                        <div className="filter-group">
                            <button 
                                className={`filter-button ${activeFilter === 'location' ? 'active' : ''} ${hasLocationSelection ? 'has-selection' : ''}`}
                                onClick={(e) => this.toggleFilter('location', e)}
                            >
                                <FontAwesomeIcon icon="map-marker-alt" size="sm" />
                                Location
                            </button>
                            {activeFilter === 'location' && (
                                <div 
                                    className="filter-popover show"
                                    onClick={this.handleFilterInteraction}
                                >
                                    <button className="filter-close" onClick={this.closeFilter}>
                                        <FontAwesomeIcon icon="times" />
                                    </button>
                                    <div className="filter-section">
                                        <div className="filter-section-title">Location Details</div>
                                        <CountryFilter 
                                            value={this.state.country}
                                            onChange={(value) => this.handleFilterSelect('country', value)}
                                        />
                                        <RelocationFilter 
                                            value={this.state.openForRelocation}
                                            onChange={(value) => this.handleFilterSelect('openForRelocation', value)}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Experience Group */}
                        <div className="filter-group">
                            <button 
                                className={`filter-button ${activeFilter === 'experience' ? 'active' : ''} ${hasExperienceSelection ? 'has-selection' : ''}`}
                                onClick={(e) => this.toggleFilter('experience', e)}
                            >
                                <FontAwesomeIcon icon="briefcase" size="sm" />
                                Experience
                            </button>
                            {activeFilter === 'experience' && (
                                <div 
                                    className="filter-popover show"
                                    onClick={this.handleFilterInteraction}
                                >
                                    <button className="filter-close" onClick={this.closeFilter}>
                                        <FontAwesomeIcon icon="times" />
                                    </button>
                                    <div className="filter-section">
                                        <div className="filter-section-title">Work Experience</div>
                                        <JobTitleFilter
                                            value={this.state.jobTitle}
                                            onChange={(value) => this.handleFilterSelect('jobTitle', value)}
                                        />
                                        <JobTypeFilter 
                                            value={this.state.jobTypeArray}
                                            onChange={(value) => this.handleFilterSelect('jobTypeArray', value)}
                                        />
                                        <WorkScheduleFilter 
                                            value={this.state.workScheduleArray}
                                            onChange={(value) => this.handleFilterSelect('workScheduleArray', value)}
                                        />
                                        <ExperienceFilter />
                                        <InternationalExperience 
                                            value={this.state.foreignJob}
                                            onChange={(value) => this.handleFilterSelect('foreignJob', value)}
                                        />
                                    </div>
                                    <div className="filter-section">
                                        <div className="filter-section-title">Industry Experience</div>
                                        <ExperienceIndustryPrime 
                                            value={this.state.industryPrime}
                                            onChange={(value) => this.handleFilterSelect('industryPrime', value)}
                                        />
                                        {/* <ExperienceIndustrySec 
                                            value={this.state.industrySec}
                                            onChange={(value) => this.handleFilterSelect('industrySec', value)}
                                        /> */}
                                        <PositionExperiencePrime 
                                            value={this.state.positionPrime}
                                            onChange={(value) => this.handleFilterSelect('positionPrime', value)}
                                        />
                                        {/* <PositionExperienceSec 
                                            value={this.state.positionSec}
                                            onChange={(value) => this.handleFilterSelect('positionSec', value)}
                                        /> */}
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Education Group */}
                        <div className="filter-group">
                            <button 
                                className={`filter-button ${activeFilter === 'education' ? 'active' : ''} ${hasEducationSelection ? 'has-selection' : ''}`}
                                onClick={(e) => this.toggleFilter('education', e)}
                            >
                                <FontAwesomeIcon icon="graduation-cap" size="sm" />
                                Education
                            </button>
                            {activeFilter === 'education' && (
                                <div 
                                    className="filter-popover show"
                                    onClick={this.handleFilterInteraction}
                                >
                                    <button className="filter-close" onClick={this.closeFilter}>
                                        <FontAwesomeIcon icon="times" />
                                    </button>
                                    <div className="filter-section">
                                        <div className="filter-section-title">Education Details</div>
                                        <EducationLevelFilter 
                                            value={this.state.educationLevel}
                                            onChange={(value) => this.handleFilterSelect('educationLevel', value)}
                                        />
                                        <EducationIndustryFilter 
                                            value={this.state.educationIndustry}
                                            onChange={(value) => this.handleFilterSelect('educationIndustry', value)}
                                        />
                                        <CurrentEducationEnrollment 
                                            value={this.state.currentEnrollment}
                                            onChange={(value) => this.handleFilterSelect('currentEnrollment', value)}
                                        />
                                        <YearOfCompletion 
                                            value={this.state.yearOfCompletion}
                                            onChange={(value) => this.handleFilterSelect('yearOfCompletion', value)}
                                        />
                                        <StudiedAbroad 
                                            value={this.state.studiedAbroad}
                                            onChange={(value) => this.handleFilterSelect('studiedAbroad', value)}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* Skills & Qualifications */}
                        <div className="filter-group">
                            <button 
                                className={`filter-button ${activeFilter === 'skills' ? 'active' : ''} ${hasSkillsSelection ? 'has-selection' : ''}`}
                                onClick={(e) => this.toggleFilter('skills', e)}
                            >
                                <FontAwesomeIcon icon="star" size="sm" />
                                Skills & Certifications
                            </button>
                            {activeFilter === 'skills' && (
                                <div
                                    className="filter-popover show"
                                    onClick={this.handleFilterInteraction}
                                >
                                    <button className="filter-close" onClick={this.closeFilter}>
                                        <FontAwesomeIcon icon="times"/>
                                    </button>
                                    <div className="filter-section">
                                        <div className="filter-section-title">Skills</div>
                                        <SkillsFilter
                                            value={this.state.skill}
                                            onChange={(value) => this.handleFilterSelect('skill', value)}
                                        />
                                        <LanguagesFilter
                                            value={this.state.language}
                                            onChange={(value) => this.handleFilterSelect('language', value)}
                                        />
                                    </div>
                                    <div className="filter-section">
                                        <div className="filter-section-title">Certifications</div>
                                        <CertificatesFilter
                                            value={this.state.certificateID}
                                            onChange={(value) => this.handleFilterSelect('certificateID', value)}
                                        />
                                        <LicensesFilter
                                            value={this.state.license}
                                            onChange={(value) => this.handleFilterSelect('license', value)}
                                        />
                                    </div>

                                    <div className="filter-section">
                                        <div className="filter-section-title">Links</div>
                                        <LinksFilter
                                            value={this.state.link}
                                            onChange={(value) => this.handleFilterSelect('link', value)}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Search Actions */}
                <div className="search-actions">
                    <button
                        className="button-primary"
                        onClick={() => this.onSearch(false)}
                        disabled={this.state.loading}
                    >
                        {this.state.loading ? 'Searching...' : 'Search Candidates'}
                    </button>
                    <button
                        className="button-secondary"
                        onClick={() => this.props.clearSearch()}
                    >
                        Clear Filters
                    </button>
                </div>

                {/* Results Section */}
                <div className="results-section">
                    <div className="results-header">
                        <h4>Search Results</h4>
                        {this.state.results.length > 0 && (
                            <span className="results-count">{this.state.results.length} candidates found</span>
                        )}
                    </div>
                    <div className="search-results-container">
                        {this.renderResults()}
                    </div>
                </div>

                {/* Loading Overlay */}
                {this.state.loading && (
                    <div className="loading-overlay">
                        <div className="loading-content">
                            <div className="loading-spinner">
                                <Spinner size="large" />
                            </div>
                            <p style={{ fontFamily: "ramblaRegular" }}>Searching candidates...</p>
                        </div>
                </div>
                )}

                <div className="dev-tools-container">
                        <div className="dev-tools-accordion">
                            <button 
                                className={`dev-tools-header ${this.state.showDevTools ? 'active' : ''}`}
                                onClick={this.toggleDevTools}
                            >
                                <FontAwesomeIcon 
                                    icon={this.state.showDevTools ? 'chevron-down' : 'chevron-right'} 
                                    size="sm" 
                                />
                                Dev Tools
                            </button>
                            {this.state.showDevTools && (
                                <div className="dev-tools-content">
                                    <h4>Current Filter Values:</h4>
                                    <pre>
                                        {JSON.stringify({
                                            packageIndex: this.state.packageIndex,
                                            openForRelocation: this.state.openForRelocation,
                                            country: this.state.country,
                                            region: this.state.region,
                                            jobTypeArray: this.state.jobTypeArray,
                                            workScheduleArray: this.state.workScheduleArray,
                                            gender: this.state.gender,
                                            maritalStatus: this.state.maritalStatus,
                                            birthDate: {
                                                start: this.state.startBirthDate,
                                                end: this.state.endBirthDate
                                            },
                                            certificateID: this.state.certificateID,
                                            jobTitle: this.state.jobTitle,
                                            educationLevel: this.state.educationLevel,
                                            educationIndustry: this.state.educationIndustry,
                                            currentEnrollment: this.state.currentEnrollment,
                                            studiedAbroad: this.state.studiedAbroad,
                                            yearOfCompletion: this.state.yearOfCompletion,
                                            industryID: this.state.industryID,
                                            currentAtWork: this.state.currentAtWork,
                                            experience: {
                                                id: this.state.experienceID,
                                                years: this.state.experienceYears
                                            },
                                            foreignJob: this.state.foreignJob,
                                            language: this.state.language,
                                            license: this.state.license,
                                            militaryCountry: this.state.militaryCountry,
                                            industryPrime: this.state.industryPrime,
                                            industrySec: this.state.industrySec,
                                            positionPrime: this.state.positionPrime,
                                            positionSec: this.state.positionSec,
                                            skill: this.state.skill,
                                            link: this.state.link
                                        }, null, 2)}
                                    </pre>
                    </div>
                            )}
                </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { 
        packageIndex,
        openForRelocation,
        country,
        region,
        jobTypeArray,
        workScheduleArray,
        gender,
        maritalStatus,
        startBirthDate,
        endBirthDate,
        certificateID,
        jobTitle,
        educationLevel,
        currentEnrollment,
        studiedAbroad,
        yearOfCompletion,
        industryID,
        currentAtWork,
        experienceID,
        experienceYears,
        foreignJob,
        language,
        license,
        militaryCountry,
        industryPrime,
        industrySec,
        positionPrime,
        positionSec,
        skill,
        link,
        results,
        loading,
        resultsSearchLimit,
        lastDoc,
        hasMore,
        total,
        searchCacheKey,
        seekerHighlights,
        shortlistLoading,
        lastDocs
    } = state.search;
    
    const { profile } = state.profile;
    
    return {
        packageIndex,
        openForRelocation,
        country,
        region,
        jobTypeArray,
        workScheduleArray,
        gender,
        maritalStatus,
        startBirthDate,
        endBirthDate,
        certificateID,
        jobTitle,
        educationLevel,
        currentEnrollment,
        studiedAbroad,
        yearOfCompletion,
        industryID,
        currentAtWork,
        experienceID,
        experienceYears,
        foreignJob,
        language,
        license,
        militaryCountry,
        industryPrime,
        industrySec,
        positionPrime,
        positionSec,
        skill,
        link,
        results,
        loading,
        resultsSearchLimit,
        lastDoc,
        hasMore,
        total,
        searchCacheKey,
        seekerHighlights,
        profile,
        shortlistLoading,
        lastDocs
    };
};

export default connect(mapStateToProps, {searchDB, clearSearch, fetchAdminHighlights, addToShortlist})(Search);