import React from 'react';
import { Select } from 'antd';
import './CommonSelect.css';

const { Option } = Select;

class SelectEmploymentType extends React.Component {
    render() {
        return (
            <div className="inputTypeSelect">
                <Select
                    showSearch
                    placeholder={this.props.placeholder}
                    optionFilterProp="children"
                    onChange={this.props.onChange}
                    value={this.props.value}
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    <Option value="fullTime">Full Time</Option>
                    <Option value="partTime">Part Time</Option>
                    <Option value="contract">Contractor</Option>
                    <Option value="temporary">Temporary</Option>
                    <Option value="internship">Internship</Option>
                    <Option value="field">Field</Option>
                </Select>
            </div>
        );
    }
}

export default SelectEmploymentType;
