import firebase from 'firebase';
import algoliasearch from 'algoliasearch';
import {
    ADD_TO_SHORTLIST,
    ADD_TO_SHORTLIST_FAILED,
    ADD_TO_SHORTLIST_SUCCESSFUL,
    CLEAR_SEARCH, DELETE_FROM_SHORTLIST, DELETE_FROM_SHORTLIST_FAILED, DELETE_FROM_SHORTLIST_SUCCESSFUL,
    FETCH_ADMIN_HIGHLIGHTS,
    FETCH_ADMIN_HIGHLIGHTS_FAIL,
    FETCH_ADMIN_HIGHLIGHTS_SUCCESS,
    FETCH_CERTIFICATES,
    FETCH_CERTIFICATES_FAILED,
    FETCH_CERTIFICATES_SUCCESSFUL,
    FETCH_EDUCATION_INDUSTRY,
    FETCH_EDUCATION_INDUSTRY_FAILED,
    FETCH_EDUCATION_INDUSTRY_SUCCESSFUL,
    FETCH_EDUCATION_LEVELS,
    FETCH_EDUCATION_LEVELS_FAILED,
    FETCH_EDUCATION_LEVELS_SUCCESSFUL,
    FETCH_JOB_TITLES,
    FETCH_JOB_TITLES_FAILED,
    FETCH_JOB_TITLES_SUCCESSFUL,
    FETCH_LANGUAGES,
    FETCH_LANGUAGES_FAILED,
    FETCH_LANGUAGES_SUCCESSFUL,
    FETCH_LICENSES,
    FETCH_LICENSES_FAILED,
    FETCH_LICENSES_SUCCESSFUL,
    FETCH_LINKS, FETCH_LINKS_FAILED,
    FETCH_LINKS_SUCCESSFUL,
    FETCH_SKILLS,
    FETCH_SKILLS_FAILED,
    FETCH_SKILLS_SUCCESSFUL, SAVE_NOTIFICATIONS, SAVE_NOTIFICATIONS_FAILED, SAVE_NOTIFICATIONS_SUCCESSFUL,
    SAVE_SEARCHES,
    SAVE_SEARCHES_FAIL,
    SAVE_SEARCHES_SUCCESS,
    SEARCH,
    SEARCH_FAILED,
    SEARCH_FIELD_CHANGED,
    SEARCH_SUCCESSFUL,
    PUSH_SEEKER,
    RECENT_VIEW_SEEKER
} from "./Types";
import {message} from "antd";

//property declarations
//property declarations
const client = algoliasearch('35LINDSEFZ', 'c13dbd56579a2df8cd4b736e2c331780');
const assetIndex = client.initIndex('dev_NAFASI');

export const searchFieldChanged = ({ prop, value }) => {
    return {
        type: SEARCH_FIELD_CHANGED,
        payload: { prop, value }
    }
};

export const clearSearch = () => {
    return {
        type: CLEAR_SEARCH
    }
};

export const searchDB = (searchParams) => {
    return async (dispatch) => {
        dispatch({ 
            type: SEARCH,
            payload: { append: searchParams.append }
        });
        
        try {
            const userID = firebase.auth().currentUser.uid;
            const idToken = await firebase.auth().currentUser.getIdToken();
            
            // If lastDoc exists, only send the necessary data
            const searchParamsToSend = {
                ...searchParams,
                userID,
                lastDoc: searchParams.lastDoc ? {
                    id: searchParams.lastDoc.id,
                    data: searchParams.lastDoc.data
                } : null
            };

            const response = await fetch('https://us-central1-nafasiio.cloudfunctions.net/searchCVPrime', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                mode: 'cors',
                body: JSON.stringify(searchParamsToSend)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Search result:', result);

            dispatch({
                type: SEARCH_SUCCESSFUL,
                payload: {
                    seekers: result.seekers || [],
                    lastDoc: result.lastDoc,
                    hasMore: result.hasMore,
                    total: result.total || 0,
                    cacheKey: result.cacheKey,
                    append: searchParams.append
                }
            });

        } catch (error) {
            console.error('Search error:', error);
            dispatch({ type: SEARCH_FAILED });
            throw error;
        }
    };
};


export const saveSearches = ({
    packageIndex,openForRelocation,country, region,jobTypeArray,workScheduleArray,gender,maritalStatus,
    startBirthDate,endBirthDate,certificateID,jobTitle,educationLevel,currentEnrollment,studiedAbroad,
    foreignJob,experience,yearOfCompletion,industryID,currentAtWork,language,license,militaryCountry,
    industryPrime,industrySec,positionPrime,positionSec,skill,link,resultsSearchLimit,searchID,timestamp
}) => {

    return async (dispatch) => {
        try {
            dispatch({type: SAVE_SEARCHES});
            const userID = firebase.auth().currentUser.uid;

            const savedSearchRef = firebase.firestore().collection("adminBucket").doc(userID).collection("savedSearches").doc(searchID);
            await savedSearchRef.set({
                packageIndex,openForRelocation,country, region,jobTypeArray,workScheduleArray,gender,maritalStatus,
                startBirthDate,endBirthDate,certificateID,jobTitle,educationLevel,currentEnrollment,studiedAbroad,
                foreignJob,experience,yearOfCompletion,industryID,currentAtWork,language,license,militaryCountry,
                industryPrime,industrySec,positionPrime,positionSec,skill,link,resultsSearchLimit,searchID,timestamp
            });

            dispatch({type: SAVE_SEARCHES_SUCCESS});

        } catch (e) {
            dispatch({type: SAVE_SEARCHES_FAIL});
            console.log(e);
        }
    }
}



// ---------------------------- SEARCH QUERIES -------------------
export const fetchEducationLevels = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_EDUCATION_LEVELS });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list'
                ]
            }).then(() => {
                // done
            });

            const { hits } = await assetIndex.search('', {
                filters: 'list:educationLevel',
                hitsPerPage: 1000
            });

            let educationLevels = [];
            hits.forEach(hit => {
                if (hit) {
                    if ("title" in hit) {
                        educationLevels.push(hit);
                    }
                }
            });

            dispatch({
                type: FETCH_EDUCATION_LEVELS_SUCCESSFUL,
                payload: educationLevels,
            });

        } catch (error) {
            console.error('Error searching education levels:', error);
            dispatch({
                type: FETCH_EDUCATION_LEVELS_FAILED,
            });
        }
    };
};


// export const fetchEducationLevels = ({ searchQuery }) => {
//     return async (dispatch) => {
//         dispatch({ type: FETCH_EDUCATION_LEVELS });

//         try {
//             assetIndex.setSettings({
//                 attributesForFaceting: [
//                     'list' // or 'filterOnly(brand)' for filtering purposes only
//                 ]
//             }).then(() => {
//                 // done
//             });
//             const {hits} = await assetIndex.search(searchQuery, {
//                 filters: 'list:educationLevel',
//                 hitsPerPage: 5
//             });

//             let educationLevels = [];
//             hits.forEach(hit => {
//                 if (hit) {
//                     if ("title" in hit) {
//                         educationLevels.push(hit);
//                     }
//                 }
//             });

//             dispatch({
//                 type: FETCH_EDUCATION_LEVELS_SUCCESSFUL,
//                 payload: educationLevels,
//             });

//         } catch (error) {
//             console.error('Error searching education levels:', error);
//             dispatch({
//                 type: FETCH_EDUCATION_LEVELS_FAILED,
//             });
//         }
//     };
// };


export const fetchEducationIndustry = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_EDUCATION_INDUSTRY });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list'
                ]
            }).then(() => {
                // done
            });

            const { hits } = await assetIndex.search('', {
                filters: 'list:educationIndustry',
                hitsPerPage: 1000
            });

            let educationIndustries = [];
            hits.forEach(hit => {
                if (hit) {
                    if ("title" in hit) {
                        educationIndustries.push(hit);
                    }
                }
            });

            dispatch({
                type: FETCH_EDUCATION_INDUSTRY_SUCCESSFUL,
                payload: educationIndustries,
            });

        } catch (error) {
            console.error('Error searching education industries:', error);
            dispatch({
                type: FETCH_EDUCATION_INDUSTRY_FAILED,
            });
        }
    };
};


// export const fetchEducationIndustry = ({ searchQuery }) => {
//     return async (dispatch) => {
//         dispatch({ type: FETCH_EDUCATION_INDUSTRY });

//         try {
//             assetIndex.setSettings({
//                 attributesForFaceting: [
//                     'list' // or 'filterOnly(brand)' for filtering purposes only
//                 ]
//             }).then(() => {
//                 // done
//             });
//             const {hits} = await assetIndex.search(searchQuery, {
//                 filters: 'list:educationIndustry',
//                 hitsPerPage: 10
//             });

//             let educationIndustries = [];
//             hits.forEach(hit => {
//                 if (hit) {
//                     if ("title" in hit) {
//                         educationIndustries.push(hit);
//                     }
//                 }
//             });

//             dispatch({
//                 type: FETCH_EDUCATION_INDUSTRY_SUCCESSFUL,
//                 payload: educationIndustries,
//             });

//         } catch (error) {
//             console.error('Error searching education industries:', error);
//             dispatch({
//                 type: FETCH_EDUCATION_INDUSTRY_FAILED,
//             });
//         }
//     };
// }


export const fetchJobTitles = ({ searchQuery }) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_JOB_TITLES });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list' // or 'filterOnly(brand)' for filtering purposes only
                ]
            }).then(() => {
                // done
            });
            const {hits} = await assetIndex.search(searchQuery, {
                filters: 'list:jobTitle',
                hitsPerPage: 10
            });

            let jobTitles = [];
            hits.forEach(hit => {
                if (hit) {
                    if ("title" in hit) {
                        jobTitles.push(hit);
                    }
                }
            });

            dispatch({
                type: FETCH_JOB_TITLES_SUCCESSFUL,
                payload: jobTitles,
            });

        } catch (error) {
            console.error('Error searching job titles:', error);
            dispatch({
                type: FETCH_JOB_TITLES_FAILED,
            });
        }
    };
};

export const fetchSkills = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_SKILLS });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list'
                ]
            }).then(() => {
                // done
            });

            const { hits } = await assetIndex.search('', {
                hitsPerPage: 1000
            });

            let skills = [];
            hits.forEach(hit => {
                if (hit && "title" in hit) {
                    skills.push(hit);
                }
            });

            dispatch({
                type: FETCH_SKILLS_SUCCESSFUL,
                payload: skills,
            });

        } catch (error) {
            console.error('Error searching skills:', error);
            dispatch({
                type: FETCH_SKILLS_FAILED,
            });
        }
    };
};


// export const fetchSkills = ({ searchQuery }) => {
//     return async (dispatch) => {
//         dispatch({ type: FETCH_SKILLS });

//         try {
//             assetIndex.setSettings({
//                 attributesForFaceting: [
//                     'list' // or 'filterOnly(brand)' for filtering purposes only
//                 ]
//             }).then(() => {
//                 // done
//             });
//             const {hits} = await assetIndex.search(searchQuery, {
//                 filters: 'list:skill',
//                 hitsPerPage: 10
//             });

//             let skills = [];
//             hits.forEach(hit => {
//                 if (hit) {
//                     if ("title" in hit) {
//                         skills.push(hit);
//                     }
//                 }
//             });

//             dispatch({
//                 type: FETCH_SKILLS_SUCCESSFUL,
//                 payload: skills,
//             });

//         } catch (error) {
//             console.error('Error searching skills:', error);
//             dispatch({
//                 type: FETCH_SKILLS_FAILED,
//             });
//         }
//     };
// };

export const fetchCertificates = ({ searchQuery }) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_CERTIFICATES });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list' // or 'filterOnly(brand)' for filtering purposes only
                ]
            }).then(() => {
                // done
            });
            const {hits} = await assetIndex.search(searchQuery, {
                filters: 'list:certificate',
                hitsPerPage: 5
            });

            let certificates = [];
            hits.forEach(hit => {
                if (hit) {
                    if ("title" in hit) {
                        certificates.push(hit);
                    }
                }
            });

            dispatch({
                type: FETCH_CERTIFICATES_SUCCESSFUL,
                payload: certificates,
            });

        } catch (error) {
            console.error('Error searching certificates:', error);
            dispatch({
                type: FETCH_CERTIFICATES_FAILED,
            });
        }
    };
};

export const fetchLanguages = ({ searchQuery }) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_LANGUAGES });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list' // or 'filterOnly(brand)' for filtering purposes only
                ]
            }).then(() => {
                // done
            });
            const {hits} = await assetIndex.search(searchQuery, {
                filters: 'list:language',
                hitsPerPage: 5
            });

            let languages = [];
            hits.forEach(hit => {
                if (hit) {
                    if ("title" in hit) {
                        languages.push(hit);
                    }
                }
            });

            dispatch({
                type: FETCH_LANGUAGES_SUCCESSFUL,
                payload: languages,
            });

        } catch (error) {
            console.error('Error searching languages:', error);
            dispatch({
                type: FETCH_LANGUAGES_FAILED,
            });
        }
    };
};

export const fetchLicenses = ({ searchQuery }) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_LICENSES });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list' // or 'filterOnly(brand)' for filtering purposes only
                ]
            }).then(() => {
                // done
            });
            const {hits} = await assetIndex.search(searchQuery, {
                filters: 'list:license',
                hitsPerPage: 5
            });

            let licenses = [];
            hits.forEach(hit => {
                if (hit) {
                    if ("title" in hit) {
                        licenses.push(hit);
                    }
                }
            });

            dispatch({
                type: FETCH_LICENSES_SUCCESSFUL,
                payload: licenses,
            });

        } catch (error) {
            console.error('Error searching licenses:', error);
            dispatch({
                type: FETCH_LICENSES_FAILED,
            });
        }
    };
};

export const fetchCVLinks = ({ searchQuery }) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_LINKS });

        try {
            assetIndex.setSettings({
                attributesForFaceting: [
                    'list' // or 'filterOnly(brand)' for filtering purposes only
                ]
            }).then(() => {
                // done
            });
            const {hits} = await assetIndex.search(searchQuery, {
                filters: 'list:link',
                hitsPerPage: 5
            });

            let links = [];
            hits.forEach(hit => {
                if (hit) {
                    if ("title" in hit) {
                        links.push(hit);
                    }
                }
            });

            dispatch({
                type: FETCH_LINKS_SUCCESSFUL,
                payload: links,
            });

        } catch (error) {
            console.error('Error searching links:', error);
            dispatch({
                type: FETCH_LINKS_FAILED,
            });
        }
    };
};


//--------------------------------------- SHORTLIST

export const addToShortlist = ({ seeker, isCoverLetterRequested, shortlistInfo}) => {
    return(dispatch) => {
        dispatch({ type: ADD_TO_SHORTLIST });
        const userID = firebase.auth().currentUser.uid;
        firebase.firestore().collection("adminBucket").doc(userID).collection("shortlist").doc(seeker.seekerID)
            .set({ ...seeker, addedOn: new Date() })
            .then(() => {
                addSeekerShortlist({seeker, userID,isCoverLetterRequested, shortlistInfo, dispatch});
            })
            .catch(e => {
                dispatch({ type: ADD_TO_SHORTLIST_FAILED });
                console.log(e);
                message.warning(`Could not add ${seeker.firstName} to your shortlist`);
            })
    }
}

// async function addSeekerShortlist({seeker, userID, isCoverLetterRequested, shortlistInfo, dispatch}) {
//     firebase.firestore().collection("adminBucket").doc(userID).collection("highlights").doc(seeker.seekerID)
//     .set({ shortlist: true, seekerID: seeker.seekerID, shortlistDate: new Date() }, {merge: true})
//     .then(() => {
//         dispatch({ type: ADD_TO_SHORTLIST_SUCCESSFUL });
//         message.success(`Added ${seeker.firstName} to your shortlist`);

//         if (isCoverLetterRequested) {

//             sendCoverLetterRequest(seeker, shortlistInfo)
//             .then(() =>{

//                 fetchAdminHighlights();

//             })
            
//         }

//         fetchAdminHighlights();
//     })
//     .catch(e => {
//         dispatch({ type: ADD_TO_SHORTLIST_FAILED });
//         console.log(e);
//         message.warning(`Could not add ${seeker.firstName} to your shortlist`);
//     })
// }

async function addSeekerShortlist({ seeker, userID, isCoverLetterRequested, shortlistInfo, dispatch }) {
    try {
        // Add seeker to the shortlist
        await firebase.firestore().collection("adminBucket").doc(userID).collection("highlights").doc(seeker.seekerID)
            .set({ shortlist: true, seekerID: seeker.seekerID, shortlistDate: new Date() }, { merge: true });

        // Dispatch success action
        dispatch({ type: ADD_TO_SHORTLIST_SUCCESSFUL });
        message.success(`Added ${seeker.firstName} to your shortlist`);

        // Send cover letter request if needed, then fetch admin highlights
        if (isCoverLetterRequested) {
            await sendCoverLetterRequest({ seeker, shortlistInfo, dispatch });
        }

        // Fetch admin highlights
        fetchAdminHighlights();
    } catch (e) {
        // Dispatch failure action and log error
        dispatch({ type: ADD_TO_SHORTLIST_FAILED });
        console.error(e);
        message.warning(`Could not add ${seeker.firstName} to your shortlist`);
    }
}

async function sendCoverLetterRequest({ seeker, shortlistInfo, dispatch }) {
    const requestID = Math.random().toString(36).substring(6).toUpperCase();
    const userID = firebase.auth().currentUser.uid;
    const coverLetterRequested = { coverLetterRequested: true };

    shortlistInfo.requestID = requestID;
    console.log(shortlistInfo);

    try {
        const firestore = firebase.firestore();

        // Reference to the cover letter document
        const docRef = firestore
            .collection("seekers")
            .doc(seeker.seekerID)
            .collection("coverLetters")
            .doc(requestID);

        // Set the document directly (overwrites if it exists, creates otherwise)
        await docRef.set(shortlistInfo);

        // Show success message
        message.success("Cover Letter Request Sent");
        sendNotification({
            notificationTitle: "Cover Letter",
            notificationMessage: `Hi ${seeker.firstName}, you have a new cover letter request from ${shortlistInfo.companyName}`,
            screen: "cover",
            userID: seeker.seekerID,
            dispatch
        });

        // Update the adminBucket highlights with coverLetterRequested
        const adminBucketRef = firestore
            .collection("adminBucket")
            .doc(userID)
            .collection("shortlist")
            .doc(seeker.seekerID);

        await adminBucketRef.set(coverLetterRequested, { merge: true });
    } catch (error) {
        console.error("Error sending seeker request:", error);
        message.error("Failed to send Cover Letter Request. Please try again.");
    }

}

export const requestCoverLetter = ({ seeker, shortlistInfo }) => {

    return async (dispatch) => {

        const requestID = Math.random().toString(36).substring(6).toUpperCase();
        const userID = firebase.auth().currentUser.uid;
        const coverLetterRequested = { coverLetterRequested: true };
        shortlistInfo.requestID = requestID;

        try {
            const firestore = firebase.firestore();

            // Reference to the cover letter document
            const docRef = firestore
                .collection("seekers")
                .doc(seeker.seekerID)
                .collection("coverLetters")
                .doc(requestID);

            // Set the document directly (overwrites if it exists, creates otherwise)
            await docRef.set(shortlistInfo);

            // Show success message
            message.success("Cover Letter Request Sent");
            sendNotification({
                notificationTitle: "Cover Letter",
                notificationMessage: `Hi ${seeker.firstName}, you have a new cover letter request from ${shortlistInfo.companyName}`,
                screen: "cover",
                userID: seeker.seekerID,
                dispatch
            });

            // Update the adminBucket highlights with coverLetterRequested
            const adminBucketRef = firestore
                .collection("adminBucket")
                .doc(userID)
                .collection("shortlist")
                .doc(seeker.seekerID);

            await adminBucketRef.set(coverLetterRequested, { merge: true });
        } catch (error) {
            console.error("Error sending seeker request:", error);
            message.error("Failed to send Cover Letter Request. Please try again.");
        }
    }
};

export const fetchAdminHighlights = () => {

    return(dispatch) => {
        dispatch({ type: FETCH_ADMIN_HIGHLIGHTS });
        let seekerHighlights = [];
        const user = firebase.auth().currentUser;
        firebase.firestore().collection("adminBucket").doc(user.uid).collection("highlights")
            .onSnapshot(function(snapshot) {
                if (snapshot.size === 0) {
                    dispatch({ type: FETCH_ADMIN_HIGHLIGHTS_FAIL });
                } else {
                    snapshot.forEach(function(doc) {
                        seekerHighlights.push(doc.data());
                    });

                    const uniqueArray = seekerHighlights.filter((item, index, self) =>
                        index === self.findIndex(t => t.seekerID === item.seekerID)
                    );

                    dispatch({ type: FETCH_ADMIN_HIGHLIGHTS_SUCCESS, payload: uniqueArray});
                }
            }, function (error) {
                console.log("Error fetching recent searches");
                console.log(error);
                dispatch({ type: FETCH_ADMIN_HIGHLIGHTS_FAIL });
            });
    }
};


export const deleteFromShortlist = ({ seeker }) => {
    return(dispatch) => {
        dispatch({ type: DELETE_FROM_SHORTLIST });
        const userID = firebase.auth().currentUser.uid;
        firebase.firestore().collection("adminBucket").doc(userID).collection("shortlist").doc(seeker.seekerID)
            .delete()
            .then(() => {
                removeSeekerShortlist({seeker, userID, dispatch});
            })
            .catch(e => {
                dispatch({ type: DELETE_FROM_SHORTLIST_FAILED });
                console.log(e);
                message.warning(`Could not delete ${seeker.firstName} from your shortlist`);
            })
    }
}


async function removeSeekerShortlist({seeker, userID, dispatch}) {
    firebase.firestore().collection("adminBucket").doc(userID).collection("highlights").doc(seeker.seekerID)
    .set({ shortlist: false, seekerID: seeker.seekerID, recentShortlist: true, shortlistDate: new Date() }, {merge: true})
    .then(() => {
        dispatch({ type: DELETE_FROM_SHORTLIST_SUCCESSFUL });
        message.success(`Deleted ${seeker.firstName} from your shortlist`);
        fetchAdminHighlights();
        removeCoverLetterRequest(seeker, userID);
    })
    .catch(e => {
        dispatch({ type: DELETE_FROM_SHORTLIST_FAILED });
        console.log(e);
        message.warning(`Could not add ${seeker.firstName} to your shortlist`);
    })
}

async function removeCoverLetterRequest(seeker, userID) {
    try {
        const coverLetterQuerySnapshot = await firebase.firestore()
            .collection("seekers")
            .doc(seeker.seekerID)
            .collection("coverLetters")
            .where("adminID", "==", userID)
            .get();

        // Check if there are documents to delete
        if (coverLetterQuerySnapshot.empty) {
            console.log('No matching documents.');
            return;
        }

       
        const deletePromises = coverLetterQuerySnapshot.docs.map(doc => doc.ref.delete());
        
        // Wait for all delete operations to complete
        await Promise.all(deletePromises);

    } catch (error) {
        console.error('Error removing cover letter requests:', error);
      
    }
}



export const recentViewSeeker = (seeker) => {
    return (dispatch, getState) => {
        const { auth } = getState();
        // Add any necessary logic for recent views
        dispatch({
            type: RECENT_VIEW_SEEKER,
            payload: seeker
        });
    };
};

function sendNotification({ notificationTitle, notificationMessage, screen, userID, dispatch }) {

    const body = JSON.stringify({ notificationTitle, notificationMessage, screen, userID });

    dispatch({ type: SAVE_NOTIFICATIONS });
    fetch("https://us-central1-nafasiio.cloudfunctions.net/sendNotification",  {
        method: 'POST',
        body,
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            console.log("success");

            dispatch({ type: SAVE_NOTIFICATIONS_SUCCESSFUL });

        }).catch(function(error) {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // ...
        // console.log(errorCode);
        console.log("notification compute error");
        console.log(error);
        dispatch({ type: SAVE_NOTIFICATIONS_FAILED });
    });
}

export const pushSeeker = ({ pushedSeeker }) => {
    console.log('pushSeeker action called with:', pushedSeeker);
    return {
        type: PUSH_SEEKER,
        payload: pushedSeeker
    };
};
