import React from "react";
import { connect } from "react-redux";
import _ from 'lodash';
import { message, Modal, Switch, Input, Form, DatePicker, Button } from 'antd';
import { ClipLoader } from "react-spinners";

//action imports
import { notificationFieldChanged, adminNotification, seekerNotification, topicNotification } from "../../../actions/NotificationsActions";

import './ManageNotifications.css';

const { TextArea } = Input;

class ManageNotifications extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            notificationTitle: this.props.notificationTitle,
            description: this.props.description,
            notificationTime: this.props.notificationTime,
            url: this.props.url,

            saveNotificationLoading: this.props.saveNotificationLoading,
            saveNotificationSuccessful: this.props.saveNotificationSuccessful,
            saveNotificationFailed: this.props.saveNotificationFailed,
            profile: this.props.profile,

            adminStatus: true,
            notificationStatus: true
        };

        this.onPickTime = this.onPickTime.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                notificationTitle: nextProps.notificationTitle,
                description: nextProps.description,
                notificationTime: nextProps.notificationTime,
                url: nextProps.url,
                saveNotificationLoading: nextProps.saveNotificationLoading,
                saveNotificationSuccessful: nextProps.saveNotificationSuccessful,
                saveNotificationFailed: nextProps.saveNotificationFailed,
                profile: nextProps.profile
            });
        }
    }


    renderButton() {
        const {saveNotificationLoading}  = this.state;

        if (saveNotificationLoading) {
            return (
                <div className="nafasi-manage-notifications__loading">
                    <ClipLoader size={20} />
                </div>
            );
        } else {
            return  (
                <div className="nafasi-manage-notifications__save-button">
                    <Button type="primary" onClick={this.onSave}>
                        Save
                    </Button>
                </div>
            );
        }
    }

    renderSuccessMessage() {
        const {saveNotificationSuccessful} = this.state;
        //
        if (saveNotificationSuccessful){
            return (
                <div>
                    <p>{message.success("Notification is successfully saved")}</p>
                </div>
            );
        } else {
            return null;
        }
    }

    renderFailedMessage() {
        const {saveNotificationFailed} = this.state;
        //
        if (saveNotificationFailed){
            return (
                <div>
                    <p>{message.error("Sorry! Notification is not saved")}</p>
                </div>
            );
        } else {
            return null;
        }
    }


    onPickTime(value) {
        this.props.notificationFieldChanged({prop: 'notificationTime', value})
    }

    onSave() {
        //extract state
        const { notificationTitle, description, notificationTime, profile, adminStatus, notificationStatus, url } = this.state;

        if (!(_.isEmpty(profile))){
            if (notificationTitle && description) {
                if (notificationStatus) {
                    if (adminStatus) {
                        if (notificationTime) {
                            this.props.adminNotification({ notificationTitle, description, notificationTime })
                        } else {
                            message.warning("Kindly enter the time");
                        }
                    } else {
                        //for seekers
                        this.props.seekerNotification({ notificationTitle, description, screen: "", pdfUrl: "" })
                    }
                } else {
                    //for topics
                    if (url) {
                        //proceed
                        message.info("topics");
                        this.props.topicNotification({ notificationTitle, description, screen: "pdf", url });
                    } else {
                        message.warning("Kindly enter the url");
                    }
                }
            } else {
                message.warning("Kindly fill in all required fields");
            }
        }
    }

    showModal() {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        this.setState({
            visible: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onAdminStatusChange = (checked) => {
        console.log(`switch to ${checked}`);
        this.setState({
            adminStatus: checked
        })
    };

    onNotificationStatusChange = (checked) => {
        console.log(`switch to ${checked}`);
        this.setState({
            notificationStatus: checked
        })
    };

    checkAccess(){
        const { profile } = this.state;
        
        if(!(_.isEmpty(profile))) {
            const {role} = this.state.profile;

            if (role === 'superAdmin') {
                return(
                    <div className="nafasi-manage-notifications__form">
                        <div className="nafasi-manage-notifications__form-group">
                            <Form.Item>
                                <Input
                                    placeholder="Enter notification title"
                                    onChange={e => this.props.notificationFieldChanged({
                                        prop: 'notificationTitle',
                                        value: e.target.value
                                    })}
                                    value={this.state.notificationTitle}
                                />
                            </Form.Item>

                            <Form.Item>
                                <TextArea
                                    rows={4}
                                    placeholder="Enter notification description"
                                    onChange={e => this.props.notificationFieldChanged({
                                        prop: 'description',
                                        value: e.target.value
                                    })}
                                    value={this.state.description}
                                />
                            </Form.Item>

                            <Form.Item>
                                <DatePicker
                                    showTime
                                    placeholder="Select date and time"
                                    onChange={this.onPickTime}
                                    value={this.state.notificationTime}
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>

                            {!this.state.notificationStatus && (
                                <Form.Item>
                                    <Input
                                        placeholder="Enter URL"
                                        onChange={e => this.props.notificationFieldChanged({
                                            prop: 'url',
                                            value: e.target.value
                                        })}
                                        value={this.state.url}
                                    />
                                </Form.Item>
                            )}
                        </div>

                        <div className="nafasi-manage-notifications__switch-group">
                            <div className="nafasi-manage-notifications__switch-container">
                                <Switch
                                    checkedChildren="Notification"
                                    unCheckedChildren="Topic"
                                    onChange={this.onNotificationStatusChange}
                                    checked={this.state.notificationStatus}
                                />
                                <span className="nafasi-manage-notifications__switch-label">
                                    {this.state.notificationStatus ? 'Notification' : 'Topic'}
                                </span>
                            </div>

                            {this.state.notificationStatus && (
                                <div className="nafasi-manage-notifications__switch-container">
                                    <Switch
                                        checkedChildren="Admin"
                                        unCheckedChildren="Seekers"
                                        onChange={this.onAdminStatusChange}
                                        checked={this.state.adminStatus}
                                    />
                                    <span className="nafasi-manage-notifications__switch-label">
                                        {this.state.adminStatus ? 'Admin' : 'Seekers'}
                                    </span>
                                </div>
                            )}
                        </div>

                        {this.renderButton()}
                        {this.renderSuccessMessage()}
                        {this.renderFailedMessage()}
                    </div>
                );
            }
        }
    }

    renderShowButton() {
        const {profile} = this.state;
        
        if (!(_.isEmpty(profile))) {
            const {role} = this.state.profile;

            if (role === 'superAdmin') {
                return (
                    <div className="nafasi-manage-notifications__add-button">
                        <Button type="primary" onClick={() => this.showModal()}>
                            Add Notification
                        </Button>
                    </div>
                );
            }
        }
    }

    render() {
        return (
            <div className="nafasi-manage-notifications">
                {this.renderShowButton()}
                <Modal
                    title={"Add a Notification"}
                    open={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    width={600}
                >
                    {this.checkAccess()}
                </Modal>
            </div>
        );
    }

}

const mapStateToProps = state => {

    const { notificationTitle, description, notificationTime, url, saveNotificationLoading, saveNotificationSuccessful, saveNotificationFailed } = state.notifications;
    const { profile} = state.profile;

    return {
        notificationTitle,
        description,
        notificationTime,
        url,
        profile,

        saveNotificationLoading,
        saveNotificationSuccessful,
        saveNotificationFailed,
    }
};

export default connect(mapStateToProps, { notificationFieldChanged, adminNotification, seekerNotification, topicNotification })(ManageNotifications);