import React from "react";
import { connect } from "react-redux";
import {message, Select} from "antd";

// Action imports
import { searchFieldChanged } from "../../../actions/SearchActions";

//property declarations
const { Option } = Select;
const jobTypeOptions = [
    { label: "Full Time", value: "fullTime" },
    { label: "Part Time", value: "partTime" },
    { label: "Contract", value: "contract" },
    { label: "Internship", value: "internship" },
    { label: "Field", value: "field" },
];

class JobTypePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            jobTypeArray: this.props.jobTypeArray,
            workScheduleArray: this.props.workScheduleArray,
        }

        this.handleJobTypeChange = this.handleJobTypeChange.bind(this);
        this.handleClear = this.handleClear.bind(this);

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                jobTypeArray: nextProps.jobTypeArray,
                workScheduleArray: nextProps.workScheduleArray,
            });
        }
    }

    handleJobTypeChange(selectedJobTypes) {
        const { workScheduleArray } = this.state;
        if (workScheduleArray.length === 0) {
            this.props.searchFieldChanged({prop: "jobTypeArray", value: selectedJobTypes});
        } else {
            message.info("Please select either 'Job Types' or 'Availability', but not both. Use one filter at a time to refine your search results");
        }
    };

    handleClear() {
        this.props.searchFieldChanged({prop: "jobTypeArray", value: []});
    }

    render() {
        const { jobTypeArray } = this.state;

        return (
            <div className="variable-section">
                <p style={{color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left"}}>Job Types</p>
                <Select
                    mode="multiple"
                    placeholder="Select job types"
                    value={jobTypeArray}
                    allowClear
                    onChange={(value) => this.handleJobTypeChange(value)}
                    onClear={this.handleClear}
                    style={{width: "100%", borderColor: '#000'}}
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {jobTypeOptions.map((option) => (
                        <Option key={option.value} value={option.value} label={option.label}>
                            {option.label}
                        </Option>
                    ))}
                </Select>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { jobTypeArray, workScheduleArray} = state.search;

    return {
        jobTypeArray,
        workScheduleArray
    };
};

export default connect(mapStateToProps, { searchFieldChanged })(
    JobTypePicker
);
