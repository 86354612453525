import {
    FETCH_ANNOUNCEMENTS,
    FETCH_ANNOUNCEMENTS_FAILED,
    FETCH_ANNOUNCEMENTS_SUCCESSFUL,
    FETCH_TRAININGS,
    FETCH_TRAININGS_FAILED,
    FETCH_TRAININGS_SUCCESSFUL,

    HOME_LOCATION_CHANGED,
    FETCH_RECENT_SEARCHES_FAILED,
    FETCH_RECENT_SEARCHES,
    FETCH_RECENT_SEARCHES_SUCCESSFUL,
    TOGGLE_MENU
} from "../actions/Types";


const INITIAL_STATE = {
    //fetched objects
    fetchedAnnouncements: [],

    fetchedTrainings: [],

    recentSearches: [],
    recentSearchesLoading: false,
    isToggled: false
};

const deepClone = obj => JSON.parse(JSON.stringify(obj));

const HomeReducer = (state = INITIAL_STATE, action) => {

    switch (action.type) {

        case HOME_LOCATION_CHANGED:
            return { ...state, [action.payload.prop]: action.payload.value };

            //recent searches
        case FETCH_RECENT_SEARCHES:
            return { ...state, recentSearchesLoading: true };
        case FETCH_RECENT_SEARCHES_SUCCESSFUL:
            return { ...state, recentSearchesLoading: false, recentSearches: deepClone(action.payload) };
        case FETCH_RECENT_SEARCHES_FAILED:
            return { ...state, recentSearchesLoading: false};




            //fetching reducers
        case FETCH_ANNOUNCEMENTS:
            return {...state, };
        case FETCH_ANNOUNCEMENTS_SUCCESSFUL:
            return {...state, fetchedAnnouncements: action.payload };
        case FETCH_ANNOUNCEMENTS_FAILED:
            return {...state, };

        case FETCH_TRAININGS:
            return {...state, };
        case FETCH_TRAININGS_SUCCESSFUL:
            return {...state, fetchedTrainings: action.payload };
        case FETCH_TRAININGS_FAILED:
            return {...state, };
        case TOGGLE_MENU:
            console.log('Toggle menu reducer:', state.isToggled);
            return {
                ...state,
                isToggled: !state.isToggled
            };


        default:
            return state;
    }
};

export default HomeReducer;