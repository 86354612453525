import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Military.css';

// Import the action
import { fetchSeekerPath } from '../../../actions/PostsActions';

//components import
import { Spinner } from "../../common/Spinner";
import MilitaryCard from "./cards/MilitaryCard";

class Military extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCandidate: this.props.selectedCandidate,
            military: this.props.military,
            militaryLoading: this.props.militaryLoading,
            profile: this.props.profile,
        }

        this.renderMilitary = this.renderMilitary.bind(this);
    }

    componentDidMount() {
        const { selectedCandidate } = this.props;
        if (selectedCandidate && selectedCandidate.id) {
            this.props.fetchSeekerPath(selectedCandidate.id, "military");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedCandidate !== this.props.selectedCandidate) {
            if (this.props.selectedCandidate && this.props.selectedCandidate.id) {
                this.props.fetchSeekerPath(this.props.selectedCandidate.id, "military");
            }
        }

        if (prevProps !== this.props) {
            this.setState({
                selectedCandidate: this.props.selectedCandidate,
                military: this.props.military,
                militaryLoading: this.props.militaryLoading,
                profile: this.props.profile,
            });
        }
    }

    renderMilitary() {
        const { military, militaryLoading, selectedCandidate } = this.state;

        if (militaryLoading) {
            return <Spinner />
        }

        if (_.isEmpty(selectedCandidate)) {
            return <p style={{ color: "#000", margin: "2rem 0" }}>Select a candidate to view</p>;
        }

        if (!military || military.length === 0) {
            return (
                <div className="no-data-message">
                    <FontAwesomeIcon icon="shield-alt" className="no-data-icon" />
                    <p>No military service listed</p>
                </div>
            );
        }

        return (
            <div className="military-timeline">
                <MilitaryCard military={military}/>
            </div>
        );
    }

    checkUser() {
        const { profile } = this.state;

        if(_.isEmpty(profile)) {
            return <h2 style={{ color: "#000" }}>Sign in to view</h2>;
        }

        if(profile.role !== "admin" && profile.role !== "superAdmin") {
            return <h3>Un-Authorized Access</h3>;
        }

        return (
            <div className="section-container">
                <div className="section-header">
                    <h4>
                        <FontAwesomeIcon icon="shield-alt" className="section-icon" />
                        Military Service
                    </h4>
                    <div className="section-summary">
                        <span className="total-count">
                            {this.state.military?.length || 0} Service Records Listed
                        </span>
                    </div>
                </div>
                <div className="section-content">
                    {this.renderMilitary()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="military-container">
                {this.checkUser()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedCandidate, military, militaryLoading } = state.posts;
    const { profile } = state.profile;

    return {
        selectedCandidate,
        military,
        militaryLoading,
        profile
    }
}

export default connect(mapStateToProps, { fetchSeekerPath })(Military);