import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { Space, Card, Typography, Skeleton, Empty } from 'antd';
import { AccountSetting01Icon } from "hugeicons-react";
import { fetchAdminPosts } from "./../../../actions/PostsActions";
import AdminJobPosts from './cards/AdminJobPosts';
import './AdminPost.css';

const { Title } = Typography;

class AdminPost extends React.Component {
	state = {
		profile: this.props.profile,
		adminPostLoading: this.props.adminPostLoading,
		adminJobs: this.props.adminJobs,
	};

	componentDidMount() {
		this.props.fetchAdminPosts();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps !== this.props) {
			this.setState({
				profile: nextProps.profile,
				adminPostLoading: nextProps.adminPostLoading,
				adminJobs: nextProps.adminJobs,
			});
		}
	}

	renderPosts() {
		const { adminJobs, adminPostLoading } = this.state;

		if (adminPostLoading) {
			return (
				<div className="nafasi-posts__loading">
					{[...Array(3)].map((_, index) => (
						<Card key={index} className="nafasi-posts__skeleton">
							<Skeleton active avatar paragraph={{ rows: 3 }} />
						</Card>
					))}
				</div>
			);
		}

		if (_.isEmpty(adminJobs)) {
			return (
				<Card className="nafasi-posts__empty">
					<Empty
						description={
							<span className="nafasi-posts__empty-text">
								No job posts found
							</span>
						}
					/>
				</Card>
			);
		}

		return (
			<div className="nafasi-posts__content">
				<AdminJobPosts />
			</div>
		);
	}

	checkUser() {
		const { profile } = this.state;

		if (!(_.isEmpty(profile))) {
			return (
				<div className="nafasi-posts__container">
					<div className="nafasi-posts">
						<Space direction="vertical" style={{ width: '100%' }} size="middle">
							<Card size="small" className="nafasi-posts__header-card">
								<div className="nafasi-posts__header">
									<AccountSetting01Icon size={24} className="nafasi-posts__icon" />
									<Title level={4} className="nafasi-posts__title">Job Posts</Title>
								</div>
							</Card>

							{this.renderPosts()}
						</Space>
					</div>
				</div>
			);
		}
		return null;
	}

	render() {
		return this.checkUser();
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile.profile,
	adminPostLoading: state.settings.adminPostLoading,
	adminJobs: state.settings.adminJobs
});

export default connect(mapStateToProps, { fetchAdminPosts })(AdminPost);