import React, { useEffect, useRef } from 'react'
import { ArrowAllDirectionIcon, BoundingBoxIcon, ChartBubble02Icon, ChartRelationshipIcon, ColumnInsertIcon, DashboardCircleRemoveIcon, DeliverySecure01Icon, DistributeHorizontalRightIcon } from 'hugeicons-react'

//-> file imports
import "./RoadMap.css"

export default function RoadMap() {
  const roadmapRef = useRef(null);

  useEffect(() => {
    // Intersection Observer setup
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('point-visible');
        }
      });
    }, { threshold: 0.1 });

    document.querySelectorAll('.point').forEach((point) => {
      observer.observe(point);
    });

    // Mouse move parallax effect
    const handleMouseMove = (e) => {
      const cards = document.querySelectorAll('.point-card');
      const illustrations = document.querySelectorAll('.floating-illustration');
      
      cards.forEach(card => {
        const rect = card.getBoundingClientRect();
        const cardCenterX = rect.left + rect.width / 2;
        const cardCenterY = rect.top + rect.height / 2;
        
        const deltaX = (e.clientX - cardCenterX) / 30;
        const deltaY = (e.clientY - cardCenterY) / 30;
        
        card.style.transform = `perspective(1000px) rotateY(${deltaX}deg) rotateX(${-deltaY}deg) translateZ(10px)`;
      });

      illustrations.forEach(illus => {
        const speed = illus.dataset.speed || 1;
        const x = (window.innerWidth - e.pageX * speed) / 100;
        const y = (window.innerHeight - e.pageY * speed) / 100;
        illus.style.transform = `translateX(${x}px) translateY(${y}px)`;
      });
    };

    const roadmap = roadmapRef.current;
    roadmap.addEventListener('mousemove', handleMouseMove);
    
    return () => {
      observer.disconnect();
      roadmap.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="roadmap" ref={roadmapRef}>
      <div className="roadmap-line"></div>
      
      {/* Points remain the same */}
      <div className="point">
        <div className="point-card">
          <div className="point-index"><DashboardCircleRemoveIcon size={22} color='gold'/></div>
          <div className="point-content">
            <div className="point-label">Centralize Applications</div>
            <div className="point-text">Organize all your email applications in one central platform.</div>
          </div>
        </div>
      </div>
      <div className="point right">
        <div className="point-card">
          <div className="point-index"><DistributeHorizontalRightIcon size={22} color='gold'/></div>
          <div className="point-content">
            <div className="point-label">Smart Matching Algorithms</div>
            <div className="point-text">Get matched with the best options based on your criteria or preferences.</div>
          </div>
        </div>
      </div>
      <div className="point">
        <div className="point-card">
          <div className="point-index"><ArrowAllDirectionIcon size={22} color='gold'/></div>
          <div className="point-content">
            <div className="point-label">Automated Processes</div>
            <div className="point-text">Automate repetitive tasks to focus on what matters most</div>
          </div>
        </div>
      </div>
      <div className="point right">
        <div className="point-card">
          <div className="point-index"><BoundingBoxIcon size={22} color='gold'/></div>
          <div className="point-content">
            <div className="point-label">Performance Tracking</div>
            <div className="point-text">Monitor your application or recruitment performance with detailed metrics.</div>
          </div>
        </div>
      </div>
      <div className="point">
        <div className="point-card">
          <div className="point-index"><ChartBubble02Icon size={22} color='gold'/></div>
          <div className="point-content">
            <div className="point-label">Interview Management</div>
            <div className="point-text">Manage all your interviews in one place</div>
          </div>
        </div>
      </div>
      <div className="point right">
        <div className="point-card">
          <div className="point-index"><ColumnInsertIcon size={22} color='gold'/></div>
          <div className="point-content">
            <div className="point-label">Career Guidance</div>
            <div className="point-text">Access resources and tools for career planning, development, and advancement.</div>
          </div>
        </div>
      </div>
    </div>
  )
}
