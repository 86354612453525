import React from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import arraySort from "array-sort";
import moment from "moment";
import { Table, Modal, message, Popconfirm } from 'antd';
import { PropertyDeleteIcon } from 'hugeicons-react';
import "./JobPosts.css";

//action import
import { deletePost, fetchPostCandidates, selectPost } from "../../../actions/PostsActions";
import EditJobPost from "./EditJobPost";

class JobPostsBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            posts: this.props.posts,
            postsLoading: this.props.postsLoading,
            jobPostDeleted: this.props.jobPostDeleted,
            jobPostDeletedFailed: this.props.jobPostDeletedFailed,

            profile: this.props.profile,
        };

        this.renderPosts = this.renderPosts.bind(this);
        this.onDeletePost = this.onDeletePost.bind(this);
        this.onSelectPost = this.onSelectPost.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                posts: nextProps.posts,
                postsLoading: nextProps.postsLoading,
                jobPostDeleted: nextProps.jobPostDeleted,
                jobPostDeletedFailed: nextProps.jobPostDeletedFailed,
                profile: nextProps.profile
            })
        }
    }

    
    onDeletePost(postID) {
        this.props.deletePost({postID});
    }

    onCancel() {
        message.info("Deletion is canceled");
    }

    renderSuccessMessage() {
        const {jobPostDeleted} = this.state;

        if (jobPostDeleted) {
            return <p>{message.success("Post is deleted successfully")}</p>
        } else {
            return null;
        }
    }

    renderFailedMessage() {
        const {jobPostDeletedFailed} = this.state;

        if (jobPostDeletedFailed) {
            return <p>{message.error("Post is not deleted ")}</p>
        } else {
            return null;
        }
    }

    onSelectPost(post) {
        if(!(_.isEmpty(post))) {
            //

            const {navigate} = this.props;
            const postID = post.postID;

            this.props.selectPost(post);
            this.props.fetchPostCandidates({ postID });
            navigate('/post-review');
        }
    }

    checkCandidatesAccess(post){
        //-> extract state
        const { profile } = this.state;

        //-> check access level
        if(!(_.isEmpty(profile))){
            //-> extract user role
            const { role } = this.state.profile;

            if(role === 'admin' || role === 'superAdmin'){
                return(
                    <div onClick={ () => this.onSelectPost(post)}>
                        <p style={{color: 'rgba(50, 148, 255)', cursor: 'pointer'}}>Candidates</p>
                    </div>
                )
            } else {
                return null;
            }
        }
    };

    checkDeleteAccess(post){
        //-> extract state
        const { profile } = this.state;

        //-> check access level
        if(!(_.isEmpty(profile))){
            //-> extract user role
            const { role } = this.state.profile;

            if(role === 'admin' || role === 'superAdmin'){
                return(
                    <Popconfirm
                        title="Are you sure to delete this post?"
                        onConfirm={() => this.onDeletePost(post.postID)}
                        onCancel={() => this.onCancel()}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div style={{textAlign: 'center'}}><PropertyDeleteIcon size={20} className={"trashIcon2"}/></div>
                    </Popconfirm>
                )
            } else {
                return null;
            }
        }
    };

    checkEditAccess(post){
        //-> extract state
        const { profile } = this.state;

        //-> check access level
        if(!(_.isEmpty(profile))){
            //-> extract user role
            const { role } = this.state.profile;

            if(role === 'admin' || role === 'superAdmin'){
                return(
                    <div style={{textAlign: 'center'}}>
                        <EditJobPost post={post}/>
                    </div>
                )
            } else {
                return null;
            }
        }
    };

    renderPosts() {
        const { posts } = this.state;

        if (!(_.isEmpty(posts))) {
            const sortedPosts = arraySort(posts, "deadline", { reverse: true });

            let processedPosts = sortedPosts.map(post => {
                let seconds = post.deadline.seconds || post.deadline._seconds;
                let time = moment.unix(seconds).format('MMM D, YYYY');
                
                const formattedLocation = `${post.companyLocation.country}, ${post.companyLocation.region}`;
                
                return { ...post, formattedLocation, time };
            });

            const columns = [
                {
                    title: 'Company',
                    dataIndex: 'companyName',
                    key: 'companyName',
                    render: (text) => (
                        <span className="table-cell company">{text}</span>
                    ),
                },
                {
                    title: 'Title',
                    dataIndex: 'postTitle',
                    key: 'postTitle',
                    render: (text) => (
                        <span className="table-cell company">{text}</span>
                    ),
                },
                {
                    title: 'Deadline',
                    dataIndex: 'time',
                    key: 'time',
                    render: (text) => (
                        <span className="table-cell deadline">{text}</span>
                    ),
                },
                {
                    title: 'Type',
                    dataIndex: 'jobType',
                    key: 'jobType',
                    render: (text) => (
                        <span className="table-cell">{text}</span>
                    ),
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    key: 'status',
                    render: (text) => (
                        <span className={`status-badge ${text.toLowerCase() === 'active' ? 'active' : 'inactive'}`}>
                            {text}
                        </span>
                    ),
                },
                {
                    title: 'Location',
                    dataIndex: 'formattedLocation',
                    key: 'formattedLocation',
                    render: (text) => (
                        <span className="table-cell location-cell" title={text}>{text}</span>
                    ),
                },
                {
                    title: 'Review',
                    key: 'review',
                    render: (_, record) => {
                        const { profile } = this.state;
                        if (isEmpty(profile)) {
                            return null;
                        }
                        const { role } = profile;
                        if (role === 'admin' || role === 'superAdmin') {
                            return (
                                <button 
                                    className="action-button review"
                                    onClick={() => this.onSelectPost(record)}
                                >
                                    <span>Candidates</span>
                                </button>
                            );
                        }
                        return null;
                    }
                },
                {
                    title: 'Edit',
                    key: 'edit',
                    render: (_, record) => {
                        const { profile } = this.state;
                        if (isEmpty(profile)) {
                            return null;
                        }
                        const { role } = profile;
                        if (role === 'admin' || role === 'superAdmin') {
                            return (
                                <div className="action-button edit">
                                    <EditJobPost post={record}/>
                                </div>
                            );
                        }
                        return null;
                    }
                },
                {
                    title: 'Actions',
                    key: 'actions',
                    render: (_, record) => {
                        const { profile } = this.state;
                        if (isEmpty(profile)) {
                            return null;
                        }
                        const { role } = profile;
                        if (role === 'admin' || role === 'superAdmin') {
                            return (
                                <Popconfirm
                                    title="Are you sure to delete this post?"
                                    onConfirm={() => this.onDeletePost(record.postID)}
                                    onCancel={() => this.onCancel()}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <button className="action-button delete">
                                        <PropertyDeleteIcon size={20} />
                                    </button>
                                </Popconfirm>
                            );
                        }
                        return null;
                    }
                },
            ];

            return (
                <div className="job-posts-table">
                    <Table 
                        dataSource={processedPosts} 
                        columns={columns} 
                        rowKey="id"
                        pagination={{
                            pageSize: 10,
                            hideOnSinglePage: true,
                            position: ['bottomCenter'],
                            style: { marginTop: '24px' }
                        }}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            );
        }

        return <h2 className="adminAlert">There are currently no job posts, add a job post to start</h2>;
    }

    render() {
        return (
            <div className="viewJobOpeningListBox">
                <h4>Job Post List</h4>
                <div>
                    {this.renderPosts()}
                    {this.renderSuccessMessage()}
                    {this.renderFailedMessage()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile } = state.profile;
    const { posts, postsLoading, jobPostDeleted, jobPostDeletedFailed } = state.posts;

    return {
        profile,

        posts,
        postsLoading,
        jobPostDeleted,
        jobPostDeletedFailed,

    }
};

// Wrapper functional component to use hooks
function JobPosts(props) {
    const navigate = useNavigate();

    return <JobPostsBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, {deletePost, fetchPostCandidates, selectPost })(JobPosts);