import React from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import _ from "lodash";
import { AnalyticsUpIcon } from 'hugeicons-react';

//actions import
import { 
    fetchGenderBasedInterviewOffersByIndustry, 
    findCommonSkills, 
    fetchJobPostStats, 
    fetchInterviewsInfo,
    fetchInterviewsGradingRatio, 
    fetchInterviewResponseTime, 
    averageTimeToHire, 
    fetchCandidatesGender 
} from '../../actions/ReportActions';

//components import
import { Spinner } from "../common/Spinner";
import DoubleBarGraph from './subComponents/DoubleBarGraph';
import LineGraph from './subComponents/SingleBarGraph';
import GeneralTab from './subComponents/GeneralTab';
import DistributionLevel from './subComponents/DistributionLevel';
import './Analytics.css';

const { TabPane } = Tabs;

class Analytics extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            industryCounts: this.props.industryCounts,
            profile: this.props.profile,
            commonSkills:this.props.commonSkills,
            postsPerPosition:this.props.postsPerPosition,
            jobCounts:this.props.jobCounts,
            interviewedCandidates:this.props.interviewedCandidates,
            offeredInterviews:this.props.offeredInterviews,
            fetchingGenderInfo:this.props.fetchingGenderInfo,
            fetchingCommonSkills:this.props.fetchingCommonSkills,
            fetchingCandidatesCount:this.props.fetchingCandidatesCount,
            generalAverageTimeToHire: this.props.generalAverageTimeToHire,
            candidatesGender: this.props.candidatesGender,
            averageResponseTime:this.props.averageResponseTime,
            isFetchingTime:this.props.isFetchingTime
        }
    }

    componentDidMount() {

    this.props.fetchGenderBasedInterviewOffersByIndustry();
    this.props.findCommonSkills();
    this.props.fetchJobPostStats();
    this.props.fetchInterviewsInfo()
    this.props.fetchInterviewResponseTime()
    this.props.averageTimeToHire();
    this.props.fetchCandidatesGender();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                industryCounts:nextProps.industryCounts,
                profile: nextProps.profile,
                commonSkills:nextProps.commonSkills,
                postsPerPosition:nextProps.postsPerPosition,
                jobCounts:nextProps.jobCounts,
                interviewedCandidates:nextProps.interviewedCandidates,
                offeredInterviews:nextProps.offeredInterviews,
                fetchingGenderInfo:nextProps.fetchingGenderInfo,
                fetchingCommonSkills:nextProps.fetchingCommonSkills,
                fetchingCandidatesCount:nextProps.fetchingCandidatesCount,
                generalAverageTimeToHire: nextProps.generalAverageTimeToHire,
                candidatesGender: nextProps.candidatesGender,
                averageResponseTime:nextProps.averageResponseTime,
                isFetchingTime:nextProps.isFetchingTime
            });
        }
    }

    renderComponent() {
        const { 
            profile, 
            interviewedCandidates, 
            offeredInterviews, 
            fetchingGenderInfo, 
            fetchingCommonSkills, 
            fetchingCandidatesCount, 
            generalAverageTimeToHire, 
            candidatesGender, 
            isFetchingTime, 
            averageResponseTime 
        } = this.state;

        if(!(_.isEmpty(profile))) {
            if (profile.role === "admin" || profile.role === "superAdmin") {
                return (
                    <div className="nafasi-analytics__content">
                        <Tabs 
                            defaultActiveKey="1" 
                            className="nafasi-analytics__tabs"
                        >
                            <TabPane 
                                tab={<span className="nafasi-analytics__tab">General</span>} 
                                key="1"
                            >
                                <GeneralTab 
                                    totalJobPosts={this.state.jobCounts}
                                    postsPerPosition={this.state.postsPerPosition}
                                    interviewedCandidates={interviewedCandidates}
                                    offeredInterviews={offeredInterviews}
                                    fetchingCandidatesCount={fetchingCandidatesCount}
                                    generalAverageTimeToHire={generalAverageTimeToHire}
                                    candidatesGender={candidatesGender}
                                    isFetchingTime={isFetchingTime}
                                    averageResponseTime={averageResponseTime}
                                />
                            </TabPane>

                            <TabPane 
                                tab={<span className="nafasi-analytics__tab">Distribution Level</span>} 
                                key="2"
                            >
                                <DistributionLevel />
                            </TabPane>

                            <TabPane 
                                tab={<span className="nafasi-analytics__tab">Industry-Gender</span>} 
                                key="3"
                            >
                                <DoubleBarGraph 
                                    industryCounts={this.state.industryCounts} 
                                    fetchingGenderInfo={fetchingGenderInfo} 
                                />
                            </TabPane>

                            <TabPane 
                                tab={<span className="nafasi-analytics__tab">Common Skills</span>} 
                                key="4"
                            >
                                <LineGraph 
                                    mostCommonSkills={this.state.commonSkills.mostCommonSkills} 
                                    fetchingCommonSkills={fetchingCommonSkills} 
                                />
                            </TabPane>
                        </Tabs>
                    </div>
                );
            } else {
                return (
                    <div className="nafasi-analytics__unauthorized">
                        Unauthorized access
                    </div>
                );
            }
        } else {
            return (
                <div className="nafasi-analytics__unauthorized">
                    No profile
                </div>
            );
        }
    }

    render() {
        return (
            <div className="nafasi-analytics">
                <div className="nafasi-analytics__header">
                    <AnalyticsUpIcon 
                        size={32} 
                        className="nafasi-analytics__icon"
                    />
                    <h2 className="nafasi-analytics__title">
                        Analytics
                    </h2>
                </div>

                {this.renderComponent()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { industryCounts, commonSkills, postsPerPosition, jobCounts ,interviewedCandidates, candidatesGender,
        offeredInterviews, fetchingGenderInfo,fetchingCommonSkills, fetchingCandidatesCount, generalAverageTimeToHire, isFetchingTime,
        averageResponseTime,} = state.reports
    const { profile } = state.profile;

    return {
        profile,
        industryCounts,
        commonSkills,
        postsPerPosition,
        jobCounts,
        interviewedCandidates,
        offeredInterviews, 
        fetchingGenderInfo,
        fetchingCommonSkills,
        fetchingCandidatesCount,
        generalAverageTimeToHire,
        candidatesGender,
        averageResponseTime,
        isFetchingTime

    }
};

export default connect(mapStateToProps,{fetchGenderBasedInterviewOffersByIndustry, findCommonSkills,averageTimeToHire,fetchCandidatesGender, fetchJobPostStats, fetchInterviewsInfo, fetchInterviewsGradingRatio, fetchInterviewResponseTime})(Analytics);

