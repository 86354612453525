import React from 'react';
import { connect } from 'react-redux';
import firebase from "firebase";
import { Form, Input, Button, Tooltip, message } from 'antd';
import { GoogleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import {fillWarning, smileyIcon} from "../common/MessageAlertIcons";
import {isMobile} from 'react-device-detect';

//action imports
import { userSignUp } from "../../actions/AuthenticationActions";

//component imports
import ForgotPassword from "./subComponents/ForgotPassword";

//file imports
import Logo from "../../assets/logos/taglineLogo.png";
import AppleBadge from "../../assets/img/app-store-badge.svg";
import GoogleBadge from "../../assets/img/google-play-badge.svg";

//style imports
import './SignIn.css';


//property declarations

class SignInBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPasswordRules: false,
            loading: this.props.loading
        };

    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.loading !== this.props.loading) {
            this.setState({ loading: nextProps.loading });
        }
    }

    validatePassword = (_, value) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/;
        if (!value) {
            return Promise.reject(new Error('Please input your password!'));
        } else if (!passwordRegex.test(value)) {
            return Promise.reject(new Error('Password must have at least 6 characters, one uppercase letter, and one special character.'));
        }
        return Promise.resolve();
    };

    handlePasswordFocus = () => {
        this.setState({ showPasswordRules: true });
    };

    handlePasswordBlur = () => {
        this.setState({ showPasswordRules: false });
    };

    handleSubmit = async (values) => {
        this.setState({loading: true});

        const { email, password } = values;
        const { navigate } = this.props;
        const there = this;
    
        if (email && password) {
            try {

                function sanitizeEmailForFirestore(e) {
                    const sanitizedEmail = e.replace(/[\/\.\.\_\~%]/g, '').substring(0, 1500);
                    return sanitizedEmail;
                }
                  
                  const userEmail = sanitizeEmailForFirestore(email);

                const docRef = firebase.firestore().collection('activeSessions').doc(userEmail);
                const doc = await docRef.get();

                // if (doc.exists) {
                //     this.setState({loading: false});
                //     message.success({
                //         content: "You are already logged in from another device. Please log out from there first.",
                //         duration: 3,
                //         icon: smileyIcon(),
                //     }).then(() => null);
                // } else {
                    firebase.auth().signInWithEmailAndPassword(email, password)
                    .then( async () => {
                        await docRef.set({ count: 1 });
                        navigate('/');
                        message.success({
                            content: "Yay, You are logged in!",
                            duration: 3,
                            icon: smileyIcon(),
                        }).then(() => null);
                     })
                    .catch((error) => {
                        there.setState({ loading: false });
                        console.log(error);
                        message.warning({
                            content: `${error.message}`,
                            duration: 3,
                            icon: fillWarning(),
                        }).then(() => null);
                    });
                // }
            } catch (error) {
                there.setState({loading: false});
                console.log(error);
                message.warning({
                    content: `${error}`,
                    duration: 3,
                    icon: fillWarning(),
                }).then(() => null);
            }
        } else {
            message.warning('Fill all required fields').then(() => null);
        }
    };

    // handleSubmit = (values) => {
    //     //-> handle login
    //     this.setState({loading: true});

    //     const { email, password } = values;
    //     const { navigate } = this.props;
    //     const there = this;


    //     //-> checking if fields are not empty
    //     if (email && password) {
    //         //sign in
    //         firebase.auth().signInWithEmailAndPassword(email, password)
    //             .then(() => {
    //                 //make sure to push to new page before reloading
    //                 navigate('/');
    //                 //window.location.reload();
    //                 message.success({
    //                     content: " Yay, You are logged in!",
    //                     duration: 3,
    //                     icon: smileyIcon(),
    //                 }).then(() => null);
    //             })
    //             .catch((error) => {
    //                 there.setState({loading: false});
    //                 console.log(error);
    //                 message.warning({
    //                     content: `${error}`,
    //                     duration: 3,
    //                     icon: fillWarning(),
    //                 }).then(() => null);
    //                 // getLoadingFalse();
    //             })
    //     } else {
    //         message.warning('Fill all required fields').then(() => null);
    //     }
    // }

    handleGoogleSignIn = async () => {
        try {
            //check that user already exists
            const { navigate } = this.props;

            const companyLocation = {
                country: '',
                countryCode: '',
                region: '',
                regionCode: '',
                streetAddress: '',
                postalCode: ''
            }
            //call login action from actions
            this.props.userSignUp({ email: '', password: '', firstName: '', lastName: '', phone: '', companyName: '', companyLocation, companyPhone: '', companyEmail: '', logo: '', navigate, google: true, login: true });
        } catch (error) {
            console.error('Google Sign-In Error:', error);
        }
    };

    render() {
        const { showPasswordRules } = this.state;

        if(isMobile) {
            return (
                <div className="mobile-view">
                    <h3 className="section-title" style={{color: "#fff"}}>For Organizations</h3>
                    <p style={{fontFamily: "ramblaBold", color: "#fff", marginBottom: "32px"}}>
                        Complete registration from a computer
                        <br/>
                        <span
                            style={{color: "#0793d9", cursor: "pointer"}}
                            onClick={() => this.props.navigate('/pricing')}
                        > go back</span>
                    </p>
                    
                    <h4 className="section-title" style={{color: "#fff"}}>For Job Applicants</h4>
                    <div className="app-badges">
                        <a href="https://play.google.com/store/apps/details?id=com.nafasi.io" target="_blank" rel="noopener noreferrer">
                            <img src={GoogleBadge} alt="Get it on Google Play" />
                        </a>
                        <a href="https://apps.apple.com/tz/app/nafasi/id6450264936" target="_blank" rel="noopener noreferrer">
                            <img src={AppleBadge} alt="Download on App Store" />
                        </a>
                    </div>
                </div>
            );
        }

        return (
            <div className="signin-container">
                <BackgroundDecoration />
                <Form
                    name="login"
                    onFinish={this.handleSubmit}
                    className="signin-form"
                    scrollToFirstError
                >
                    <img src={Logo} alt="nafasi logo"/>
                    <h2 className="section-title">For Organizations</h2>

                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'Please enter a valid email address',
                            },
                            {
                                required: true,
                                message: 'Email is required',
                            },
                        ]}
                    >
                        <Input placeholder="Email" size="large" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Password is required',
                            }
                        ]}
                    >
                        <Input
                            type="password"
                            placeholder="Password"
                            onFocus={this.handlePasswordFocus}
                            onBlur={this.handlePasswordBlur}
                            className="filter-search-input"
                        />
                    </Form.Item>

                    {showPasswordRules && (
                        <div className="password-rules">
                            <InfoCircleOutlined style={{marginRight: '4px'}}/>
                            Password must have at least 6 characters, one uppercase letter, and one special character
                        </div>
                    )}

                    <ForgotPassword/>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={this.state.loading}>
                            Sign In
                        </Button>
                        <Button type="default" icon={<GoogleOutlined/>} onClick={this.handleGoogleSignIn}>
                            Sign in with Google
                        </Button>
                    </Form.Item>

                    <Link to="/register">
                        <p className="register-link">
                            Don't have an account? Register here
                        </p>
                    </Link>

                    <div className="signin-divider">
                        <span>For Job Applicants</span>
                    </div>

                    <div className="app-badges">
                        <a href="https://play.google.com/store/apps/details?id=com.nafasi.io" target="_blank" rel="noopener noreferrer">
                            <img src={GoogleBadge} alt="Get it on Google Play" />
                        </a>
                        <a href="https://apps.apple.com/tz/app/nafasi/id6450264936" target="_blank" rel="noopener noreferrer">
                            <img src={AppleBadge} alt="Download on App Store" />
                        </a>
                    </div>
                </Form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { loading } = state.authentication;
    return {
        loading,

    }
};

// Wrapper functional component to use hooks
function SignIn(props) {
    const navigate = useNavigate();

    return <SignInBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, { userSignUp })(SignIn);

const BackgroundDecoration = () => {
    // Create 20 random dots
    const dots = Array.from({ length: 20 }).map((_, i) => ({
        id: i,
        top: `${Math.random() * 100}%`,
        left: `${Math.random() * 100}%`,
        animationDelay: `${Math.random() * 2}s`
    }));

    return (
        <div className="background-decoration">
            {dots.map(dot => (
                <div
                    key={dot.id}
                    className="decoration-dot"
                    style={{
                        top: dot.top,
                        left: dot.left,
                        animationDelay: dot.animationDelay
                    }}
                />
            ))}
        </div>
    );
};