import React, { useState, useEffect } from 'react';
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
    const year = moment().format("YYYY");
    const [country, setCountry] = useState("Kenya");

    useEffect(() => {
        // Get user's location and convert to country name
        fetch('https://ipapi.co/json/')
            .then(response => response.json())
            .then(data => {
                setCountry(data.country_name);
            })
            .catch(() => {
                setCountry("Tanzania"); // Fallback
            });
    }, []);

    return (
        <footer className="apple-footer">
            <div className="footer-about">
                <h4>Don't Miss Out.</h4>
                <p>
                    Nafasi is dedicated to connecting job seekers with their dream careers. 
                    Our platform offers the tools and resources needed to land the perfect job.
                    Join us to start your journey today. We provide a comprehensive suite of job search tools and resources, 
                    including resume building, interview preparation, and job matching.
                    Our mission is to empower job seekers to achieve their career goals by providing them with the most 
                    relevant and up-to-date job listings, company information, and career advice.
                    Nafasi is available on the App Store and Play Store, and our website offers exclusive features 
                    for recruiters to manage their hiring process efficiently.
                </p>
            </div>

            <div className="footer-content">
                <div className="footer-section">
                    <h6>Services</h6>
                    <ul>
                        <li><NavLink to="/pricing">Pricing</NavLink></li>
                        <li><NavLink to="/developers">API</NavLink></li>
                    </ul>
                </div>
                
                <div className="footer-section">
                    <h6>Customer Support</h6>
                    <ul>
                        <li><NavLink to="/faq">FAQ</NavLink></li>
                        <li><NavLink to="/contact-us">Contact Us</NavLink></li>
                        <li><NavLink to="/contact-us">Location</NavLink></li>
                    </ul>
                </div>

                <div className="footer-section">
                    <h6>Legal</h6>
                    <ul>
                        <li><NavLink to="/terms">Terms & Conditions</NavLink></li>
                        <li><NavLink to="/privacy">Privacy Policy</NavLink></li>
                        <li><NavLink to="/disclosure">Disclosure</NavLink></li>
                    </ul>
                </div>

                <div className="footer-section">
                    <h6>Social Media</h6>
                    <ul>
                        <li>
                            <a href="https://twitter.com/nafasiio" target="_blank" rel="noreferrer noopener">
                                <FontAwesomeIcon icon={['fab', 'twitter']} /> Twitter
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/nafasiio" target="_blank" rel="noreferrer noopener">
                                <FontAwesomeIcon icon={['fab', 'linkedin']} /> LinkedIn
                            </a>
                        </li>
                        <li>
                            <a href="https://instagram.com/nafasi.io?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noreferrer noopener">
                                <FontAwesomeIcon icon={['fab', 'instagram']} /> Instagram
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="footer-mini">
                <div className="footer-legal">
                    <div className="footer-copyright">
                        <p>Copyright © {year} Nafasi. All rights reserved.</p>
                    </div>
                    <div className="footer-locale">
                        <p>{country}</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;