import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { Space, Card, Typography, Skeleton, Empty } from 'antd';
import { UserAdd01Icon } from "hugeicons-react";
import RecruitersRequestsCard from "./cards/RecruitersRequestsCard";
import './RecruitersRequests.css';

const { Title } = Typography;

class RecruitersRequests extends React.Component {
	state = {
		profile: this.props.profile,
		recruitersRequests: this.props.recruitersRequests,
		loading: this.props.loading,
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps !== this.props) {
			this.setState({
				profile: nextProps.profile,
				recruitersRequests: nextProps.recruitersRequests,
				loading: nextProps.loading,
			});
		}
	}

	renderRequests() {
		const { recruitersRequests, loading } = this.state;

		if (loading) {
			return (
				<div className="nafasi-requests__loading">
					{[...Array(3)].map((_, index) => (
						<Card key={index} className="nafasi-requests__skeleton">
							<Skeleton active avatar paragraph={{ rows: 3 }} />
						</Card>
					))}
				</div>
			);
		}

		const requests = _.orderBy(recruitersRequests, ['createdOn'], ['desc']);

		if (requests.length === 0) {
			return (
				<Card className="nafasi-requests__empty">
					<Empty
						description={
							<span className="nafasi-requests__empty-text">
								No recruiter requests found
							</span>
						}
					/>
				</Card>
			);
		}

		return (
			<div className="nafasi-requests__content">
				<RecruitersRequestsCard items={requests} />
			</div>
		);
	}

	checkUser() {
		const { profile } = this.state;

		if (!(_.isEmpty(profile)) && profile.role === "superAdmin") {
			return (
				<div className="nafasi-requests__container">
					<div className="nafasi-requests">
						<Space direction="vertical" style={{ width: '100%' }} size="middle">
							<Card size="small" className="nafasi-requests__header-card">
								<div className="nafasi-requests__header">
									<UserAdd01Icon size={24} className="nafasi-requests__icon" />
									<Title level={4} className="nafasi-requests__title">Recruiters</Title>
								</div>
							</Card>

							{this.renderRequests()}
						</Space>
					</div>
				</div>
			);
		}
		return null;
	}

	render() {
		return this.checkUser();
	}
}

const mapStateToProps = (state) => ({
	profile: state.profile.profile,
	recruitersRequests: state.settings.recruitersRequests,
	loading: state.settings.loading
});

export default connect(mapStateToProps)(RecruitersRequests);