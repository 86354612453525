import React from 'react';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Form, Input, message} from 'antd';
import {BarLoader} from 'react-spinners';
import {NavLink} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import { faHeadset, faPhoneAlt, faEnvelope, faBriefcase, faMapMarkerAlt, faUser, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

// Add icons to library
library.add(faHeadset, faPhoneAlt, faEnvelope, faBriefcase, faMapMarkerAlt, faUser, faPaperPlane);

//-> action imports
import {contactUsDetailsChanged, saveContactDetails} from '../../actions/LandingActions';

//-> component imports
import Logo from '../../assets/logos/taglineLogo.png';

//style imports
import './ContactUs.css';

class ContactUs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            contactName: this.props.contactName,
            contactEmail: this.props.contactEmail,
            contactPhone: this.props.contactPhone,
            contactNote: this.props.contactNote,

            contactLoading: this.props.contactLoading,
            contactLoadingSuccess: this.props.contactLoadingSuccess,
            contactLoadingFailed: this.props.contactLoadingFailed,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                contactName: nextProps.contactName,
                contactEmail: nextProps.contactEmail,
                contactPhone: nextProps.contactPhone,
                contactNote: nextProps.contactNote,
                contactLoading: nextProps.contactLoading,
                contactLoadingSuccess: nextProps.contactLoadingSuccess,
                contactLoadingFailed: nextProps.contactLoadingFailed,
            });
        }
    }

    onSend() {
        //-> extract state
        const { contactName, contactEmail, contactPhone, contactNote } = this.state;
        this.setState({loading: true});

        if (contactName && contactEmail && contactPhone && contactNote) {
            function sanitizeEmailForFirestore(e) {
                return e.replace(/[\/\.\.\_\~%]/g, '').substring(0, 1500);
            }

            const userEmail = sanitizeEmailForFirestore(contactEmail);
            this.props.saveContactDetails({ contactName, contactEmail: userEmail, contactPhone, contactNote });
        } else {
            message.warning('Please fill all the details to send us a message').then(() => null);
        }
    }

    handleSubmit() {
        const { contactLoading } = this.state;

        if (contactLoading) {
            return (
                <div>
                    <BarLoader size={20} css={{ textAlign: 'center' }} />
                </div>
            );
        } else {
            return (
                <div className="modalButton" style={{ border: "none", marginTop: "1rem"}}>
                    <Button type="default" onClick={() => this.onSend()}>
                        <span>Send Message</span>
                    </Button>
                </div>
            );
        }
    }

    renderLogo() {
        if (isMobile) {
            return (
                <NavLink to="/">
                    <img src={Logo} alt="nafasi icon logo" />
                </NavLink>
            );
        }
    }

    render() {
        return (
            <div className="contact-page">
                <div className="contact-hero">
                    <h1>Contact Nafasi</h1>
                    <p>We're here to help and answer any question you might have</p>
                </div>

                <div className="contact-grid">
                    <div className="contact-card support">
                        <div className="card-content">
                            <FontAwesomeIcon icon="headset" className="card-icon" />
                            <h3>Support</h3>
                            <p>Need help with something? We're here for you.</p>
                            <div className="contact-info">
                                <div className="info-item">
                                    <FontAwesomeIcon icon="phone-alt" className="info-icon" />
                                    <p>+255 22 261 7000</p>
                                </div>
                                <div className="info-item">
                                    <FontAwesomeIcon icon="envelope" className="info-icon" />
                                    <p>support@nafasi.io</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="contact-card sales">
                        <div className="card-content">
                            <FontAwesomeIcon icon="briefcase" className="card-icon" />
                            <h3>Sales Inquiries</h3>
                            <p>Interested in our business solutions?</p>
                            <div className="contact-info">
                                <div className="info-item">
                                    <FontAwesomeIcon icon="envelope" className="info-icon" />
                                    <p>business@nafasi.io</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="contact-card location">
                        <div className="card-content">
                            <FontAwesomeIcon icon="map-marker-alt" className="card-icon" />
                            <h3>Visit Us</h3>
                            <p>Come say hello at our office in Mbezi Beach</p>
                        </div>
                        <div className="map-wrapper">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.3867757654025!2d39.22812185005812!3d-6.722570167573401!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c5163c81a0ca9%3A0xb51658ed8dce81dd!2sFirm%2023!5e0!3m2!1sen!2stz!4v1615366077200!5m2!1sen!2stz"
                                width="100%"
                                frameBorder="0"
                                allowFullScreen=""
                                title="Contact Us Map"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>

                <div className="contact-form-section">
                    <h2>Send us a message</h2>
                    <div className="contact-form-container">
                        <Form
                            name="contact"
                            onFinish={this.handleSubmit}
                            className="contact-form"
                            layout="vertical"
                            scrollToFirstError
                        >
                            <div className="form-row">
                                <Form.Item
                                    name="name"
                                    rules={[{ required: true, message: 'Please input your name!' }]}
                                >
                                    <Input 
                                        prefix={<FontAwesomeIcon icon="user" className="input-icon" />}
                                        placeholder="Your Name" 
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="email"
                                    rules={[
                                        { type: 'email', message: 'Invalid email format' },
                                        { required: true, message: 'Please input your email' }
                                    ]}
                                >
                                    <Input 
                                        prefix={<FontAwesomeIcon icon="envelope" className="input-icon" />}
                                        placeholder="Email" 
                                    />
                                </Form.Item>
                            </div>

                            <Form.Item 
                                name="number" 
                                rules={[{ required: true, message: 'Please input your phone number' }]}
                            >
                                <Input 
                                    prefix={<FontAwesomeIcon icon="phone-alt" className="input-icon" />}
                                    placeholder="Phone Number" 
                                />
                            </Form.Item>

                            <Form.Item 
                                name="message" 
                                rules={[{ required: true, message: 'Please input your message' }]}
                            >
                                <Input.TextArea 
                                    placeholder="How can we help you?" 
                                    rows={4}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button 
                                    type="primary" 
                                    htmlType="submit" 
                                    className="submit-button"
                                    loading={this.state.contactLoading}
                                >
                                    Send Message
                                    <FontAwesomeIcon icon="paper-plane" className="button-icon" />
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { contactName, contactEmail, contactPhone, contactNote, contactLoading, contactLoadingSuccess, contactLoadingFailed } = state.landing;

    return {
        contactName,
        contactEmail,
        contactPhone,
        contactNote,
        contactLoading,
        contactLoadingSuccess,
        contactLoadingFailed,
    };
};

export default connect(mapStateToProps, { contactUsDetailsChanged, saveContactDetails })(ContactUs);