import React, { Component } from 'react';
import { connect } from "react-redux";
import firebase from "firebase";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Space, Card, Typography, Input, Button, Form, message } from 'antd';
import { LicenseDraftIcon } from "hugeicons-react";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './BlogEditor.css';

//action imports
import { saveBlog, settingsFieldChanged } from "../../../actions/SettingsActions";

const { Title } = Typography;
const { TextArea } = Input;

class BlogEditor extends Component {
	constructor(props) {
		super(props);
		const contentBlock = htmlToDraft('');
		let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
		const editorState = EditorState.createWithContent(contentState);

		this.state = {
			editorState,
			title: "",
			description: "",
			saving: false
		};
	}

	componentWillReceiveProps(nextProps) {
		if (this.props !== nextProps) {
			this.setState({
				editorState: nextProps.editorState,
				title: nextProps.title,
				description: nextProps.description
			});
		}
	}

	onEditorStateChange = (editorState) => {
		this.props.settingsFieldChanged({ prop: "editorState", value: editorState })
	};

	saveContent = async () => {
		const { title, description } = this.state;
		
		if (!title.trim() || !description.trim()) {
			message.error('Please fill in all required fields');
			return;
		}

		this.setState({ saving: true });
		
		try {
			const contentState = this.state.editorState.getCurrentContent();
			const html = draftToHtml(convertToRaw(contentState));
			await this.props.saveBlog({ html, title, description });
			message.success('Blog post saved successfully');
		} catch (error) {
			message.error('Failed to save blog post');
		} finally {
			this.setState({ saving: false });
		}
	};

	loadContent = (htmlContent) => {
		const contentBlock = htmlToDraft(htmlContent);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorState = EditorState.createWithContent(contentState);
			this.props.settingsFieldChanged({ prop: "editorState", value: editorState })
		}
	}

	uploadImageCallBack = (file) => {
		return new Promise((resolve, reject) => {
			if (file.size > 2 * 1024 * 1024) {
				message.error('File size cannot exceed 2MB');
				reject('File size cannot exceed 2MB');
				return;
			}

			const timestamp = new Date().getTime();
			const fileName = `blogImages/${timestamp}_${file.name}`;
			const uploadTask = firebase.storage.ref(fileName).put(file);

			uploadTask.on(
				firebase.storage.TaskEvent.STATE_CHANGED,
				(snapshot) => {
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					message.loading(`Uploading image: ${Math.round(progress)}%`);
				},
				(error) => {
					message.error('Failed to upload image');
					reject(error);
				},
				() => {
					uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
						message.success('Image uploaded successfully');
						resolve({ data: { link: downloadURL } });
					});
				}
			);
		});
	};

	render() {
		const { editorState, saving } = this.state;
		
		return (
			<div className="nafasi-blog-editor__container">
				<div className="nafasi-blog-editor">
					<Space direction="vertical" style={{ width: '100%' }} size="middle">
						<Card size="small" className="nafasi-blog-editor__header-card">
							<div className="nafasi-blog-editor__header">
								<LicenseDraftIcon size={24} className="nafasi-blog-editor__icon" />
								<Title level={4} className="nafasi-blog-editor__title">Create Blog Post</Title>
							</div>
						</Card>

						<Card size="small" className="nafasi-blog-editor__content">
							<Form layout="vertical" className="nafasi-blog-editor__form">
								<Form.Item 
									label="Title" 
									required
									className="nafasi-blog-editor__form-item"
								>
									<Input
										placeholder="Enter blog title"
										onChange={e => this.props.settingsFieldChanged({ prop: "title", value: e.target.value })}
										value={this.state.title}
									/>
								</Form.Item>

								<Form.Item 
									label="Description" 
									required
									className="nafasi-blog-editor__form-item"
								>
									<TextArea
										placeholder="Enter blog description"
										onChange={e => this.props.settingsFieldChanged({ prop: "description", value: e.target.value })}
										value={this.state.description}
										rows={4}
									/>
								</Form.Item>

								<div className="nafasi-blog-editor__wysiwyg">
									<Editor
										editorState={editorState}
										wrapperClassName="nafasi-blog-editor__wrapper"
										editorClassName="nafasi-blog-editor__editor"
										onEditorStateChange={this.onEditorStateChange}
										toolbar={{
											image: {
												uploadCallback: this.uploadImageCallBack,
												alt: { present: true, mandatory: true }
											},
											fontFamily: {
												options: ['Rambla', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman'],
												className: undefined,
												component: undefined,
												dropdownClassName: undefined,
											}
										}}
										defaultFontFamily="Rambla"
									/>
								</div>

								<div className="nafasi-blog-editor__actions">
									<Button
										type="primary"
										onClick={this.saveContent}
										loading={saving}
										className="nafasi-blog-editor__save-btn"
									>
										Save Post
									</Button>
								</div>
							</Form>
						</Card>
					</Space>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const { title, description, editorState } = state.settings;
	return { title, description, editorState };
}

export default connect(mapStateToProps, { saveBlog, settingsFieldChanged })(BlogEditor);