import React from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import { stack as Menu } from "react-burger-menu";
import '../styles/Landing.css';

//action imports

//-> file imports
import Logo from '../assets/logos/logo.png';

//-> component imports
import SignIn from "./authentication/SignIn";
import SignUp from "./authentication/SignUp";
import LandingPage from "./landing/SplitLanding";
import Faq from "./landing/Faq";
import ContactUs from "./landing/ContactUs";
import Footer from "./common/Footer";
import Pricing from './landing/Pricing';
import Download from "./download/Download";
import Blog from "./settings/blogs/Blog";
import Reference from "./landing/Reference";
import Privacy from './legal/Privacy';
import TermsAndConditions from './legal/TermsAndConditions';
import Developers from './landing/Developers'
import Disclosure from './legal/Disclosure';
import ChangeDevice from './landing/ChangeDevice';

const navStyles = {
    navContainer: {
        height: '48px', // Reduced height like Apple's nav
        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
        backdropFilter: 'blur(20px)', // Blur effect like Apple's nav
        WebkitBackdropFilter: 'blur(20px)', // For Safari support
        boxShadow: 'none', // Remove shadow for cleaner look
        position: 'fixed',
        width: '100%',
        top: 0,
        zIndex: 1000,
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)' // Subtle border
    },
    navList: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: '0 20px',
        maxWidth: '1200px', // Maximum width for content
        margin: '0 auto', // Center the nav content
        listStyle: 'none'
    },
    navLogo: {
        height: '28px', // Smaller logo
        marginRight: 'auto',
        opacity: '0.8',
        transition: 'opacity 0.2s ease'
    },
    navLink: {
        textDecoration: 'none',
        color: '#1d1d1f',
        padding: '0 16px',
        fontSize: '0.82rem',
        fontWeight: '400',
        letterSpacing: '-0.01em',
        transition: 'all 0.2s ease',
        opacity: '0.8',
        fontFamily: "ramblaRegular"
    },
    consoleButton: {
        backgroundColor: '#000000',
        color: '#ffffff',
        padding: '6px 16px',
        borderRadius: '16px',
        fontSize: '0.82rem',
        fontWeight: '400',
        letterSpacing: '-0.01em',
        transition: 'all 0.3s ease',
        marginLeft: '16px',
        position: 'relative',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        transform: 'translateY(0)',
        boxShadow: `
            0 1px 2px rgba(0, 0, 0, 0.1),
            0 2px 4px rgba(0, 0, 0, 0.1),
            0 4px 8px rgba(0, 0, 0, 0.1)
        `,
        overflow: 'hidden',
        textDecoration: 'none'
    }
};

const mobileMenuStyles = {
    bmBurgerButton: {
        position: 'absolute',
        width: '20px',
        height: '16px',
        right: '16px',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1001,
    },
    bmBurgerBars: {
        background: '#000000',
        height: '2px',
        borderRadius: '2px',
    },
    bmCrossButton: {
        height: '24px',
        width: '24px',
        right: '16px',
        top: '16px',
    },
    bmCross: {
        background: '#000000',
    },
    bmMenuWrap: {
        position: 'fixed',
        top: '0',
        height: '100vh',
        width: '100%',
        maxWidth: '320px',
        right: '0',
    },
    bmMenu: {
        background: 'rgba(255, 255, 255, 0.98)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        padding: '60px 24px 24px',
        height: '100%',
        boxSizing: 'border-box',
        overflow: 'auto',
    },
    bmItem: {
        display: 'block !important',
        padding: '16px 0',
        color: '#1d1d1f',
        textDecoration: 'none',
        fontSize: '1rem',
        fontWeight: '400',
        letterSpacing: '-0.01em',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        opacity: 1,
        visibility: 'visible',
    },
    bmItemList: {
        padding: '0',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)',
        backdropFilter: 'blur(5px)',
        WebkitBackdropFilter: 'blur(5px)',
        top: 0,
        left: 0,
    }
};

class LandingBase extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            menuOpen: false,
        }
    }

    //handle state change for the mobile side menu
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})
    }

    //close the mobile side menu
    closeMenu (){
        this.setState({menuOpen: false})
    }

    componentDidMount() {
        console.log('Landing component mounted');
    }

    renderSystem() {
        console.log('Is mobile',isMobile )
       
        if(isMobile) {
            return (
                <Routes className="landing-body">
                    <Route exact path={"/"} element={<LandingPage/>} />
                    <Route exact path={"/faq"} element={<Faq/>} />
                    <Route exact path={"/contact-us"} element={<ContactUs/>} />

                    <Route exact path={"/register"} element={<SignUp/>}/>
                    <Route exact path={"/pricing"} element={<Pricing/>} />
                    <Route exact path={"/download"} element={<Download/>} />
                    <Route exact path={"/blog/:id"} element={<Blog/>} />
                    <Route exact path={"/reference/:id"} element={<Reference/>} />
                    <Route exact path={"/privacy"} element={<Privacy/>} />
                    <Route exact path={"/disclosure"} element={<Disclosure/>} />
                    <Route exact path={"/terms"} element={<TermsAndConditions/>} />
                    <Route exact path={"/developers"} element={<Developers/>} />
                </Routes>
            )
        } else {
            return (
                <Routes>
                    <Route exact path={"/"} element={<LandingPage/>}/>
                    <Route exact path={"/faq"} element={<Faq/>}/>
                    <Route exact path={"/contact-us"} element={<ContactUs/>}/>
                    <Route exact path={"/login"} element={<SignIn/>}/>
                    <Route exact path={"/register"} element={<SignUp/>}/>
                    <Route exact path={"/pricing"} element={<Pricing/>}/>
                    <Route exact path={"/download"} element={<Download/>}/>
                    <Route exact path={"/blog/:id"} element={<Blog/>} />
                    <Route exact path={"/reference/:id"} element={<Reference/>} />
                    <Route exact path={"/privacy"} element={<Privacy/>} />
                    <Route exact path={"/disclosure"} element={<Disclosure/>} />
                    <Route exact path={"/terms"} element={<TermsAndConditions/>} />
                    <Route exact path={"/developers"} element={<Developers/>} />
                    <Route exact path={"/change-device"} element={<ChangeDevice/>} />
                </Routes>
            )
        }
    }

    renderNavigation(){
        console.log('Rendering navigation');
       
        if(isMobile){
            return (
                <div style={{
                    ...navStyles.navContainer,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 16px',
                    position: 'relative'
                }}>
                    <NavLink to={'/'}>
                        <img 
                            src={Logo} 
                            alt="nafasi icon logo" 
                            style={{
                                ...navStyles.navLogo,
                                marginRight: 0,
                                height: '24px'
                            }} 
                        />
                    </NavLink>
                    <Menu
                        pageWrapId={"page-wrap"}
                        outerContainerId={"outer-container"}
                        right
                        isOpen={this.state.menuOpen}
                        onStateChange={(state) => this.handleStateChange(state)}
                        styles={mobileMenuStyles}
                    >
                        <div style={{ 
                            height: '100%', 
                            display: 'flex', 
                            flexDirection: 'column',
                            paddingTop: '20px'
                        }}>
                            <div style={{ flex: 1 }}>
                                <NavLink to={'/'} className={'menu-item'} onClick={() => this.closeMenu()}>
                                    Home
                                </NavLink>
                                <NavLink to={'/faq'} className={'menu-item'} onClick={() => this.closeMenu()}>
                                    FAQ
                                </NavLink>
                                <NavLink to={'/contact-us'} className={'menu-item'} onClick={() => this.closeMenu()}>
                                    Contact us
                                </NavLink>
                                <NavLink to={'/pricing'} className={'menu-item'} onClick={() => this.closeMenu()}>
                                    Pricing
                                </NavLink>
                                <NavLink to={'/developers'} className={'menu-item'} onClick={() => this.closeMenu()}>
                                    Developers
                                </NavLink>
                            </div>
                            <NavLink 
                                to={'/download'} 
                                className={'menu-item console-button'} 
                                onClick={() => this.closeMenu()}
                                style={{
                                    marginTop: 'auto',
                                    padding: '12px 20px',
                                    background: '#000000',
                                    color: '#ffffff !important',
                                    borderRadius: '16px',
                                    border: 'none',
                                    textAlign: 'center',
                                    marginBottom: '24px',
                                    textDecoration: 'none',
                                }}
                            >
                                <span style={{ color: '#ffffff' }}>Sign Up</span>
                            </NavLink>
                        </div>
                    </Menu>
                </div>
            )
        } else {
            return (
                <nav style={navStyles.navContainer}>
                    <div style={navStyles.navList}>
                        <NavLink to={'/'} style={{ marginRight: 'auto' }}>
                            <img src={Logo} alt="nafasi icon logo" style={navStyles.navLogo} />
                        </NavLink>
                        <NavLink 
                            to={'/faq'} 
                            style={navStyles.navLink}
                            className={({isActive}) => isActive ? 'active-nav-link' : ''}
                        >
                            FAQ
                        </NavLink>
                        <NavLink 
                            to={'/contact-us'} 
                            style={navStyles.navLink}
                            className={({isActive}) => isActive ? 'active-nav-link' : ''}
                        >
                            Contact us
                        </NavLink>
                        <NavLink 
                            to={'/pricing'} 
                            style={navStyles.navLink}
                            className={({isActive}) => isActive ? 'active-nav-link' : ''}
                        >
                            Pricing
                        </NavLink>
                        <NavLink 
                            to={'/developers'} 
                            style={navStyles.navLink}
                            className={({isActive}) => isActive ? 'active-nav-link' : ''}
                        >
                            Developers
                        </NavLink>
                        <NavLink 
                            to={'/login'} 
                            style={navStyles.consoleButton}
                            className={({isActive}) => isActive ? 'active-console-button' : ''}
                        >
                            <span style={{ fontFamily: "ramblaBold" }}>Console</span>
                        </NavLink>
                    </div>
                </nav>
            )
        }
    };

    render(){
        console.log('Landing render called');
        return(
            <div className="landing-wrapper">
                <div className="landing-nav">
                    {this.renderNavigation()}
                </div>
                <div id="page-wrap">
                    <div className="landing-body">
                        {this.renderSystem()}
                    </div>
                    <Footer/>
                </div>
            </div>
        )
    }
}

// Wrapper functional component to use hooks
function Landing(props) {
    const navigate = useNavigate();

    return <LandingBase {...props} navigate={navigate} />;
}

export default  connect(null)(Landing);