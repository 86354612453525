import React from 'react';
import {connect} from 'react-redux';
import _ from "lodash";
import {message, Button, Input } from "antd";
import {ClipLoader} from "react-spinners";
import moment from "moment";

//actions import
import {editInterviewOffer, postsFieldChanged} from "../../../actions/PostsActions";

//components import
import {PickDate} from "../../common/PickDate";

//property declarations
const { TextArea } = Input;


class EditInviteForInterview extends React.Component {

	constructor(props) {
		super(props);

		this.state = {

			selectedCandidate: this.props.selectedCandidate,
			selectedPost: this.props.selectedPost,
			interviewDate: this.props.interviewDate,
			profile: this.props.profile,
			inviteComment: this.props.inviteComment,
			inviteInterviewLoading: this.props.inviteInterviewLoading,
		}

		this.onSelectInterviewDate = this.onSelectInterviewDate.bind(this);
	}

	componentWillReceiveProps(nextProps, nextContext) {

		if (nextProps !== this.props) {
			this.setState({
				profile: nextProps.profile,

				selectedCandidate: nextProps.selectedCandidate,

				selectedPost: nextProps.selectedPost,
				interviewDate: nextProps.interviewDate,
				inviteComment: nextProps.inviteComment,
				inviteInterviewLoading: nextProps.inviteInterviewLoading
			})
		}
	}

	onSelectInterviewDate(value) {
		this.props.postsFieldChanged({prop: 'interviewDate', value})
	}

	renderButton() {
		const {inviteInterviewLoading} = this.state;
		
		if (inviteInterviewLoading) {
			return (
				<div style={{ 
					display: 'flex',
					justifyContent: 'center',
					padding: '12px 0'
				}}>
					<ClipLoader size={24} color="#0066cc" />
				</div>
			);
		}
		
		return (
			<div style={{
				display: 'flex',
				justifyContent: 'flex-end',
				marginTop: '24px'
			}}>
				<Button 
					type="primary"
					onClick={() => this.onAssign()}
					style={{
						background: '#0066cc',
						borderRadius: '8px',
						height: '40px',
						padding: '0 24px',
						fontFamily: 'ramblaBold',
						border: 'none',
						boxShadow: 'none'
					}}
				>
					Update Interview
				</Button>
			</div>
		);
	}

	onAssign() {
		let { selectedCandidate, profile, interviewDate, selectedPost, inviteComment } = this.state;

		if(!(_.isEmpty(profile))) {
			if((profile.role === "admin") || (profile.role === "superAdmin")) {
				//check if selected employee is not empty
				if (!(_.isEmpty(selectedCandidate))) {

					const userID = selectedCandidate.userID;
					const name = selectedCandidate.name;
					const firstName = name.split(" ")[0];

					//check if fetched asset is not empty
					if (interviewDate && !(_.isEmpty(selectedPost)) && inviteComment) {

						const companyName = selectedPost.companyName;

						this.props.editInterviewOffer({
							interviewID: selectedCandidate.interviewID,
							updatedOn: new Date(),
							interviewDate: interviewDate.toDate(),
							inviteComment,
							postID: selectedPost.postID,
							userID,
							firstName,
							companyName
						});

					} else {
						message.warning('Please select an interview date and write a comment');
					}
				} else {
					message.warning("There is no candidate to invite");
				}
			} else {
				message.warning("Un-Authorized Operation");
			}
		}
	}

	render() {
		const { selectedPost, selectedCandidate, inviteComment } = this.state;

		let seconds = selectedCandidate.interviewDate.seconds || selectedCandidate.interviewDate._seconds;
		const dateMoment = moment.unix(seconds);

		return (
			<div style={{ padding: '8px' }}>
				<div style={{
					marginBottom: '24px'
				}}>
					<h3 style={{ 
						fontFamily: 'ramblaBold',
						color: '#1d1d1f',
						fontSize: '16px',
						marginBottom: '8px'
					}}>
						{selectedPost ? selectedPost.postTitle : null}
					</h3>
					<p style={{ 
						fontFamily: 'ramblaRegular',
						color: '#86868b',
						fontSize: '14px',
						textAlign: 'left'
					}}>
						Current Interview Date: {dateMoment.format("MMM D, YYYY | HH:mm")}
					</p>
				</div>

				<div style={{ marginBottom: '24px' }}>
					<PickDate
						label="New Interview Date"
						onChange={this.onSelectInterviewDate}
						value={this.state.interviewDate}
					/>
				</div>

				<div>
					<label style={{
						display: 'block',
						fontFamily: 'ramblaBold',
						color: '#1d1d1f',
						fontSize: '14px',
						marginBottom: '8px'
					}}>
						Interview Notes
					</label>
					<TextArea
						rows={4}
						onChange={(event) => this.props.postsFieldChanged({ 
							prop: "inviteComment",
							value: event.target.value 
						})}
						placeholder="Add any additional notes or instructions (max 200 characters)"
						maxLength={200}
						value={inviteComment}
						style={{
							borderRadius: '8px',
							border: '1px solid #e5e5e7',
							padding: '12px',
							fontFamily: 'ramblaRegular',
							fontSize: '14px',
							resize: 'none'
						}}
					/>
				</div>

				{this.renderButton()}
			</div>
		);
	}
}

const mapStateToProps = (state) => {

	const {selectedPost, interviewDate, inviteInterviewLoading, inviteComment} = state.posts;
	const { profile } = state.profile;

	return {
		selectedPost,
		interviewDate,
		inviteInterviewLoading,
		profile,
		inviteComment
	}
};

export default connect(mapStateToProps, {editInterviewOffer, postsFieldChanged})(EditInviteForInterview);