import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate} from 'react-router-dom';
import {Form, Input, Button, Checkbox, Select, Tooltip, message} from 'antd';
import {GoogleOutlined, InfoCircleOutlined, EyeOutlined, EyeInvisibleOutlined} from '@ant-design/icons';
import {Link} from "react-router-dom"; // Import Ant Design styles
import {isMobile} from "react-device-detect";

// //-> action imports
import { userSignUp } from '../../actions/AuthenticationActions';

//file import
import Logo from "../../assets/logos/taglineLogo.png";
import AppleBadge from "../../assets/img/app-store-badge.svg";
import GoogleBadge from "../../assets/img/google-play-badge.svg";

import './SignUp.css';
import firebase from "firebase";

const { Option } = Select;

class SignUpBase extends React.Component {
    formRef = React.createRef(); // Initialize form reference

    constructor(props) {
        super(props);

        this.state = {
            confirmDirty: false,
            showPasswordRules: false,
            loading: this.props.loading,
            showPassword: false,
            showConfirmPassword: false,

            //polyfill data
            firstName: '',
            lastName: '',
            phone: '',
            companyName: '',
            companyPhone: '',
            companyEmail: '',
            logo: '',
            country: null,
            region: null,
            address: '',
            postalCode: '',

            fetchedTermsAndConditions: this.props.fetchedTermsAndConditions,
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                fetchedTermsAndConditions: nextProps.fetchedTermsAndConditions,
                loading: nextProps.loading
            });
        }
    };

    handleSubmit = (values) => {
        //-> handle login
        const { firstName, lastName, phone, companyName, companyPhone, companyEmail, logo, country, region, address, postalCode } = this.state;
        const { email, password } = values;
        const { navigate } = this.props;

        //-> checking if fields are not empty
        if (email && password) {
            const companyLocation = {
                country: '',
                countryCode: '',
                region: '',
                regionCode: '',
                streetAddress: '',
                postalCode
            }

            //call login action from actions
            this.props.userSignUp({ email, password, firstName, lastName, phone, companyName, companyLocation, companyPhone, companyEmail, logo, navigate, google: false, login: false, loginUser: null });
        } else {
            message.warning('Fill all required fields').then(() => null);
        }
    }

    handleGoogleSignUp = async () => {
        try {
            const { firstName, lastName, phone, companyName, companyPhone, companyEmail, logo, country, region, address, postalCode } = this.state;
            const { navigate } = this.props;

            const companyLocation = {
                country: '',
                countryCode: '',
                region: '',
                regionCode: '',
                streetAddress: '',
                postalCode
            }

            //call login action from actions
            this.props.userSignUp({ email: '', password: '', firstName, lastName, phone, companyName, companyLocation, companyPhone, companyEmail, logo, navigate, google: true, login: false });
        } catch (error) {
            console.error('Google Sign-In Error:', error);
        }
    };

    validatePassword = (_, value) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{6,}$/;
        if (!value) {
            return Promise.reject(new Error('Please input your password!'));
        } else if (!passwordRegex.test(value)) {
            return Promise.reject(new Error('Password must have at least 6 characters, one uppercase letter, and one special character.'));
        }
        return Promise.resolve();
    };

    handlePasswordFocus = () => {
        this.setState({ showPasswordRules: true });
    };

    handlePasswordBlur = () => {
        this.setState({ showPasswordRules: false });
    };

    handleConfirmBlur = () => {
        this.setState({ confirmDirty: this.state.confirmDirty });
    };

    compareToFirstPassword = (rule, value) => {
        // Access the current form using the ref
        const form = this.formRef.current;
        if (value && value !== form.getFieldValue('password')) {
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        }
        return Promise.resolve();
    };

    validateToNextPassword = (rule, value) => {
        // Access the current form using the ref
        const form = this.formRef.current;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm']);
        }
        return Promise.resolve();
    };

    togglePasswordVisibility = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    };

    toggleConfirmPasswordVisibility = () => {
        this.setState(prevState => ({
            showConfirmPassword: !prevState.showConfirmPassword
        }));
    };

    renderTermsAndAgreement(){
        const { fetchedTermsAndConditions} = this.state;

        if(!(_.isEmpty(fetchedTermsAndConditions))){
            const termsAndConditionsArray = _.map(fetchedTermsAndConditions, doc => doc);
            let privacy = "";
            let terms = "";


            termsAndConditionsArray.forEach(doc => {
                if (doc) {
                    switch (doc.docFileID) {
                        case "nafasiPrivacyPolicy":
                            privacy = doc.docFile;
                            break;
                        case "termsAndCondition":
                            terms = doc.docFile;
                            break;
                        default:
                            break;
                    }
                }
            });

            return(
                <Checkbox className={"regular"}>
                    I have read the <span style={{ color: "#0793d9"}} onClick={() => window.open(`${terms}`,'name','width=700,height=600')}>terms and conditions</span> and <span style={{ color: "#0793d9"}} onClick={() => window.open(`${privacy}`,'name','width=700,height=600')}>privacy policy</span>
                </Checkbox>
            )
        } else {
            return <h4 className={'adminAlert'}>terms and conditions loading....</h4>;
        }
    };

    render() {
        const { loading, showPasswordRules, showPassword, showConfirmPassword } = this.state;

        if(isMobile) {
            return (
                <div className="mobile-view">
                    <h3 className="section-title">For Organizations</h3>
                    <p style={{fontFamily: "ramblaBold", color: "#fff", marginBottom: "24px"}}>
                        Complete registration from a computer
                        <br/>
                        <span
                            style={{color: "#0793d9", cursor: "pointer"}}
                            onClick={() => this.props.navigate('/pricing')}
                        > go back</span>
                    </p>
                    
                    <h4 className="section-title">For Job Applicants</h4>
                    <div className="app-badges">
                        <a href="https://play.google.com/store/apps/details?id=com.nafasi.io" target="_blank" rel="noopener noreferrer">
                            <img src={GoogleBadge} alt="Get it on Google Play" />
                        </a>
                        <a href="https://apps.apple.com/tz/app/nafasi/id6450264936" target="_blank" rel="noopener noreferrer">
                            <img src={AppleBadge} alt="Download on App Store" />
                       </a>
                   </div>
                </div>
            );
        }

            return (
            <div className="signup-container">
                <BackgroundDecoration />
                <Form
                    name="register"
                    onFinish={this.handleSubmit}
                    className="signup-form"
                    scrollToFirstError
                    ref={this.formRef}
                >
                    <img src={Logo} alt="nafasi logo"/>
                    <h2 className="section-title">For Organizations</h2>

                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: 'Please enter a valid email address',
                            },
                            {
                                required: true,
                                message: 'Email is required',
                            },
                        ]}
                    >
                        <Input placeholder="Email"/>
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            { required: true, message: 'Please input your password!' },
                            { validator: this.validatePassword }
                        ]}
                        hasFeedback
                    >
                        <div className="nafasi-password-wrapper">
                            <Input
                                type={showPassword ? 'text' : 'password'}
                                className="nafasi-password-input"
                                placeholder="Password"
                                onFocus={this.handlePasswordFocus}
                                onBlur={this.handlePasswordBlur}
                            />
                            <button
                                type="button"
                                className="nafasi-password-toggle"
                                onClick={this.togglePasswordVisibility}
                                aria-label={showPassword ? 'Hide password' : 'Show password'}
                            >
                                {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                            </button>
                        </div>
                    </Form.Item>

                    {showPasswordRules && (
                        <div className="password-rules">
                            <InfoCircleOutlined style={{marginRight: '4px'}}/>
                            Password must have at least 6 characters, one uppercase letter, and one special character
                        </div>
                    )}

                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        rules={[
                            { required: true, message: 'Please confirm your password!' },
                            { validator: this.compareToFirstPassword }
                        ]}
                        hasFeedback
                    >
                        <div className="nafasi-password-wrapper">
                            <Input
                                type={showConfirmPassword ? 'text' : 'password'}
                                className="nafasi-password-input"
                                placeholder="Confirm Password"
                                onBlur={this.handleConfirmBlur}
                            />
                            <button
                                type="button"
                                className="nafasi-password-toggle"
                                onClick={this.toggleConfirmPasswordVisibility}
                                aria-label={showConfirmPassword ? 'Hide password' : 'Show password'}
                            >
                                {showConfirmPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                            </button>
                        </div>
                    </Form.Item>

                    <Form.Item
                        name="agreement"
                        valuePropName="checked"
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error('Please accept the terms and conditions')),
                            },
                        ]}
                    >
                        {this.renderTermsAndAgreement()}
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={this.state.loading}>
                            Sign Up
                        </Button>
                        <Button
                            type="default"
                            icon={<GoogleOutlined/>}
                            onClick={this.handleGoogleSignUp}
                        >
                            Sign up with Google
                        </Button>
                    </Form.Item>

                    <Link to="/login">
                        <p className="register-link">
                            Already Registered? Sign in
                        </p>
                    </Link>

                    <div className="signin-divider">
                        <span>For Job Applicants</span>
                    </div>

                    <div className="app-badges">
                        <a href="https://play.google.com/store/apps/details?id=com.nafasi.io" target="_blank"
                           rel="noopener noreferrer">
                            <img src={GoogleBadge} alt="Get it on Google Play"/>
                        </a>
                        <a href="https://apps.apple.com/tz/app/nafasi/id6450264936" target="_blank"
                           rel="noopener noreferrer">
                            <img src={AppleBadge} alt="Download on App Store"/>
                        </a>
                    </div>
                </Form>
            </div>
            );
    }
}

const mapStateToProps = (state) => {
    const {loading} = state.authentication;
    const {fetchedTermsAndConditions} = state.settings;
    return {
        loading,
        fetchedTermsAndConditions
    }
};

// // Wrapper functional component to use hooks
function SignUp(props) {
    const navigate = useNavigate();

    return <SignUpBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, { userSignUp })(SignUp);

const BackgroundDecoration = () => {
    const dots = Array.from({ length: 20 }).map((_, i) => ({
        id: i,
        top: `${Math.random() * 100}%`,
        left: `${Math.random() * 100}%`,
        animationDelay: `${Math.random() * 2}s`
    }));

    return (
        <div className="background-decoration">
            {dots.map(dot => (
                <div
                    key={dot.id}
                    className="decoration-dot"
                    style={{
                        top: dot.top,
                        left: dot.left,
                        animationDelay: dot.animationDelay
                    }}
                />
            ))}
        </div>
    );
};