import React from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useNavigate} from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { FilePdfOutlined, DeleteOutlined } from '@ant-design/icons';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import { message } from 'antd';
import { Popconfirm } from 'antd';

//actions import
import { credentialsChanged } from "../../actions/AuthenticationActions";
import ChangePassword from "./subComponents/ChangePassword";
import { Button } from 'antd';

//-> component imports
import "./Profile.css"
import { BankIcon, Calendar02Icon, CallIcon, Image01Icon, Image02Icon, ImageDelete01Icon, Location01Icon, Mail02Icon, PackageDeliveredIcon, ProfileIcon, UserAccountIcon, UserSharingIcon } from 'hugeicons-react';
import ProfileModal from './subComponents/ProfileModal';

class ProfileBase extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            profile: this.props.profile,
            imgLoaded:false,
            imgLoaded: false,
            showModal: false
        };

        this.renderDueDate = this.renderDueDate.bind(this);
        this.renderEditProfile = this.renderEditProfile.bind(this);
        this.showProfileModal = this.showProfileModal.bind(this);
    }

    componentDidMount() {
        if (!_.isEmpty(this.props.profile) && !this.checkRequiredFields(this.props.profile)) {
            this.showProfileModal();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                profile: nextProps.profile
            });

            if (!_.isEmpty(nextProps.profile) && !this.checkRequiredFields(nextProps.profile)) {
                this.showProfileModal();
            }
        }
    }

    checkRequiredFields(profile) {
        if (!profile) return false;

        const requiredFields = {
            firstName: value => typeof value === 'string' && value.length > 0,
            lastName: value => typeof value === 'string' && value.length > 0,
            phone: value => typeof value === 'string' && value.length > 0,
            companyName: value => typeof value === 'string' && value.length > 0,
            companyPhone: value => typeof value === 'string' && value.length > 0,
            companyEmail: value => typeof value === 'string' && value.length > 0,
            logo: value => typeof value === 'string' && value.length > 0,
            companyRegDocument: value => typeof value === 'string' && value.length > 0,
            adminIDs: value => typeof value === 'string' && value.length > 0,
            companyLocation: value => {
                return value && 
                    typeof value === 'object' &&
                    value.country &&
                    value.countryCode &&
                    value.region &&
                    value.regionCode &&
                    value.streetAddress;
            }
        };

        return Object.entries(requiredFields).every(([key, validator]) => {
            return profile.hasOwnProperty(key) && validator(profile[key]);
        });
    }
    

    handleImageError = () => {
        this.setState({ imgLoaded: false });
      };
    
      handleImageLoad = () => {
        this.setState({ imgLoaded: true });
      };

    renderDueDate() {
        //extract state
        const { profile, imgLoaded  } = this.state;

        if (!(_.isEmpty(profile))) {

            //compute hire date
            if ("paymentPackage" in profile) {
                let seconds;
                profile.dueDate.seconds ? seconds = profile.dueDate.seconds : seconds = profile.dueDate._seconds;
                const date = moment.unix(seconds);
                const dueDate = date.format("MMMM Do YYYY");
                //const years = date.fromNow(true);

                return(

                    <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                        <div className="input-with-icon">
                            <input
                                type="text"
                                className="form-control"
                                defaultValue={profile.paymentPackage.title}
                                disabled
                                placeholder="Company"
                            />
                            <span className="icon">
                                <PackageDeliveredIcon size={20} color='#0793d9' />
                            </span>
                        </div>

                        <div className="input-with-icon">
                            <input
                                type="text"
                                className="form-control"
                                defaultValue={dueDate}
                                disabled
                                placeholder="Company"
                                style={{fontFamily: 'ramblaBold', fontSize: '0.9rem', color: '#181515'}}
                            />
                            <span className="icon">
                                <Calendar02Icon size={20} color='#fff' />
                            </span>
                        </div>
                    </div>
                );
            }
        }
    }

    renderBar() {
        //extract state
        const {profile} = this.state;

        return (
            <div>
                <div className={"profileInfoList"}>
                    <h2 style={{ fontFamily: "ramblaBold", color: "#0793d9"}}>Company</h2>
                    <img src={profile.logo} style={{ height: "3rem", width: "3rem", margin: "2rem 0" }} alt={"logo"}/>
                    <div className={"profileContact"}>
                        <FontAwesomeIcon icon="phone" style={{opacity: '.8', fontSize: '1.1rem', margin: '0.6rem 0.3rem 0 0'}} flip="horizontal" color={"gray"}/>
                        <p>{profile.companyPhone}</p>
                    </div>

                    <div className={"profileContact"}>
                        <FontAwesomeIcon icon="envelope" style={{opacity: '.8', fontSize: '1.2rem', margin: '0.6rem 0.3rem 0 0'}} color={"gray"}/>
                        <p>{profile.companyEmail}</p>
                    </div>

                    <div className={"profileContact"}>
                        <FontAwesomeIcon icon="map-marker-alt" style={{opacity: '.8', fontSize: '1.2rem', margin: '0.6rem 0.3rem 0 0'}} color={"gray"}/>
                        <p>{profile.companyLocation.country}</p>
                        <p>{profile.companyLocation.region}</p>
                        <p>{profile.companyLocation.streetAddress}</p>
                    </div>
                </div>

                <div className={"profileInfoList"}>
                    { this.renderDueDate() }
                </div>

                <div className={"profileInfoList"}>
                    <h2 style={{ fontFamily: "ramblaBold", color: "#0793d9"}}>Admin</h2>
                    <p style={{ fontFamily: "ramblaBold", fontSize: "1.2rem", textAlign: "left",color: "#181515"  }}>{profile.firstName} {profile.lastName}</p>
                    <div className={"profileContact"}>
                        <FontAwesomeIcon icon="phone" style={{opacity: '.8', fontSize: '1.1rem', margin: '0.6rem 0.3rem 0 0', }} flip="horizontal" color={"gray"}/>
                        <p >{profile.phone}</p>
                    </div>

                    <div className={"profileContact"}>
                        <FontAwesomeIcon icon="envelope" style={{opacity: '.8', fontSize: '1.2rem', margin: '0.6rem 0.3rem 0 0'}} color={"gray"}/>
                        <p>{profile.email}</p>
                    </div>

                    <div className={"profileContact"}>
                        <FontAwesomeIcon icon="map-marker-alt" style={{opacity: '.8', fontSize: '1.2rem', margin: '0.6rem 0.3rem 0 0'}} color={"gray"}/>
                        <p>{profile.companyLocation.country}</p>
                        <p>{profile.companyLocation.region}</p>
                        <p>{profile.companyLocation.streetAddress}</p>
                    </div>
                </div>
            </div>
        );
    }


    renderEditProfile(){
        const { profile } = this.state;
        if(!(_.isEmpty(profile))){
            this.props.credentialsChanged({prop: 'firstName', value: profile.firstName});
            this.props.credentialsChanged({prop: 'lastName', value: profile.lastName});
            this.props.credentialsChanged({prop: 'phone', value: profile.phone});
            this.props.credentialsChanged({prop: 'companyName', value: profile.companyName});
            this.props.credentialsChanged({prop: 'companyPhone', value: profile.companyPhone});
            this.props.credentialsChanged({prop: 'companyEmail', value: profile.companyEmail});
            this.props.credentialsChanged({prop: 'logo', value: profile.logo});
            this.props.credentialsChanged({prop: 'companyRegDocument', value: profile.companyRegDocument});
            this.props.credentialsChanged({prop: 'adminIDs', value: profile.adminIDs});
            this.props.credentialsChanged({prop: 'country', value: { countryName: profile.companyLocation.country, countryShortCode: profile.companyLocation.countryCode }});
            this.props.credentialsChanged({prop: 'region', value: { name: profile.companyLocation.region, shortCode: profile.companyLocation.regionCode }});
            this.props.credentialsChanged({prop: 'address', value: profile.companyLocation.streetAddress});
            this.props.credentialsChanged({prop: 'postalCode', value: profile.companyLocation.postalCode});
            this.props.navigate('/edit-profile');
        }
    }

    showProfileModal = () => {
        this.setState({ showModal: true });
    };
    
    hideProfileModal = () => {
        this.setState({ showModal: false });
    };
    
    handleNavigation = () => {
        this.hideProfileModal();
        this.renderEditProfile();
    };

    handleDocumentDelete = async (documentType) => {
        try {
            const docId = this.state.profile.userID;
            const storageRef = firebase.storage().ref(`${documentType}/${docId}`);
            
            // Delete from Storage
            await storageRef.delete();
            
            // Update Firestore
            await firebase.firestore()
                .collection('users')
                .doc('admins')
                .collection(docId)
                .doc('public')
                .collection('account')
                .doc('info')
                .update({
                    [documentType]: firebase.firestore.FieldValue.delete()
                });

            // Update Redux state
            this.props.credentialsChanged({ 
                prop: documentType, 
                value: null 
            });

            // Update local state
            this.setState(prevState => ({
                profile: {
                    ...prevState.profile,
                    [documentType]: null
                }
            }));

            message.success('Document deleted successfully');
        } catch (error) {
            console.error('Error deleting document:', error);
            message.error('Failed to delete document. Please try again.');
        }
    };

    handleLogoDelete = async () => {
        try {
            const docId = this.state.profile.userID;
            const storageRef = firebase.storage().ref(`companyLogos/${docId}`);
            
            // Delete from Storage
            await storageRef.delete();
            
            // Update Firestore
            await firebase.firestore()
                .collection('users')
                .doc('admins')
                .collection(docId)
                .doc('public')
                .collection('account')
                .doc('info')
                .update({
                    logo: firebase.firestore.FieldValue.delete()
                });

            // Update Redux state
            this.props.credentialsChanged({ 
                prop: 'logo', 
                value: null 
            });

            // Update local state
            this.setState(prevState => ({
                profile: {
                    ...prevState.profile,
                    logo: null
                }
            }));

            message.success('Logo deleted successfully');
        } catch (error) {
            console.error('Error deleting logo:', error);
            message.error('Failed to delete logo. Please try again.');
        }
    };

    renderDocumentPreview = (document, label, type) => {
        if (!document) return null;

        return (
            <div className="nafasi-profile__document-preview">
                <div className="nafasi-profile__document-icon">
                    <FilePdfOutlined />
                </div>
                <div className="nafasi-profile__document-info">
                    <span className="nafasi-profile__document-name">{label}</span>
                    <div className="nafasi-profile__document-actions">
                        <a 
                            href={document}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="nafasi-profile__document-view"
                        >
                            View PDF
                        </a>
                        {/* <Popconfirm
                            title="Delete Document"
                            description="Are you sure you want to delete this document?"
                            onConfirm={() => this.handleDocumentDelete(type)}
                            okText="Yes"
                            cancelText="No"
                            placement="topRight"
                            okButtonProps={{ 
                                style: { 
                                    background: '#ff3b30',
                                    borderColor: '#ff3b30'
                                } 
                            }}
                        >
                            <button 
                                className="nafasi-profile__document-remove"
                            >
                                <DeleteOutlined />
                            </button>
                        </Popconfirm> */}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const {profile} = this.state;

        if (this.state.showModal) {
            return (
                <div>
                    <ProfileModal
                        visible={this.state.showModal}
                        onConfirm={this.handleNavigation}
                    />
                </div>
            );
        }

        if(!(_.isEmpty(profile))) {
            if (profile.role === "admin" || profile.role === "superAdmin") {
                return (
                    <div className="nafasi-profile">
                        <div className="nafasi-profile__container">
                            {/* Company Card */}
                            <div className="nafasi-profile__company-card">
                                <div className="nafasi-profile__company-header">
                                    <h4 className="nafasi-profile__company-title">Company Details</h4>
                                </div>
                                <div className="nafasi-profile__company-body">
                                    <div className="nafasi-profile__company-grid">
                                        <div className="nafasi-profile__info-container">
                                            <div className="nafasi-profile__form-group">
                                                <div className="nafasi-profile__input-with-icon">
                                                    <span className="nafasi-profile__icon">
                                                        <CallIcon size={20} color='#0793d9'/>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="nafasi-profile__input"
                                                        defaultValue={profile.companyPhone}
                                                        disabled
                                                        placeholder="Company Phone"
                                                    />
                                                </div>
                                            </div>

                                            <div className="nafasi-profile__form-group">
                                                <div className="nafasi-profile__input-with-icon">
                                                    <span className="nafasi-profile__icon">
                                                        <Mail02Icon size={20} color='#0793d9'/>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="nafasi-profile__input"
                                                        defaultValue={profile.companyEmail}
                                                        disabled
                                                        placeholder="Company Email"
                                                    />
                                                </div>
                                            </div>

                                            <div className="nafasi-profile__form-group">
                                                <div className="nafasi-profile__input-with-icon">
                                                    <span className="nafasi-profile__icon">
                                                        <Location01Icon size={20} color='#0793d9'/>
                                                    </span>
                                                    <input
                                                        type="text"
                                                        className="nafasi-profile__input"
                                                        defaultValue={`${profile.companyLocation.country}, ${profile.companyLocation.region}, ${profile.companyLocation.streetAddress}`}
                                                        disabled
                                                        placeholder="Company Location"
                                                    />
                                                </div>
                                            </div>

                                            <div className="nafasi-profile__documents">
                                                <h5 className="nafasi-profile__documents-title">Documents</h5>
                                                {this.renderDocumentPreview(profile.companyRegDocument, 'Company Registration', 'companyRegDocument')}
                                                {this.renderDocumentPreview(profile.adminIDs, 'Admin IDs', 'adminIDs')}
                                            </div>

                                            <div className="nafasi-profile__form-group">
                                                <label className="nafasi-profile__documents-title" style={{ marginTop: '1rem'}}>Due Date</label>
                                                {this.renderDueDate()}
                                            </div>
                                        </div>
                                        
                                        <div className="nafasi-profile__logo-container">
                                            {profile.logo ? (
                                                <img
                                                    src={profile.logo}
                                                    className="nafasi-profile__logo"
                                                    alt="Company Logo"
                                                    onError={this.handleImageError}
                                                    onLoad={this.handleImageLoad}
                                                />
                                            ) : (
                                                <div className="nafasi-profile__logo-placeholder">
                                                    <Image01Icon size={64} color="#86868b" />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Admin Card */}
                            <div className="nafasi-profile__admin-card">
                                <div className="nafasi-profile__admin-header">
                                    <div className="nafasi-profile__admin-avatar">
                                        <UserSharingIcon size={32} color='#0071e3' />
                                    </div>
                                </div>
                                <div className="nafasi-profile__admin-body">
                                    <h2 className="nafasi-profile__admin-title">Admin Information</h2>
                                    
                                    <div className="nafasi-profile__form-group">
                                        <div className="nafasi-profile__input-with-icon">
                                            <span className="nafasi-profile__icon">
                                                <ProfileIcon size={20} color='#0793d9'/>
                                            </span>
                                            <input
                                                type="text"
                                                className="nafasi-profile__input"
                                                defaultValue={`${profile.firstName} ${profile.lastName}`}
                                                disabled
                                                placeholder="Full Name"
                                            />
                                        </div>
                                    </div>

                                    <div className="nafasi-profile__form-group">
                                        <div className="nafasi-profile__input-with-icon">
                                            <span className="nafasi-profile__icon">
                                                <CallIcon size={20} color='#0793d9'/>
                                            </span>
                                            <input
                                                type="text"
                                                className="nafasi-profile__input"
                                                defaultValue={profile.phone}
                                                disabled
                                                placeholder="Phone"
                                            />
                                        </div>
                                    </div>

                                    <div className="nafasi-profile__form-group">
                                        <div className="nafasi-profile__input-with-icon">
                                            <span className="nafasi-profile__icon">
                                                <Mail02Icon size={20} color='#0793d9'/>
                                            </span>
                                            <input
                                                type="text"
                                                className="nafasi-profile__input"
                                                defaultValue={profile.email}
                                                disabled
                                                placeholder="Email"
                                            />
                                        </div>
                                    </div>

                                    <div className="nafasi-profile__form-group">
                                        <div className="nafasi-profile__input-with-icon">
                                            <span className="nafasi-profile__icon">
                                                <Location01Icon size={20} color='#0793d9'/>
                                            </span>
                                            <input
                                                type="text"
                                                className="nafasi-profile__input"
                                                defaultValue={`${profile.companyLocation.country}, ${profile.companyLocation.region}, ${profile.companyLocation.streetAddress}`}
                                                disabled
                                                placeholder="Location"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="nafasi-profile__actions">
                                    <ChangePassword />
                                    <button 
                                        className="nafasi-profile__button nafasi-profile__button--primary"
                                        onClick={() => this.renderEditProfile()}
                                    >
                                        Update Profile
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className="nafasi-profile__unauthorized">
                        <p className="nafasi-profile__unauthorized-message">Unauthorized access</p>
                    </div>
                );
            }
        }
        return null;
    }
}

const mapStateToProps = (state) => {

    const {profile} = state.profile;

    return {
        profile
    }
};

// Wrapper functional component to use hooks
function Profile(props) {
    const navigate = useNavigate();

    return <ProfileBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, { credentialsChanged }) (Profile);
