import React from 'react';
import { connect } from 'react-redux';
import RecentSearches from "./recentSearches/RecentSearches";
import Announcements from "./announcements/Announcements";
import Posts from "./posts/Posts";
import UpcomingInterviews from './upcomingInterviews/UpcomingInterviews';
import './Home.css';

class Home extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isToggled: this.props.isToggled,
            isLoaded: false
        };
    }

    componentDidMount() {
        // Add a slight delay before showing animations
        setTimeout(() => {
            this.setState({ isLoaded: true });
        }, 100);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                isToggled: nextProps.isToggled,
            });
        }
    }

    render(){
        const { isLoaded } = this.state;
        return(
            <div className={`home ${isLoaded ? 'home--loaded' : ''}`}>
                <div className="home__section home__section--recent">
                    <RecentSearches/>
                </div>
                <div className="home__section home__section--announcements">
                    <Announcements />
                </div>
                <div className="home__section home__section--posts">
                    <Posts/>
                </div>
                <div className="home__section home__section--interviews">
                    <UpcomingInterviews/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    profile: state.profile.profile,
    recentSearches: state.home.recentSearches,
    recentSearchesLoading: state.home.recentSearchesLoading,
    isToggled: state.home.isToggled
});

export default connect(mapStateToProps)(Home);