import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Employment.css';

// Import the action
import { fetchSeekerPath } from '../../../actions/PostsActions';

//components import
import { Spinner } from "../../common/Spinner";
import EmploymentCard from "./cards/EmploymentCard";

class Employment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCandidate: this.props.selectedCandidate,
            employment: this.props.employment,
            employmentLoading: this.props.employmentLoading,
            profile: this.props.profile,
        }

        this.renderEmployment = this.renderEmployment.bind(this);
    }

    componentDidMount() {
        const { selectedCandidate } = this.props;
        if (selectedCandidate && selectedCandidate.id) {
            this.props.fetchSeekerPath(selectedCandidate.id, "employment");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedCandidate !== this.props.selectedCandidate) {
            if (this.props.selectedCandidate && this.props.selectedCandidate.id) {
                this.props.fetchSeekerPath(this.props.selectedCandidate.id, "employment");
            }
        }

        if (prevProps !== this.props) {
            this.setState({
                selectedCandidate: this.props.selectedCandidate,
                employment: this.props.employment,
                employmentLoading: this.props.employmentLoading,
                profile: this.props.profile,
            });
        }
    }

    renderEmployment() {
        const { employment, employmentLoading, selectedCandidate } = this.state;

        if (employmentLoading) {
            return <Spinner />
        }

        if (_.isEmpty(selectedCandidate)) {
            return <p style={{ color: "#000", margin: "2rem 0" }}>Select a candidate to view</p>;
        }

        if (!employment || employment.length === 0) {
            return (
                <div className="no-data-message">
                    <FontAwesomeIcon icon="briefcase" className="no-data-icon" />
                    <p>No employment history available</p>
                </div>
            );
        }

        return (
            <div className="employment-timeline">
                <EmploymentCard employment={employment}/>
            </div>
        );
    }

    checkUser() {
        const { profile } = this.state;

        if(_.isEmpty(profile)) {
            return <h2 style={{ color: "#000" }}>Sign in to view</h2>;
        }

        if(profile.role !== "admin" && profile.role !== "superAdmin") {
            return <h3>Un-Authorized Access</h3>;
        }

        return (
            <div className="section-container">
                <div className="section-header">
                    <h4>
                        <FontAwesomeIcon icon="briefcase" className="section-icon" />
                        Employment History
                    </h4>
                </div>
                <div className="section-content">
                    {this.renderEmployment()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="employment-container">
                {this.checkUser()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedCandidate, employment, employmentLoading } = state.posts;
    const { profile } = state.profile;

    return {
        selectedCandidate,
        employment,
        employmentLoading,
        profile
    }
}

export default connect(mapStateToProps, { fetchSeekerPath })(Employment);