import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Referees.css";

class RefereeCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            referees: this.props.referees,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                referees: nextProps.referees,
            });
        }
    }

    renderReferees() {
        const { referees } = this.state;

        console.log(referees[0]);
        return (
            <div className="referees-grid">
                {referees.map((referee, index) => (
                    <div key={index} className="referee-card">
                        <div className="referee-header">
                            <div className="referee-icon">
                                <FontAwesomeIcon icon="user-tie" />
                            </div>
                            <div className="referee-info">
                                <h4>{referee.firstName || 'Unnamed'} {referee.lastName || ''}</h4>
                                <span className="referee-title">
                                    {referee.title || 'No Title Listed'}
                                </span>
                            </div>
                        </div>

                        <div className="referee-details">
                            {referee.company && (
                                <div className="detail-item">
                                    <FontAwesomeIcon icon="building" className="detail-icon" />
                                    <span style={{ fontFamily: "ramblaRegular" }}>{referee.company}</span>
                                </div>
                            )}
                            
                            {referee.email && (
                                <div className="detail-item">
                                    <FontAwesomeIcon icon="envelope" className="detail-icon" />
                                    <a href={`mailto:${referee.email}`} className="detail-link">
                                        {referee.email}
                                    </a>
                                </div>
                            )}
                            
                            {referee.phone && (
                                <div className="detail-item">
                                    <FontAwesomeIcon icon="phone" className="detail-icon" />
                                    <a href={`tel:${referee.phone}`} className="detail-link">
                                        {referee.phone}
                                    </a>
                                </div>
                            )}

                            {referee.relationship && (
                                <div className="detail-item">
                                    <FontAwesomeIcon icon="handshake" className="detail-icon" />
                                    <span>{referee.relationship}</span>
                                </div>
                            )}
                        </div>

                        {referee.description && (
                            <p className="referee-description">
                                {referee.description}
                            </p>
                        )}
                    </div>
                ))}
            </div>
        );
    }

    render() {
        return (
            <div className="referees-timeline">
                {this.renderReferees()}
            </div>
        );
    }
}

export default RefereeCard;