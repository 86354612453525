import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { connect } from "react-redux";
import _ from "lodash";
import "./Posts.css";

//action imports
import { fetchPosts } from "../../actions/PostsActions";

//component imports
import AddJobPost from "./subComponents/AddJobPost";
import JobPosts from "./subComponents/JobPosts";
import {LicenseDraftIcon} from "hugeicons-react";

class Posts extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            profile: this.props.profile,
        }
    }

    componentWillMount() {
        const { profile } = this.state;

        if(!(_.isEmpty(profile))){
            this.props.fetchPosts();
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps !== this.props){
            this.setState({
                profile: nextProps.profile
            })
        }
    }

    renderComponent() {
        const { profile } = this.state;

        if(!(_.isEmpty(profile))) {
            return (
                <div style={{
                    padding: '0px',
                    background: 'linear-gradient(145deg, #f5f5f7 0%, #ffffff 100%)',
                    borderRadius: '30px',
                    minHeight: 'calc(100vh - 180px)',
                    boxShadow: '0 4px 24px rgba(0, 0, 0, 0.04)'
                }}>

                    <div className={""}>
                        <AddJobPost/>
                    </div>

                    <div className={"viewJobOpeningsBox"}>
                        <JobPosts/>
                    </div>
                </div>
            );
        } else {
            return (
                <div style={{
                    padding: '40px',
                    textAlign: 'center',
                    color: '#1d1d1f',
                    fontFamily: 'ramblaBold, sans-serif',
                    fontSize: '17px'
                }}>
                    No profile
                </div>
            );
        }
    }

    render() {
        return (
            <div style={{ background: '#ffffff', padding: '20px' }}>
                <div className={"childHeaderBarBox"} style={{
                        marginBottom: '32px',
                        borderBottom: '1px solid #e5e5e7',
                        paddingBottom: '16px'
                    }}>
                        <LicenseDraftIcon size={28} style={{ color: "#1d1d1f" }} />
                        <h3 style={{
                            fontSize: '24px',
                            fontWeight: 'bold',
                            fontFamily: 'ramblaBold, sans-serif',
                            color: '#1d1d1f',
                            margin: 0
                        }}>Your Job Posts</h3>
                </div>
                {this.renderComponent()}
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { profile } = state.profile;

    return {
        profile,
    }
}

export default connect(mapStateToProps, { fetchPosts })(Posts);