import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font } from '@react-pdf/renderer';
import moment from 'moment';
import _ from 'lodash';

// Register fonts
Font.register({
    family: 'SF Pro Display',
    fonts: [
        { src: 'https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYREGULAR.woff', fontWeight: 'normal' },
        { src: 'https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYMEDIUM.woff', fontWeight: 'medium' },
        { src: 'https://fonts.cdnfonts.com/s/59278/SFPRODISPLAYBOLD.woff', fontWeight: 'bold' }
    ]
});

const styles = StyleSheet.create({
    page: {
        padding: '48 60',
        fontFamily: 'SF Pro Display',
        fontSize: 11,
        color: '#1d1d1f',
        backgroundColor: '#ffffff'
    },
    headerContainer: {
        marginBottom: 40
    },
    header: {
        backgroundColor: '#1d1d1f',
        padding: '32 40',
        marginBottom: 24,
        borderRadius: 12
    },
    name: {
        fontSize: 32,
        fontWeight: 'bold',
        color: '#ffffff',
        marginBottom: 16,
        textTransform: 'uppercase',
        letterSpacing: 0.5
    },
    contactInfo: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 8,
        marginBottom: 8
    },
    contactText: {
        fontSize: 11,
        color: '#f5f5f7',
        opacity: 0.8
    },
    dot: {
        color: '#86868b',
        opacity: 0.6
    },
    summary: {
        fontSize: 14,
        lineHeight: 1.6,
        color: '#424245',
        marginBottom: 40,
        paddingHorizontal: 40
    },
    section: {
        marginBottom: 32
    },
    sectionHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 20
    },
    sectionTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#1d1d1f',
        textTransform: 'uppercase',
        letterSpacing: 0.5
    },
    sectionLine: {
        flex: 1,
        height: 1,
        backgroundColor: '#e5e5e7',
        marginLeft: 16
    },
    experienceItem: {
        marginBottom: 24,
        paddingLeft: 40
    },
    experienceHeader: {
        marginBottom: 8
    },
    companyName: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#1d1d1f',
        marginBottom: 4
    },
    jobTitle: {
        fontSize: 14,
        color: '#424245',
        marginBottom: 4
    },
    dateLocation: {
        fontSize: 11,
        color: '#86868b'
    },
    description: {
        fontSize: 12,
        lineHeight: 1.5,
        color: '#424245',
        marginTop: 8
    },
    skillsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 16,
        paddingLeft: 40
    },
    skillGroup: {
        width: '45%',
        marginBottom: 20
    },
    skillGroupTitle: {
        fontSize: 14,
        fontWeight: 'medium',
        color: '#1d1d1f',
        marginBottom: 8
    },
    tagsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 8
    },
    tag: {
        backgroundColor: '#f5f5f7',
        paddingVertical: 4,
        paddingHorizontal: 12,
        borderRadius: 16,
        fontSize: 11,
        color: '#1d1d1f'
    },
    educationItem: {
        marginBottom: 20,
        paddingLeft: 40
    },
    institution: {
        fontSize: 14,
        fontWeight: 'medium',
        color: '#1d1d1f',
        marginBottom: 4
    },
    degree: {
        fontSize: 12,
        color: '#424245',
        marginBottom: 4
    },
    linksContainer: {
        paddingLeft: 40
    },
    link: {
        fontSize: 12,
        color: '#06c',
        marginBottom: 8,
        textDecoration: 'none'
    },
    referencesContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 24,
        paddingLeft: 40
    },
    referenceItem: {
        width: '45%',
        marginBottom: 16
    },
    referenceName: {
        fontSize: 14,
        fontWeight: 'medium',
        color: '#1d1d1f',
        marginBottom: 4
    },
    referenceDetails: {
        fontSize: 11,
        color: '#424245',
        marginBottom: 2
    }
});

const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const seconds = timestamp.seconds || timestamp._seconds;
    return moment.unix(seconds).format('MMM YYYY');
};

const SectionHeader = ({ title }) => (
    <View style={styles.sectionHeader}>
        <Text style={styles.sectionTitle}>{title}</Text>
        <View style={styles.sectionLine} />
    </View>
);

const GeneratePDF = ({ data }) => {
    const {
        selectedCandidateProfile = {},
        selectedCandidate = {},
        skills = [],
        hobbies = [],
        employment = [],
        education = [],
        military = [],
        awards = [],
        licenses = [],
        links = [],
        languages = [],
        certificates = [],
        referees = []
    } = data;

    const {
        firstName = '',
        lastName = '',
        email = '',
        phone = '',
        location = {},
        summary = ''
    } = selectedCandidateProfile;

    const {
        birthdate,
        gender = '',
        maritalStatus = ''
    } = selectedCandidate;

    // Group skills by category (example categories)
    const skillCategories = {
        technical: skills.filter(s => s.category === 'technical'),
        soft: skills.filter(s => s.category === 'soft'),
        other: skills.filter(s => !s.category)
    };

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.headerContainer}>
                    <View style={styles.header}>
                        <Text style={styles.name}>{firstName} {lastName}</Text>
                        <View style={styles.contactInfo}>
                            <Text style={styles.contactText}>{email}</Text>
                            <Text style={styles.dot}>•</Text>
                            <Text style={styles.contactText}>{phone}</Text>
                            <Text style={styles.dot}>•</Text>
                            <Text style={styles.contactText}>
                                {location.region}, {location.country}
                            </Text>
                        </View>
                        <View style={styles.contactInfo}>
                            <Text style={styles.contactText}>{formatDate(birthdate)}</Text>
                            {gender && (
                                <>
                                    <Text style={styles.dot}>•</Text>
                                    <Text style={styles.contactText}>{gender}</Text>
                                </>
                            )}
                            {maritalStatus && (
                                <>
                                    <Text style={styles.dot}>•</Text>
                                    <Text style={styles.contactText}>{maritalStatus}</Text>
                                </>
                            )}
                        </View>
                    </View>
                    {summary && (
                        <Text style={styles.summary}>{summary}</Text>
                    )}
                </View>

                {employment.length > 0 && (
                    <View style={styles.section}>
                        <SectionHeader title="Experience" />
                        {employment.map((job, index) => (
                            <View key={index} style={styles.experienceItem}>
                                <View style={styles.experienceHeader}>
                                    <Text style={styles.companyName}>{job.jobCompany}</Text>
                                    <Text style={styles.jobTitle}>{job.jobTitle.title}</Text>
                                    <Text style={styles.dateLocation}>
                                        {formatDate(job.timeOfWorkFrom)} - {job.currentWork ? 'Present' : formatDate(job.timeOfWorkTo)}
                                        {' • '}{job.location.region}, {job.location.country}
                                    </Text>
                                </View>
                                <Text style={styles.description}>{job.description}</Text>
                            </View>
                        ))}
                    </View>
                )}

                {education.length > 0 && (
                    <View style={styles.section}>
                        <SectionHeader title="Education" />
                        {education.map((edu, index) => (
                            <View key={index} style={styles.educationItem}>
                                <Text style={styles.institution}>{edu.institution}</Text>
                                <Text style={styles.degree}>
                                    {edu.educationLevel.title} in {edu.educationField}
                                </Text>
                                <Text style={styles.dateLocation}>
                                    {formatDate(edu.timeOfStudyFrom)} - {edu.currentEnrollment ? 'Present' : formatDate(edu.timeOfStudyTo)}
                                </Text>
                            </View>
                        ))}
                    </View>
                )}

                {(skills.length > 0 || languages.length > 0) && (
                    <View style={styles.section}>
                        <SectionHeader title="Skills & Languages" />
                        <View style={styles.skillsContainer}>
                            {Object.entries(skillCategories).map(([category, categorySkills]) => 
                                categorySkills.length > 0 && (
                                    <View key={category} style={styles.skillGroup}>
                                        <Text style={styles.skillGroupTitle}>
                                            {_.startCase(category)} Skills
                                        </Text>
                                        <View style={styles.tagsContainer}>
                                            {categorySkills.map((skill, index) => (
                                                <Text key={index} style={styles.tag}>
                                                    {skill.title}
                                                </Text>
                                            ))}
                                        </View>
                                    </View>
                                )
                            )}
                            {languages.length > 0 && (
                                <View style={styles.skillGroup}>
                                    <Text style={styles.skillGroupTitle}>Languages</Text>
                                    <View style={styles.tagsContainer}>
                                        {languages.map((lang, index) => (
                                            <Text key={index} style={styles.tag}>
                                                {lang.title} - {lang.proficiency.title}
                                            </Text>
                                        ))}
                                    </View>
                                </View>
                            )}
                        </View>
                    </View>
                )}

                {(certificates.length > 0 || awards.length > 0) && (
                    <View style={styles.section}>
                        <SectionHeader title="Certifications & Awards" />
                        <View style={styles.skillsContainer}>
                            {certificates.length > 0 && (
                                <View style={styles.skillGroup}>
                                    <Text style={styles.skillGroupTitle}>Certifications</Text>
                                    <View style={styles.tagsContainer}>
                                        {certificates.map((cert, index) => (
                                            <Text key={index} style={styles.tag}>
                                                {cert.title}
                                            </Text>
                                        ))}
                                    </View>
                                </View>
                            )}
                            {awards.length > 0 && (
                                <View style={styles.skillGroup}>
                                    <Text style={styles.skillGroupTitle}>Awards</Text>
                                    {awards.map((award, index) => (
                                        <View key={index} style={{ marginBottom: 8 }}>
                                            <Text style={styles.degree}>{award.title}</Text>
                                            <Text style={styles.dateLocation}>
                                                {formatDate(award.dateAwarded)}
                                            </Text>
                                        </View>
                                    ))}
                                </View>
                            )}
                        </View>
                    </View>
                )}

                {links.length > 0 && (
                    <View style={styles.section}>
                        <SectionHeader title="Links" />
                        <View style={styles.linksContainer}>
                            {links.map((link, index) => (
                                <Text key={index} style={styles.link}>
                                    {link.title}: {link.url}
                                </Text>
                            ))}
                        </View>
                    </View>
                )}

                {referees.length > 0 && (
                    <View style={styles.section}>
                        <SectionHeader title="References" />
                        <View style={styles.referencesContainer}>
                            {referees.map((ref, index) => (
                                <View key={index} style={styles.referenceItem}>
                                    <Text style={styles.referenceName}>
                                        {ref.firstName} {ref.lastName}
                                    </Text>
                                    <Text style={styles.referenceDetails}>
                                        {ref.title} at {ref.company}
                                    </Text>
                                    <Text style={styles.referenceDetails}>
                                        {ref.email} • {ref.phone}
                                    </Text>
                                </View>
                            ))}
                        </View>
                    </View>
                )}
            </Page>
        </Document>
    );
};

export default GeneratePDF; 