import React from 'react';
import { Table, Popover } from 'antd';
import moment from "moment";
import DescriptionModal from './DescriptionModal';
import './AssessmentCard.css';

class AssessmentCard extends React.Component {
	state = {
		items: this.props.items,
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.items !== this.props.items) {
			this.setState({ items: nextProps.items });
		}
	}

	render() {
		const { items } = this.state;

		const processedResults = items.map(result => {
			const logDate = result.logDate.seconds || result.logDate._seconds;
			const time = moment.unix(logDate).format("DD/MMM/YYYY | HH:mm");
	
			const bdSeconds = result.birthdate.seconds || result.birthdate._seconds;
			const birthday = moment.unix(bdSeconds).format("DD/MMM/YYYY");
			
			const formattedLocation = `${result.location.region}, ${result.location.country}`;
			const fullName = `${result.firstName} ${result.lastName}`;
	
			return { ...result, time, birthday, formattedLocation, fullName };
		});

		const columns = [
			{
				title: 'Name',
				dataIndex: 'fullName',
				key: 'fullName',
				width: '15%',
				ellipsis: true,
				render: (text, record) => (
					<Popover content={text} trigger="hover">
						<div className="nafasi-assessment__cell">{text}</div>
					</Popover>
				),
			},
			{
				title: 'Gender',
				dataIndex: 'gender',
				key: 'gender',
				width: '8%',
				render: (text) => (
					<div className="nafasi-assessment__cell">{text}</div>
				),
			},
			{
				title: 'Email',
				dataIndex: 'email',
				key: 'email',
				width: '20%',
				ellipsis: true,
				render: (text) => (
					<Popover content={text} trigger="hover">
						<div className="nafasi-assessment__cell">{text}</div>
					</Popover>
				),
			},
			{
				title: 'Location',
				dataIndex: 'formattedLocation',
				key: 'formattedLocation',
				width: '15%',
				ellipsis: true,
				render: (text) => (
					<Popover content={text} trigger="hover">
						<div className="nafasi-assessment__cell">{text}</div>
					</Popover>
				),
			},
			{
				title: 'Attended',
				dataIndex: 'attended',
				key: 'attended',
				width: '10%',
				align: 'center',
				render: (attended) => {
					const status = attended ? 'yes' : 'no';
					return (
						<div className={`nafasi-assessment__attendance nafasi-assessment__attendance--${status}`}>
							{attended ? 'Yes' : 'No'}
						</div>
					);
				},
			},
			{
				title: 'Time',
				dataIndex: 'time',
				key: 'time',
				width: '15%',
				render: (text) => (
					<div className="nafasi-assessment__cell">{text}</div>
				),
			},
			{
				title: 'Assessment',
				dataIndex: 'assessment',
				key: 'assessment',
				width: '7%',
				align: 'center',
				render: (_, record) => (
					<div className="nafasi-assessment__description">
						<DescriptionModal record={record}/>
					</div>
				),
			}
		];

		return (
			<div className="nafasi-assessment">
				<Table 
					dataSource={processedResults} 
					columns={columns} 
					rowKey="id"
					pagination={{ 
						pageSize: 10,
						showSizeChanger: true,
						showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
					}}
					bordered={false}
					scroll={{ x: 800 }}
					className="nafasi-assessment__table"
				/>
			</div>
		);
	}
}

export default AssessmentCard;