import React, { useState } from 'react';
import { Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BriefcaseBusinessIcon } from 'hugeicons-react';
import './Truncated.css';

const TruncatedExperience = ({ seeker, maxLength }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const formatExperience = (employment) => {
        if (!employment || employment.length === 0) return 'No experience listed';
        
        return employment.map((exp, index) => (
            <div key={index} className="experience-item">
                <div className="experience-header">
                    <h4>{exp.jobTitle}</h4>
                    <span className="experience-duration">
                        {exp.currentWork ? 'Current' : ''} • {exp.overallYears} Years {exp.overallMonths} Months
                    </span>
                </div>
                {exp.experiences && exp.experiences.length > 0 && (
                    <div className="experience-positions">
                        {exp.experiences.map((position, idx) => (
                            <div key={idx} className="experience-position">
                                <div className="position-title">{position.experience}</div>
                                <div className="position-duration">
                                    {position.current ? 'Current - ' : ''}{position.experienceYears} Years {position.experienceMonths} Months
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        ));
    };

    const truncatedText = seeker.employment?.length > 0
        ? `${seeker.employment[0].jobTitle} (${seeker.employment[0].overallYears}y ${seeker.employment[0].overallMonths}m)`
        : 'No experience listed';

    return (
        <>
            <div 
                className="truncated-link"
                onClick={() => setIsModalVisible(true)}
                title="Click to view full experience"
            >
                <FontAwesomeIcon icon="briefcase" className="mr-2" />
                <p style={{ fontFamily: "ramblaRegular" }}>{truncatedText}</p>
                {seeker.employment?.length > 1 && (
                    <span className="more-indicator">
                        +{seeker.employment.length - 1} more
                    </span>
                )}
            </div>

            <Modal
                title={
                    <div className="modal-title">
                        <FontAwesomeIcon icon="briefcase" className="mr-2" />
                        Work Experience
                    </div>
                }
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
                width={600}
                className="experience-modal"
            >
                <div className="experience-list">
                    {formatExperience(seeker.employment)}
                </div>
            </Modal>
        </>
    );
};

export default TruncatedExperience;