import React, { Component } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { searchFieldChanged } from '../../../actions/SearchActions';

const { Option } = Select;

class CurrentEducationEnrollment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentEnrollment: this.props.currentEnrollment
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps !== this.props) {
            this.setState({
                currentEnrollment: nextProps.currentEnrollment
            });
        }
    }

    handleChange(value) {
        if (value === undefined || value === "") {
			// Dispatch an action to set foreignJob to null
			this.props.searchFieldChanged({ prop: "currentEnrollment", value: null });
		} else {
			// Otherwise, proceed as usual
            this.props.searchFieldChanged({ prop: "currentEnrollment", value });
		}
    };

    render() {
        const { currentEnrollment } = this.state;

        return (
            <div className="variable-section">
                <p style={{color: "#000", fontFamily: "myriadBold", marginBottom: 3, textAlign: "left"}}>Current
                    Education Enrollment</p>
                <Select value={currentEnrollment} allowClear onChange={this.handleChange} placeholder="Enrollment"
                        style={{width: "100%"}}>
                    <Option value={true}>Enrolled</Option>
                    <Option value={false}>Completed</Option>
                </Select>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentEnrollment } = state.search;

    return {
        currentEnrollment
    };
};

const mapDispatchToProps = {
    searchFieldChanged, // Assuming you have an action creator to update the status
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrentEducationEnrollment);
