import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined, ClockCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, EnvironmentOutlined, CalendarOutlined, TeamOutlined, FileTextOutlined, UserOutlined } from '@ant-design/icons';
import { List, Card, Tag, Empty, Spin, Collapse, Typography, Modal, Tabs } from 'antd';
import firebase from "firebase";
import moment from 'moment';
import './RecruiterDetailView.css';

const { Panel } = Collapse;
const { Paragraph } = Typography;
const { TabPane } = Tabs;

const RecruiterDetailView = ({ recruiter, visible, onClose }) => {
    const [jobPosts, setJobPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedPost, setSelectedPost] = useState(null);

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return 'N/A';
        if (timestamp.toDate) {
            return moment(timestamp.toDate()).format('DD/MM/YYYY');
        }
        if (timestamp._seconds) {
            return moment.unix(timestamp._seconds).format('DD/MM/YYYY');
        }
        return moment(timestamp).format('DD/MM/YYYY');
    };

    const formatInterviewDate = (timestamp) => {
        if (!timestamp) return '';
        if (timestamp.toDate) {
            return moment(timestamp.toDate()).format('DD/MM/YYYY HH:mm');
        }
        if (timestamp._seconds) {
            return moment.unix(timestamp._seconds).format('DD/MM/YYYY HH:mm');
        }
        return moment(timestamp).format('DD/MM/YYYY HH:mm');
    };

    useEffect(() => {
        const fetchJobPosts = async () => {
            if (!visible || !recruiter) return;
            
            setLoading(true);
            try {
                const jobPostsRef = firebase.firestore().collection('jobPosts')
                    .where('companyID', '==', recruiter.userID);
                const snapshot = await jobPostsRef.get();
                
                const posts = [];
                for (const doc of snapshot.docs) {
                    const post = { id: doc.id, ...doc.data() };
                    const candidatesRef = doc.ref.collection('candidates');
                    const candidatesSnapshot = await candidatesRef.get();
                    post.candidates = candidatesSnapshot.docs.map(candDoc => ({
                        id: candDoc.id,
                        ...candDoc.data()
                    }));
                    posts.push(post);
                }
                setJobPosts(posts);
            } catch (error) {
                console.error('Error fetching job posts:', error);
            }
            setLoading(false);
        };

        fetchJobPosts();
    }, [recruiter, visible]);

    const getCandidateStatusTag = (candidate) => {
        if (candidate.selected === true) {
            return <Tag color="success" icon={<CheckCircleOutlined />}>Selected</Tag>;
        } else if (candidate.selected === false) {
            return <Tag color="error" icon={<CloseCircleOutlined />}>Not Selected</Tag>;
        } else if (candidate.interviewed) {
            return <Tag color="processing" icon={<CheckCircleOutlined />}>Interviewed</Tag>;
        } else if (candidate.interviewDate) {
            const formattedDate = formatInterviewDate(candidate.interviewDate);
            return formattedDate ? (
                <Tag icon={<ClockCircleOutlined />} color="warning">
                    Interview: {formattedDate}
                </Tag>
            ) : <Tag color="default">Applied</Tag>;
        }
        return <Tag color="default">Applied</Tag>;
    };

    const handleViewDetails = (post) => {
        setSelectedPost(post);
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
        setSelectedPost(null);
    };

    const renderPostModal = () => {
        if (!selectedPost) return null;

        return (
            <Modal
                title={selectedPost.postTitle}
                open={modalVisible}
                onCancel={handleModalClose}
                footer={null}
                width={800}
                bodyStyle={{ padding: '12px' }}
            >
                <div className="recruiter-detail-view__modal-content">
                    <Tabs className="recruiter-detail-view__modal-tabs">
                        <TabPane tab="Description" key="description">
                            <div className="recruiter-detail-view__description-text">
                                {selectedPost.description}
                            </div>
                        </TabPane>
                        {selectedPost.file && (
                            <TabPane tab="Attachment" key="attachment">
                                <iframe
                                    src={selectedPost.file}
                                    className="recruiter-detail-view__pdf-viewer"
                                    title="Job Post PDF"
                                />
                            </TabPane>
                        )}
                    </Tabs>
                </div>
            </Modal>
        );
    };

    if (!recruiter) return null;

    return (
        <div className={`recruiter-detail-view ${visible ? 'visible' : ''}`}>
            <div className="recruiter-detail-view__header">
                <ArrowLeftOutlined onClick={onClose} className="recruiter-detail-view__back" />
                <h2>{recruiter.companyName}</h2>
            </div>

            <div className="recruiter-detail-view__content">
                {loading ? (
                    <div className="recruiter-detail-view__loading">
                        <Spin size="large" />
                    </div>
                ) : jobPosts.length === 0 ? (
                    <Empty description="No job posts found" />
                ) : (
                    <List
                        className="recruiter-detail-view__posts"
                        dataSource={jobPosts}
                        renderItem={post => (
                            <List.Item>
                                <Card 
                                    title={post.postTitle}
                                    className="recruiter-detail-view__post-card"
                                    extra={
                                        <Tag 
                                            icon={<FileTextOutlined />}
                                            style={{ cursor: 'pointer', margin: 0 }}
                                            onClick={() => handleViewDetails(post)}
                                        >
                                            View Details
                                        </Tag>
                                    }
                                >
                                    <div className="recruiter-detail-view__stats">
                                        <div className="recruiter-detail-view__stat-card">
                                            <div className="recruiter-detail-view__stat-value">
                                                {post.candidates?.length || 0}
                                            </div>
                                            <div className="recruiter-detail-view__stat-label">
                                                Total Candidates
                                            </div>
                                        </div>
                                        <div className="recruiter-detail-view__stat-card">
                                            <div className="recruiter-detail-view__stat-value">
                                                {post.candidates?.filter(c => c.selected === true).length || 0}
                                            </div>
                                            <div className="recruiter-detail-view__stat-label">
                                                Selected
                                            </div>
                                        </div>
                                        <div className="recruiter-detail-view__stat-card">
                                            <div className="recruiter-detail-view__stat-value">
                                                {post.candidates?.filter(c => c.interviewed).length || 0}
                                            </div>
                                            <div className="recruiter-detail-view__stat-label">
                                                Interviewed
                                            </div>
                                        </div>
                                    </div>

                                    <div className="recruiter-detail-view__post-info">
                                        <div className="recruiter-detail-view__post-info-item">
                                            <div className="recruiter-detail-view__post-info-label">
                                                <EnvironmentOutlined /> Location
                                            </div>
                                            <div className="recruiter-detail-view__post-info-value">
                                                {post.companyLocation?.region}, {post.companyLocation?.country}
                                            </div>
                                        </div>
                                        <div className="recruiter-detail-view__post-info-item">
                                            <div className="recruiter-detail-view__post-info-label">
                                                <CalendarOutlined /> Posted
                                            </div>
                                            <div className="recruiter-detail-view__post-info-value">
                                                {formatTimestamp(post.datePosted)}
                                            </div>
                                        </div>
                                        <div className="recruiter-detail-view__post-info-item">
                                            <div className="recruiter-detail-view__post-info-label">
                                                <CalendarOutlined /> Deadline
                                            </div>
                                            <div className="recruiter-detail-view__post-info-value">
                                                {formatTimestamp(post.deadline)}
                                            </div>
                                        </div>
                                        <div className="recruiter-detail-view__post-info-item">
                                            <div className="recruiter-detail-view__post-info-label">
                                                <TeamOutlined /> Job Type
                                            </div>
                                            <div className="recruiter-detail-view__post-info-value">
                                                {post.jobType?.charAt(0).toUpperCase() + post.jobType?.slice(1) || 'N/A'}
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <Collapse>
                                        <Panel 
                                            header={
                                                <div className="recruiter-detail-view__candidates-header">
                                                    <div className="recruiter-detail-view__candidates-title">
                                                        <UserOutlined />
                                                        Candidates ({post.candidates?.length || 0})
                                                    </div>
                                                    <div className="recruiter-detail-view__candidates-summary">
                                                        <Tag color="success" icon={<CheckCircleOutlined />}>
                                                            {post.candidates?.filter(c => c.selected === true).length || 0}
                                                            <span> Selected</span>
                                                        </Tag>
                                                        <Tag color="processing" icon={<CheckCircleOutlined />}>
                                                            {post.candidates?.filter(c => c.interviewed).length || 0}
                                                            <span> Interviewed</span>
                                                        </Tag>
                                                        <Tag color="warning" icon={<ClockCircleOutlined />}>
                                                            {post.candidates?.filter(c => c.interviewDate && !c.interviewed).length || 0}
                                                            <span> Scheduled</span>
                                                        </Tag>
                                                    </div>
                                                </div>
                                            }
                                            key="candidates"
                                        >
                                            <List
                                                className="recruiter-detail-view__candidates-list"
                                                dataSource={post.candidates || []}
                                                renderItem={candidate => (
                                                    <List.Item className="recruiter-detail-view__candidate-item">
                                                        <div className="recruiter-detail-view__candidate-info">
                                                            <div className="recruiter-detail-view__candidate-name">
                                                                {candidate.name}
                                                            </div>
                                                            {getCandidateStatusTag(candidate)}
                                                        </div>
                                                    </List.Item>
                                                )}
                                            />
                                        </Panel>
                                    </Collapse>
                                </Card>
                            </List.Item>
                        )}
                    />
                )}
            </div>
            {renderPostModal()}
        </div>
    );
};

export default RecruiterDetailView; 