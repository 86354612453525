import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Awards.css";

class AwardsCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            awards: this.props.awards,
        };
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps !== this.props) {
            this.setState({
                awards: nextProps.awards,
            });
        }
    }

    formatDate(timestamp) {
        if (!timestamp) return 'N/A';
        if (timestamp.toDate) {
            return moment(timestamp.toDate()).format('MMM YYYY');
        }
        if (timestamp.seconds || timestamp._seconds) {
            const seconds = timestamp.seconds || timestamp._seconds;
            return moment.unix(seconds).format('MMM YYYY');
        }
        return moment(timestamp).format('MMM YYYY');
    }

    renderAwards() {
        const { awards } = this.state;

        return (
            <div className="awards-grid">
                {awards.map((award, index) => (
                    <div key={index} className="award-card">
                        <div className="award-header">
                            <div className="award-icon">
                                <FontAwesomeIcon icon="medal" />
                            </div>
                            <div className="award-info">
                                <h4>{award.title || 'Untitled Award'}</h4>
                                <span className="award-date">
                                    Awarded: {this.formatDate(award.dateAwarded)}
                                </span>
                            </div>
                        </div>

                        {award.issuer && (
                            <div className="award-issuer">
                                <FontAwesomeIcon icon="building" className="issuer-icon" />
                                <span>{award.issuer}</span>
                            </div>
                        )}

                        {award.description && (
                            <p className="award-description">
                                {award.description}
                            </p>
                        )}

                        {award.url && (
                            <a 
                                href={award.url} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="view-link"
                            >
                                <FontAwesomeIcon icon="external-link-alt" />
                                View Award
                            </a>
                        )}
                    </div>
                ))}
            </div>
        );
    }

    render() {
        return (
            <div className="awards-timeline">
                {this.renderAwards()}
            </div>
        );
    }
}

export default AwardsCard;