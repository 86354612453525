import React from 'react';
import { connect } from 'react-redux';
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Interviews.css';

//components import
import { Spinner } from "../../common/Spinner";
import InterviewsCard from "./cards/InterviewsCard";

class InterviewsBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedCandidate: this.props.selectedCandidate,
            interviews: this.props.interviews,
            myInterviews: this.props.myInterviews,
            interviewsLoading: this.props.interviewsLoading,
            selectedPost: this.props.selectedPost,
            profile: this.props.profile,
        }

        this.renderInterviews = this.renderInterviews.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                selectedCandidate: nextProps.selectedCandidate,
                interviews: nextProps.interviews,
                myInterviews: nextProps.myInterviews,
                interviewsLoading: nextProps.interviewsLoading,
                selectedPost: nextProps.selectedPost,
                profile: nextProps.profile,
            })
        }
    }

    onPerformInterview() {
        const { navigate } = this.props;
        navigate('/perform-interview');
    }

    checkForInterview() {
        const { selectedCandidate, selectedPost, myInterviews } = this.state;
        if (selectedPost && !(_.isEmpty(selectedCandidate))) {
            let interviews = [];
            if (myInterviews.length !== 0) {
                myInterviews.forEach(interview => {
                    if (!interview.infoBox.general) {
                        interviews.push(interview);
                    }
                });

                const status = interviews.some(interview => interview.infoBox.postID === selectedPost.postID);
                if (status) {
                    return (
                        <button 
                            onClick={() => this.onPerformInterview()}
                            className="perform-interview-button"
                        >
                            <FontAwesomeIcon icon="clipboard-check" />
                            Perform {selectedPost.postTitle} Interview Rating
                        </button>
                    );
                }
            }
        }
    }

    renderInterviews() {
        const { interviews, interviewsLoading, selectedCandidate } = this.state;

        if (interviewsLoading) {
            return <Spinner />
        }

        if (_.isEmpty(selectedCandidate)) {
            return <p style={{ color: "#000", margin: "2rem 0" }}>Select a candidate to view</p>;
        }

        // Initialize arrays for different interview types
        const externalInterviews = [];
        let generalInterview = null;

        // Sort interviews into their respective categories
        if (interviews && interviews.length > 0) {
            interviews.forEach(interview => {
                if (interview.infoBox.general) {
                    generalInterview = interview;
                } else {
                    externalInterviews.push(interview);
                }
            });
        }

        return (
            <div className="interviews-content">
                {/* General Interview Section - Always visible */}
                <div className="interview-section">
                    {generalInterview ? (
                        <InterviewsCard interview={generalInterview} general={true} />
                    ) : (
                        <div className="no-general-interview">
                            <FontAwesomeIcon icon="chart-line" className="no-data-icon" />
                            <p>No general interview assessment available</p>
                        </div>
                    )}
                </div>
                
                {/* Job-Specific Interviews Section */}
                {externalInterviews.length > 0 && (
                    <div className="interview-section">
                        <div className="section-divider">
                            <span>Job-Specific Assessments</span>
                        </div>
                        {externalInterviews.map((item, index) => (
                            <InterviewsCard key={index} interview={item} general={false} />
                        ))}
                    </div>
                )}
            </div>
        );
    }

    checkUser() {
        const { profile } = this.state;

        if(_.isEmpty(profile)) {
            return <h2 style={{ color: "#000" }}>Sign in to view</h2>;
        }

        if(profile.role !== "admin" && profile.role !== "superAdmin") {
            return <h3>Un-Authorized Access</h3>;
        }

        return (
            <div className="section-container">
                <div className="section-header">
                    <h4>
                        <FontAwesomeIcon icon="comments" className="section-icon" />
                        Interview Assessment System
                    </h4>
                    <div className="section-summary">
                        <span className="total-count">
                            {this.state.interviews?.length || 0} Assessments
                        </span>
                    </div>
                </div>
                {this.checkForInterview()}
                <div className="section-content">
                    {this.renderInterviews()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="interviews-container">
                {this.checkUser()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { selectedCandidate, selectedPost, interviews, myInterviews, interviewsLoading } = state.posts;
    const { profile } = state.profile;

    return {
        selectedCandidate,
        interviews,
        myInterviews,
        interviewsLoading,
        profile,
        selectedPost
    }
}

// Wrapper functional component to use hooks
function Interviews(props) {
    const navigate = useNavigate();
    return <InterviewsBase {...props} navigate={navigate} />;
}

export default connect(mapStateToProps, {})(Interviews);