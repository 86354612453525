import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { FaCalendarAlt, FaChevronRight } from 'react-icons/fa';
import './UpcomingInterviews.css';

class UpcomingInterviews extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            notifications: this.props.notifications,
            accountInfo: this.props.accountInfo,
            upcomingInterviews: this.props.upcomingInterviews,
            fetchingUpcomingInterviews: this.props.fetchingUpcomingInterviews,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState({
                notifications: nextProps.notifications,
                accountInfo: nextProps.accountInfo,
                upcomingInterviews: nextProps.upcomingInterviews,
                fetchingUpcomingInterviews: nextProps.fetchingUpcomingInterviews,
            });
        }
    }

    renderInterviews() {
        const { upcomingInterviews } = this.state;

        if (_.isEmpty(upcomingInterviews)) {
            return (
                <div className="interviews-empty">
                    <div className="empty-icon-wrapper">
                        <FaCalendarAlt size={32} className="calendar-icon" />
                    </div>
                    <h3>No Interviews Yet</h3>
                    <p>Your upcoming interviews will appear here</p>
                </div>
            );
        }

        const groupedInterviews = _.groupBy(upcomingInterviews, interview => {
            const seconds = interview.interviewDate.seconds || interview.interviewDate._seconds;
            return moment.unix(seconds).format('YYYY-MM-DD');
        });

        return (
            <div className="interviews-list">
                {Object.entries(groupedInterviews).map(([date, interviews]) => {
                    const momentDate = moment(date);
                    const isToday = momentDate.isSame(moment(), 'day');
                    const isTomorrow = momentDate.isSame(moment().add(1, 'day'), 'day');
                    
                    let dateLabel = momentDate.format('dddd, MMMM D');
                    if (isToday) dateLabel = 'Today';
                    if (isTomorrow) dateLabel = 'Tomorrow';

                    return (
                        <div key={date} className="interview-group">
                            <div className="date-header">
                                <span className="date-label">{dateLabel}</span>
                                <span className="interview-count">{interviews.length} interview{interviews.length !== 1 ? 's' : ''}</span>
                            </div>
                            {interviews.map(interview => (
                                <div key={interview.interviewID} className="interview-item">
                                    <div className="interview-item-icon">
                                        <FaCalendarAlt size={16} />
                                    </div>
                                    <div className="interview-item-content">
                                        <div className="interview-item-primary">
                                            {interview.name}
                                            {isToday && <span className="today-badge">Today</span>}
                                        </div>
                                        <div className="interview-item-secondary">
                                            <span className="job-title">{interview.jobPostName}</span>
                                            <span className="dot">•</span>
                                            <span className="time">{moment.unix(interview.interviewDate.seconds || interview.interviewDate._seconds).format('h:mm A')}</span>
                                        </div>
                                    </div>
                                    <FaChevronRight className="chevron-icon" size={12} />
                                </div>
                            ))}
                        </div>
                    );
                })}
            </div>
        );
    }

    render() {
        return (
            <div className="upcoming-interviews-container">
                <div className="upcoming-interviews-header">
                    <h2>Upcoming Interviews</h2>
                    <button className="view-all-button">View All</button>
                </div>
                {this.renderInterviews()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    notifications: state.notifications.notifications,
    accountInfo: state.settings.accountInfo,
    upcomingInterviews: state.posts.upcomingInterviews,
    fetchingUpcomingInterviews: state.posts.fetchingUpcomingInterviews
});

export default connect(mapStateToProps)(UpcomingInterviews);