import firebase from "firebase";
import {
    NOTIFICATION_CHANGED,
    FETCH_NOTIFICATIONS,
    FETCH_NOTIFICATIONS_FAILED,
    FETCH_NOTIFICATIONS_SUCCESSFUL,
    SAVE_NOTIFICATIONS,
    SAVE_NOTIFICATIONS_FAILED,
    SAVE_NOTIFICATIONS_SUCCESSFUL,
    DELETE_NOTIFICATION,
    DELETE_NOTIFICATION_SUCCESSFUL,
    DELETE_NOTIFICATION_FAILED,
    CLEAR_NOTIFICATIONS_MESSAGES,
} from "./Types";
import {message} from "antd";


//------------------------------------------------------------- NOTIFICATIONS ---------------------------------------------------------------

export const notificationFieldChanged = ({ prop, value}) => {
    return {
        type: NOTIFICATION_CHANGED,
        payload: { prop, value }
    }
};

export const adminNotification = ({ notificationTitle, description, notificationTime }) => {

    //create posts ID
    const notificationID = Math.random().toString(36).substring(6).toUpperCase();
    //convert notification time to date object
    const time = notificationTime.toDate();

    return(dispatch) => {

        dispatch({type: SAVE_NOTIFICATIONS});

        firebase.firestore().collection("adminNotifications").doc(notificationID)
            .set({ notificationID, title: notificationTitle, description, date: time })
            .then(() => {
                //
                dispatch({ type: SAVE_NOTIFICATIONS_SUCCESSFUL});
                message.success("Notification Sent");
                dispatch({type: CLEAR_NOTIFICATIONS_MESSAGES});

            }).catch((error) => {
            dispatch({ type: SAVE_NOTIFICATIONS_FAILED });
            message.warning("Notification Failed");
            console.log("notification creation failed");
            console.log(error);
            dispatch({type: CLEAR_NOTIFICATIONS_MESSAGES});
        });
    }

};

export const seekerNotification = ({notificationTitle, description, screen, pdfUrl }) => {
    return(dispatch) => {
        sendGeneralNotification({ notificationTitle, notificationMessage: description, screen, pdfUrl, dispatch });
    };
}

export const topicNotification = ({ notificationTitle, description, screen, url }) => {
    return(dispatch) => {
        dispatch({ type: SAVE_NOTIFICATIONS });

        //post downloadURL and file name to an object in database
        const date = new Date();
        const topicID = Math.random().toString(36).substring(6).toUpperCase();
        firebase.firestore().collection('topics').doc(topicID)
            .set({ topicID, date, title: notificationTitle, description, url, active: true }, { merge: true })
            .then(() => {
                sendGeneralNotification({
                    notificationTitle: `Topic: ${notificationTitle}`,
                    notificationMessage: description,
                    screen,
                    pdfUrl: url,
                    dispatch
                });
            }).catch((error) => {
            console.log(error);
            dispatch({ type: SAVE_NOTIFICATIONS_FAILED });
        });
    };
}

function sendGeneralNotification({ notificationTitle, notificationMessage, screen, pdfUrl, dispatch }) {

    const body = JSON.stringify({ notificationTitle, notificationMessage, screen, pdfUrl });

    dispatch({ type: SAVE_NOTIFICATIONS });
    fetch("https://us-central1-nafasiio.cloudfunctions.net/sendGeneralNotification",  {
        method: 'POST',
        body,
        mode: 'cors',
        headers: {'Content-Type': 'application/json'},
    }).then((response) => response.json())
        .then((response) => {
            console.log(response);
            console.log("success");

            dispatch({ type: SAVE_NOTIFICATIONS_SUCCESSFUL });

        }).catch(function(error) {
        // Handle Errors here.
        // const errorCode = error.code;
        // const errorMessage = error.message;
        // // ...
        // console.log(errorCode);
        console.log("notification compute error");
        console.log(error);
        dispatch({ type: SAVE_NOTIFICATIONS_FAILED });
    });
}



export  const fetchNotifications = ({ notificationFetchLimit }) => {
    //
    return async (dispatch) => {
        dispatch({ type: FETCH_NOTIFICATIONS });

        try {
            const userID = firebase.auth().currentUser.uid;

            // Fetching notifications from the 'notifications' collection
            const notificationsRef = firebase.firestore().collection('adminNotifications');
            const notificationsQuery = notificationsRef.orderBy('date', 'desc').limit(notificationFetchLimit);

            // Fetching notifications from the 'users/clients' collection
            const clientsRef = firebase.firestore().collection('users').doc('admins').collection(userID).doc('public');
            const clientNotificationsQuery = clientsRef.collection('notifications').orderBy('date', 'desc').limit(notificationFetchLimit);

            // Performing the queries and getting the results
            const [notificationsSnapshot, clientNotificationsSnapshot] = await Promise.all([
                notificationsQuery.get(),
                clientNotificationsQuery.get(),
            ]);

            // Extracting the data from the snapshots
            const notifications = notificationsSnapshot.docs.map((doc) => doc.data());
            const clientNotifications = clientNotificationsSnapshot.docs.map((doc) => doc.data());

            // Concatenating the results
            const allNotifications = [...notifications, ...clientNotifications];

            // Sorting the combined notifications by date in descending order
            const sortedNotifications = allNotifications.sort((a, b) => b.date - a.date);

            // Taking the first 20 notifications
            const fetchedNotifications = sortedNotifications.slice(0, 20);

            dispatch({
                type: FETCH_NOTIFICATIONS_SUCCESSFUL,
                payload: { fetchedNotifications, notificationFetchLimit }
            });

        } catch (e) {
            dispatch({ type: FETCH_NOTIFICATIONS_FAILED });
        }
    }
};

export const deleteNotification = ({ notificationID }) => {

    return(dispatch) => {
        dispatch({ type: DELETE_NOTIFICATION });

        firebase.firestore().collection("adminNotifications").doc(notificationID)
            .delete()
            .then(() => {
                dispatch({ type: DELETE_NOTIFICATION_SUCCESSFUL });
                console.log("notification is deleted");
                dispatch({type: CLEAR_NOTIFICATIONS_MESSAGES});

            }).catch(e => {
                dispatch({ type: DELETE_NOTIFICATION_FAILED });
                console.log(e);
                dispatch({type: CLEAR_NOTIFICATIONS_MESSAGES});
        })

    };
};